import styles from './ExitUnsavedChangesConfirmation.module.css';

const ExitUnsavedChangesConfirmation = ({exitCreateWindow, showDiscardChangesMessage, setShowDiscardChangesMessage}) => {

    return(
    <>
        {showDiscardChangesMessage &&<div className = {styles.container}>
            <div className = {styles.background}/>
            <div className = {styles.discardChangesContainer}>
                <p className= {styles.exitConfirmationButtonMessageTitle}>Are you sure?</p>
                <p className= {styles.exitConfirmationButtonMessage}>The changes you've made won't be saved</p>
                <span className = {styles.exitCofirmationButtonsContainer}>
                <button className = {styles.discardChanges} onClick = {exitCreateWindow}>Discard changes</button>
                <button className = {styles.keepEditing} onClick = {()=>setShowDiscardChangesMessage(false)}>Keep editing</button>
                </span>
            </div>
            </div>
        }
  </>)
}

export default ExitUnsavedChangesConfirmation;