import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './Mine.module.css';
import profilePicture from '../../../assets/images/ProfileSample.png';
import candidates from '../../../assets/images/Candidates.png';
import editIcon from '../../../assets/images/EditIcon.png'
import location from '../../../assets/images/location.png'
import loading from '../../../assets/images/loading.png'
import time from '../../../assets/images/time.png'
import shareIcon from '../../../assets/images/share.png'
import UserContext from '../../../Firebase Contexts/UserDataContext';
import {getListingApplicants, getUserProfilePicture, getUserById, getUserReviews,
  abandonTaskViaVendor, getListingById, markTaskCompletedViaClient} from '../../../Firebase'
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';

const Mine = ({ listing, index, deleteListing }) => {

  const isMobile = window.targetPlatform == "mobile";
  const {user} = useContext(UserContext);
  const {mineListings, setMineListings, historyListings, setHistoryListings, selectedMineTask, setSelectedMineTask} = useContext(ListingsContext);
  
  const {setEditListingData, setCandidatesData, setSharedListingData, setAbandonTaskData, profilePicturesId, setProfilePicturesId,
    rooms, setRooms, setCurrentRoomIndex, setThereIsSelectedDiscussion, setReviewData} = useContext(UIContext);

  //Applicant data
  const [applicantProfilePicture, setApplicantProfilePicture] = useState((listing.applicantProfilePicture!=null)?listing.applicantProfilePicture: profilePicture);

  const [listingInfo, setListingInfo] = useState({
    title: listing.title,
    description: listing.description,
    locationRef: listing.location,
    type: listing.type,
    dateRef: listing.date,
    timeRef: listing.time,
    duration: listing.duration,
    price: listing.price,
    currency: listing.currency,
    selectedApplicant: (listing.selectedApplicant)? listing.selectedApplicant:null,
    roomId: listing.roomId,
  })
  const parentRef = useRef(null);

  useEffect(() => {
    setListingInfo({
      id: listing.id,
      title: listing.title,
      description: listing.description,
      locationRef: listing.location,
      type: listing.type,
      dateRef: listing.date,
      timeRef: listing.time,
      duration: listing.duration,
      price: listing.price,
      selectedApplicant: (listing.selectedApplicant)? listing.selectedApplicant:null,
      roomId: listing.roomId,
    });
    //if a selected applicant exists, fetch their profile picture
    if(listing.selectedApplicant && listing.applicantProfilePicture==null){
      getApplicantProfilePicture();
    }
  }, [listing]);
  

  const getCurrency = (isoCode) => {
    const currencyMap = {
      "usd": "$",
      "cad": "$",
      "gbp": "£",
      "eur": "€",
      "aud": "$",
      "nzd": "$",
      "dkk": "kr",
      "sek": "kr",
      "nok": "kr",
      "zar": "R",
    };
    return currencyMap[isoCode] || null;
  };
  
  const currency = useRef(getCurrency(listingInfo.currency));

  const getApplicantProfilePicture = async () => {
    //Save client profile picture
    const newApplicantProfilePicture = await getUserProfilePicture(listing.selectedApplicant);
    
    if(newApplicantProfilePicture==null){return}
    // Create a new version of assignedTasks with the updated client
    const updatedMineListingsTasks = mineListings.map((currentListing) => {
      if (currentListing.id === listing.id) {
        // Create a new applicant object with the updated profile picture
        return { ...currentListing, applicantProfilePicture: newApplicantProfilePicture};
      }
      return currentListing; // Return the original client for others
    });
    
    // Update the entire assignedTasks array with the updated version
    setMineListings(updatedMineListingsTasks);
    // Update the clientProfilePicture state with the new profile picture
    setApplicantProfilePicture(newApplicantProfilePicture);
  }

  //#region Edit
  const getCurrentTime = (withAMorPM) => {
    const selectedTime = listingInfo.timeRef;
  
    // Parse hours and minutes
    const [hours, minutes] = selectedTime.split(":").map(Number);
  
    // Format hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    // Set AM/PM
    const getAmOrPm = hours < 12 ? "AM" : "PM";
  
    // Combine formatted hours and minutes with AM/PM if necessary
    const formattedTime = withAMorPM
      ? `${formattedHours}:${formattedMinutes} ${getAmOrPm}`
      : `${formattedHours}:${formattedMinutes}`;
  
    return formattedTime;
  };

  const getDate = ()=>{
    const seconds = listingInfo.dateRef.seconds;
    const nanoseconds = listingInfo.dateRef.nanoseconds;
  
    // Convert nanoseconds to milliseconds (divide by 1,000,000)
    const milliseconds = nanoseconds / 1000000;
  
    // Create a JavaScript Date object
    const date = new Date(seconds * 1000 + milliseconds);
    const localizedDateString = date.toLocaleDateString();
    
    return localizedDateString;
  }

  const isEdit = true;

  const HandleEdit = ()=>{
    if(!window.creationForm){
      setEditListingData({
        listing: listing,
        editTitle: listingInfo.title,
        editDescription: listingInfo.description,
        editLocation: listingInfo.locationRef,
        editType: listingInfo.type,
        editDate: listingInfo.dateRef,
        editTime: listingInfo.timeRef,
        editDuration: listingInfo.duration,
        editPrice: listingInfo.price,
        isEdit : isEdit,

      //Pass listing deletion data
        index: index,
        deleteListing: deleteListing
      })

      window.setEditForm(true);
      window.shouldScroll(false);
      }
    }
  //#endregion

//#region scroll to top on closing listing
  const scrollToParent = () => {
    const parentElement = parentRef.current;
    const scrollOffset = -100; // Adjust this value to scroll higher than the parent
  
    const parentRect = parentElement.getBoundingClientRect();
    const parentTop = parentRect.top + window.scrollY;
    const scrollPosition = parentTop + scrollOffset;
    const currentScrollPosition = window.scrollY;
  
    if (currentScrollPosition > scrollPosition) {
      setTimeout(()=>{
        const scrollOptions = {
          behavior: 'smooth',
          block: 'start', // Scroll to the top of the parent element
        };
        parentElement.scrollIntoView(scrollOptions);
      })
    }
  };
  //#endregion

//#region Description animation handling

const descriptionContainerRef = useRef(null);

const descriptionIsExpanded = useRef(false);


const handleDescriptionExpansion = ()=>{
  let isExpanded = descriptionIsExpanded.current;
  if(!isExpanded){
    descriptionContainerRef.current.classList.remove(styles.initialDescriptionHeight);
    descriptionContainerRef.current.classList.remove(styles.descriptionCollapsed);
    descriptionContainerRef.current.classList.add(styles.descriptionExpanded);
  }else if(isExpanded){
    scrollToParent();
    descriptionContainerRef.current.classList.remove(styles.descriptionExpanded);
    descriptionContainerRef.current.classList.add(styles.descriptionCollapsed);
  }
  descriptionIsExpanded.current = !isExpanded;
};
//#endregion


//#region Candidates

const openCandidates = async () => {
  const applicantIds = await getListingApplicants(listing.id);

  setCandidatesData({
    usersIds: applicantIds,
    listingId: listing.id,
    listingTitle: listingInfo.title
  })

  window.setOpenCandidates(true);
}
//#endregion

const fetchUserReviews = async()=>{
  const userReviews = await getUserReviews(listing.selectedApplicant);

  return userReviews;
 }

const showUser = async()=>{
  const showAbandonOption = true;
  const isVendor = false;
  const user = await getUserById(listing.selectedApplicant)
  window.showProfile(user.profilePicture, user.name, user.surname, user.stars, user.topTasks, await fetchUserReviews(), null, showAbandonOption, isVendor);

}

const handleShare = ()=>{
  setSharedListingData({
    listingId: listing.id
  })
  window.setIsCopied(true);
}

const openMessages = ()=>{
  if(!isMobile){
    //window.openMessages is located in MessagingSystem.js
    window.openMessages();
  }else{
    //window.openMessages is located in AppButtons.js
    window.mobileCheckIfButtonShouldExecute("Messages")
  }
}

const abandonTask = async () => {
  const taskAbandonedViaVendor = false;

  const currentListing = mineListings.findIndex(item => item.id === selectedMineTask);
  const result = await abandonTaskViaVendor(mineListings[currentListing].uid, user.name, user.profilePhoto, mineListings[currentListing].id, mineListings[currentListing].selectedApplicant, mineListings[currentListing].title, mineListings[currentListing].roomId, taskAbandonedViaVendor);

  if(result){
    setSelectedMineTask(null);
    setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== mineListings[currentListing].roomId));
    setRooms((prev) => prev.filter(item => item.roomId !== mineListings[currentListing].roomId));
    setCurrentRoomIndex("");
    setThereIsSelectedDiscussion(false);
    
    setMineListings((prevListingInfo) => {
      const updatedListings = [...prevListingInfo]; // Copy the existing state
      for (let i = 0; i < updatedListings.length; i++) {
        if (updatedListings[i].id === mineListings[currentListing].id) {
          updatedListings[i] = {
            ...updatedListings[i],
            selectedApplicant: null,
            roomId: null,
          };
          break; // Exit the loop after updating the matching listing
        }
      }
      return updatedListings;
    });

    disableAbandonWindow();
  }
}

const disableAbandonWindow = ()=>{
  window.setOpenAbandonTask(false);
  window.exitOtherProfiles();
}

const openAbandonWindow = async ()=>{
  setAbandonTaskData({
    disableAbandonWindow: disableAbandonWindow,
    abandonTask: abandonTask,
    taskId: listing.id,
  });

  window.setOpenAbandonTask(true);
}

window.openAbandonWindowViaClient = openAbandonWindow;

const isConfirmationButtonLocked = useRef(false);
const [loadingIconIsEnabled, setLoadingIconIsEnabled] = useState(false);
const [confirmCompletionEnabled, setConfirmCompletionEnabled] = useState(false);
const isMyListing = useRef(true);

const completeTask = async() =>{
  if(!isConfirmationButtonLocked.current){
    setConfirmCompletionEnabled(false);
    setLoadingIconIsEnabled(true);

    isConfirmationButtonLocked.current = true;
    //#region get listing from mine section if it exists, else get it from firebase before it gets deleted
    let currentListing = mineListings.find((mineListing) => mineListing.id == listing && mineListing.roomId);
    if(!currentListing){
      currentListing = await getListingById(listingInfo.id);
    }
    //#endregion
    let notificationToDelete = null;
    const historyTaskData = await markTaskCompletedViaClient(listingInfo.id, user.id, notificationToDelete, "confirmationRequestTask", listing.roomId, listingInfo.selectedApplicant,
    user.profilePhoto, user.name, currentListing.title, currentListing.roomId);
    
    if(historyTaskData){
      //#region handle message
      setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== listingInfo.roomId));
      setRooms((prev) => prev.filter(item => item.roomId !== listingInfo.roomId));
      setCurrentRoomIndex("");
      setThereIsSelectedDiscussion(false);
      //#endregion
      
      setMineListings((prevMineListings) => {
        //Find the current listing
        const updatedMineListing = prevMineListings.filter((mineListing) => {
          return mineListing.id !== listingInfo.id;
        });
        // Update the state with the filtered array
        return updatedMineListing;
      });

      setHistoryListings([...historyListings, historyTaskData]);
    
      const reviewedListing = {
        clientId: currentListing.uid,
        vendorId: currentListing.selectedApplicant,
        historyListingId: historyTaskData.historyTaskId,
        type: currentListing.type,
        date: currentListing.date,
      };

      setReviewData({
        listing: reviewedListing,
        user: user,
        isMine: isMyListing,
        reviewAfterConfirmation: true,
      })

      window.setOpenReviewForm(true);
    }else{
      isConfirmationButtonLocked.current = false;
    }
    setLoadingIconIsEnabled(false);
  }
}

return (
    <>
      <div ref={parentRef} className={`${(isMobile)? styles.mobileTaskBody: styles.desktopTaskBody} ${styles.taskBodyInitial}`}>
        <div className = {styles.completionContainer}>
        {<div onClick = {handleDescriptionExpansion} className = {styles.expansionButton}/>}
          {loadingIconIsEnabled && <img src = {loading} className = {styles.loadingIcon} alt = "Loading"/>}
          {listingInfo.selectedApplicant!=null && <div className = {styles.completeButton}>
            {!loadingIconIsEnabled && !confirmCompletionEnabled && <p className = {styles.completionText} onClick = {()=>setConfirmCompletionEnabled(true)}>Mark as Complete</p>}
            {confirmCompletionEnabled && <p className = {styles.completionText} style = {{color: "rgb(50, 85, 160)", fontWeight:"600", marginRight: "1em"}} onClick = {()=>completeTask()}>Confirm</p>}
            {confirmCompletionEnabled && <p className = {styles.completionText} style = {{color: "red", fontWeight:"600"}} onClick = {()=>setConfirmCompletionEnabled(false)}>cancel</p>}
          </div>}
        </div>
        <div>
          <div className={styles.header}>
            <img src={(user.profilePhoto != null)?user.profilePhoto:profilePicture} alt='User Profile Picture' className={styles.profilePicture} />
            <h1 className={styles.title}>{listingInfo.title}</h1>
          </div>
          {!listingInfo.selectedApplicant && <img src = {shareIcon} alt = 'Share' className={styles.shareIcon} onClick = {handleShare}/>}

            <span className={styles.locationContainer}>
              <img src = {location} className = {styles.filterIcon} alt = "Location"/>
            <p className = {styles.detailText} style = {{fontSize: "1.05em", fontWeight:"650"}}>{listingInfo.locationRef.description.split(',')[0].trim()}</p>
            </span>
          <div className={styles.details}>
            <span className={styles.detailContainer}>
            <img src = {time} className = {styles.filterIcon} alt = "Clock"/>
            <p className={styles.detailText}>{(listing.duration.hours != 0) ? ((listing.duration.hours < 2) ? 1 + " hour" : listing.duration.hours + " hours") : ""} {(listingInfo.duration.minutes != 0) ? ((listingInfo.duration.minutes < 2) ? 1 + " minute" : listingInfo.duration.minutes + " minutes") : ""}</p>
            </span>
            <span className={styles.detailContainer}>
            <p className={styles.detailText}>{listingInfo.type}</p>
            </span>
            <span className={styles.detailContainer}>
            <p className={styles.detailText}>{(currency.current == "$")? currency.current + listing.price : listing.price + currency.current}</p>
            </span>
          </div>
          {!listingInfo.selectedApplicant && <div className = {styles.EditShareContainer}>
          <img src={editIcon} alt='Edit' className={styles.editIcon} onClick={HandleEdit}/>
          </div>}
            <div className = {styles.dateBookmarkContainer}>
              <p className={styles.desiredDate}>{getDate()} {getCurrentTime(true)}</p>
              {/* if applicant is not selected, show candidates button */}
              {!listingInfo.selectedApplicant &&<div className = {styles.applicantsContainer} onClick={()=>openCandidates()}
>
                <img
                  src={candidates}
                  alt='Applicants'
                  className={styles.candidatesIcon}
                />
                <p className={styles.applicantsText}>Applicants</p>

              </div>}

              {listingInfo.selectedApplicant &&
              <div className = {styles.vendorContainer}>
                <p className = {styles.contactVendorText} onClick = {()=>openMessages()}>Message</p>
                <img
                  src={applicantProfilePicture}
                  alt='Applicant Profile Picture'
                  className={styles.applicantIcon}
                  onClick = {()=>{setSelectedMineTask(listingInfo.id); showUser()}}
                />
              </div>}

            </div>
            
          <div className={`${styles.descriptionContainer} ${styles.initialDescriptionHeight}`}
          onClick={handleDescriptionExpansion}
          ref = {descriptionContainerRef}>
            <div className = {styles.descriptionLine}/>
            {listingInfo.description}
          </div>
          <div className={styles.viewDescription}>
          </div>
        </div>
    </div>

  </>
  );
};

export default Mine;
