import React, {useRef, useState, useContext} from 'react';
import styles from './ConnectWindow.module.css';
import ListingsContext from "../../../../Firebase Contexts/Listings Context/ListingsContext"
import {connectApplicantToMineList} from '../../../../Firebase'
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import UIContext from '../../../../Firebase Contexts/UIContext/UIContext';

const ConnectWindow = ({checkIfConnectionWindowIsEnabled, setConnectPressed, candidateName,
  listingId, candidateId, exitOtherProfiles, candidatesShouldBeAbleToClose,
  handleApplicantConfirmation, exitCandidates, exitCandidatesMobile, listingTitle, candidateProfilePhoto})=>{

    const isMobile = window.targetPlatform == "mobile";

    const {mineListings, setMineListings} = useContext(ListingsContext);
    const {user} = useContext(UserContext);
    const {roomsAlreadyFetched, getProfilePhotos, unreadMessages} = useContext(UIContext);
    
    const confirmationButtonRef = useRef(null);
    const confirmationButtonPressed = useRef(false);

    const handleCancelButton = ()=>{
      //If confirmation button is pressed
      if(!confirmationButtonPressed.current){
        setConnectPressed(false);
        checkIfConnectionWindowIsEnabled(false);
        }
    }

    const handleConfirmation = async()=>{
      if(!confirmationButtonPressed.current){
        confirmationButtonPressed.current = true;
        confirmationButtonRef.current.classList.remove(styles.confirmationButtonEnabled);
        confirmationButtonRef.current.classList.add(styles.confirmationButtonDisabled);
        const result = await connectApplicantToMineList(listingId, candidateId, user.profilePhoto, listingTitle, user.id, candidateProfilePhoto);
        if(result.result!=false){
          roomsAlreadyFetched.current = false;
          getProfilePhotos(unreadMessages);
          roomsAlreadyFetched.current = false;
          setMineListings((prevListingInfo) => {
              const updatedListings = [...prevListingInfo]; // Copy the existing state
              //search for the listingId parameter that matches the id of the mine listings in context
              for (let i = 0; i < updatedListings.length; i++) {
                if (updatedListings[i].id === listingId) {
                  updatedListings[i] = {
                    ...updatedListings[i],
                    selectedApplicant: candidateId,
                    roomId: result.roomId,
                  };
                  break; // Exit the loop after updating the matching listing
                }
              }
              return updatedListings;
            });
            handleApplicantConfirmation();
            exitOtherProfiles();
            if(isMobile){
              exitCandidatesMobile();
            }else{
              exitCandidates();
            }
          checkIfConnectionWindowIsEnabled(false);
        }else{
          //if connection failed
          confirmationButtonPressed.current = false;
          confirmationButtonRef.current.classList.remove(styles.confirmationButtonDisabled);
          confirmationButtonRef.current.classList.add(styles.confirmationButtonEnabled);
        }
        setConnectPressed(false);
      }
  }

    return(
        <>
        <div className={styles.container}>
                <span className = {styles.connectConfirmationButtons}>
                  <button className = {styles.connectConfirmationButton} onClick = {handleConfirmation} ref = {confirmationButtonRef}>Confirm</button>
                  <button className = {styles.notNowButton} onClick = {handleCancelButton}>Not now</button>
                </span>
        </div>
        </>
    )
}

export default ConnectWindow;