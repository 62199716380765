import {useState, useRef, useContext, useEffect} from 'react';
import styles from './ReportUser.module.css';
import { reportUser } from '../../../../Firebase';
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';

const ReportUser = ({listing, user, isMine})=>{

  const isMobile = window.targetPlatform == "mobile";

  const {historyListings, setHistoryListings} = useContext(ListingsContext);

  const [description, setDescription] = useState("");

  const [step, setStep] = useState(1);

  const questionsTextRef = useRef(null);
  const ratingTextRef = useRef(null);
  const commentsTextRef = useRef(null);

  const nextStepButtonRef = useRef(null);

  const desktopContainerRef = useRef(null);

  const mobileContainerRef = useRef(null);
  const backgroundRef = useRef(null);

  const reasonOfReport = useRef([""]);



  const [cancelReview, setCancelReview] = useState(false);

  const handleDescription = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
  }

  const handleReviewNextStep = ()=> {

    if(step==1 && reasonOfReport.current[0]!=""){
        setStep(2);
        questionsTextRef.current.classList.remove(styles.processTextSelected);
        questionsTextRef.current.classList.add(styles.processTextUnselected);

        ratingTextRef.current.classList.remove(styles.processTextUnselected);
        ratingTextRef.current.classList.add(styles.processTextSelected);

    }else if(step==2){
        setStep(3);
        ratingTextRef.current.classList.remove(styles.processTextSelected);
        ratingTextRef.current.classList.add(styles.processTextUnselected);
        
        commentsTextRef.current.classList.remove(styles.processTextUnselected);
        commentsTextRef.current.classList.add(styles.processTextSelected);

        nextStepButtonRef.current.innerText = "Submit Report"
    }else if(step==3){
        setStep(1);
        window.setOpenReportForm(false);
        SubmitReport();
    }
  }

  const SubmitReport = async() => {
    
    updateReportAvailability(true);

    //if the current user is the client, set them as the reviewer, or vice versa for vendor
    let accuser;
    let accused;

    if(user.id==listing.clientId){
        accuser = listing.clientId;
        accused = listing.vendorId;
    }else if(user.id == listing.vendorId){
        accuser = listing.vendorId;
        accused = listing.clientId;
    }

    await reportUser(listing.clientId, listing.vendorId, accuser, accused, reasonOfReport.current, description, listing.historyListingId);

    window.shouldScroll(true);
  }

  const updateReportAvailability = (flag)=>{
    setHistoryListings((prevHistoryListings) => {
        return prevHistoryListings.map((item) => {
          if (item.historyListingId === listing.historyListingId) {
            // Update the reviewSubmitted property for the matching item
            if (isMine.current) {
                return { ...item, clientReportSubmitted: flag };
              } else {
                return { ...item, vendorReportSubmitted: flag };
              }
            }
          return item; // Preserve other items as they are
        });
      });
  }

    const handlePreviousStep = ()=>{
    if(step==3){
        setStep(2);
        nextStepButtonRef.current.classList.remove(styles.submitReviewButton);
        nextStepButtonRef.current.innerText = "Next";

        commentsTextRef.current.classList.remove(styles.processTextSelected);
        commentsTextRef.current.classList.add(styles.processTextUnselected);

        ratingTextRef.current.classList.remove(styles.processTextUnselected);
        ratingTextRef.current.classList.add(styles.processTextSelected);
    }else if(step==2){
        setStep(1);
        ratingTextRef.current.classList.remove(styles.processTextSelected);
        ratingTextRef.current.classList.add(styles.processTextUnselected);
        
        questionsTextRef.current.classList.remove(styles.processTextUnselected);
        questionsTextRef.current.classList.add(styles.processTextSelected);
    }
    }

    const handleNextButton = ()=>{
            handleReviewNextStep()
    }

    const handlePreviousButton = ()=>{
        if(step == 2){
            setStep(1);
            
            nextStepButtonRef.current.classList.remove(styles.disabledNextButton);
            nextStepButtonRef.current.classList.add(styles.nextButton);
            
            ratingTextRef.current.classList.remove(styles.processTextSelected);
            ratingTextRef.current.classList.add(styles.processTextUnselected);
            
            questionsTextRef.current.classList.remove(styles.processTextUnselected);
            questionsTextRef.current.classList.add(styles.processTextSelected);
        }else {
            handlePreviousStep();
        }
    }

    const exitReview = ()=>{
        if(isMobile){
            mobileContainerRef.current.classList.remove(styles.slideUp);
            mobileContainerRef.current.classList.add(styles.slideDown);
        }else {
            desktopContainerRef.current.classList.add(styles.disabled);
        }
        backgroundRef.current.classList.remove(styles.fadeIn);
        backgroundRef.current.classList.add(styles.fadeOut);
        window.shouldScroll(true);
    }

    const handleAnimationEnd = (event)=>{
        if(event.animationName.includes("fadeOut")){
            // console.log("exited")
            window.setOpenReportForm(false);
        }
    }

    //#region Question selection
    const choices = useRef([0]);
    
    const [shouldReRender, setShouldReRender] = useState(false);
    
    const questionMappings = [
      { questionNumber: 1, choiceIndex: 0, values: [1, "Never showed up"] },
      { questionNumber: 2, choiceIndex: 0, values: [2, "Didn't do the task"] },
      { questionNumber: 3, choiceIndex: 0, values: [3, "Didn't do a good job"] },
    ];
    
    const handleQuestionSelection = (questionNumber) => {
      const mapping = questionMappings.find((map) => map.questionNumber === questionNumber);
      if (mapping) {
        choices.current[mapping.choiceIndex] = choices.current[mapping.choiceIndex] === mapping.values[0] ? 0 : mapping.values[0];
        reasonOfReport.current[mapping.choiceIndex] = choices.current[mapping.choiceIndex] === mapping.values[0] ? mapping.values[1]:"";
        if(reasonOfReport.current[0]=="" && nextStepButtonRef.current.classList.contains(styles.nextButton)){
            nextStepButtonRef.current.classList.remove(styles.nextButton);
            nextStepButtonRef.current.classList.add(styles.disabledNextButton);
        }else if(reasonOfReport.current[0]!=="" && nextStepButtonRef.current.classList.contains(styles.disabledNextButton)){
            nextStepButtonRef.current.classList.remove(styles.disabledNextButton);
            nextStepButtonRef.current.classList.add(styles.nextButton);
        }
        setShouldReRender(!shouldReRender);
      }
    };

    // const 

    //#endregion


  const desktopVersion = ()=>{

    return(
        <>
        <div className = {`${styles.background} ${styles.fadeIn}`} onClick = {()=>setCancelReview(true)} ref = {backgroundRef} onAnimationEnd={handleAnimationEnd} />
        <div className = {styles.reviewForm} ref = {desktopContainerRef}>
            <div className = {styles.processTextContainer}>

                <p ref = {questionsTextRef} className = {styles.processTextSelected}>Reason</p>
                <p ref = {ratingTextRef} className = {styles.processTextUnselected}>Comments</p>
                <p ref = {commentsTextRef} className = {styles.processTextUnselected}>Submit</p>
            </div>

            {step == 1 && !cancelReview && 
                <div>
                    <p className = {styles.questionTitle}>What would you like to report?</p>
                    <div className = {styles.choicesContainer}>
                        <span className = {(choices.current[0]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(1)}><p className = {(choices.current[0]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Never showed up</p></span>
                        <span className = {(choices.current[0]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(2)}><p className = {(choices.current[0]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Didn't do the task</p></span>
                        <span className = {(choices.current[0]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(3)}><p className = {(choices.current[0]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Didn't do a good job</p></span>
                    </div>
                </div>
            }

            {step == 2 && !cancelReview &&
                <div>
                <p className = {styles.questionTitle}>What exactly happened?</p>

                <textarea
                    onChange={handleDescription}
                    value={description}
                    className={styles.commentInput}
                    placeholder="Share your experience..."
                    maxLength={500}
                />
            </div>
            }
            
            {!cancelReview &&
            <div>
                {step == 3 && <p className = {styles.submissionText}>Ready to submit?</p>}

                <button
                ref = {nextStepButtonRef}
                className = {step==3?`${styles.nextButton} ${styles.submitReviewButton}`:styles.nextButton}
                onClick = {handleNextButton}
                >{step==3?"Submit Report":"Next"}
                </button>
            </div>}
            
            {(step == 2 || step == 3 )&& !cancelReview && <button
            className = {styles.previousButton}
            onClick = {handlePreviousButton}
            >Previous
            </button>}

            {cancelReview && 
            <div className = {styles.cancelReviewContainer}>
                <p className = {styles.continueButton} onClick = {()=>{setCancelReview(false)}}>Continue</p>
                <p className = {styles.cancelButton} onClick = {exitReview}>Exit</p>
            </div>
            }
        </div>
        </>
    )
  }
  const mobileVersion = ()=>{
    return(
        <>
        <div className = {`${styles.background} ${styles.fadeIn}`} onClick = {()=>setCancelReview(true)} ref = {backgroundRef} onAnimationEnd = {handleAnimationEnd}/>
        <div className = {`${styles.mobileContainer} ${styles.slideUp}`} ref = {mobileContainerRef}>

            <div className = {styles.processTextContainer}>
                <p ref = {questionsTextRef} className = {styles.processTextSelected}>Reason</p>
                <p ref = {ratingTextRef} className = {styles.processTextUnselected}>Comments</p>
                <p ref = {commentsTextRef} className = {styles.processTextUnselected}>Submit</p>
            </div>

            {step == 1 && !cancelReview && 
                <div>
                    <p className = {styles.questionTitle}>What would you like to report?</p>
                    <div className = {styles.choicesContainer}>
                        <span className = {(choices.current[0]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(1)}><p className = {(choices.current[0]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Never showed up</p></span>
                        <span className = {(choices.current[0]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(2)}><p className = {(choices.current[0]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Didn't do the task</p></span>
                        <span className = {(choices.current[0]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(3)}><p className = {(choices.current[0]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Didn't do a good job</p></span>
                    </div>
                </div>
            }

            {step == 2 && !cancelReview &&
                <div className={styles.commentContainer}>
                    <p className = {styles.questionTitle}>What exactly happened?</p>

                    <textarea
                        onChange={handleDescription}
                        value={description}
                        className={styles.commentInput}
                        placeholder="Share your experience..."
                        maxLength={500}
                    />
            </div>
            }
            
            {!cancelReview && 
            <div>
                {step == 3 && <p className = {styles.submissionText}>Ready to submit?</p>}

                <button
                ref = {nextStepButtonRef}
                className = {step==3?`${styles.nextButton} ${styles.submitReviewButton}`:styles.nextButton}
                onClick = {handleNextButton}
                >{step==3?"Submit Report":"Next"}
                </button>
            </div>
                }

            {(step == 2 || step == 3 ) && !cancelReview &&<button
            className = {styles.previousButton}
            onClick = {handlePreviousButton}
            >Previous
            </button>}

            {cancelReview && 
            <div className = {styles.mobileCancelReviewContainer}>
                <p className = {styles.mobileContinueButton} onClick = {()=>{setCancelReview(false)}}>Continue</p>
                <p className = {styles.mobileCancelButton} onClick = {exitReview}>Exit</p>
            </div>
            }
        </div>
        </>
    )
  }

    return(
        <>
        {isMobile? mobileVersion() : desktopVersion()}
        </>
    )
}

export default ReportUser;