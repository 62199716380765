import styles from './History.module.css';
import reportIMG from '../../../assets/images/report.png';
import React, { useState, useRef, useEffect, useContext } from 'react';
import cancelIcon from '../../../assets/images/CancelIcon.png';
import sampleProfile from '../../../assets/images/ProfileSample.png';
import time from '../../../assets/images/time.png';
import location from '../../../assets/images/location.png';
import taskCompleted from '../../../assets/images/CompletedIcon.png';
import taskDeleted from '../../../assets/images/xFilter.png';
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';

const History = ({ listing, user}) => {

const isMobile = window.targetPlatform == "mobile";

const {setReviewData} = useContext(UIContext);

const {historyListings, setHistoryListings} = useContext(ListingsContext);


const getCurrency = (isoCode) => {
  const currencyMap = {
    "usd": "$",
    "cad": "$",
    "gbp": "£",
    "eur": "€",
    "aud": "$",
    "nzd": "$",
    "dkk": "kr",
    "sek": "kr",
    "nok": "kr",
    "zar": "R",
  };
  return currencyMap[isoCode] || null;
};

const currency = useRef(getCurrency(listing.currency));

//#region Time and date
const getCurrentTime = (withAMorPM) => {
  const selectedTime = listing.time;

  // Parse hours and minutes
  const [hours, minutes] = selectedTime.split(":").map(Number);

  // Format hours and minutes with leading zeros
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Set AM/PM
  const getAmOrPm = hours < 12 ? "AM" : "PM";

  // Combine formatted hours and minutes with AM/PM if necessary
  const formattedTime = withAMorPM
    ? `${formattedHours}:${formattedMinutes} ${getAmOrPm}`
    : `${formattedHours}:${formattedMinutes}`;

  return formattedTime;
};

const getDate = ()=>{
  const seconds = listing.date.seconds;
  const nanoseconds = listing.date.nanoseconds;

  // Convert nanoseconds to milliseconds (divide by 1,000,000)
  const milliseconds = nanoseconds / 1000000;

  // Create a JavaScript Date object
  const date = new Date(seconds * 1000 + milliseconds);
  const localizedDateString = date.toLocaleDateString();
  
  return localizedDateString;
}
//#endregion
  
const result = "completed";// canceled

  const isMine = useRef((user.id == listing.clientId));

  const handleResult = () => {
    if (result === "completed") {
      return <span style={{ color: "green" }}>{result}</span>;
    } else if (result === "canceled") {
      return <span style={{ color: "red" }}>{result}</span>;
    } else if (result === "deleted") {
      return <span style={{ color: "black" }}>{result}</span>;
    }
    return null; // or return a default value if none of the conditions match
  };
  
  const handleImageResult = () => {
    if (result === "completed") {
      return taskCompleted;
    } else if (result === "canceled") {
      return cancelIcon;
    } else if (result === "deleted") {
      return taskDeleted;
    }
    return null; // or return a default value if none of the conditions match
  };
  
  //#region Review

  const handleRateUser = () => {
    setReviewData({
      listing: listing,
      user: user,
      isMine: isMine
    })
    window.setOpenReviewForm(true);
    window.shouldScroll(false);
  }
  //#endregion
  
  //#region Report

  const handleReportUser = () => {
    setReviewData({
      listing: listing,
      user: user,
      isMine: isMine
    })
    window.setOpenReportForm(true);
    window.shouldScroll(false);
  }
  //#endregion

  return (
    <>
      <div className = {(isMobile)? styles.mobileTaskBody: styles.desktopTaskBody}>
        <div>
            {/*----------Header----------*/}
          <div className={styles.header}>
            <h1 className={styles.title}>{listing.title}</h1>
          </div>
            {/*----------Details----------*/}
            <span className={styles.locationContainer}>
              <img src = {location} className = {styles.filterIcon} alt = "Location"/>
            <p className = {styles.detailText} style = {{fontSize: "1.05em", fontWeight:"650"}}>{listing.location.description.split(',')[0].trim()}</p>
            </span>
          <div className={styles.details}>
            <span className={styles.detailContainer}>
              <img src = {time} className = {styles.filterIcon} alt = "Clock"/>
              <p className={styles.detailText}>{(listing.duration.hours != 0) ? ((listing.duration.hours < 2) ? 1 + " hour" : listing.duration.hours + " hours") : ""} {(listing.duration.minutes != 0) ? ((listing.duration.minutes < 2) ? 1 + " minute" : listing.duration.minutes + " minutes") : ""}</p>
            </span>
            <span className={styles.detailContainer}>
              <p className={styles.detailText}>{listing.type}</p>
            </span>
            <span className={styles.detailContainer}>
              <p className={styles.detailText}>{(currency.current == "$")? currency.current + listing.price : listing.price + currency.current}</p>
            </span>
          </div>
          {/*----------Result----------*/}
          <div className = {styles.resultContainer}>
            {/* <img src={handleImageResult()} className = {styles.resultIcon}/>
            <p className = {styles.result}>{handleResult()}</p>
            <p className={styles.desiredDate}>{getDate()} {getCurrentTime(true)}</p> */}
            <p className= {styles.mineOrAssigned}>{(listing.clientId == user.id)?"Mine":"Assigned"}</p>
            {/* <img src = {x} className= {styles.reportButton} onClick = {()=>window.setOpenReportForm(true)}/> */}
          </div>
        </div>
        <span className = {styles.vendorContainer}>
            {(!listing.clientReportSubmitted && user.id == listing.clientId || !listing.vendorReportSubmitted && user.id == listing.vendorId) && <img src = {reportIMG} className= {styles.reportButton} onClick = {handleReportUser}/>}
            { listing.clientId == user.id && (!listing.clientReviewSubmitted && user.id == listing.clientId || !listing.vendorReviewSubmitted && user.id == listing.vendorId) && <p className = {styles.reviewUserButton} onClick = {handleRateUser}>Leave a review</p>}
            <img src = {(listing.profilePicture!=null)?listing.profilePicture:sampleProfile} className = {styles.vendorProfilePicture} alt = "User Profile Picture"/>
        </span>

    </div>
  </>
  );
};

export default History;
