import styles from './SearchBar.module.css'
import React, {useState, useEffect, useRef, useLayoutEffect, useContext} from 'react';
import x from '../../../../assets/images/x.png'
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import SearchContext from '../../../../Firebase Contexts/SearchContext/SearchContext';
import {getSearchedListings} from '../../../../Firebase'
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';
import locationIMG from '../../../../assets/images/location.png';
import magnifier from '../../../../assets/images/MagnifierUnselected.png';
import UIContext from '../../../../Firebase Contexts/UIContext/UIContext';

const SearchBar = ({startSearchTransition, typeIsSelected, endedTransition})=>{

    const {savedListings, savedListingsLoaded, appliedListings, setAppliedListings,
           bannedListings, setBannedListings, lastDocumentListingFetched,
           differentSearchIsLoading, setDifferentSearchIsLoading,
           setIsLoadingForTasks, isLoadingForTasks, setNoLocationAvailable} = useContext(ListingsContext)

    const {userPersistenceChecked, user, userLocation, userPreferredCountry, userPreferredMeasurementSystem} = useContext(UserContext);

    const {setSearchInput, prevLocation, setPrevLocation, prevSearchInput, setPrevSearchInput, searchFilters,
      setFiltersIdentifiedBySearch, searchedListings, isSearching, setIsSearching, setSearchedListings,
      stoppedSearchingTasks, searchedSomething, setSearchedSomething, fetchedListings,
      setFetchedListings, isListingLink, setNewSearchFilters, setSearchFilters,
      locationNotFound, setLocationNotFound, wrongLocationName, setWrongLocationName,
      setCurrentLocation, typesOfTasks, transitioned, maxRadiusSearched,
      suggestedLocationAlternative, setSuggestedLocationAlternative,
      preventMobileExecution, findSimilarLocations} = useContext(SearchContext);

    const {searchOrLocation, setSearchOrLocation, newlySelectedCountryIsoCode} = useContext(UIContext);
  //Flag for when search is happening
  const containerRef = useRef();

useEffect(() => {
  if (userPersistenceChecked) {
    setTimeout(() => {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      setIsSearching(false);
    }, 500); // Delay for 0.5 seconds
  }
}, [userPersistenceChecked]);
  
const [suggetionsWindowWidth, setSuggetionsWindowWidth] = useState(false)

//#region Window resize
const handleWindowResize = () => {
    //Flags to prevent error of state not existing if filters dont exist
    if (containerRef.current && typeIsSelected && !isListingLink && !locationNotFound) {
      window.setShouldRunFilterWidthCheck(true);
    }
    if(!isMobile){
      setSuggetionsWindowWidth(containerRef.current.offsetWidth)
    }
    window.filterWidth.current = (containerRef.current.offsetWidth);
  };

  useEffect(()=>{
    if(!isMobile){
      setSuggetionsWindowWidth(containerRef.current.offsetWidth)};
      window.filterWidth.current = (containerRef.current.offsetWidth);
  }, [window.activeComponent])


  useLayoutEffect(() => {
    const handleResize = () => {
      handleWindowResize();
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [typeIsSelected]);
  //#endregion
      
  useEffect(()=>{
    if(!isMobile && !transitioned){
      searchInputRef.current.focus();
    }
  },[userPersistenceChecked, isSearching]);
  
//Updates the filterWidth with the value of first render
  useLayoutEffect(() => {
    window.selectedTask.current = containerRef.current.offsetWidth;
  },[])

  const isMobile = window.targetPlatform == "mobile";
  const searchInputRef = useRef(null);
  const locationInputRef = useRef(null);
  const goButtonRef = useRef(null);
  const [searchedTask, setSearchedTask] = useState("");
  const [searchedLocationTask, setSearchedLocationTask] = useState("");
  const [goButtonPressed, setGoButtonPressed] = useState(false);

  useEffect(()=>{
    setSearchedTask(searchOrLocation.search)
    setSearchedLocationTask(searchOrLocation.location)
  },[searchOrLocation])
    
  const preventFirstExecution = useRef(true);

  useEffect(()=>{
    if(isMobile && preventFirstExecution.current == false && preventMobileExecution.current == false){
      preventMobileExecution.current = false;
      handleOnEnterKey(true);
    }else  {preventFirstExecution.current = false;}
  },[searchedTask, searchedLocationTask])

  const changeValue = (event)=>{
    if(userPersistenceChecked){
      setSearchedTask(event.target.value);
    }
  }

  const handleOnEnterKey = async(comingFromMobile, selectedFromAlterlatives, frontPageSelectedTask) =>{
    //if searched for location and found it
    //if{ user has exists and their savedListings are loaded, its ready to show results
    let readyToShowResults;

    if(userPersistenceChecked && user.id){
      readyToShowResults = (savedListingsLoaded.current)?true:false;
    }else if(userPersistenceChecked && user.id==null){
      readyToShowResults = true
    }

    if(!isLoadingForTasks && readyToShowResults){

      locationInputRef.current.blur();
      setLocationNotFound(false);

      let resultedPredictions = suggestedLocationAlternative;
      let result;
      let finalSelectedPrediction = {description:"", place_id:""};

      if(selectedFromAlterlatives!==true){
        
        if((searchedLocationTask!=prevLocation || suggestedLocationAlternative==null && !isMobile) || comingFromMobile){
          setPrevLocation(searchedLocationTask);

          if((comingFromMobile == null || !comingFromMobile) && searchedLocationTask && searchedLocationTask!=""){
            resultedPredictions = (suggestedLocationAlternative && suggestedLocationAlternative.length>0 && searchedLocationTask==prevLocation)?suggestedLocationAlternative:await handleLocation();
            setSuggestedLocationAlternative((resultedPredictions.length>0)?resultedPredictions:null);
            result = ((resultedPredictions.length>0)?checkIfInputedPlaceExists(resultedPredictions):{result:false});
            if(result.result){
              finalSelectedPrediction = resultedPredictions[result.selectedIndex];
            }
          }else if(!isMobile && searchedLocationTask == ""){
            finalSelectedPrediction = {description:"", place_id:""};
          }else{
            finalSelectedPrediction = (searchFilters.location)?searchFilters.location:{description:"", place_id:""};
          }
        }else if(suggestedLocationAlternative!=null){
          finalSelectedPrediction = suggestedLocationAlternative[0];
          setSearchedLocationTask(suggestedLocationAlternative[0].description.split(',')[0]);
          if(isMobile){window.setSearchedValue(suggestedLocationAlternative[0].description.split(',')[0])}
          setSuggestedLocationAlternative(null);
        }
        
        if(finalSelectedPrediction.description && finalSelectedPrediction.description!="" && resultedPredictions && resultedPredictions.length>1){
            findSimilarLocations(resultedPredictions, finalSelectedPrediction.description.split(',').slice(0, -1).join(','));
        }
      }else{
        finalSelectedPrediction = suggestedLocationAlternative[0];
        setSearchedLocationTask(suggestedLocationAlternative[0].description.split(',').slice(0, -1).join(','));
      }

      setCurrentLocation((finalSelectedPrediction.description!="")?finalSelectedPrediction.description:searchedLocationTask);

      //Start searching animation from home page
      startSearchTransition();

      if(isMobile){
        setGoButtonPressed(true);
      }

      if((searchedLocationTask!="" && finalSelectedPrediction.place_id!="") || searchedLocationTask==""){

        if(frontPageSelectedTask !="" || searchedTask != prevSearchInput || searchedLocationTask!=prevLocation.current){
          if(isListingLink){
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              window.history.pushState({}, '', 'http://localhost:3000');
            } else {
              window.history.pushState({}, '', 'https://www.taskare.com');
            }
          } 

          if(!isMobile){
            setFiltersIdentifiedBySearch((prev)=>({
              ...prev,
              location: finalSelectedPrediction
            }))
            setWrongLocationName(finalSelectedPrediction.description);
          }
          
          if (isMobile) {
            const updatedSearch = frontPageSelectedTask || searchedTask;
            setSearchOrLocation((prev) => ({
              ...prev,
              location: finalSelectedPrediction.description,
              search: updatedSearch,
            }));
          }          
          
          setSearchFilters((prev)=>({
            ...prev,
            location: finalSelectedPrediction
          }))
          const updatedFilters = {...searchFilters, location: finalSelectedPrediction}
          window.shouldAppButtonsRender();
          searchInputRef.current.blur();
          //Start desktop animation for transition
          //Set context searchInput value
          if(!isMobile && goButtonRef.current.classList.contains(styles.goButtonLightBlue)){
            goButtonRef.current.classList.remove(styles.goButtonLightBlue);
            goButtonRef.current.classList.add(styles.goButtonNormalBlue);
          }
          lastDocumentListingFetched.current = null;
          const frontPageTask= (frontPageSelectedTask=="" || frontPageSelectedTask==null)?searchedTask:frontPageSelectedTask;
          setSearchInput(frontPageTask);
          setSearchedTask(frontPageTask);
          // setPrevLocation(searchedLocationTask);
          setPrevSearchInput(frontPageTask);

          const calledFromFilters = false;
          const calledFromListings = false;
          setDifferentSearchIsLoading(true);
          setIsLoadingForTasks(true);
          if((updatedFilters.location.description!="" || userLocation.latitude != null)){
            maxRadiusSearched.current = 1;
            if(maxRadiusSearched.current < searchFilters.radius){
              maxRadiusSearched.current = searchFilters.radius;
            }
              setFetchedListings([]);
              setSearchedListings([]);
              setDifferentSearchIsLoading(true);
              setIsLoadingForTasks(true);
              setNoLocationAvailable(false);
              
              const result = await getSearchedListings(calledFromFilters, calledFromListings, fetchedListings, setFetchedListings, frontPageTask,
              updatedFilters, setFiltersIdentifiedBySearch, userLocation,
              setSearchedListings, stoppedSearchingTasks,
              savedListings, appliedListings, bannedListings, lastDocumentListingFetched,
              suggestedTypes, userPreferredMeasurementSystem);

              setDifferentSearchIsLoading(false);
              setIsLoadingForTasks(false);

              //setIsNotFoundTasksVisible is located in at listedTasks
              window.setIsNotFoundTasksVisible((result == 0)?true:false);
              preventMobileExecution.current = false
          }else{
            setIsLoadingForTasks(false);
            setNoLocationAvailable(true);
          }
        }
      } else {
        setFetchedListings([])
        setSearchedListings([])
        setWrongLocationName(searchedLocationTask)
        setLocationNotFound(true);
        setDifferentSearchIsLoading(false);
        setIsLoadingForTasks(false);
        lastDocumentListingFetched.current = null;
        }
    }
  }

  window.initializeNewSearch = handleOnEnterKey;

  //#region Input Selection
  const selectMobileOrDesktoptInput = (input) => {
    if(isMobile){
      setSearchOrLocation((prev)=>({
        ...prev,
        selectedInput: (input=="search")?"search":"location"
      }));
      window.setEnableSearchOrLocationInput(true);
    } else {
    }
  }
  //#endregion


  const [isLocationInputFocused, setIsLocationInputFocused] = useState(false);

  const [thereAreSuggestions, setThereAreSuggestions] = useState(false);
  const [selectedLocationSuggestionIndex, setSelectedLocationSuggestionIndex] = useState(null);

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [selectedPrediction, setSelectedPrediction] = useState({description: "", place_id: ""});

  useEffect(() => {
    if ((suggestedTypes.length > 0 && searchedTask!="" && isSearchInputFocused) || (suggestedLocations.length > 0 && searchedLocationTask!="" && isLocationInputFocused)) {
      setThereAreSuggestions(true);
    } else {
      setThereAreSuggestions(false);
    }
  }, [searchedLocationTask, searchedTask]);

//#region Location functionality
  const [suggestedLocations, setSuggestedLocations] = useState([]); // Use state to store suggestions

  const selectedSuggestionRefs = useRef(Array(suggestedLocations.length).fill(null)); // Initialize the ref

  const handleLocationInput = (event)=>{
    setSearchedLocationTask(event.target.value);
  }

  const handleLocation = async () => {
    const value = searchedLocationTask;
    const onlyLetters = value.replace(/[^a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, '');
    
    setIsLoadingForTasks(true);

    return new Promise((resolve, reject) => {
      if (onlyLetters || value === '') {
        if (autocompleteService && value) {
          // Fetch location predictions based on user input
          autocompleteService.getPlacePredictions(
            {
              input: value,
              types: ["(cities)"],
              componentRestrictions: { country: (newlySelectedCountryIsoCode!="")?newlySelectedCountryIsoCode:userPreferredCountry.preferredCountryCode },
            },
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setSuggestedLocations(predictions.map((prediction) => prediction));
                resolve(predictions);
              } else {
                resolve([]);
                // console.log("Error fetching predictions:", status);
                // reject(status);
              }
            }
          );
        }
      }
    });
  };
  
  useEffect(() => {
    if (suggestedLocations[selectedLocationSuggestionIndex]) {
      const currentLocation = {description: suggestedLocations[selectedLocationSuggestionIndex].description, place_id: suggestedLocations[selectedLocationSuggestionIndex].place_id}
      setSearchedLocationTask(currentLocation.description);
      setSelectedPrediction(currentLocation);
    }
    
    if (
      selectedLocationSuggestionIndex != null &&
      selectedSuggestionRefs.current[selectedLocationSuggestionIndex]
    ) {
      selectedSuggestionRefs.current[selectedLocationSuggestionIndex].classList.add(
        styles.selectedOption
      );
    }
    // Remove the class from previously selected suggestions
    suggestedLocations.forEach((_, index) => {
      if (
        index !== selectedLocationSuggestionIndex &&
        selectedSuggestionRefs.current[index]
      ) {
        selectedSuggestionRefs.current[index].classList.remove(
          styles.selectedOption
        );
      }
    });
  }, [selectedLocationSuggestionIndex]);

  const locationInputUpdated = useRef(false);
  
  const [triggerLocationSearchOnEnter, setTriggerLocationSearchOnEnter] = useState(false);
  useEffect (()=>{
    if(!isMobile && locationInputUpdated.current){
      if(selectedPrediction.description != prevLocation){
        handleOnEnterKey(false);
      }
    }
  },[locationInputUpdated.current, triggerLocationSearchOnEnter])

  const checkIfInputedPlaceExists = (resultedPredictions) => {
    const selectedLocation = searchedLocationTask.description
      ? searchedLocationTask.description
      : searchedLocationTask;
  
    // Regular expression to match words with Unicode support
    const regex = /[\p{L}]+/gu;
  
    const selectedWords = selectedLocation.match(regex) || [];
  
    let maxCommonWords = 0; // Keep track of the maximum number of common words found.
    let selectedPrediction = null;
    let matchIndex = -1;
  
    for (let i = 0; i < resultedPredictions.length; i++) {
      const suggestedLocation = resultedPredictions[i];
      const suggestedWords = suggestedLocation.description.match(regex) || [];
  
      let commonWords = 0;
  
      for (const selectedWord of selectedWords) {
        const formattedSelectedWord = selectedWord.toLowerCase();
  
        for (const suggestedWord of suggestedWords) {
          const formattedSuggestedWord = suggestedWord.toLowerCase();
  
          if (formattedSelectedWord === formattedSuggestedWord) {
            commonWords++;
            break; // Exit the inner loop as we found a match for this selected word.
          }
        }
      }
  
      // Check if all selectedWords were found in suggestedWords before considering it a match.
      if (commonWords === selectedWords.length && commonWords > maxCommonWords) {
        maxCommonWords = commonWords;
        selectedPrediction = suggestedLocation;
        matchIndex = i;
      }
    }
  
    if (selectedPrediction) {
      setSelectedLocationSuggestionIndex(matchIndex);
      setSelectedPrediction(selectedPrediction);
      return { result: true, selectedIndex: matchIndex };
    } else {
      return { result: false, selectedIndex: -1 };
    }
  }
  
  //#endregion

//#region Search functionality

  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [visibleSuggestions, setVisibleSuggestions] = useState([]);

  const [selectedSearchSuggestionIndex, setSelectedSearchSuggestionIndex] = useState(null);
  
  const handleSearchInputFocus = () => {
    setIsSearchInputFocused(true);
  };

  const handleSearchInputBlur = () => {
    setIsSearchInputFocused(false);
    setSelectedSearchSuggestionIndex(null);
  };

  const suggestedTypes = typesOfTasks.flatMap(category => category.tasks);

  const handleSearchArrowKey = (event) => {
    if (event.key === 'ArrowUp') {
      // Move selection up
      setSelectedSearchSuggestionIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.max(0, prevIndex - 1)
      );
    } else if (event.key === 'ArrowDown') {
      // Move selection down
      setSelectedSearchSuggestionIndex((prevIndex) =>
        prevIndex === null
          ? 0
          : Math.min(visibleSuggestions.length - 1, prevIndex + 1)
      );
    }
  };

useEffect(()=>{
  if (
    selectedSearchSuggestionIndex != null &&
    selectedSuggestionRefs.current[selectedSearchSuggestionIndex]
  ) {
    selectedSuggestionRefs.current[selectedSearchSuggestionIndex].classList.add(
      styles.selectedOption
    );
  }
  // Remove the class from previously selected suggestions
  suggestedTypes.forEach((_, index) => {
    if (
      index !== selectedSearchSuggestionIndex &&
      selectedSuggestionRefs.current[index]
    ) {
      selectedSuggestionRefs.current[index].classList.remove(
        styles.selectedOption
      );
    }
  });
}, [selectedSearchSuggestionIndex]);

  useEffect(() => {
    if(searchedTask!="" && searchedTask){
      // Filter and sort the suggested types
      const filteredAndSortedSuggestions = suggestedTypes
        .filter((suggestion) => suggestion.toLowerCase().includes(searchedTask.toLowerCase()))
        .sort((a, b) => {
          // Calculate the index of the typed letters in each suggestion
          const indexA = a.toLowerCase().indexOf(searchedTask.toLowerCase());
          const indexB = b.toLowerCase().indexOf(searchedTask.toLowerCase());
  
          // Sort by the index (earlier occurrence comes first)
          if (indexA !== indexB) {
            return indexA - indexB;
          }
  
          // If the index is the same, sort alphabetically
          return a.localeCompare(b);
        });
      setVisibleSuggestions(filteredAndSortedSuggestions);
  }
  }, [searchedTask]);
  
  const handleSearchSuggestionSelection = (index)=>{
    setSearchedTask(visibleSuggestions[index]);
  }

  const selectMouseHoveredSearchOption = (index)=>{
    setSearchedTask(visibleSuggestions[index]);
  }

  const [initiateSearchOnEnter, setInitiateSearchOnEnter] = useState(false);

  const handleSearchSubmit = ()=>{
    setSearchedTask((visibleSuggestions.length>0 && visibleSuggestions[selectedSearchSuggestionIndex]!=null)?visibleSuggestions[selectedSearchSuggestionIndex]:searchedTask);
    setSelectedSearchSuggestionIndex(null);
    searchInputRef.current.blur();
    setInitiateSearchOnEnter(true);
  }

  useEffect(()=>{
    if(initiateSearchOnEnter){
      setInitiateSearchOnEnter(false);
      handleOnEnterKey(false);
    }
  },[initiateSearchOnEnter])

  //#endregion


  return(
    <div>
        <span ref = {containerRef} className={isMobile ? `${styles.searchContainer} ${(!goButtonPressed)?styles.searchContainerMobile:styles.searchContainerMobileExtraBottomSpace}`: `${styles.searchContainer} ${styles.searchContainerDesktop}`}>
          <img src = {magnifier} style = {{width: "20px", paddingLeft: "0.7em"}} alt = "Magnifier"/>
          <input
            ref={searchInputRef}
            type = "text"
            onChange={changeValue}
            value = {searchedTask}
            onFocus={handleSearchInputFocus}
            onBlur={handleSearchInputBlur}
            placeholder = "What?"
            className= {isMobile ? styles.mobileSearch: styles.desktopSearch}
            disabled={isSearching} // Add the disabled attribute
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !isMobile) {
                  event.preventDefault(); // Prevent default form submission
                  handleSearchSubmit();
              }
              if (
                event.key === 'ArrowUp' ||
                event.key === 'ArrowDown'
              ) {
                event.preventDefault(); // Prevent scrolling
                handleSearchArrowKey(event);
              }
          }}
          onMouseDown={(isMobile)?((event)=>{event.preventDefault(); selectMobileOrDesktoptInput("search")}):null}
        />
              
          <div style = {{height: "50%", width:"3px", background:"rgb(200,200,200)"}} />

          <img src = {locationIMG} style = {{width: "20px", paddingLeft: "0.5em"}} alt = "Location"/>  
          <input
            ref={locationInputRef}
            type = "text"
            onChange={handleLocationInput}
            value = {searchedLocationTask.split(',')[0]}
            placeholder = "Where?"
            className= {isMobile ? styles.mobileSearch: styles.desktopSearch}
            disabled={isSearching} // Add the disabled attribute
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault(); // Prevent default form submission
                    handleOnEnterKey(false);
                }
            }}
            onMouseDown={(isMobile)?((event)=>{event.preventDefault(); selectMobileOrDesktoptInput("location")}):null}
          />

      {!isMobile && <button ref = {goButtonRef} onClick = {()=>handleOnEnterKey(false)} className = {(!isListingLink)?`${styles.goButtonDesktop} ${styles.goButtonLightBlue}`:`${styles.goButtonDesktop} ${styles.goButtonNormalBlue}`}>Search</button>}

      </span>
      {!goButtonPressed && isMobile && <button ref = {goButtonRef} onClick = {()=>(!isSearching)?handleOnEnterKey(true):""} className = {(isListingLink)?`${styles.goButton} ${styles.goButtonNormalBlue}`:`${styles.goButton} ${styles.goButtonLightBlue}`}>Find tasks</button>}

      {/* Search Autocomplete */}
      {thereAreSuggestions && !isMobile && isSearchInputFocused && (
        <div className={styles.desktopOptionsContainer}
        style={{width: suggetionsWindowWidth}}
        >
          {visibleSuggestions.map((x, index) => (
            <p
              key={index}
              ref={(el) => (selectedSuggestionRefs.current[index] = el)}
              className={`${styles.option} ${
                selectedLocationSuggestionIndex === index ? styles.selected : ''
              }`}
              onMouseDown={()=>selectMouseHoveredSearchOption(index)}
              onClick={() => {
                handleSearchSuggestionSelection(index);
              }}
            >
              {x}
            </p>
          ))}
        </div>
      )}
    </div>
    );
};

export default SearchBar;