import styles from './AccountSettings.module.css';
import verified from '../../../assets/images/verified.png';
import { signOutUser } from '../../../Firebase';
import { useRef, useState, useEffect, useContext } from 'react';
import UserContext from '../../../Firebase Contexts/UserDataContext';
import goBackImg from "../../../assets/images/Arrow.png";
import CategoriesContent from './CategoriesContent/CategoriesContent';
import profileIcon from '../../../assets/images/ProfileSelectedOutline.png'
import bugIcon from '../../../assets/images/bugIcon.png'
import cookiesIcon from '../../../assets/images/cookiesIcon.png'
import termsOfServiceIcon from '../../../assets/images/termsOfServiceIcon.png'
import globeIcon from '../../../assets/images/globeIcon.png'
import changePasswordIcon from '../../../assets/images/changePasswordIcon.png'
import jobPreferences from '../../../assets/images/jobPreferences.png'
import privacyPolicy from '../../../assets/images/privacyPolicy.png'

const AccountSettings = ()=>{

  const {user, authListenercompleted} = useContext(UserContext);

  const isMobile = window.targetPlatform == "mobile";

  const [categoriesContentIsVisible, setCategoriesContentIsVisible] = useState((isMobile)?false:true);
  const [isSelectedContentEnabled, setIsSelectedContentEnabled] = useState(false);
  const [selectedContent, setSelectedContent] = useState((isMobile)?"":"profile");
  const [categoriesAreHidden, setCategoriesAreHidden] = useState(false);
  const selectedContentText = useRef("");

  const selectContect = (content, text) => {
    setCategoriesContentIsVisible(true);
    setSelectedContent(content);
    selectedContentText.current = text;
  }

  const closeCategoriesContentIsVisible = ()=>{
    setCategoriesContentIsVisible(false)
  }
  //#region Slide animation
  const accountSettingsTabRef = useRef(null);

  useEffect(()=>{
    if(isMobile){
      accountSettingsTabRef.current.classList.add(styles.slideRight);
    }
  },[])

  const resetSelectedContext = ()=>{
    // console.log("wtf");
    setSelectedContent("");
    selectedContentText.current = "";
  }

  const initializeComponentExit = ()=>{
    if(!categoriesContentIsVisible){
      window.mobileHandleNavButtonsOnClick("Search")
      window.mobileSetSearchScrollPosition();
      window.setTypeShouldBeVisible(true);
      accountSettingsTabRef.current.classList.add(styles.slideLeft);
    }else{
      window.initializeCategoryExit();
    }
  }

  const handleAnimEnd = (event)=>{
      if(event.animationName.includes("slideLeft")){
          window.setActiveComponent("Search");
          window.setPrevActiveComponent("Search");
      }else if(event.animationName.includes("slideRight")){
        if(accountSettingsTabRef.current){
          setTimeout(() => {        
            accountSettingsTabRef.current.classList.add(styles.containerAbsolute);
          }, 0);
        }
          window.setPrevActiveComponent("Account Settings");
          window.setTypeShouldBeVisible(false);
      } 
  }

  const handleAnimStart = (event) => {
    if(window.prevActiveComponent == "Tasks"){
      window.changeTasksIndex();
    } else if(window.prevActiveComponent == "Saved"){
      window.changeSavedIndex();
    } else if(window.prevActiveComponent == "Notifications"){
      window.changeNotificationsIndex();
    } else if(window.prevActiveComponent == "Profile"){
      window.changeProfileIndex();
    }else if(window.prevActiveComponent == "Applied Tasks"){
      window.changeAppliedTasksIndex();
    }else if(window.prevActiveComponent == "Messages"){
      window.changeMessagesIndex();
    }

    if(event.animationName.includes("slideRight")){
      accountSettingsTabRef.current.classList.add(styles.highContainerIndex);
    }else if(event.animationName.includes("slideLeft")){
      accountSettingsTabRef.current.classList.remove(styles.containerAbsolute);
    }
  }

  const changeAccountSettingsIndex = () => {
      accountSettingsTabRef.current.classList.remove(styles.highContainerIndex);
      accountSettingsTabRef.current.classList.remove(styles.containerAbsolute);
      accountSettingsTabRef.current.classList.add(styles.lowContainerIndex);
  }
  window.changeAccountSettingsIndex = changeAccountSettingsIndex;

  useEffect(()=>{
      if(!isMobile){
          window.hideSearch();
      }
  },[])


  //#endregion

  const redirectUser = (currentButton) => {
    let listingURL;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        listingURL = "http://localhost:3000/"+currentButton;
    } else {
        listingURL = "https://www.taskare.com/"+currentButton;
    }
    window.open(listingURL);
  };
  
  return(
    <div ref = {accountSettingsTabRef} className = {(isMobile)?styles.container : styles.containerDesktop} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
    {isMobile && <img src = {goBackImg} style = {{position:"absolute", zIndex:"9995", width: "20px", marginTop:"1em", marginLeft:"1em", transform: "rotate(180deg)"}} onClick = {initializeComponentExit}
    alt="Back Arrow" />}
    {isSelectedContentEnabled && isMobile && selectedContentText.current != "" && <p className = {styles.categoryTitle}>{selectedContentText.current}</p>}
      {(!categoriesAreHidden || !isMobile) && 
        <div className={(!isMobile)?styles.containerContentDesktop:styles.containerContentMobile}>
          <div className = {(isMobile)?styles.elementsContainerMobile:styles.elementsContainerDesktop}>
            {!isMobile && <p className = {styles.settingsElementText}>Account Settings</p>}

            {!isMobile && <div className = {styles.nameAndVerified} onClick = {()=>selectContect("profile", "Profile")}>
              <img src = {user.profilePhoto} className = {styles.profilePicture} alt="User Profile Picture"/>
              <p className = {styles.userNameSurname}>{user.name} {user.surname}</p>
              {/* <img src = {verified} className = {styles.verified}/> */}
            </div>}

            {/* <p className = {styles.userEmail}>{user.email}</p>
            <p className = {styles.userPhoneNumber}>{user.phoneNumber}</p> */}

            <p className = {styles.titleElementText}>Edit Profile</p>
            
            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick = {()=>selectContect("changeProfilePicture", "Edit Profile")}>
              <img src = {profileIcon} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt="User Profile Picture"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Change Profile Picture</p>
            </span>

            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick = {()=>selectContect("changePassword", "Edit Profile")}>
              <img src = {changePasswordIcon} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Change Password"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Change Password</p>
            </span>

            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick = {()=>selectContect("changeCountry", "Edit Profile")}>
              <img src = {globeIcon} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Globe"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Change Country &<br/> Measurement System</p>
            </span>

            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick = {()=>selectContect("changeJobPreferences", "Edit Profile")}>
              <img src = {jobPreferences} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Job preferences"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop} onClick = {()=>selectContect("changeJobPreferences", "Edit Profile")}>Job Preferences</p>
            </span>
            </div>

          <div className = {(isMobile)?styles.elementsContainerMobile:styles.elementsContainerDesktop}>
            <p className = {styles.titleElementText}>Legal and Compliance</p>

            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick={()=>redirectUser("termsofservice")}>
              <img src = {termsOfServiceIcon} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Terms of Service"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Terms of Service</p>
            </span>

            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick={()=>redirectUser("privacypolicy")}>
              <img src = {privacyPolicy} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Privacy Policy"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Privacy Policy</p>
            </span>

            <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick={()=>redirectUser("cookiepolicy")}>
              <img src = {cookiesIcon} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Cookie Policy"/>
              <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Cookie Policy</p>
            </span>
            </div>
          <div className = {(isMobile)?styles.elementsContainerMobile:styles.elementsContainerDesktop} style = {{marginBottom:(isMobile)?"4em":""}}>
          
          <span className={(isMobile)?styles.grouppedCategoryMobile:styles.grouppedCategoryDesktop} onClick = {()=>selectContect("reportABug", "Techical")} style = {{marginTop:"1em"}}>
          <img src = {bugIcon} className = {(isMobile)?styles.iconMobile:styles.iconDesktop} alt = "Bug Report"/>
            <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Report a Bug</p>
          </span>
            {/* <p className = {(isMobile)?styles.elementTextMobile:styles.elementTextDesktop}>Latest Version: <span style = {{fontWeight:"600"}}>1.0.0</span></p> */}
            <p className = {(isMobile)?styles.signOutMobile:styles.signOutDesktop} onClick = {()=>{signOutUser()}}>Sign out</p>
          </div>
        </div>
      }
      {categoriesContentIsVisible &&
      <CategoriesContent
      selectedContent = {selectedContent}
      resetSelectedContext = {resetSelectedContext}
      setIsSelectedContentEnabled = {setIsSelectedContentEnabled}
      setCategoriesAreHidden = {setCategoriesAreHidden}
      closeCategoriesContentIsVisible = {closeCategoriesContentIsVisible}/>}
    </div>
  )
}

export default AccountSettings;