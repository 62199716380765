import styles from './SharedListedTask.module.css'
import React, {useRef, useState, useEffect, useContext} from 'react'
import {saveListing, deleteSavedListing, addApplicantToListing, applyListing} from '../../../Firebase';
import SearchContext from '../../../Firebase Contexts/SearchContext/SearchContext';
import location from '../../../assets/images/location.png';
import time from '../../../assets/images/time.png';
import UserContext from '../../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
import bookmarkOutline from '../../../assets/images/BookmarkListingUnselected.png';
import bookmarkSelected from '../../../assets/images/BookmarkListingSelected.png';
import shareIcon from '../../../assets/images/share.png';
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';
import noTaskFoundIMG from '../../../assets/images/NoTaskFound.png';
const SharedListedTask = ()=>{

  const{listing, listingFetchingEnded, searchedListings, setSearchedListings} = useContext(SearchContext);
  const {savedListings, setSavedListings, savedListingsLoaded, appliedListings,
    setAppliedListings} = useContext(ListingsContext);
  const {user, userPersistenceChecked} = useContext(UserContext);
  const {setSharedListingData} = useContext(UIContext);

  const isMobile = window.targetPlatform == "mobile";

  const findIfListingIsSavedByUser = () => {
    if(savedListings.some(savedListing => savedListing.id === listing.id)){
      return true;
    }else {
      return false;
    }
  }

  const isSaved = findIfListingIsSavedByUser();


  const [bookmark, setBookmark] = useState(isSaved? isSaved: false);
  const [saveButtonIsEnabled, setSaveButtonIsEnabled] = useState(true);

//#region save process handling

  useEffect(()=>{
    //Apply the latest value of isSaved if changed -  typically after user sign in
    setBookmark(isSaved);
    listIsSaved.current = isSaved;
  },[isSaved])

const deleteSavedListingFromContext = (listingId) => {
  setSavedListings((prevSavedListings) => {
    // Use the filter method to create a new array without the matching listingId
    const updatedListings = prevSavedListings.filter(
      (tempListing) => tempListing.id !== listingId
    );
    return updatedListings;
  });
};

const updateListingToContext = (listingId) => {
  setSearchedListings((prevSearchedListings) => {
    return prevSearchedListings.map((prevListing) => {
      if (prevListing.id === listingId) {
        // Update the matching listing
        return {
          ...prevListing,
          isSaved: (prevListing.isSaved==true)?false:true,
        };
      } else {
        // Keep other listings unchanged
        return prevListing;
      }
    });
  });
};

  
  const saveListingToContext = (listingId) => {
    setSavedListings((prevSavedListings) => {
      const newSavedListing = {
        id: listingId,
        date: listing.date,
        description: listing.description,
        duration: listing.duration,
        location: listing.location,
        price: listing.price,
        time: listing.time,
        title: listing.title,
        type: listing.type,
        uid: listing.uid,
      };
      return [...prevSavedListings, newSavedListing];
    });
  };

  const listIsSaved = useRef(isSaved);

  const handleSave = async() => {
    
    if(user.id){
      //If not saved
      if(!listIsSaved.current && saveButtonIsEnabled){
        setSaveButtonIsEnabled(false);
        const result = await saveListing(user.id, listing.id)
        setBookmark(result)
        // console.log("saved");
        if(result){
          saveListingToContext(listing.id);
          updateListingToContext(listing.id)
        }
        listIsSaved.current  = true;
      }//Else if not saved
      else if(listIsSaved.current && saveButtonIsEnabled){
        setSaveButtonIsEnabled(false);
        //Delete listing
        const result = await deleteSavedListing(user.id, listing.id);
        setBookmark(result);
        //if listing deleted successfully from firebase, delete it from context
        if(!result){
          deleteSavedListingFromContext(listing.id);
          updateListingToContext(listing.id)
        }

        listIsSaved.current = false;
      }
      setSaveButtonIsEnabled(true);
    }else if(!user.id){
      window.setSignInHidden(false);
    }
  };
//#endregion

//#region Copy to clipboard
const handleShare = ()=>{
  setSharedListingData({
    listingId: listing.id
  })
  window.setIsCopied(true);
}
//#endregion


//#region Apply
const checkIfUserApplied = () => {
 if(appliedListings.some(appliedListing => appliedListing.id === listing.id)){
    return true;
  }else {
    return false;
  }
}



const hasApplied = checkIfUserApplied();


const applyButtonRef = useRef(null);

const handleApply = async()=>{
  if(user.id){
    if(applyButtonRef.current.innerText == "Apply now" && !hasApplied){
      const result = await addApplicantToListing(listing.id, user.id)
      const endResult = await applyListing(user.id, listing.id);
      if(endResult){
        //Update the hasApplied to searchedListings
        setSearchedListings((prevSearchedListings) =>
            prevSearchedListings.map((prevSearchedListing) => {
              if (prevSearchedListing.id === listing.id) {
                // If the condition is true, add the isBanned property
                return { ...prevSearchedListing, hasApplied: true };
              }
              // If the condition is not met, return the object as-is
              return prevSearchedListing;
            })
          );
        if(isSaved){
          setSavedListings((prevSearchedListings) =>
            prevSearchedListings.map((prevSearchedListing) => {
              if (prevSearchedListing.id === listing.id) {
                // If the condition is true, add the isBanned property
                return { ...prevSearchedListing, hasApplied: true };
              }
              // If the condition is not met, return the object as-is
              return prevSearchedListing;
            })
          );
        }
        
        setAppliedListings([...appliedListings, {id: listing.id}]);
      }
      applyButtonRef.current.innerText = (result)? "Applied": "Something went wrong";
    } 
  }else{
    window.setSignInHidden(false);
  }
};
//#endregion

return(
  <>
  {listing && listing.id==null && listingFetchingEnded && (<div className = {styles.noTaskFoundContainer}>
    <p className = {styles.pageNotFoundTitle}>This page cannot be found</p>
    <p className = {styles.pageNotFoundDescription}>The page you're trying to access does not appear to exist. To resolve this issue, please double-check for any spelling mistakes in the URL. </p>
    <img src = {noTaskFoundIMG} style = {{width:"200px"}}/>
  </div>
  )}

  {listing && listing.id!=null && listingFetchingEnded &&(
    <div className = {isMobile? styles.mobileContainer : styles.desktopContainer}>
      <h1 className={styles.title}>{listing.title}</h1>
      <span className = {styles.actionButtonsContainer}>
        { user.id != listing.uid && <p className = {styles.applyButton} onClick = {handleApply} ref = {applyButtonRef}>{(!hasApplied)?"Apply now":"Applied"}</p>}
        { user.id != listing.uid && <img
          src={bookmark ? bookmarkSelected : bookmarkOutline}
          alt=''
          className={styles.bookmark}
          onClick={handleSave}
        />}
        <img src = {shareIcon} alt = '' className={styles.share} onClick = {handleShare}/>
      </span>

      <span className={styles.locationContainer}>
        <img src = {location} className = {styles.filterIcon}/>
        <p className = {styles.locationDetail}>{listing.location.description.split(',')[0].trim()}</p>
      </span>

      <div className={styles.details}>

      <span className={styles.detailContainer}>
      <img src = {time} className = {styles.filterIcon}/>
      <p className={styles.detailText}>{(listing.duration.hours != 0) ? ((listing.duration.hours < 2) ? 1 + " hour" : listing.duration.hours + " hours") : ""} {(listing.duration.minutes != 0) ? ((listing.duration.minutes < 2) ? 1 + " minute" : listing.duration.minutes + " minutes") : ""}</p>
      </span>
      
      <span className={styles.detailContainer}>
      <p className={styles.detailText}>{listing.type}</p>
      </span><span className={styles.detailContainer}>
      <p className={styles.detailText}>${listing.price}</p>
      </span>

      </div>
      <div className={styles.mobileDescriptionContainer}/>
      <span className = {styles.descriptionContainer}>
      <p style = {{fontWeight:"bold"}}>Description</p>
      <div className={styles.descriptionDesktopVersion}>
        <p className={styles.description}>{listing.description}</p>
      </div>
      </span>
    </div>
  )}
  </>
)
}

export default SharedListedTask