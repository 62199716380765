import React, { useContext, useRef, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styles from './Payment.module.css';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import loadingIMG from '../../../../assets/images/loading.png';
import Decimal from 'decimal.js';

const PaymentForm = ({publishListing}) => {

  const {user, userPreferredCountry} = useContext(UserContext)
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [error, setError] = useState("");
  const [errorEnabled, setErrorEnabled] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const currencyAdjustment = {
    US: 4.99, // Exchange rate for USD to EUR
    CA: 6.99, // Exchange rate for CAD to EUR
    GB: 4.99, // Exchange rate for GBP to EUR
    IE: 4.99,    // EUR is the base currency for Ireland
    AU: 6.99, // Exchange rate for AUD to EUR
    NZ: 6.99, // Exchange rate for NZD to EUR
    DK: 40, // Exchange rate for DKK to EUR
    SE: 49.99, // Exchange rate for SEK to EUR
    NO: 49.99, // Exchange rate for NOK to EUR
    GR: 4.99,    // EUR is the base currency for Greece
    ZA: 99, // Exchange rate for ZAR to EUR
    // Add more exchange rates as needed
  };

  const exchangeRate = currencyAdjustment[userPreferredCountry.preferredCountryCode];

  const price = (exchangeRate).toFixed(2); // Round to 2 decimal places
    
  const cardNameRef = useRef(null);

  // const serviceFee = useRef(Math.ceil((price * 0.08 + 0.80) * 100) / 100);
  // const totalAmount = parseFloat(new Decimal(serviceFee.current).plus(price).toNumber().toFixed(2));
  const roundedTotalAmount = Math.ceil(price * 100) / 100; // Rounds up to 2 decimal places

  const getCurrency = (isoCode) => {
    const currencyMap = {
      US: "usd",
      CA: "cad",
      GB: "gbp",
      IE: "eur",
      AU: "aud",
      NZ: "nzd",
      DK: "dkk",
      SE: "sek",
      NO: "nok",
      GR: "eur",
      ZA: "zar",
      // Add more country codes and corresponding currency codes as needed
    };
    return currencyMap[isoCode] || null;
  };

  const getCurrencySign = (isoCode) => {
    const currencySignMap = {
      US: '$',
      CA: '$',
      GB: '£',
      GR: '€',
      AU: '$',
      NZ: '$',
      DK: 'kr',
      SE: 'kr',
      NO: 'kr',
      ZA: 'R',
    };
  
    return currencySignMap[isoCode] || '';
  };

  const currency = getCurrency(userPreferredCountry.preferredCountryCode);

  const currencySign = getCurrencySign(userPreferredCountry.preferredCountryCode);

  const [submitPressed, setSubmitPressed] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    cardNameRef.current.classList.remove(styles.errorBorder);
    
    if(cardName =="" || cardName == null){
      cardNameRef.current.classList.add(styles.errorBorder);
      return;
    }

    if(!submitPressed){
      setSubmitPressed(true);
      setErrorEnabled(false);
      setError("");

      if (!stripe || !elements) {
        return;
      }
    
    try {
      // Create a payment method using the card element
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
  
      if (error) {
        console.error(error);
        setSubmitPressed(false);
        setErrorEnabled(true);

        setError(() => {
          if (error.code === "card_declined") {
            return "The card was declined";
          }else if(error.code == "incomplete_zip"){
            return "Incomplete post code";
          } else if(error.code == "incomplete_number"){
            return "Incomplete card number";
          } else if(error.code == "incomplete_expiry"){
            return "Incomplete expiration date";
          } else if(error.code == "incomplete_cvc"){
            return "Incomplete cvc";
          }
          else return error.code;
        });
        
        setPaymentStatus('Payment failed. Please check your card details and try again.');
        return; // Stop execution if there's an error
      }
  
      // Send the payment method ID and other details to your server for further processing
      const paymentDetails = {
        paymentMethodId: paymentMethod.id,
        amount: roundedTotalAmount*100,//roundedTotalAmount * 100,
        currency: currency,
        customerEmail: user.email,
        customerName: cardName,
      };
  
      // Make a POST request to your Cloud Function endpoint
      const response = await fetch('https://us-central1-xelpie-47bb1.cloudfunctions.net/handleClientNewListingPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentDetails),
      });
  
      if (response.ok) {
        const data = await response.json();
        setPaymentStatus("success");
        publishListing();
      } else {
        console.error('Server error:', response.statusText);
        setPaymentStatus('Payment failed. Please try again.'); // Set an error state
        setSubmitPressed(false);
      }
      } catch (error) {
        console.error('Error during payment:', error.message);
        setPaymentStatus('Payment failed. Please try again.'); // Set an error state
        setSubmitPressed(false);
      }
    }
  };

  const [cardName, setCardName] = useState("");

  const handleNameChange = (event) => {
    setCardName(event.target.value);
  }

  return (
    <>
    <form onSubmit={handleSubmit} className={styles.paymentForm}>
      <div className={styles.paymentLabel}>
        {/* <p className={styles.paymentText}>Your task offering: <span style = {{color: "rgb(90, 95, 103)", fontWeight: "400"}}>{(currencySign=="$")?currencySign+price : price + currencySign}</span></p> */}
        {/* <p className={styles.paymentText}>Service fee: <span style = {{color: "rgb(90, 95, 103)", fontWeight: "400"}}>{(currencySign=="$")?currencySign+serviceFee.current : serviceFee.current + currencySign}</span></p> */}
        <p className={styles.paymentText}>Service fee: <span style = {{color: "rgb(90, 95, 103)", fontWeight: "400"}}>{(currencySign=="$")?currencySign+roundedTotalAmount : roundedTotalAmount + currencySign}</span></p>
        <p className={styles.cardDetailsLabel}>Card Information</p>

        <div className={styles.cardInformationContainer} ref = {cardNameRef}>
          <input onChange = {handleNameChange} value = {cardName} className = {styles.cardInformation} placeholder= "Name"/>
        </div>

      </div>
      <div className={styles.paymentCardElement}>
        <CardElement />
      </div>
      <button type="submit" disabled={!stripe} className={styles.paymentButton}>
        Pay {(currencySign=="$")?currencySign+roundedTotalAmount : roundedTotalAmount + currencySign}
      </button>
      {submitPressed && <img src = {loadingIMG} className = {styles.loadingIconStyle} alt = "Loading"/>}
      {errorEnabled && <p className={styles.errorStyle}>{error}</p>}
    </form>
    </>
  );
};

const Payment = ({ price ,publishListing}) => {
  const stripePromise = loadStripe('pk_live_51MMYthKa0SS6uvc6nJwxDKNY4065JDUSL6tJLYD89RVtf6oCeRWuhVkiURuelToPJD2eOX00bme6SKIvSigO1PC600Z9cSNolO');

  return (
    <div className={styles.paymentContainer}>
      <Elements stripe={stripePromise}>
        <PaymentForm price={price} publishListing = {publishListing}/>
      </Elements>
    </div>
  );
};

export default Payment;
