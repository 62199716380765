import React, {useRef, useState, useContext} from 'react';
import styles from './AbandonTask.module.css';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import {deleteUserApplicationToListing} from '../../../../Firebase'

const AbandonTask = ({disableAbandonWindow, abandonTask, taskId})=>{

    const isMobile = window.targetPlatform == "mobile";
    const {user} = useContext(UserContext);
    const buttonWasPressed = useRef(false);
    const abandonButtonRef = useRef(false);

    const backgroundRef = useRef(null);

    const exitWindow = ()=>{
        if(!buttonWasPressed.current){
            disableAbandonWindow();
        }
    }

    const handleAbandon = async()=>{
        if(!buttonWasPressed.current){
            buttonWasPressed.current = true;
            abandonButtonRef.current.classList.add(styles.disabledButton);
            const result = await abandonTask();
            if(result!=null){
                disableAbandonWindow();
            }else {
                abandonButtonRef.current.classList.remove(styles.disabledButton);
                buttonWasPressed.current = false;
            }
        }
    }

    return(
        <>
        <div className = {styles.background} ref = {backgroundRef} onClick = {exitWindow}/>
        <div className={styles.container}>
                <div className = {styles.connectConfirmation}>
                <p className = {styles.titleText}>Are you sure?</p>
                <span className = {styles.connectConfirmationButtons}>
                <button ref = {abandonButtonRef} className = {styles.abandonTask} onClick = {handleAbandon}>Unmatch</button>
                <button className = {styles.notNowButton} onClick = {exitWindow}>Go back</button>
                </span>
            </div>
        </div>
        </>
    )
}

export default AbandonTask;