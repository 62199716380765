import React, { useState, useRef, useEffect, useLayoutEffect, useContext} from 'react';
import styles from './Filters.module.css';
import Radius from './Radius/Radius';
import Type from './Type/Type';
import Price from './Price/Price';
import xBlack from '../../../../assets/images/XBlack.png'
import xWhite from '../../../../assets/images/XWhite.png'
import SearchContext from '../../../../Firebase Contexts/SearchContext/SearchContext';
import {getSearchedListings} from '../../../../Firebase';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';

const Filters = ({selectedType}) => {

  const {searchFilters, searchInput, prevSearchInput, setNewSearchFilters,
         filtersIdentifiedBySearch, setFiltersIdentifiedBySearch, maxRadiusSearched,
         searchedListings, setSearchedListings, setSearchFilters,
         initiateFilterBasedSearch, stoppedSearchingTasks, typesOfTasks,
         fetchedListings, setFetchedListings, isSearching, setIsSearching, prevLocation} = useContext(SearchContext);
         
  const {userPersistenceChecked, user, userLocation, userPreferredMeasurementSystem} = useContext(UserContext);
  const {savedListings, savedListingsLoaded, appliedListings, setAppliedListings,
         bannedListings, setBannedListings, lastDocumentListingFetched,
         setIsLoadingForTasks} = useContext(ListingsContext)
  const isMobile = window.targetPlatform == "mobile";

  //#region Radius
  const [radiusFilterSubmit, setRadiusFilterSubmit] = useState("");
  const [radiusValue, setRadiusValue] = useState("");

  const radiusValueRef = useRef(null);
  //#endregion

  //#region Type
  const [typeFilter, setTypeFilter] = useState(selectedType);
  const [typeFilterSubmit, setTypeFilterSubmit] = useState(selectedType);
  const typeRef = useRef(null);
    
  //#endregion

  //#region Price
  const [priceSubmit, setPriceSubmit] = useState('');

  const [pricePosition, setPricePosition] = useState({ top: 0, left: 0 });

  //used to automatically select the price input field
  const priceRef = useRef(null);
  const priceButtonRef = useRef(null);
  //#endregion

  //#region Date

  const [isDateUsed, setIsDateUsed] = useState(false);

  const [date, setDate] = useState("");

  const previousDateRef = useRef(null);

  const dateInputRef = useRef(null);

  const handleDateInput = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    nextYear.setHours(0, 0, 0, 0);
    //if the selected date is earlier than today or later than next year, don't update the date
    if (selectedDate < yesterday || selectedDate > nextYear) {
      event.target.value = previousDateRef.current || today.toISOString().split('T')[0];
    } else {
      previousDateRef.current = event.target.value;
      }
  };

  const handleDateSubmit = ()=>{
    if(previousDateRef.current){
    initiateFilterBasedSearch.current = true;
    // Parse the string to create a Date object
    const newDate = new Date(previousDateRef.current);
    setDate(newDate);
    }else{
      setIsDateUsed(false);
    }
  }
  //#endregion

  //#region Filter visibility control
  //Used to Update the component that's clicked
  const [showLocationAndRadius, setShowLocationAndRadius] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  const updateVisibility = (location, type, date, price, clickEvent) => {

    if(clickEvent.target.tagName=="IMG"){
      return;
    }
    
    setShowLocationAndRadius(location);
    setShowType(type);
    setShowDate(date);
    setShowPrice(price);
  };

  const locationAndRadiusVisibility = (clickEvent) => {
    window.setRadiusIsOpened(false);
    updateVisibility(!showLocationAndRadius, false, false, false, clickEvent || window.event);
  };
  const typeVisibility = (clickEvent) => {
    if(!isSearching){
      window.setRadiusIsOpened(false);
      updateVisibility(false, !showType, false, false, clickEvent || window.event);
    }
  };
  const dateVisibility = (clickEvent) => {
    if(!isSearching){
      window.setRadiusIsOpened(false);
      updateVisibility(false, false, !showDate, false, clickEvent || window.event);
    }
  };
  const priceVisibility = (clickEvent) => {
    if(!isSearching){
      window.setRadiusIsOpened(false);
      // Get the position of the Price button
      const priceButtonRect = priceButtonRef.current.getBoundingClientRect();
      
      // Pass the position as props to the Price component
      setPricePosition({ top: priceButtonRect.bottom, left: priceButtonRect.left });

      updateVisibility(false, false, false, !showPrice, clickEvent || window.event);
    }
  };

  const enableDate = () => {
    if(!isSearching){
      setIsDateUsed(true);
    }
  };

  useLayoutEffect(()=>{
    if (dateInputRef.current) {
      dateInputRef.current.focus(); // Focus on the date input
      dateInputRef.current.click(); // Trigger a click event to open the date picker
    }
  },[isDateUsed])
  
  //#endregion


  const handleXPress = (message)=>{
    initiateFilterBasedSearch.current = true;
    if(!isSearching){
      if(message=="location"){
        setFiltersIdentifiedBySearch((prevFilters)=>({
          ...prevFilters,
          location: {description:"", place_id:""},
        }))
      }else if(message=="radius"){
        if(maxRadiusSearched.current != 1){
          lastDocumentListingFetched.current = null;
          maxRadiusSearched.current = 1;
          setFetchedListings([]);
          //setIsNotFoundTasksVisible is located in at listedTasks
          window.setIsNotFoundTasksVisible(false);
          setSearchedListings([]);
          setTimeout(()=>{
            window.fetchMoreListings();
          },[50])
        }

        setFiltersIdentifiedBySearch((prevFilters)=>({
          ...prevFilters,
          radius: "",
        }))
      }else if(message=="type"){

        lastDocumentListingFetched.current = null;
  
        setFetchedListings([]);
        //setIsNotFoundTasksVisible is located in at listedTasks
        setSearchedListings([]);

        setFiltersIdentifiedBySearch((prevFilters)=>({
          ...prevFilters,
          type: "",
        }))
        window.setIsNotFoundTasksVisible(false);

        setTimeout(()=>{
          window.fetchMoreListings();
        },[50])

      }else if(message=="date"){
        setFiltersIdentifiedBySearch((prevFilters)=>({
          ...prevFilters,
          date: "",
        }))
        setIsDateUsed(false);
      }else if(message=="price"){
        setFiltersIdentifiedBySearch((prevFilters)=>({
          ...prevFilters,
          price: "",
        }))
      }
      setShouldRunFilterWidthCheck(true);
    };
  }
//#region Apply correct style on filters based on container width
const containerRef = useRef(null);
const [isOverflowing, setIsOverflowing] = useState(false);
//Allows the useLayoutEffect to check whether the filter div overflows the mainmenu div
const [shouldRunFilterWidthCheck, setShouldRunFilterWidthCheck] = useState(false);
const [initialRender, setInitialRnder] = useState(true);
window.setShouldRunFilterWidthCheck = setShouldRunFilterWidthCheck;

//This function double initializes to slow down execution of the code for the filterwidth to be valid,
//solves initial position/problem
useLayoutEffect(()=>{
  if(initialRender){
    setInitialRnder(false);
  }

  if(!initialRender)
    {if (containerRef.current.scrollWidth > window.filterWidth.current) {
      //console.log("is overflowing " + containerRef.current.scrollWidth + " > " + filterWidth);
      setIsOverflowing(true);
    } else {
      //console.log("is NOT overflowing " + containerRef.current.scrollWidth + " < " + filterWidth);
      setIsOverflowing(false);
    }}
})

useLayoutEffect(()=>{
  if(shouldRunFilterWidthCheck){
    if (containerRef.current.scrollWidth > window.filterWidth.current) {
      //console.log("is overflowing " + containerRef.current.scrollWidth + " > " + filterWidth);
      setIsOverflowing(true);
    } else {
      //console.log("is NOT overflowing " + containerRef.current.scrollWidth + " < " + filterWidth);
      setIsOverflowing(false);
    }
    setShouldRunFilterWidthCheck(false);
  }

  //console.log(containerRef.current.scrollWidth +" lol "+filterWidth);
});

//#endregion

//Update the filters inside the context
useEffect(()=>{
  setNewSearchFilters(searchFilters.location, radiusValue, typeFilterSubmit, date, priceSubmit);
  fetchListings();
},[radiusFilterSubmit, typeFilterSubmit, date, priceSubmit])


const fetchListings = async()=>{
  if(searchFilters.location.description!="" || userLocation.latitude != null){
    let readyToShowResults;
    if(userPersistenceChecked && user.id){
      readyToShowResults = (savedListingsLoaded.current)?true:false;
    }else if(userPersistenceChecked && user.id==null){
      readyToShowResults = true
  }

    if(initiateFilterBasedSearch.current && readyToShowResults){
      setIsSearching(true);
      initiateFilterBasedSearch.current = false;
      //Create new const object with the updated values
      const updatedFilters = {
        location: searchFilters.location,
        radius: radiusFilterSubmit,
        type: typeFilterSubmit,
        date: date,
        price: priceSubmit,
      }
    const calledFromFilters = true;
    const calledFromListings = false;

    setIsLoadingForTasks(true);

    // console.log("came from filters");
      
    await getSearchedListings(calledFromFilters, calledFromListings, fetchedListings, setFetchedListings,
      searchInput, updatedFilters, setFiltersIdentifiedBySearch, userLocation,
      setSearchedListings, stoppedSearchingTasks,
      savedListings, appliedListings, bannedListings, lastDocumentListingFetched,
      typesOfTasks, userPreferredMeasurementSystem);

      setIsLoadingForTasks(false);
    }
    setIsSearching(false);
  }else {
    // console.log("user location required");
  }
}

useEffect(()=>{
  
  setRadiusValue(filtersIdentifiedBySearch.radius);
  setRadiusFilterSubmit(filtersIdentifiedBySearch.radius);

  setTypeFilter(filtersIdentifiedBySearch.type);
  setTypeFilterSubmit(filtersIdentifiedBySearch.type);

  setDate(filtersIdentifiedBySearch.date)

  setPriceSubmit(filtersIdentifiedBySearch.price);
},[filtersIdentifiedBySearch])


// maou maou maou
  return (
    <div className = {(isMobile? styles.mobileContainer:styles.desktopContainer)}>
            <div className={isOverflowing? styles.overflownFilterContainer: styles.overflownFilterContainer}
      style={{ width: `${window.filterWidth.current}px`, top: isMobile ? '6.28em' : '7.25em'}} ref={containerRef}
      >
      {/*-----------------Type---------------------------------------------*/}
      <div className={styles.filterWrapper}>
        <div className={(!isSearching && showType)? `${styles.filterElement} ${styles.selectedFilter}`: styles.filterElement} onClick={typeVisibility}>
          <span className={(!isSearching && showType)? `${styles.filterElementText} ${styles.selectedFilterText}` : styles.filterElementText}
          style={typeFilterSubmit === "" ? { paddingRight: '15px' } : null}>{typeFilterSubmit ? typeFilterSubmit : "Type of task"}</span>
          {typeFilterSubmit !="" && (<img onClick = {()=>handleXPress('type')}
          src={(showType)? xWhite:xBlack} alt="Remove Filter" className={styles.x} />)}
        </div>
      </div>
      {/*-----------------Radius---------------------------------------------*/}
      <div className={styles.filterWrapper}>
        <div className={(!isSearching && showLocationAndRadius)?`${styles.filterLocation} ${styles.selectedFilter}`:styles.filterLocation} onClick={()=>locationAndRadiusVisibility()}>
          <span className={(!isSearching && showLocationAndRadius)?`${styles.filterElementText} ${styles.selectedFilterText}`:styles.filterElementText}
          style={radiusFilterSubmit == 0 ? { paddingRight: '15px' } : null}>{(radiusFilterSubmit) ? (radiusFilterSubmit==1)?"Exact location":radiusFilterSubmit + " Km" : "Distance"}</span>
          {radiusFilterSubmit != 0 && (<img onClick = {()=>handleXPress('radius')}
          src={(showLocationAndRadius)? xWhite:xBlack} alt="Remove Filter" className={styles.x} />)}
        </div>
      </div>
      {/*----------------THE END----------------------------------------------*/}
    </div>
      {showLocationAndRadius && <Radius
        locationVisibility={locationAndRadiusVisibility}
        radiusValueRef = {radiusValueRef}
        radiusValue = {radiusValue}
        setRadiusValue = {setRadiusValue}
        setRadiusFilterSubmit = {setRadiusFilterSubmit}
      />}
      {showType && <Type
        setTypeFilter={setTypeFilter}
        setTypeFilterSubmit={setTypeFilterSubmit}
        typeVisibility={typeVisibility}
        inputRef={typeRef}
      />}
    </div>
  );
};

export default Filters;


