import React, { useRef, useState, useEffect } from 'react';
import UserContext from './UserDataContext';

function UserContextProvider({ children }) {

  const [user, setUser] = useState({
    id: null,
    name: "",
    surname: "",
    profilePhoto: null,
    email: "",
  });

  const [userProfile, setUserProfile] = useState({
    candidateRating: null,
    candidateTopTasks: null,
    candidateReviews: null
  });
  
  const [userPreferredCountry, setUserPreferredCountry] = useState({
    preferredCountry: "",
    preferredCountryCode: "",
  });
  
  const [userPreferredMeasurementSystem, setUserPreferredMeasurementSystem] = useState("");

  //#region Change User data
  const setUserData = (id, name, surname, phoneNumber, profilePhoto, email) => {
    setUser(prevUser => ({
       ...prevUser,
       //if the prop values are null, keep the already existing values
       id: id? id : user.id,
       name:  name? name : user.name,
       surname: surname? surname : user.surname,
       phoneNumber: phoneNumber? phoneNumber : user.phoneNumber,
       profilePhoto: profilePhoto? profilePhoto : user.profilePhoto,
       email: email? email : user.email,
      }))
      //console.log( id ,name, surname, phoneNumber,profilePhoto, email);
  };

  const setUserNameSurname = (name, surname)=>{
    setUser.name(name);
    setUser.surname(surname);
  }

  const setUserProfilePhoto = (profilePhoto)=>{
    setUser.profilePhoto(profilePhoto);
  }
  //#endregion

  const authListenerCompleted = useRef(false);
  const [userPersistenceChecked, setUserPersistenceChecked] = useState(false);

  const checkIfPersitenceIsEnded = ()=>{
    setUserPersistenceChecked(true)
  }

  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const [userSelectedTypes, setUserSelectedTypes] = useState([]);


  //#region check for task updates
  const alreadyCheckedForTasks = useRef(false);

//#endregion

return (
    <UserContext.Provider value={{ user, setUser, setUserData, setUserNameSurname,
    setUserProfilePhoto, authListenerCompleted, userPersistenceChecked, setUserPersistenceChecked, checkIfPersitenceIsEnded,
    userLocation, setUserLocation, alreadyCheckedForTasks, userPreferredCountry, setUserPreferredCountry,
    userSelectedTypes, setUserSelectedTypes, userPreferredMeasurementSystem, setUserPreferredMeasurementSystem}}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;