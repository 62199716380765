import styles from './ListedTasks.module.css';
import { useRef, useEffect, useState, useContext } from 'react';
import ListedTask from "../../../Tasks/ListedTask";
import SearchContext from '../../../../Firebase Contexts/SearchContext/SearchContext';
import loadingIcon from '../../../../assets/images/loading.png'
import noTaskFoundIMG from '../../../../assets/images/NoTaskFound.png'
import {getSearchedListings} from '../../../../Firebase';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';

const ListedTasks = () => {
   const {searchFilters, searchInput, prevSearchInput, setNewSearchFilters,
    filtersIdentifiedBySearch, setFiltersIdentifiedBySearch, transitioned,
    searchedListings, setSearchedListings, typesOfTasks, currentLocation,
    initiateFilterBasedSearch, stoppedSearchingTasks, endedTransition,
    fetchedListings, setFetchedListings, isSearching, setIsSearching, prevLocation,
    suggestedLocationAlternative } = useContext(SearchContext);
    
    const {savedListings, savedListingsLoaded, appliedListings, setAppliedListings,
      bannedListings, setBannedListings, lastDocumentListingFetched,
      isLoadingForTasks, setIsLoadingForTasks, differentSearchIsLoading,
      setDifferentSearchIsLoading, noLocationAvailable, setNoLocationAvailable
    } = useContext(ListingsContext)
      
    const {userLocation, userPreferredMeasurementSystem} = useContext(UserContext);

  const isMobile = window.targetPlatform == "mobile";

  const [listedTasksShouldReRender, setListedTasksShouldReRender] = useState(false);
  window.listedTasksShouldReRender = listedTasksShouldReRender;
  window.setListedTasksShouldReRender = setListedTasksShouldReRender;

  const [isNotFoundTasksVisible, setIsNotFoundTasksVisible] = useState(false);
  window.setIsNotFoundTasksVisible = setIsNotFoundTasksVisible;

  useEffect(()=>{
    if(stoppedSearchingTasks){
      setListedTasksShouldReRender(!listedTasksShouldReRender)
    }
  },[stoppedSearchingTasks])

  const containerRef = useRef(null);

  useEffect(()=>{
    if(window.typeShouldBeVisible == false){
      containerRef.current.classList.add(styles.hidden);
    } else {
      containerRef.current.classList.remove(styles.hidden);
    }
  },[window.typeShouldBeVisible])

  const [isBottom, setIsBottom] = useState(false);
  const [noResultsReturned, setNoResultsReturned] = useState(false);

  const [moreListingsLoading, setMoreListingsLoading] = useState(false);

  //#region Automatic listings fetch
  useEffect(()=>{
  },[differentSearchIsLoading])
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    // Check if the user has scrolled to the bottom of the page
    if (scrollTop + clientHeight >= scrollHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    // Add an event listener to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchMoreListings = async() => {

    if((searchFilters.location.description!="" || userLocation.latitude != null)){
      setIsLoadingForTasks(true);
      setDifferentSearchIsLoading(true);
      setIsNotFoundTasksVisible(false);
      setTimeout(()=>{
        setMoreListingsLoading(true);
      },[50])

      const calledFromFilters = false;
      const calledFromListings = true;
      const result = await getSearchedListings(calledFromFilters, calledFromListings, fetchedListings, setFetchedListings,
        searchInput, searchFilters, setFiltersIdentifiedBySearch, userLocation,
        setSearchedListings, stoppedSearchingTasks,
        savedListings, appliedListings, bannedListings, lastDocumentListingFetched,
        typesOfTasks, userPreferredMeasurementSystem);

        setDifferentSearchIsLoading(false);
        setMoreListingsLoading(false);
        setIsLoadingForTasks(false);
        setIsNotFoundTasksVisible((searchedListings==0 && result == 0)?true:false);
        setNoResultsReturned((result<10)?true:false);
    }else {
      // console.log("user location required");
    }
  }
  window.fetchMoreListings = fetchMoreListings;

  useEffect(() => {
    // If bottom is reached, fetch more listings
    if (isBottom && transitioned && !isLoadingForTasks) {

      fetchMoreListings();
    }
  }, [isBottom]);
  //#endregion

  return (
    <div ref = {containerRef} className={isMobile ? styles.mobileListedTasksLayout : styles.desktopListedTasksLayout}>
      {/* {console.log(searchedListings)} */}
      {differentSearchIsLoading && isLoadingForTasks && <img className={(isMobile)?styles.loadingIconStyleMobile:styles.loadingIconStyleDesktop} src = {loadingIcon} alt = "Loading"/>}
      {/* Use map to render searchedListings components */}
      {(searchFilters.location.description!="" || userLocation.latitude !=null) && searchedListings.map((listing, index) => (
        <ListedTask key={index} listing={listing}
                    isSaved = {listing.isSaved}
                    hasApplied = {listing.hasApplied}
                    />
      ))}
      { !isMobile && <div style = {{height: "4em"}}/>}
      {!isLoadingForTasks && isNotFoundTasksVisible && moreListingsLoading && <img className={(isMobile)?styles.loadingIconStyleMobile:styles.loadingIconStyleDesktop} src = {loadingIcon} alt = "Loading"/>}
      {!noLocationAvailable && isNotFoundTasksVisible && searchedListings.length == 0 && !isLoadingForTasks && <div className = {styles.noTasksFoundContainer} style = {(isMobile)?{marginTop:"0em"}:{marginTop:"-4em"}}>
        { <p className = {styles.noTasksMessage}><span style = {{fontSize:"1.25em", marginRight:"1em"}}>{(currentLocation == "" || currentLocation == null || currentLocation == undefined)?"No results found":"We couldn't find any tasks in"} <span style = {{fontWeight: "bold", color: "rgb(44,44,44)"}}>{currentLocation.split(',')[0].trim()}</span></span></p>}
        { suggestedLocationAlternative!= null && suggestedLocationAlternative.length > 1 && <p className = {styles.alternativeLocations}><span style = {{color: "rgb( 44, 44, 44)", marginLeft:  isMobile ? "0em" : "0em"}}>Not the location you're looking for?</span>  {isMobile && <br />} <span style = {{color: "rgb(50, 85, 160)", textDecoration: "underline", cursor:"pointer", marginLeft: isMobile ? "0em" : "0em"}} onClick = {()=>window.setShowAlternativeLocations(true)}>Choose from the alternatives</span></p>}
        { (suggestedLocationAlternative && suggestedLocationAlternative.length <= 1 ) && <p className = {styles.noTasksMessage}>Try a different search</p>}
        { <img src = {noTaskFoundIMG} className = {styles.noTasksIcon} alt = "No tasks found"/>}
      </div>}

      { endedTransition && noLocationAvailable && <p className = {styles.noLocationExists}>Select a place or enable your location to find tasks near you.</p>}

      { (searchFilters.location.description!="" || userLocation.latitude !=null) &&
      noResultsReturned &&  !isLoadingForTasks && searchedListings.length > 0 && <div className = {(isMobile)? styles.noResultsReturnedMobile : styles.noResultsReturned}>There are no more results for this search</div>}

    </div>
  );
}

export default ListedTasks;
