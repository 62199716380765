import styles from './Policies.module.css';
import React, {useEffect} from 'react';
import logo from '../../assets/images/LogoTwo.png';

const CookiePolicy = () => {

    const isMobile = window.targetPlatform == "mobile";

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto' // You can use 'auto' instead of 'smooth' for instant scrolling
        });
  }, []);

    return(
    <div className = {(isMobile)?styles.contentContainerMobile:styles.contentContainerDesktop}>

        <div className = {(isMobile)?styles.logoContainerMobile:styles.logoContainerDesktop} onClick={() => window.location.href = "https://www.taskare.com"}>
            <img src = {logo} className={styles.logo} alt = "Taskare Logo"/>
        </div>

        <p className={styles.categoryTextTitle}>Cookie Policy</p>

        <p className={styles.textTitle}>1. Authentication Cookies:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We use cookies to authenticate users and maintain their session information. These cookies are essential for the proper functioning of our website.</p>
        <p className={styles.textTitle}>2. Persistence Between Sessions:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>Cookies are employed to remember users and maintain their login status between sessions, providing a seamless and personalized user experience.</p>
        <p className={styles.textTitle}>3. Duration:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>Our authentication cookies have a persistent duration to ensure users remain logged in across sessions.</p>
        <p className={styles.textTitle}>4. User Consent:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>By using our website, you consent to the use of authentication cookies for the purposes outlined in this policy.</p>
        <p className={styles.textTitle}>5. Opt-Out:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>You may choose to disable cookies through your browser settings, but please note that doing so may affect the functionality of our authentication system.</p>
        <p className={styles.textTitle}>6. Third-Party Cookies:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We do not use third-party cookies for tracking or advertising purposes.</p>
        <p className={styles.textTitle}>7. Cookie Management:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>Users can manage their cookie preferences through their browser settings.</p>
        <p className={styles.textTitle}>8. Updates:</p>

        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>This cookie policy may be updated from time to time. Any changes will be effective immediately upon posting.</p>
    </div>
    )
}

export default CookiePolicy;