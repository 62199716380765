import React, { useState, useRef, useEffect, useContext } from "react";
import styles from './App.module.css'
import AppButtons from "./components/AppButtons/AppButtons";
// Import core components
import MainContent from "./components/Main Content/MainContent";
import SignIn from "./components/UserSign/Sign In/SignIn";
import SignUp from "./components/UserSign/Sign Up/SignUp";
import {CheckIfAuthListenerCompleted} from './Firebase';
import UserContext from "./Firebase Contexts/UserDataContext";
import SearchContextProvider from "./Firebase Contexts/SearchContext/SearchContextProvider";
import UIContextProvider from "./Firebase Contexts/UIContext/UIContextProvider";
import TermsOfService from "./components/Policies/TermsOfService";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import CookiePolicy from "./components/Policies/CookiePolicy";
import AboutUs from "./components/Core Components/AboutUs/AboutUs";
import ContactUs from "./components/Core Components/ContactUs/ContactUs";

function App() {
  const {checkIfPersitenceIsEnded} = useContext(UserContext);

  const isMobile = /Mobi/i.test(navigator.userAgent);
  const isTablet = /Tablet|iPad/i.test(navigator.userAgent);
  const selectedPlatform = isMobile && !isTablet ? true : false;
  
  const [isLightTheme, setIsLightTheme] = useState(true);
  window.isLightTheme = isLightTheme;
  window.setIsLightTheme = setIsLightTheme;
  window.targetPlatform = (selectedPlatform? "mobile":"desktop");

  const scrollToTop = (isSmooth) => {
    window.scrollTo(isSmooth ? { top: 0, behavior: 'smooth' }:{ top: 0 })
  };

  window.scrollToTop = scrollToTop;

  const shouldScroll = (shouldScroll)=>{

    if(shouldScroll){
      document.body.style.overflow = 'auto';
    }else{
      document.body.style.overflow = 'hidden';
    }
  }
  window.shouldScroll = shouldScroll;

  const getMatchingKeyword = () => {
    const currentURL = window.location.href;
  
    if (currentURL.includes("aboutus")) {
      return "aboutus";
    } else if (currentURL.includes("contactus")) {
      return "contactus";
    } else if (currentURL.includes("termsofservice")) {
      return "termsofservice";
    } else if (currentURL.includes("privacypolicy")) {
      return "privacypolicy";
    } else if (currentURL.includes("cookiepolicy")) {
      return "cookiepolicy";
    } else {
      return ""; // Return null if no match is found
    }
  };
  
  const currentURL = getMatchingKeyword();

  const DesktopVersion = ()=>{

    return(
        <div className = {styles.desktopContainer}>

          {currentURL == "aboutus" && <AboutUs />}
          {currentURL == "contactus" && <ContactUs />}

          {currentURL == "termsofservice" && <TermsOfService />}
          {currentURL == "privacypolicy" && <PrivacyPolicy />}
          {currentURL == "cookiepolicy" && <CookiePolicy/>}
          
          {currentURL == "" && <AppButtons />}

          {currentURL == "" &&
          <UIContextProvider>
            <MainContent />
          </ UIContextProvider>}
        </div>
      );
  }

  const MobileVersion = ()=>{
    return (
      <>
      <div className={styles.mobileButtonsContainer}>          
        {currentURL == "" && <AppButtons />}
      </div>
      
      {currentURL == "aboutus" && <AboutUs />}
      {currentURL == "contactus" && <ContactUs />}

      {currentURL == "termsofservice" && <TermsOfService />}
      {currentURL == "privacypolicy" && <PrivacyPolicy />}
      {currentURL == "cookiepolicy" && <CookiePolicy/>}

      {currentURL == "" &&
      <UIContextProvider>
        <MainContent />
      </ UIContextProvider>}
      </>
    );
  }

return (
    <div>
      <SearchContextProvider>
          <CheckIfAuthListenerCompleted checkIfPersitenceIsEnded = {checkIfPersitenceIsEnded}/>
          <style>{isLightTheme ?  'body {background-color: rgb(255,255,255)}': 'body { background-color: rgb(50,54,59)}' }</style>
          <style>{window.targetPlatform =="mobile" ? 'body { margin-top: 3em; } body {margin-bottom: 3em}': 'body { margin-top: 0; }'}</style>
          {window.targetPlatform =="mobile" ? <MobileVersion/> : <DesktopVersion/>}
          <SignIn />
          <SignUp />
      </SearchContextProvider>
    </div>
  );
}

export default App;
