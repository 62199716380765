import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './Assigned.module.css';
import sampleProfile from '../../../assets/images/ProfileSample.png';
import time from '../../../assets/images/time.png'
import locationImg from '../../../assets/images/location.png'
import {getUserProfilePicture, markTaskCompletedViaClient, abandonTaskViaVendor,
getUserById, getUserReviews, sendConfirmationTaskRequestNotification,
deleteConversationRoom} from '../../../Firebase'
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';
import SearchContext from '../../../Firebase Contexts/SearchContext/SearchContext';
import UserContext from '../../../Firebase Contexts/UserDataContext';

const Assigned = ({ task }) => {

  const isMobile = window.targetPlatform == "mobile";
  const {appliedListings, setAppliedListings, setMineListings,
    assignedTasks, setAssignedTasks, historyListings,
    setHistoryListings, selectedAssignedTask, setSelectedAssignedTask} = useContext(ListingsContext);
  const [expanded, setExpanded] = useState(false);

  const {user} = useContext(UserContext);
  const {setCompleteTaskData, setAbandonTaskData, setProfilePicturesId, setCurrentRoomIndex,
    setThereIsSelectedDiscussion, setRooms} = useContext(UIContext);
  const {searchedListings, setSearchedListings} = useContext(SearchContext);

  const parentRef = useRef(null);

  const getCurrency = (isoCode) => {
    const currencyMap = {
      "usd": "$",
      "cad": "$",
      "gbp": "£",
      "eur": "€",
      "aud": "$",
      "nzd": "$",
      "dkk": "kr",
      "sek": "kr",
      "nok": "kr",
      "zar": "R",
    };
    return currencyMap[isoCode] || null;
  };
  
  const currency = useRef(getCurrency(task.currency));

  //#region Description animation handling

const descriptionContainerRef = useRef(null);

const descriptionIsExpanded = useRef(false);


const handleDescriptionExpansion = ()=>{
  let isExpanded = descriptionIsExpanded.current;
  if(!isExpanded){
    descriptionContainerRef.current.classList.remove(styles.initialDescriptionHeight);
    descriptionContainerRef.current.classList.remove(styles.descriptionCollapsed);
    descriptionContainerRef.current.classList.add(styles.descriptionExpanded);
  }else if(isExpanded){
    scrollToParent();
    descriptionContainerRef.current.classList.remove(styles.descriptionExpanded);
    descriptionContainerRef.current.classList.add(styles.descriptionCollapsed);
  }
  descriptionIsExpanded.current = !isExpanded;
};
//#endregion

  //#region scroll to top on closing listing
  const scrollToParent = () => {
    const parentElement = parentRef.current;
    const scrollOffset = -50; // Adjust this value to scroll higher than the parent
  
    const parentRect = parentElement.getBoundingClientRect();
    const parentTop = parentRect.top + window.pageY;
    const scrollPosition = parentTop + scrollOffset;
    const currentScrollPosition = window.pageY;
  
    if (currentScrollPosition > scrollPosition) {
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };
  //#endregion

  //#region Time and date
  const getCurrentTime = (withAMorPM) => {
    const selectedTime = task.time;
  
    // Parse hours and minutes
    const [hours, minutes] = selectedTime.split(":").map(Number);
  
    // Format hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    // Set AM/PM
    const getAmOrPm = hours < 12 ? "AM" : "PM";
  
    // Combine formatted hours and minutes with AM/PM if necessary
    const formattedTime = withAMorPM
      ? `${formattedHours}:${formattedMinutes} ${getAmOrPm}`
      : `${formattedHours}:${formattedMinutes}`;
  
    return formattedTime;
  };

  const getDate = ()=>{
    const seconds = task.date.seconds;
    const nanoseconds = task.date.nanoseconds;
  
    // Convert nanoseconds to milliseconds (divide by 1,000,000)
    const milliseconds = nanoseconds / 1000000;
  
    // Create a JavaScript Date object
    const date = new Date(seconds * 1000 + milliseconds);
    const localizedDateString = date.toLocaleDateString();
    
    return localizedDateString;
  }
//#endregion

const [clientProfilePicture, setClientProfilePicture] = useState((task.clientProfilePicture!=null)?task.clientProfilePicture: sampleProfile);
const showClientProfilePicture = async () => {
  //Save client profile picture
  const newClientProfilePicture = await getUserProfilePicture(task.uid);
  if(newClientProfilePicture==null){return;}
  // Create a new version of assignedTasks with the updated client
  const updatedAssignedTasks = assignedTasks.map((client) => {
    if (client.uid === task.uid) {
      // Create a new client object with the updated profile picture
      return { ...client, clientProfilePicture: newClientProfilePicture };
    }
    return client; // Return the original client for others
  });
  
  // Update the entire assignedTasks array with the updated version
  setAssignedTasks(updatedAssignedTasks);
  
  // Update the clientProfilePicture state with the new profile picture
  setClientProfilePicture(newClientProfilePicture);
}

const abandonTask = async ()=>{
  const taskAbandonedViaVendor = true;

  const currentListing = assignedTasks.findIndex(item => item.id === selectedAssignedTask);
  
  const result = await abandonTaskViaVendor(assignedTasks[currentListing].uid, user.name, user.profilePhoto, assignedTasks[currentListing].listingId, assignedTasks[currentListing].selectedApplicant, assignedTasks[currentListing].title, assignedTasks[currentListing].roomId, taskAbandonedViaVendor);
  if(result!=null){
    //#region update messages
    setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== assignedTasks[currentListing].roomId));
    setRooms((prev) => prev.filter(item => item.roomId !== assignedTasks[currentListing].roomId));
    setCurrentRoomIndex("");
    setThereIsSelectedDiscussion(false);
    //#endregion
    setSelectedAssignedTask(null);
    setAssignedTasks((prevAssignedTasks) => {
      // Use filter to create a new array without the task to be removed
      const updatedAssignedTasks = prevAssignedTasks.filter((assignedTask) => {
        return assignedTask.id !== assignedTasks[currentListing].id;
      });
    
      // Return the updated array to set the state
      return updatedAssignedTasks;
    });
  }

  setAppliedListings((prevAppliedListings)=>{
    const updatedAppliedListings = prevAppliedListings.filter((appliedTask)=>{
      return  appliedTask.id !== assignedTasks[currentListing].listingId;
    });

    return updatedAppliedListings;
  })

  setSearchedListings((prevSearchedListings) => {
    return prevSearchedListings.map((listing) => {
      if (listing.id === assignedTasks[currentListing].listingId) {
        return { ...listing, hasApplied: false };
      }
      return listing;
    });
  });

  return result;
}

const requestTaskCompletionByUser = async(clientId, userId, vendorName, vendorProfilePicture, assignedListingId, listingId, listingTitle, roomId) =>{
  const result = await sendConfirmationTaskRequestNotification(clientId, userId, vendorName, vendorProfilePicture, assignedListingId, listingId, listingTitle, roomId);
  
  if(result){
    task.pendingConfirmation = true;

    setAssignedTasks(prev => {
      if (prev.id === task.id) {
        return {
          ...prev,
          pendingConfirmation: true
        };
      } else {
        return prev;
      }
    });
    
    return true;
  }else{
    return false;
  }
}

const disableStatusWindow = ()=>{
  window.setUpdateTaskStatus(false);
}
const disableAbandonWindow = ()=>{
  window.setOpenAbandonTask(false);
  window.exitOtherProfiles();
}

const openStatusWindow = async ()=>{
  setCompleteTaskData({
    disableStatusWindow: disableStatusWindow,
    requestTaskCompletionByUser: requestTaskCompletionByUser,
    abandonTask: abandonTask,
    task: task,
  })
  window.setUpdateTaskStatus(true);
}

const openAbandonWindow = async ()=>{
  setAbandonTaskData({
    disableAbandonWindow: disableAbandonWindow,
    abandonTask: abandonTask,
    taskId: task.id,
  });

  window.setOpenAbandonTask(true);
}

window.openAbandonWindowViaVendor = openAbandonWindow;


useEffect(()=>{
  if(task.clientProfilePicture==null){
    showClientProfilePicture();
  }
},[assignedTasks])


const fetchUserReviews = async()=>{
  const userReviews = await getUserReviews(task.uid);

  return userReviews;
 }

const showUser = async()=>{
  setSelectedAssignedTask(task.id);
  const showAbandonOption = true;
  const isVendor = true;
  const user = await getUserById(task.uid)
  window.showProfile(user.profilePicture, user.name, user.surname, user.stars, user.topTasks, await fetchUserReviews(), null, showAbandonOption, isVendor);
}

const openMessages = ()=>{
  if(!isMobile){
    //window.openMessages is located in MessagingSystem.js
    window.openMessages();
  }else{
    //window.openMessages is located in AppButtons.js
    window.mobileCheckIfButtonShouldExecute("Messages")
  }
}

  return (
    <>
      <div ref={parentRef} className={(isMobile)? styles.mobileTaskBody: styles.desktopTaskBody}>
      {<div onClick = {handleDescriptionExpansion} className = {styles.expansionButton}/>}
        <div>
          <div className={styles.header}>
            <h1 className={styles.title}>{task.title}</h1>
          </div>

          <span className={styles.locationContainer}>
              <img src = {locationImg} className = {styles.filterIcon} alt = "Location"/>
            <p className = {styles.detailText} style = {{fontSize: "1.05em", fontWeight:"650"}}>{task.location.description.split(',')[0].trim()}</p>
            </span>
          <div className={styles.details}>
            <span className={styles.detailContainer}>
              <p className={styles.detailText}>{task.type}</p>
            </span>
            <span className={styles.detailContainer}>
              <img src = {time} className = {styles.filterIcon}/>
              <p className={styles.detailText}>{(task.duration.hours != 0) ? ((task.duration.hours < 2) ? 1 + " hour" : task.duration.hours + " hours") : ""} {(task.duration.minutes != 0) ? ((task.duration.minutes < 2) ? 1 + " minute" : task.duration.minutes + " minutes") : ""}</p>
            </span>
            <span className={styles.detailContainer}>
              <p className={styles.detailText}>{(currency.current == "$")? currency.current + task.price : task.price + currency.current}</p>
            </span>
          </div>

          <div className = {styles.dateBookmarkContainer}>
              <p className={styles.desiredDate}>Needed for {getDate()} at {getCurrentTime(true)}</p>
            
            <div className = {styles.clientContainer}>
              <p className = {styles.contactVendorText} onClick = {()=>openMessages()}>Message</p>

              <img
                src={(clientProfilePicture!=null)?clientProfilePicture:sampleProfile}
                alt=''
                className={styles.client}
                onClick={()=>showUser()}
              />
            </div>
            
          </div>

          <div className={`${styles.descriptionContainer} ${styles.initialDescriptionHeight}`}
          onClick={handleDescriptionExpansion}
          ref = {descriptionContainerRef}>
            <div className = {styles.descriptionLine}/>
            {task.description}
          </div>
          <div className={styles.viewDescription}>
          </div>
        
        </div>
    </div>
  </>
  );
};

export default Assigned;
