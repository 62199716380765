import styles from  './CategoriesContent.module.css';
import React, {useRef, useState, useEffect, useContext} from 'react';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import ImageCrop from '../../ImageCrop/ImageCrop';
import UIContext from '../../../../Firebase Contexts/UIContext/UIContext';
import {forgotPassword, getUserById ,getUserReviews, saveMeasurementSystemChanges} from '../../../../Firebase';
import confirmationIcon from '../../../../assets/images/confirmation.png'
import loading from '../../../../assets/images/loading.png'
import SearchContext from '../../../../Firebase Contexts/SearchContext/SearchContext';
import Resizer from "react-image-file-resizer";
import Profile from '../../Profile/Profile';

const CategoriesContent = ({selectedContent, resetSelectedContext, setIsSelectedContentEnabled, setCategoriesAreHidden, closeCategoriesContentIsVisible}) => {

  const isMobile = window.targetPlatform == "mobile";
  const {user, userPreferredCountry} = useContext(UserContext);
  const {imageDataCrop, setImageDataCrop, imageIsLoading, setImageIsLoading,
    fileLimitReachedError, setFileLimitReachedError, measurementSystemButtonPressed} = useContext(UIContext);

//#region Slide animation
  const categoriesContentRef = useRef(null);
  const categoriesContentBackgroundRef = useRef(null);

  useEffect(()=>{
      if(isMobile){
      categoriesContentRef.current.classList.add(styles.slideIn);
      categoriesContentBackgroundRef.current.classList.add(styles.slideIn);
  }
  },[])

  const handleAnimEnd = (event)=>{
      if(event.animationName.includes("slideOut")){
        closeCategoriesContentIsVisible();
        resetSelectedContext();
    }else if(event.animationName.includes("slideIn")){
      if(isMobile){setCategoriesAreHidden(true);}
    }
  }

  const initializeCategoryExit = ()=>{
    categoriesContentRef.current.classList.add(styles.slideOut);
    if(isMobile){categoriesContentBackgroundRef.current.classList.add(styles.slideOut);}
  } 
  window.initializeCategoryExit = initializeCategoryExit;

  const handleAnimStart = (event) => {
    if(event.animationName.includes("slideIn")){
      setIsSelectedContentEnabled(true);
      categoriesContentRef.current.classList.add(styles.highContainerIndex);
      if(isMobile){categoriesContentBackgroundRef.current.classList.add(styles.highContainerIndex);}
    }else if(event.animationName.includes("slideOut")){
      if(isMobile){setCategoriesAreHidden(false);}
      setIsSelectedContentEnabled(false);
    }
  }

  const changeAccountSettingsIndex = () => {
      categoriesContentRef.current.classList.remove(styles.highContainerIndex);
      if(isMobile){categoriesContentBackgroundRef.current.classList.remove(styles.highContainerIndex);}
      categoriesContentRef.current.classList.add(styles.lowContainerIndex);
      if(isMobile){categoriesContentBackgroundRef.current.classList.add(styles.lowContainerIndex);}
  }
  window.changeAccountSettingsIndex = changeAccountSettingsIndex;

  //#endregion
    
  const ChangeProfilePicture = ()=>{

    const [imageSrc, setImageSrc] = useState(user.profilePhoto);
    const hiddenFileInputRef = useRef(null);
    const currentImgRef = useRef(null);

    const handleImageClick = () => {
      if(!imageIsLoading){
        hiddenFileInputRef.current.click();
      }
    };

    const handleImageUpload = async (event) => {
      const selectedImage = event.target.files[0]; // Get the selected image from the input
    
      if (selectedImage && !imageIsLoading) {
        if(selectedImage.size <= 20 * 1024 * 1024){
          const reader = new FileReader();
          setFileLimitReachedError(false);

          reader.onload = (e) => {
            const imageDataUrl = e.target.result;
      
            setImageSrc(imageDataUrl); // Update the imageSrc state with the selected image data
            setImageDataCrop(prev => ({ ...prev, photoIsSaved: false, uploadedPhoto: imageDataUrl }));
            window.setIsImageCropperVisible(true);
      
            // Revoke the object URL to avoid memory leaks
            URL.revokeObjectURL(imageDataUrl);
          };
      
          await reader.readAsDataURL(selectedImage);
        }else{
          setFileLimitReachedError(true);
        }
      }
    };

  const handleEdit = ()=>{
    setImageDataCrop(prev=>({...prev, photoIsSaved: false, uploadedPhoto: prev.initialUploaded}));
    window.setIsImageCropperVisible(true);
  }

  return(
      <div className={styles.changeProfilePictureStyleContainer}>
          <p className={styles.categoryTextTitle}>Change your profile picture</p>
          <img src = {imageDataCrop.latestCroppedImage} className = {(isMobile)?styles.profilePictureMobile:styles.profilePictureDesktop} onClick = {(imageDataCrop.photoIsSaved && imageDataCrop.newProfilePicture != null && !imageIsLoading)?()=>handleEdit():()=>handleImageClick()} alt = "Profile Picture"/>
          {imageIsLoading && <img src = {loading} className = {styles.loadingIcon} alt = "Loading"/>}
          {!imageIsLoading && imageDataCrop.newProfilePicture != null && <p className={styles.editProfilePictureText} onClick = {handleEdit}>Edit</p>}
          {!imageIsLoading && !window.isImageCropperVisible && <p className={styles.textButtonStyle} style = {{marginTop:(imageDataCrop.photoIsSaved)?"0em":"1em"}} onClick = {handleImageClick}>Change your profile picture</p>}
          {fileLimitReachedError && <p className={styles.textTitle} style = {{fontSize:"0.8em", color: "red"}}>Please select an image with a file size of 20MB or less. </p>}
          {!window.isImageCropperVisible && <p className={styles.textTitle} style = {{fontSize:"0.9em", fontWeight: "600"}}>*The changes will be reflected the next time you access your account. </p>}
          <input
            type="file"
            accept="image/*"
            onClick={handleImageClick}
            onChange={handleImageUpload}
            className={styles.hiddenInput}
            ref={hiddenFileInputRef}
          />

          <div style = {{marginTop:"2em"}}>

          <p className ={styles.textTitle}>Your profile picture is your introduction!</p>
          <p className ={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop}> Make it count! A friendly image ensures a positive and memorable first impression on the platform.</p>            

          <p className ={styles.textTitle}>Let your profile picture speak volumes!</p>
          <p className ={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop}>A professional image builds trust, making your interactions more genuine and reliable.</p>
        </div>
      </div>
  )
  }

  const ChangePassword = () => {
    const [emailResetButtonPressed, setEmailResetButtonPressed] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const handleResetPassword = async ()=>{
      if(!emailResetButtonPressed){
        setEmailResetButtonPressed(true);
        // const result = await forgotPassword(user.email);
        const result = true;
          if(result){
            setEmailSent(true);
          }
     }
    }
  

    return(
      <div>
        <p className={styles.categoryTextTitle}>Reset your password</p>

        <p className={styles.textStyle}>
          Reset your password effortlessly. Click the button below for an email with instructions. Check your inbox (and spam) for details.
        </p>
        {!emailSent && <>
          <p className={styles.sendEmailButton} onClick={handleResetPassword}>Send Email</p>
          <p className={styles.textTitle}> Pick a strong password</p>
          <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop}>
            Consider using a combination of uppercase and lowercase letters, numbers, and special characters. Avoid using easily guessable information, and aim for a length of at least 8 characters.
          </p>

          <p className={styles.textTitle}>Keep in mind</p>
          <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop}>
            If you signed up using Google, please note that this method won't apply. You can reset your Google password directly through your Google account settings.
          </p>
          </>
        }
        {emailSent && (
          <p className={styles.textStyle}>
            An email was sent to <span style={{ color: "black", fontWeight: "700" }}>{user.email}</span>. Follow the instructions to reset it.
          </p>
        )}
    </div>
    )
  }

  const ChangeCountry = () => {

    const {initializeCountryChange, newlySelectedCountry, countryCodes} = useContext(UIContext)
    const {userPreferredMeasurementSystem} = useContext(UserContext)

    const [successMessage, setSuccessMessage] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState((newlySelectedCountry!="")?newlySelectedCountry:userPreferredCountry.preferredCountry)

    const [coutriesAreExpanded, setCoutriesAreExpanded] = useState(false)
    const [currentMeasurementSystem, setCurrentMeasurementSystem] = useState(userPreferredMeasurementSystem)

    const countryRefs = useRef([]);
    const countryChangeAlreadyStarted = useRef(false);

    const selectNewCountry = async (country, index) => {
      if(countryChangeAlreadyStarted.current == false && selectedCountry!=country.name){
        countryChangeAlreadyStarted.current = true;
        const result = await initializeCountryChange(index);
        if(result){
          countryRefs.current[index].classList.add(styles.currentlySelectedCountry);
          setSelectedCountry(country.name);
  
          setSuccessMessage(true);
          countryChangeAlreadyStarted.current = false;
        }
      }
    }

    const selectNewMeasurementSystem = async (selected) =>{

      if(!measurementSystemButtonPressed.current && currentMeasurementSystem != selected){
        measurementSystemButtonPressed.current = true;
        const result = await saveMeasurementSystemChanges(user.id, selected);
        setCurrentMeasurementSystem((result)?selected : currentMeasurementSystem);
        measurementSystemButtonPressed.current = false;
      }
    }

    return(
      <div>
        <p className={styles.categoryTextTitle}>Choose your country</p>
        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop}>Your selected country is currently <span className={styles.textTitle}>{selectedCountry}</span>. If you want to search for tasks on another country, please select your desired country from the list. </p>
        <p className={styles.selectCountryButton} style = {{width: "fitContent"}}onClick = {()=>setCoutriesAreExpanded(!coutriesAreExpanded)}>Select a new country</p>
        <div style = {{marginBottom:"4em"}}/>
        {coutriesAreExpanded && countryCodes.map((country, index) => (
        <div key={index}>
          <p ref={(ref) => (countryRefs.current[index] = ref)} className={(countryCodes[index].name!=selectedCountry)?styles.countriesButton:styles.currentlySelectedCountry} onClick = {()=>selectNewCountry(country, index)}>{country.name}</p>
        </div>
         ))}

        <p className={styles.categoryTextTitle} style = {{marginTop:"2em"}}>Choose a measurement system</p>
        <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop}>The measurement system you choose will affect your distance filter when searching for tasks</p>
        
        <span className={styles.measurementOptionContainer}>
          <span className={styles.measurementOption} onClick = {()=>selectNewMeasurementSystem("miles")}>
            <p>Miles</p>
            <span className = {(currentMeasurementSystem == "miles")?styles.selectedMeasurement:styles.unselectedMeasurement}/>
          </span>
          <span className={styles.measurementOption} onClick = {()=>selectNewMeasurementSystem("metric")}>
            <p>Metric system</p>
            <span className = {(currentMeasurementSystem == "metric")?styles.selectedMeasurement:styles.unselectedMeasurement}/>
          </span>
        </span>
        {<p className={styles.textTitle} style = {{fontSize:"0.9em", fontWeight: "600"}}>*The changes will be reflected the next time you access your account. </p>}

      </div>
    )
  }
  
  const ChangeJobPreferences = () => {
    const { typesOfTasks } = useContext(SearchContext);
    const { handleNewTypeChanges, prevSelectedTypes, currentlySelectedTypes,
      setCurrentlySelectedTypes, shouldConfirmationBeVisible, setShouldConfirmationBeVisible } = useContext(UIContext);
    
    const typesRefs = useRef([]);

    const saveButtonIsNotPressed = useRef(false);

    const handleTypeChanges = async() => {
      if(!saveButtonIsNotPressed.current){
        saveButtonIsNotPressed.current = true;
        const result = await handleNewTypeChanges(currentlySelectedTypes);
        if(result){
          saveButtonIsNotPressed.current = false;
          setShouldConfirmationBeVisible(true);
          setTimeout(()=>{
            setShouldConfirmationBeVisible(false);
          },[3000])
        }else{
          saveButtonIsNotPressed.current = false;
        }
      }
    }
  
    const selectType = (index) => {
      const selectedType = typesOfTasks[index].name;
      setCurrentlySelectedTypes((prev) => {
        if (prev.includes(selectedType)) {
          // If the type is already selected, remove it
          return prev.filter((type) => type !== selectedType);
        } else {
          // If the type is not selected, add it
          return [...prev, selectedType];
        }
      });
    };

    const hasChanges = JSON.stringify(currentlySelectedTypes) !== JSON.stringify(prevSelectedTypes);
  
    return (
      <div>
        <p className={styles.categoryTextTitle}>Define your task preferences</p>
        <p className={styles.textStyle}>
          Select what kind of tasks you would like to see the most on your front
          page
        </p>
        {hasChanges && <p className={(isMobile)?styles.savedtypesChangesButtonMobile:styles.savedtypesChangesButtonDesktop} onClick = {()=>handleTypeChanges()}>Save my changes</p>}
        <div>
          {shouldConfirmationBeVisible && !hasChanges && <p className={(isMobile)?styles.savedMessagesMessageMobile:styles.savedMessagesMessageDesktop}>The changes have been saved</p>}
        </div>
        <div className={styles.typesOfTasksContainer}>
          {typesOfTasks.map((category, index) => (
            <p
              ref={(ref) => (typesRefs.current[index] = ref)}
              className={
                (currentlySelectedTypes.includes(category.name))? styles.selectedTypeButton : styles.typesOfTasksButton
              }
              key={index}
              onClick={() => selectType(index)}
            >
              {category.name}
            </p>
          ))}
        </div>
      </div>
    );
  };

  const ReportABug = () => {

    const {sendReport} = useContext(UIContext);
    const [bugDescription, setBugDescription] = useState("");
    const [successReport, setSuccessReport] = useState(false);
  
    const handleBugDescriptionChange = (event) => {
      setBugDescription(event.target.value);
    };
    const reportButtonPressed = useRef(false);

    const isSendButtonVisible = bugDescription.split(/\s+/).length > 10;
  
    const handleSendReport = async() => {
    if(!reportButtonPressed.current){
      reportButtonPressed.current = true;
      const result = await sendReport(bugDescription);

      if(result){
        reportButtonPressed.current = false;
        setBugDescription("");
        setSuccessReport(true);
        setTimeout(()=>{setSuccessReport(false)},[5000])
      }else{
        reportButtonPressed.current = false;
      }
    };
  }
  
    return (
      <div>
        <p className={styles.categoryTextTitle}>Report a bug</p>
        <p className={styles.textTitle}>Did you come across a pesky bug?</p>
        <p className={isMobile ? styles.simpleTextMobile : styles.simpleTextDesktop}>
          Let us know what you observed, and we'll work diligently to resolve it!
        </p>
        <textarea
          className={(isMobile)?styles.reportTextAreaMobile:styles.reportTextAreaDesktop}
          placeholder="Please describe the steps you took to encounter the bug..."
          value={bugDescription}
          onChange={handleBugDescriptionChange}
          maxLength={300}
        />
        {isSendButtonVisible && (
          <p className={styles.sendBugButton} onClick={handleSendReport}>
            Send report
          </p>
        )}

        {successReport && <p className = {styles.textTitle}>The report has been successfully sent. Thank you for your feedback! </p>}
      </div>
    );
  };

  const DesktopUserProfile = () => {

    const {reviews, setReviews, userProfileInfo, setUserProfileInfo, userProfileLoaded} = useContext(UIContext);

    const loadUserData = async (userId) => {
      const currentUser = await getUserById(userId);
      return currentUser;
    }

    const loadUserReviews = async (userId) => {
      try {
          const reviewsDocs = await getUserReviews(userId); // Wait for the promise to resolve
      
          return reviewsDocs; // Return the array of review objects
        } catch (error) {
          console.error(error);
          return []; // Return an empty array if there's an error
        }
      }

    useEffect(() => {
        // Call the test function when the component mounts
        if(!userProfileLoaded.current){
          userProfileLoaded.current = true;
          loadUserReviews(user.id)
          .then((reviewsData) => {
            setReviews(reviewsData);
          })
          .catch((error) => {
              console.error('Error:', error);
          });
    
          loadUserData(user.id)
          .then((info) => {
            setUserProfileInfo(info); // Store reviews data in state
          })
          .catch((error) => {
              console.error('Error:', error);
          });
      }
    });

    return(
      <div >
        <p className={styles.categoryTextTitle}>Profile</p>
        <div style = {{marginTop:"-1em"}}>
          <Profile
          candidateProfilePhoto = {user.profilePhoto}
          profileRenderedFromProfileTab = {true}
          candidateName = {user.name}
          candidateLastName = {user.surname}
          candidateRating = {userProfileInfo.stars}
          candidateTopTasks = {userProfileInfo.topTasks? userProfileInfo.topTasks : 0}
          candidateReviews = {reviews}
          />
        </div>
      </div>
    )
  }

  return(
    <>
      {isMobile && <div className={styles.containerMobileBackground} ref = {categoriesContentBackgroundRef}/>}
      <div className = {(isMobile)?styles.containerMobile:styles.containerDesktop} ref = {categoriesContentRef} onAnimationStart={handleAnimStart} onAnimationEnd={handleAnimEnd}>
        <div className = {(isMobile)?styles.contentContainerMobile:styles.contentContainerDesktop}>
          {selectedContent == "changeProfilePicture" && <ChangeProfilePicture />}
          {selectedContent == "changePassword" && <ChangePassword />}
          {selectedContent == "changeCountry" && <ChangeCountry />}
          {selectedContent == "changeJobPreferences" && <ChangeJobPreferences />}
          {selectedContent == "reportABug" && <ReportABug />}
          {!isMobile && selectedContent == "profile" && <DesktopUserProfile />}
        </div>
      </div>
    </>
  )
}

export default CategoriesContent;