import styles from "./MessagingSystem.module.css";
import {useRef, useState, useEffect, useLayoutEffect, useContext} from 'react'
import exitButton from "../../../assets/images/XGrey.png";
import arrowIMG from "../../../assets/images/arrowEnter.png";
import sampleProfile from "../../../assets/images/ProfileSample.png";
import UIContext from "../../../Firebase Contexts/UIContext/UIContext";
import UserContext from "../../../Firebase Contexts/UserDataContext";
import { getUserRooms, getInitialConversation, sendMessageNotification } from "../../../Firebase";
import shortid from 'shortid';

const MessagingSystemDesktop = ()=>{

    const {sendNewMessage, receiverId, setReceiverId,
        roomId, setRoomId, profilePicturesId, profilePicturesExistenceChecked,
        setProfilePicturesExistenceChecked, setProfilePicturesId, roomsAlreadyFetched,
        rooms, setRooms, currentRoomIndex, setCurrentRoomIndex, preventFirstListenerOfRooms, thereIsSelectedDiscussion,
        setThereIsSelectedDiscussion, currentlySelectedProfileIndex, setCurrentlySelectedProfileIndex,
        deleteReadMessagesNotifications, unreadMessages, getProfilePhotos,
        numberOfMessageNotifications, setNumberOfMessageNotifications} = useContext(UIContext);
        
    const {user} = useContext(UserContext);

    const [messagesIsExpanded, setMessagesIsExpanded] = useState(false);
    window.messagesIsExpanded = messagesIsExpanded;

    const openMessages = ()=>{
      setMessagesIsExpanded(true);
    }

    window.openMessages = openMessages;
    
    const messagingSystemContainerRef = useRef(null);
    const currentTextRef = useRef(null);

    const currentScrollPosition = useRef(0);
    const messagesNumbersContainerRef = useRef(null);

    const notifyUserOnNewMessageDesktop = (number) =>{
    
      // if(number!=0 && messagesNumbersContainerRef){
      //   messagesNumbersContainerRef.current.classList.remove(styles.hidden);
      // }else if(number == 0 ){
      //   messagesNumbersContainerRef.current.classList.add(styles.hidden);
      // }
      setNumberOfMessageNotifications(number);
    }
    window.notifyUserOnNewMessageDesktop = notifyUserOnNewMessageDesktop;


    useEffect(()=>{
        if(messagesIsExpanded && messagingSystemContainerRef){
            messagingSystemContainerRef.current.classList.remove(styles.messagingSystemContainerCollapsed);
            messagingSystemContainerRef.current.classList.add(styles.messagingSystemContainerExpanded);
        }else {
            messagingSystemContainerRef.current.classList.remove(styles.messagingSystemContainerExpanded);
            messagingSystemContainerRef.current.classList.add(styles.messagingSystemContainerCollapsed);
        }

    if(!roomsAlreadyFetched.current){
        getProfilePhotos(unreadMessages);
    }
    },[messagesIsExpanded])

    const [currentText, setCurrentText] = useState("");

    const discussionContainerRef = useRef(null);


    useEffect(()=>{
      if(currentRoomIndex!=="" && rooms.length>0 && discussionContainerRef.current){
        discussionContainerRef.current.scrollTop = discussionContainerRef.current.scrollHeight;
      }
    },[currentRoomIndex])

      const handleTextInput = (event) => {
          event.preventDefault();
          const value = event.target.value;
          setCurrentText(value);
      };


      useEffect(() => {
          const handleScroll = () => {
            if (discussionContainerRef.current) {
              currentScrollPosition.current = discussionContainerRef.current.scrollTop;
              // You can log or perform any action with the currentScrollPositionRef.current here
            }
          };
      
          if (discussionContainerRef.current) {
            discussionContainerRef.current.addEventListener('scroll', handleScroll);
          }
      
          return () => {
            // Clean up the event listener when the component unmounts
            if (discussionContainerRef.current) {
              discussionContainerRef.current.removeEventListener('scroll', handleScroll);
            }
          };
        }, []);

      const handleMessage = async(roomId, senderId, receiverId, messageText) => {

          setCurrentText("");
          if(messageText!=""){
            const textId = shortid.generate();
            const newText = { id: textId, senderId: user.id, text: messageText };

            setRooms((prev) => {
                const updatedRooms = prev.map((room) => {
                  if (room.roomId === roomId) {
                    const updatedConversations = Array.isArray(room.conversation)
                      ? [...room.conversation, newText]
                      : [newText];
              
                    // Sort conversations based on timestamp in descending order
                    updatedConversations.sort((a, b) => {
                        if (a && a.timestamp && a.timestamp.seconds && b && b.timestamp && b.timestamp.seconds) {
                          return a.timestamp.seconds - b.timestamp.seconds;
                        }
                        // Handle the case where either a or b doesn't have a valid timestamp
                        return 0;
                      });
                                        
                    return { ...room, conversation: updatedConversations };
                  }
                  return room;
                });
              
                // If the room with roomId doesn't exist, create a new room
                if (!updatedRooms.some((room) => room.roomId === roomId)) {
                  updatedRooms.push({ roomId, conversation: [newText] });
                }
              
                // Sort rooms based on the latest message timestamp in descending order
                updatedRooms.sort((a, b) => {
                  const latestMessageA = a.conversation[0]?.timestamp.seconds || 0;
                  const latestMessageB = b.conversation[0]?.timestamp.seconds || 0;
                  return latestMessageB - latestMessageA;
                });
              
                return updatedRooms;
              });
            
              setTimeout(() => {
                // discussionContainerRef.current.scrollTop = discussionContainerRef.current.scrollHeight;
                if(discussionContainerRef.current){
                  discussionContainerRef.current.scrollTo({
                    top: discussionContainerRef.current.scrollHeight,
                    behavior: "smooth",
                  });
                }
              }, 0);
              
            await sendNewMessage(roomId, senderId, receiverId, messageText, textId);
            await sendMessageNotification(user.id, receiverId, roomId);
          }

          currentTextRef.current.focus();
      }

      const fetchInitialRoomConversation = async (roomId) => {
          const isRoomIdMatch = rooms.some(room => room.roomId === roomId);
          if(!isRoomIdMatch){
              const conversation = await getInitialConversation(roomId);
              conversation.sort((a, b) => a.timestamp - b.timestamp);

              if(conversation){
                  const updatedRooms = [...rooms, { roomId, conversation }]
                  setRooms(updatedRooms);
              }
          }
      }

      useEffect(()=>{
          if(rooms.length>0 && rooms.some(room => room.roomId === roomId)){
              setCurrentRoomIndex(rooms.findIndex((room) => room.roomId === roomId));
          }
      },[roomId, rooms])

      useLayoutEffect(() => {
          if (discussionContainerRef.current) {
            // Scroll to the current scroll position
              discussionContainerRef.current.scrollTop = currentScrollPosition.current//discussionContainerRef.current.scrollHeight;
            // Set firstRender to false after scrolling
          }

          const messageNotificationsToDelete = unreadMessages.filter(obj => obj.roomId === roomId);
          deleteReadMessagesNotifications(messageNotificationsToDelete);
        }, [roomId]);

        useLayoutEffect(() => {
          if (currentRoomIndex.current && discussionContainerRef.current) {
            // Scroll to the end of the container
              discussionContainerRef.current.scrollTop = discussionContainerRef.current.scrollHeight;
            // Set firstRender to false after scrolling
          }
        }, [currentRoomIndex]);

        useEffect(()=>{
          if(messagesIsExpanded){
            const messageNotificationsToDelete = unreadMessages.filter(obj => obj.roomId === roomId);
            deleteReadMessagesNotifications(messageNotificationsToDelete);
          }
        },[messagesIsExpanded])

  return(
      <>
      <div className={styles.messagingSystemContainerCollapsed} ref = {messagingSystemContainerRef} onClick={()=>{if(!messagesIsExpanded){setMessagesIsExpanded(!messagesIsExpanded)}}}>
          <p className = {(!messagesIsExpanded)?styles.messagesContainerCollapsed:styles.messagesContainerExpanded}>Messages</p>
          {!messagesIsExpanded && numberOfMessageNotifications > 0 && <p className = {(numberOfMessageNotifications > 0)?styles.unreadMessagesDesktop:styles.hidden} ref = {messagesNumbersContainerRef}>{numberOfMessageNotifications}</p>}
          {messagesIsExpanded && <div>
              <img src = {exitButton} className = {styles.exitButton} onClick={()=>{setMessagesIsExpanded(!messagesIsExpanded)}} alt = "Exit"/>

              <div className = {styles.headerContainer}>
                  {profilePicturesId.length > 0 && <div className = {styles.profilesContainer}>
                    {profilePicturesId.map((x, index) => (
                      <div key = {index} style = {{position: "relative"}}>
                      <span key={index} className={currentlySelectedProfileIndex !== "" &&
                      currentlySelectedProfileIndex === index
                        ? `${styles.profileStyle} ${styles.testCircle}`
                        : styles.profileStyle}>
                        <img key={index}
                        alt = "User Profile Picture"
                        src={(x.vendorId)?(x.vendorProfilePicture!=null)?x.vendorProfilePicture:sampleProfile : (x.clientProfilePicture!=null)?x.clientProfilePicture:sampleProfile}
                        className={
                          currentlySelectedProfileIndex !== "" &&
                          currentlySelectedProfileIndex === index
                            ? `${styles.profilePicture} ${styles.selectedProfile}`
                            : styles.profilePicture
                        }
                        
                        onClick = {()=>{
                            preventFirstListenerOfRooms.current = [...preventFirstListenerOfRooms.current, {roomId: x.conversationId, firstRenderDone: true}]
                            setRoomId(x.conversationId);
                            setReceiverId((x.vendorId)? x.vendorId : x.clientId);
                            setThereIsSelectedDiscussion(true);
                            fetchInitialRoomConversation(x.conversationId);
                            setCurrentlySelectedProfileIndex(index);
                        }}
                        />
                      </span>
                    {x.unreadMessages > 0 && <p key = {index} className= {styles.unreadMessages}>{x.unreadMessages}</p>}
                    </div>
                    ))}
                  </div>}
              </div>
              {profilePicturesId.length > 0 && <div className={styles.profilesLineDesktop}/>}

              <div className={styles.messagingContainer}>
                  {!thereIsSelectedDiscussion && profilePicturesExistenceChecked && <p className = {styles.noDiscussionMessageDesktop}>{(profilePicturesId.length>0)?"Select a person to start a discussion":"You will need to connect with people to start a discussion"}</p>}
              </div>

              <div className={styles.discussionContainer} ref = {discussionContainerRef}>
                  {currentRoomIndex !== "" && rooms[currentRoomIndex].conversation.map((message, index) => (
                      <p
                          key={index}
                          className={
                            user.id === (message.senderId)? styles.userText : styles.interlocutorText}>
                          {message.text}
                      </p>
                  ))}
              </div>

              {thereIsSelectedDiscussion && <div className={styles.inputContainer}>
                  <textarea className = {styles.textBar}
                  placeholder="Aa"
                  onChange = {handleTextInput}
                  value = {currentText}
                  ref = {currentTextRef}
                  // onInput={adjustTextareaHeight}
                  style = {{
                      display: "block",
                      overflow: "hidden",
                      resize: "none",
                  }}
                  onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent default form submission
                          handleMessage(roomId, user.id, receiverId, currentText);
                      }
                  }}
                  />

                  <img src = {arrowIMG} className = {styles.sendMessageButton} onClick = {()=>handleMessage(roomId, user.id, receiverId, currentText)} alt = "Send Message Arrow"/>
              </div>}
          
              <div className = {styles.profileSeparatorLine}/>
          </div>}
      </div>
      </>

  )
}

export default MessagingSystemDesktop;