import {useState, useRef, useContext, useEffect} from 'react';
import styles from './ReviewForm.module.css';
import StarRating from '../../StarsRating/StarRating';
import { createReview } from '../../../../Firebase';
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';

const ReviewForm = ({listing, user, isMine, reviewAfterConfirmation})=>{

  const isMobile = window.targetPlatform == "mobile";

  const {historyListings, setHistoryListings} = useContext(ListingsContext);

  const [description, setDescription] = useState("");

  const [step, setStep] = useState(1);

  const questionsTextRef = useRef(null);
  const ratingTextRef = useRef(null);
  const commentsTextRef = useRef(null);

  const nextStepButtonRef = useRef(null);
  const stars = useRef(0);

  const desktopContainerRef = useRef(null);

  const mobileContainerRef = useRef(null);
  const backgroundRef = useRef(null);

  const [cancelReview, setCancelReview] = useState(false);

  const handleDescription = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
  }
  // Callback function to receive the selected rating

  const handleRatingChange = (rating) => {
    stars.current = rating;
    nextStepButtonRef.current.classList.remove(styles.disabledNextButton);
    nextStepButtonRef.current.classList.add(styles.nextButton);

  };

  const handleReviewNextStep = ()=> {

    if(step==1){
        setStep(2);
        questionsTextRef.current.classList.remove(styles.processTextSelected);
        questionsTextRef.current.classList.add(styles.processTextUnselected);

        ratingTextRef.current.classList.remove(styles.processTextUnselected);
        ratingTextRef.current.classList.add(styles.processTextSelected);
        if(stars.current==0){
            nextStepButtonRef.current.classList.remove(styles.nextButton);
            nextStepButtonRef.current.classList.add(styles.disabledNextButton);
        }
    }else if(step==2 && stars.current>0){
        setStep(3);
        ratingTextRef.current.classList.remove(styles.processTextSelected);
        ratingTextRef.current.classList.add(styles.processTextUnselected);
        
        commentsTextRef.current.classList.remove(styles.processTextUnselected);
        commentsTextRef.current.classList.add(styles.processTextSelected);

        nextStepButtonRef.current.innerText = "Submit review"
    }else if(step==3){
        setStep(1);
        window.setOpenReviewForm(false);
        SubmitReview();
    }
  }

  const SubmitReview = async() => {
    updateReviewAvailability(true);
    //if the current user is the client, set them as the reviewer, or vice versa for vendor
    let reviewer;
    let reviewee;

    if(user.id==listing.clientId){
        reviewer = listing.clientId;
        reviewee = listing.vendorId;
    }else if(user.id == listing.vendorId){
        reviewer = listing.vendorId;
        reviewee = listing.clientId;
    }

    const result = await createReview(listing.clientId, listing.vendorId, reviewer, reviewee, stars.current, listing.type, listing.date, description, userImpressions.current, listing.historyListingId);
    // updateReviewAvailability(result);
    window.shouldScroll(true);
  }

  const updateReviewAvailability = (flag)=>{
    setHistoryListings((prevHistoryListings) => {
        return prevHistoryListings.map((item) => {
          if (item.historyListingId === listing.historyListingId) {
            // Update the reviewSubmitted property for the matching item
            if (isMine.current) {
                return { ...item, clientReviewSubmitted: flag };
              } else {
                return { ...item, vendorReviewSubmitted: flag };
              }          }
          return item; // Preserve other items as they are
        });
      });
  }

  const handlePreviousStep = ()=>{
    if(step==3){
        setStep(2);
        nextStepButtonRef.current.classList.remove(styles.submitReviewButton);
        nextStepButtonRef.current.innerText = "Next";

        commentsTextRef.current.classList.remove(styles.processTextSelected);
        commentsTextRef.current.classList.add(styles.processTextUnselected);

        ratingTextRef.current.classList.remove(styles.processTextUnselected);
        ratingTextRef.current.classList.add(styles.processTextSelected);
    }else if(step==2){
        setStep(1);
        ratingTextRef.current.classList.remove(styles.processTextSelected);
        ratingTextRef.current.classList.add(styles.processTextUnselected);
        
        questionsTextRef.current.classList.remove(styles.processTextUnselected);
        questionsTextRef.current.classList.add(styles.processTextSelected);
    }
  }

    const handleNextButton = ()=>{
        if(stepQuestion>=3){
            setStepQuestion(4)
            handleReviewNextStep()
        } else{
            handleNextQuestionStep()
        }
    }
    const handlePreviousButton = ()=>{
        if(stepQuestion==4 && step == 2){
            setStepQuestion(3);
            setStep(1);

            nextStepButtonRef.current.classList.remove(styles.disabledNextButton);
            nextStepButtonRef.current.classList.add(styles.nextButton);
            
            ratingTextRef.current.classList.remove(styles.processTextSelected);
            ratingTextRef.current.classList.add(styles.processTextUnselected);
            
            questionsTextRef.current.classList.remove(styles.processTextUnselected);
            questionsTextRef.current.classList.add(styles.processTextSelected);
        } else if(stepQuestion==3){
            setStepQuestion(2);
        } else if(stepQuestion==2){
            setStepQuestion(1);
        }else {
            handlePreviousStep();
        }
    }

    const exitReview = ()=>{
        if(isMobile){
            mobileContainerRef.current.classList.remove(styles.slideUp);
            mobileContainerRef.current.classList.add(styles.slideDown);
        }else {
            desktopContainerRef.current.classList.add(styles.disabled);
        }
        backgroundRef.current.classList.remove(styles.fadeIn);
        backgroundRef.current.classList.add(styles.fadeOut);
        window.shouldScroll(true);
    }

    const handleAnimationEnd = (event)=>{
        if(event.animationName.includes("fadeOut")){
            // console.log("exited")
            window.setOpenReviewForm(false);
        }
    }

    //#region Question selection
    const choices = useRef([0, 0, 0]);
    const userImpressions = useRef(["", "", ""]);
    
    const [shouldReRender, setShouldReRender] = useState(false);
    const [stepQuestion, setStepQuestion] = useState(1);
    
    const questionMappings = [
      { questionNumber: 1, choiceIndex: 0, values: [1, "Poor overall experience"] },
      { questionNumber: 2, choiceIndex: 0, values: [2, "Good overall experience"] },
      { questionNumber: 3, choiceIndex: 0, values: [3, "Excellent overall experience"] },
      { questionNumber: 4, choiceIndex: 1, values: [1, "Slow"] },
      { questionNumber: 5, choiceIndex: 1, values: [2, "On time"] },
      { questionNumber: 6, choiceIndex: 1, values: [3, "Very fast"] },
      { questionNumber: 7, choiceIndex: 2, values: [1, "Poor communication"] },
      { questionNumber: 8, choiceIndex: 2, values: [2, "Good communication"] },
      { questionNumber: 9, choiceIndex: 2, values: [3, "Excellent communication"] },
    ];
    
    const handleQuestionSelection = (questionNumber) => {
      const mapping = questionMappings.find((map) => map.questionNumber === questionNumber);
      if (mapping) {
        choices.current[mapping.choiceIndex] = choices.current[mapping.choiceIndex] === mapping.values[0] ? 0 : mapping.values[0];
        userImpressions.current[mapping.choiceIndex] = choices.current[mapping.choiceIndex] === mapping.values[0] ? mapping.values[1]:"";
        setShouldReRender(!shouldReRender);
      }
    };


    const handleNextQuestionStep = ()=>{
        setStepQuestion(stepQuestion+1);
    }

    // const 

    //#endregion


  const desktopVersion = ()=>{

    return(
        <>
        <div className = {`${styles.background} ${styles.fadeIn}`} onClick = {()=>setCancelReview(true)} ref = {backgroundRef} onAnimationEnd={handleAnimationEnd} />
        <div className = {styles.reviewForm} ref = {desktopContainerRef}>
            <div className = {styles.processTextContainer}>
                <p ref = {questionsTextRef} className = {styles.processTextSelected}>Questions</p>
                <p ref = {ratingTextRef} className = {styles.processTextUnselected}>Rating</p>
                <p ref = {commentsTextRef} className = {styles.processTextUnselected}>Comments</p>
            </div>

            {step == 1 && !cancelReview && 
                <div>
                    <div className = {styles.questionsCounter}>{stepQuestion}/3</div>
                    {stepQuestion==1 &&
                        <div>
                            <p className = {styles.questionTitle}>How was the service quality?</p>
                            <div className = {styles.choicesContainer}>
                                <span className = {(choices.current[0]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(1)}><p className = {(choices.current[0]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Poor</p></span>
                                <span className = {(choices.current[0]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(2)}><p className = {(choices.current[0]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Good</p></span>
                                <span className = {(choices.current[0]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(3)}><p className = {(choices.current[0]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Excellent</p></span>
                            </div>
                        </div>
                    }

                    {stepQuestion==2 && !cancelReview &&
                        <div>
                            <p className = {styles.questionTitle}>How fast was it?</p>
                            <div className = {styles.choicesContainer}>
                                <span className = {(choices.current[1]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(4)}><p className = {(choices.current[1]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Slow</p></span>
                                <span className = {(choices.current[1]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(5)}><p className = {(choices.current[1]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Fast</p></span>
                                <span className = {(choices.current[1]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(6)}><p className = {(choices.current[1]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Very fast</p></span>
                            </div>
                        </div>
                    }
                    
                    {stepQuestion==3 && !cancelReview &&
                        <div>
                            <p className = {styles.questionTitle}>How was the communication?</p>
                            <div className = {styles.choicesContainer}>
                                <span className = {(choices.current[2]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(7)}><p className = {(choices.current[2]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Poor communication</p></span>
                                <span className = {(choices.current[2]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(8)}><p className = {(choices.current[2]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Good communication</p></span>
                                <span className = {(choices.current[2]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(9)}><p className = {(choices.current[2]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Excellent communication</p></span>
                            </div>
                        </div>
                    }
                </div>
            }{step == 2 && !cancelReview &&
                <div className = {styles.starsContainer}>
                    <p className = {styles.starsText}>Click on the stars to choose</p>
                    <span><StarRating starRating  = {stars.current} onRatingChange = {handleRatingChange} isReviewForm={true}/></span>
                </div>
            }
            {step == 3 && !cancelReview &&
                <div className={styles.commentContainer}>
                    <textarea onChange = {handleDescription} value = {description} className = {styles.commentInput} placeholder = "Share your experience..." maxLength={500}/>
                </div>
            }
            
            {!cancelReview &&
            <button
            ref = {nextStepButtonRef}
            className = {step==3?`${styles.nextButton} ${styles.submitReviewButton}`:styles.nextButton}
            onClick = {handleNextButton}
            >{step==3?"Submit review":"Next"}
            </button>}
            
            {stepQuestion!=1 && !cancelReview &&<button
            className = {styles.previousButton}
            onClick = {handlePreviousButton}
            >Previous
            </button>}

            {cancelReview && 
            <div className = {styles.cancelReviewContainer}>
                {reviewAfterConfirmation && <p className = {styles.cancelMessage}>You can continue the review later in the History section. </p>}
                <p className = {styles.continueButton} onClick = {()=>{setCancelReview(false)}}>Continue review</p>
                <p className = {styles.cancelButton} onClick = {exitReview}>Exit review</p>
            </div>
            }
        </div>
        </>
    )
  }
  const mobileVersion = ()=>{
    return(
        <>
        <div className = {`${styles.background} ${styles.fadeIn}`} onClick = {()=>setCancelReview(true)} ref = {backgroundRef} onAnimationEnd = {handleAnimationEnd}/>
        <div className = {`${styles.mobileContainer} ${styles.slideUp}`} ref = {mobileContainerRef}>

            <div className = {styles.processTextContainer}>
                <p ref = {questionsTextRef} className = {styles.processTextSelected}>Questions</p>
                <p ref = {ratingTextRef} className = {styles.processTextUnselected}>Rating</p>
                <p ref = {commentsTextRef} className = {styles.processTextUnselected}>Comments</p>
            </div>

            {step == 1 && !cancelReview && 
                <div>
                    {stepQuestion==1 &&
                        <div>
                            <p className = {styles.questionTitle}>How was the service quality?</p>
                            <div className = {styles.mobileQuestionsCounter}>{stepQuestion}/3</div>
                            <div className = {styles.choicesContainer}>
                                <span className = {(choices.current[0]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(1)}><p className = {(choices.current[0]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Poor</p></span>
                                <span className = {(choices.current[0]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(2)}><p className = {(choices.current[0]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Good</p></span>
                                <span className = {(choices.current[0]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(3)}><p className = {(choices.current[0]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Excellent</p></span>
                            </div>
                        </div>
                    }

                    {stepQuestion==2 && !cancelReview &&
                        <div>
                            <p className = {styles.questionTitle}>How fast was it?</p>
                            <div className = {styles.mobileQuestionsCounter}>{stepQuestion}/3</div>
                            <div className = {styles.choicesContainer}>
                                <span className = {(choices.current[1]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(4)}><p className = {(choices.current[1]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Slow</p></span>
                                <span className = {(choices.current[1]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(5)}><p className = {(choices.current[1]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Fast</p></span>
                                <span className = {(choices.current[1]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(6)}><p className = {(choices.current[1]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Very fast</p></span>
                            </div>
                        </div>
                    }
                    
                    {stepQuestion==3 && !cancelReview &&
                        <div>
                            <p className = {styles.questionTitle}>How was the communication?</p>
                            <div className = {styles.mobileQuestionsCounter}>{stepQuestion}/3</div>
                            <div className = {styles.choicesContainer}>
                                <span className = {(choices.current[2]==1)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(7)}><p className = {(choices.current[2]==1)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Poor communication</p></span>
                                <span className = {(choices.current[2]==2)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(8)}><p className = {(choices.current[2]==2)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Good communication</p></span>
                                <span className = {(choices.current[2]==3)?styles.choiceContainerSelected : styles.choiceContainerUnselected} onClick = {()=>handleQuestionSelection(9)}><p className = {(choices.current[2]==3)?styles.questionAnswerSelected:styles.questionAnswerUnselected}>Excellent communication</p></span>
                            </div>
                        </div>
                    }
                </div>
            }{step == 2 && !cancelReview &&
                <div className = {styles.starsContainer}>
                    <p className = {styles.starsText}>Click on the stars to choose</p>
                    <span><StarRating starRating  = {stars.current} onRatingChange = {handleRatingChange} isReviewForm={true}/></span>
                </div>
            }
            {step == 3 && !cancelReview &&
                <div className={styles.commentContainer}>
                    <textarea onChange = {handleDescription} value = {description} className = {styles.commentInput} placeholder = "Share your experience..." maxLength={500}/>
                </div>
            }
            
            {!cancelReview && <button
            ref = {nextStepButtonRef}
            className = {step==3?`${styles.nextButton} ${styles.submitReviewButton}`:styles.nextButton}
            onClick = {handleNextButton}
            >{step==3?"Submit review":"Next"}
            </button>}

            {stepQuestion!=1 && !cancelReview && <button
            className = {styles.previousButton}
            onClick = {handlePreviousButton}
            >Previous
            </button>}

            {cancelReview && 
            <div className = {styles.mobileCancelReviewContainer}>
                <p className = {styles.mobileContinueButton} onClick = {()=>{setCancelReview(false)}}>Continue review</p>
                <p className = {styles.mobileCancelButton} onClick = {exitReview}>Exit review</p>
            </div>
            }
        </div>
        </>
    )
  }

    return(
        <>
        {isMobile? mobileVersion() : desktopVersion()}
        </>
    )
}

export default ReviewForm;