import React, { useRef, useState, useEffect, useContext } from 'react';
import styles from './Radius.module.css';
import locationIcon from '../../../../../assets/images/location.png';
import radiusIcon from '../../../../../assets/images/radius.png';
import SearchContext from '../../../../../Firebase Contexts/SearchContext/SearchContext';
import { searchNumbers } from 'libphonenumber-js';
import ListingsContext from '../../../../../Firebase Contexts/Listings Context/ListingsContext';
import UserContext from '../../../../../Firebase Contexts/UserDataContext';

const LocationAndRadius = ({
  locationVisibility,
  radiusValueRef,
  radiusValue,
  setRadiusValue,
  setRadiusFilterSubmit,
}) => {

  const isMobile = window.targetPlatform == 'mobile';
  const {setFiltersIdentifiedBySearch, initiateFilterBasedSearch, searchFilters,
    maxRadiusSearched, setFetchedListings, setSearchedListings} = useContext(SearchContext);

  const {userPreferredMeasurementSystem} = useContext(UserContext);
    const {lastDocumentListingFetched} = useContext(ListingsContext);

  useEffect(() => {
    window.setRadiusIsOpened(true);
  }, []);
  

  const submitRadius = (number) => {
    window.setShouldRunFilterWidthCheck(true);
    setRadiusFilterSubmit(number);

    setFiltersIdentifiedBySearch((prevFilters)=>({
      ...prevFilters,
      radius: number,
    }))
  };

  const handleOnEnterKey = (number) => {
    submitRadius(number);
    setRadiusValue(number);
    maxRadiusSearched.current = number;
    if(searchFilters.radius != number){
      initiateFilterBasedSearch.current = true;
      if(maxRadiusSearched.current < searchFilters.radius){
        lastDocumentListingFetched.current = null;
        setFetchedListings([]);
        setSearchedListings([]);
      }

      setTimeout(() => {
        window.fetchMoreListings();
      }, 50);
    }
    window.setRadiusIsOpened(false);
    locationVisibility();
  };

  return (
    <div
      className={
        isMobile ? styles.mobileContainer : styles.desktopContainer
      }
    >
      <span className={(isMobile)? `${styles.container} ${styles.containerMobile}`
            : `${styles.container} ${styles.containerDesktop}`
        }
      >
        <p className= {(searchFilters.radius == 1)? styles.selectedDistanceStyle : styles.distanceStyle} onClick = {()=>{handleOnEnterKey(1)}} >Exact location</p>
        <p className= {(searchFilters.radius == 5)? styles.selectedDistanceStyle : styles.distanceStyle} onClick = {()=>{handleOnEnterKey((userPreferredMeasurementSystem=="miles")? 3:5)}}>{(userPreferredMeasurementSystem=="miles")? "3 Mi":"5 Km"}</p>
        <p className= {(searchFilters.radius == 10)? styles.selectedDistanceStyle : styles.distanceStyle} onClick = {()=>{handleOnEnterKey((userPreferredMeasurementSystem=="miles")? 6:10)}} >{(userPreferredMeasurementSystem=="miles")? "6 Mi":"10 Km"}</p>
        <p className= {(searchFilters.radius == 15)? styles.selectedDistanceStyle : styles.distanceStyle} onClick = {()=>{handleOnEnterKey((userPreferredMeasurementSystem=="miles")? 9:15)}} >{(userPreferredMeasurementSystem=="miles")? "9 Mi":"15 Km"}</p>

      </span>
    </div>
  );
};

export default LocationAndRadius;
