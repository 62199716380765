import React, { useState, useRef, useEffect, useLayoutEffect, useContext } from 'react';
import styles from './ListedTask.module.css';
import profilePicture from '../../assets/images/ProfileSample.png';
import bookmarkOutline from '../../assets/images/BookmarkListingUnselected.png';
import bookmarkSelected from '../../assets/images/BookmarkListingSelected.png';
import banIcon from '../../assets/images/BanIcon.png';
import shareIcon from '../../assets/images/share.png';
import calendar from '../../assets/images/Calendar.png';
import location from '../../assets/images/location.png';
import time from '../../assets/images/time.png';
import UserContext from '../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../Firebase Contexts/Listings Context/ListingsContext';
import threeDots from '../../assets/images/ThreeDots.png';
import { getUserById, getUserReviews, saveListing, deleteSavedListing,
        addApplicantToListing, applyListing, banListing} from '../../Firebase';
import SearchContext from '../../Firebase Contexts/SearchContext/SearchContext';
import UIContext from '../../Firebase Contexts/UIContext/UIContext';

const ListedTask = ({listing, isSaved, hasApplied}) => {
  const isMobile = window.targetPlatform == "mobile";
  const {user} = useContext(UserContext);
  const {savedListings, setSavedListings, appliedListings,
         setAppliedListings, bannedListings, setBannedListings} = useContext(ListingsContext);
  const {searchedListings, setSearchedListings, setFetchedListings} = useContext(SearchContext);
  const {setSharedListingData} = useContext(UIContext);
  
  const [isBanned, setIsBanned] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [bookmark, setBookmark] = useState(isSaved? isSaved: false);
  const [saveButtonIsEnabled, setSaveButtonIsEnabled] = useState(true);

  const [triggerReRender, setTriggerReRender] = useState(false);

  const parentRef = useRef(null);

  const gotBanned = useRef(false);

  const applyButtonRef = useRef(null);

  useEffect(()=>{
    //Apply the latest value of isSaved if changed -  typically after user sign in
    setBookmark(isSaved);
    listIsSaved.current = isSaved;
  },[isSaved])

  const getCurrency = (isoCode) => {
    const currencyMap = {
      "usd": "$",
      "cad": "$",
      "gbp": "£",
      "eur": "€",
      "aud": "$",
      "nzd": "$",
      "dkk": "kr",
      "sek": "kr",
      "nok": "kr",
      "zar": "R",
    };
    return currencyMap[isoCode] || null;
  };
  
  const currency = getCurrency(listing.currency);


//#region save process handling

const deleteSavedListingFromContext = (listingId) => {
  setSavedListings((prevSavedListings) => {
    // Use the filter method to create a new array without the matching listingId
    const updatedListings = prevSavedListings.filter(
      (tempListing) => tempListing.id !== listingId
    );
    return updatedListings;
  });
};

const updateListingToContext = (listingId) => {
  setSearchedListings((prevSearchedListings) => {
    return prevSearchedListings.map((prevListing) => {
      if (prevListing.id === listingId) {
        // Update the matching listing
        return {
          ...prevListing,
          isSaved: (prevListing.isSaved==true)?false:true,
        };
      } else {
        // Keep other listings unchanged
        return prevListing;
      }
    });
  });
  
  setFetchedListings((prevFetchedListings) => {
    return prevFetchedListings.map((prevListing) => {
      if (prevListing.id === listingId) {
        // Update the matching listing
        return {
          ...prevListing,
          isSaved: (prevListing.isSaved==true)?false:true,
        };
      } else {
        // Keep other listings unchanged
        return prevListing;
      }
    });
  });
};

  
  const saveListingToContext = (listingId) => {
    setSavedListings((prevSavedListings) => {
      const newSavedListing = {
        id: listingId,
        date: listing.date,
        description: listing.description,
        duration: listing.duration,
        location: listing.location,
        price: listing.price,
        time: listing.time,
        title: listing.title,
        type: listing.type,
        uid: listing.uid,
      };
      return [...prevSavedListings, newSavedListing];
    });
  };

  const listIsSaved = useRef(isSaved);

  const handleSave = async() => {
    if(user.id){
      //If not saved
      if(!listIsSaved.current && saveButtonIsEnabled){
        setSaveButtonIsEnabled(false);
        const result = await saveListing(user.id, listing.id)
        setBookmark(result)
        // console.log("saved");
        if(result){
          saveListingToContext(listing.id);
          updateListingToContext(listing.id)
        }
        listIsSaved.current  = true;
      }//Else if not saved
      else if(listIsSaved.current && saveButtonIsEnabled){
        setSaveButtonIsEnabled(false);
        //Delete listing
        const result = await deleteSavedListing(user.id, listing.id);
        setBookmark(result);
        //if listing deleted successfully from firebase, delete it from context
        if(!result){
          deleteSavedListingFromContext(listing.id);
          updateListingToContext(listing.id)
        }

        listIsSaved.current = false;
      }
      setSaveButtonIsEnabled(true);
    }else if(!user.id){
      window.setSignInHidden(false);
    }
  };
//#endregion

//#region scroll to top on closing listing
  const scrollToParent = () => {
    const parentElement = parentRef.current;
    const scrollOffset = -50; // Adjust this value to scroll higher than the parent
  
    const parentRect = parentElement.getBoundingClientRect();
    const parentTop = parentRect.top + window.scrollY;
    const scrollPosition = parentTop + scrollOffset;
    const currentScrollPosition = window.scrollY;
    if (currentScrollPosition > scrollPosition) {
      setTimeout(()=>{
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      })
    }
  };
  //#endregion

  //#region Ban animation handling
  const banButtonPressed = useRef(false);

  const handleBan = async ()=>{
    
    if(!banButtonPressed.current && user.id){
      banButtonPressed.current = true;
      const result = await banListing(user.id, listing.id, isBanned);
      if(result){
        setBannedListings([...bannedListings, {id: listing.id}]);

        descriptionIsExpanded.current = false;

        gotBanned.current = true;
        parentRef.current.classList.remove(styles.taskBodyInitial);
        parentRef.current.classList.remove(styles.taskBodyUnbanned);
        parentRef.current.classList.add(styles.taskBodyBanned);

        setSearchedListings((prevSearchedListings) =>
          prevSearchedListings.map((prevSearchedListing) => {
            if (prevSearchedListing.id === listing.id) {
              // If the condition is true, add the isBanned property
              return { ...prevSearchedListing, isExpanded: false,
                isSaved: false, hasApplied: false };
            }
            // If the condition is not met, return the object as-is
            return prevSearchedListing;
          })
        );
        
        setFetchedListings((prevFetchedListings) =>
          prevFetchedListings.map((prevSearchedListing) => {
            if (prevSearchedListing.id === listing.id) {
              // If the condition is true, add the isBanned property
              return { ...prevSearchedListing, isExpanded: false,
                isSaved: false, hasApplied: false };
            }
            // If the condition is not met, return the object as-is
            return prevSearchedListing;
          })
        );
      }

      banButtonPressed.current = false;
    }else if(!user.id){
      window.setSignInHidden(false);
    }
  };
  
  const banAnimationEnd = (event)=>{
    if(event.animationName.includes("taskBodyBanned") && !isBanned){
      setIsBanned(true);
    }else if(event.animationName.includes("taskBodyUnbanned") && isBanned){
    }
}

  const handleUnban= async()=>{
    const result = await banListing(user.id, listing.id, isBanned);
    if(result){
      gotBanned.current = false;
      setIsBanned(false);
      parentRef.current.classList.remove(styles.taskBodyBanned);
      parentRef.current.classList.add(styles.taskBodyUnbanned);
    }
  };

  useLayoutEffect(()=>{
    if(descriptionContainerRef.current!=null){
      descriptionContainerRef.current.classList.add(styles.initialDescriptionHeight)
    }
  },[isBanned])
//#endregion
  
//#region Description animation handling

const descriptionContainerRef = useRef(null);

const descriptionIsExpanded = useRef((listing.isExpanded==true)?true:false);


useEffect(()=>{
  if(window.activeComponent == "Search"){
    if(listing.isExpanded){
      descriptionContainerRef.current.classList.remove(styles.descriptionExpanded);
      descriptionContainerRef.current.classList.add(styles.initialDescriptionExpandedHeight);
    }else {
      descriptionContainerRef.current.classList.remove(styles.descriptionCollapsed);
      descriptionContainerRef.current.classList.add(styles.initialDescriptionHeight);
    }
  }
}, [window.activeComponent])

const handleDescriptionExpansion = ()=>{
  let isExpanded = descriptionIsExpanded.current;
  if(!isExpanded){
    descriptionContainerRef.current.classList.remove(styles.initialDescriptionHeight);
    descriptionContainerRef.current.classList.remove(styles.descriptionCollapsed);
    descriptionContainerRef.current.classList.add(styles.descriptionExpanded);
  }else if(isExpanded){
    descriptionContainerRef.current.classList.remove(styles.descriptionExpanded);
    descriptionContainerRef.current.classList.remove(styles.initialDescriptionExpandedHeight);
    descriptionContainerRef.current.classList.add(styles.descriptionCollapsed);
    scrollToParent();
  }
  descriptionIsExpanded.current = !isExpanded;
  setSearchedListings((prevSearchedListings) =>
    prevSearchedListings.map((prevSearchedListing) => {
      if (prevSearchedListing.id === listing.id) {
        // If the condition is true, add the isBanned property
        return { ...prevSearchedListing, isExpanded: !isExpanded };
      }
      // If the condition is not met, return the object as-is
      return prevSearchedListing;
    })
  );
  
  setFetchedListings((prevFetchedListings) =>
    prevFetchedListings.map((prevSearchedListing) => {
      if (prevSearchedListing.id === listing.id) {
        // If the condition is true, add the isBanned property
        return { ...prevSearchedListing, isExpanded: !isExpanded };
      }
      // If the condition is not met, return the object as-is
      return prevSearchedListing;
    })
  );
};
//#endregion

const applyButtonPressed = useRef(false);

const handleApply = async () => {
  if (user.id) {
    if (
      applyButtonRef.current.innerText === "Apply now" &&
      !hasApplied &&
      !applyButtonPressed.current
    ) {
      applyButtonPressed.current = true;

      try {
        const result = await addApplicantToListing(listing.id, user.id);
        const endResult = await applyListing(
          user.id,
          user.profilePhoto,
          listing.uid,
          listing.id,
          listing.title
        );

        if (endResult) {
          setSearchedListings((prevSearchedListings) =>
            prevSearchedListings.map((prevSearchedListing) =>
              prevSearchedListing.id === listing.id
                ? { ...prevSearchedListing, hasApplied: true }
                : prevSearchedListing
            )
          );

          setFetchedListings((prevFetchedListings) =>
            prevFetchedListings.map((prevFetchedListing) =>
              prevFetchedListing.id === listing.id
                ? { ...prevFetchedListing, hasApplied: true }
                : prevFetchedListing
            )
          );

          if (isSaved) {
            setSavedListings((prevSavedListings) =>
              prevSavedListings.map((prevSavedListing) =>
                prevSavedListing.id === listing.id
                  ? { ...prevSavedListing, hasApplied: true }
                  : prevSavedListing
              )
            );
          }

          setAppliedListings((prevAppliedListings) => [
            ...prevAppliedListings,
            { id: listing.id }
          ]);
        }

        applyButtonRef.current.classList.remove(styles.applyButton);
        applyButtonRef.current.classList.add(styles.appliedButton);
        applyButtonRef.current.innerText = result ? "Applied" : "Something went wrong";
      } catch (error) {
        console.error("Error during application:", error);
      } finally {
        applyButtonPressed.current = false;
      }
    }
  } else {
    window.setSignInHidden(false);
  }
};


//#region Edit
const getCurrentTime = (withAMorPM) => {
  const selectedTime = listing.time;

  // Parse hours and minutes
  const [hours, minutes] = selectedTime.split(":").map(Number);

  // Format hours and minutes with leading zeros
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Set AM/PM
  const getAmOrPm = hours < 12 ? "AM" : "PM";

  // Combine formatted hours and minutes with AM/PM if necessary
  const formattedTime = withAMorPM
    ? `${formattedHours}:${formattedMinutes} ${getAmOrPm}`
    : `${formattedHours}:${formattedMinutes}`;

  return formattedTime;
};

//#endregion

const getDate = ()=>{
  const seconds = listing.date.seconds;
  const nanoseconds = listing.date.nanoseconds;

  // Convert nanoseconds to milliseconds (divide by 1,000,000)
  const milliseconds = nanoseconds / 1000000;

  // Create a JavaScript Date object
  const date = new Date(seconds * 1000 + milliseconds);
  const localizedDateString = date.toLocaleDateString();
  
  return localizedDateString;
}

//#region More options button handling
  const moreButtonRef = useRef(null);
  const openMoreOptions = ()=>{
      blockInitialClick.current=true;
      moreButtonRef.current.classList.remove(styles.hidden);
      moreButtonRef.current.classList.add(styles.moreContainer);
  }

  const closeMoreOptions = ()=>{
    
    if(moreButtonRef.current){
      moreButtonRef.current.classList.add(styles.hidden);
      moreButtonRef.current.classList.remove(styles.moreContainer);
    }
  }

const blockInitialClick = useRef(true);

useEffect(() => {
  const handleMoreOptionsClick = (event) => {
    if (moreButtonRef.current && moreButtonRef.current.contains(event.target)) {
      // Handle click on the "More Options" button here

    } else if (!blockInitialClick.current) {
      closeMoreOptions();
    } else {
      blockInitialClick.current = false;
    }
  };

  document.addEventListener('click', handleMoreOptionsClick);

  return () => {
    document.removeEventListener('click', handleMoreOptionsClick);
  };
}, []);

//#endregion
  
//#region Get user data
const [listingUser, setListingUser] = useState(null);


const fetchUserReviews = async()=>{
  const userReviews = await getUserReviews(listing.uid);

  return userReviews;
 }

  const getUserData = async()=>{
   const userData = await getUserById(listing.uid);
   return userData;
  }
  const handleListUser = async()=>{
    const result = await getUserData()
    setListingUser(result);
  } 

useEffect(()=>{
  handleListUser();
},[listing]);
//#endregion

//#region show poster
const showPoster = async()=>{
  if(listingUser){
    window.showProfile(listingUser.profilePicture, listingUser.name, listingUser.surname, listingUser.stars, listingUser.topTasks, await fetchUserReviews());
  }
  setTriggerReRender(!triggerReRender);
}
//#endregion


//#region Copy to clipboard
const handleShare = ()=>{
  setSharedListingData({
    listingId: listing.id
  })
  window.setIsCopied(true);
}
//#endregion

return (
    <>
      {!hidden && (
      <div ref={parentRef} className={`${(isMobile)? styles.mobileTaskBody : styles.desktopTaskBody} ${styles.taskBodyInitial}`} onAnimationEnd={banAnimationEnd}
      >
        {<div onClick = {handleDescriptionExpansion} className = {styles.expansionButton}/>}
        {/* {console.log(listing)} */}
      {/*----------Not Banned----------*/}
        {!gotBanned.current && (
        <div>
          <div className={styles.header}>
            {listingUser && <img alt = "User Profile Picture" src={(listingUser.profilePicture!=null)?listingUser.profilePicture:profilePicture} className={styles.profilePicture} />}
            <h1 className={styles.title}>{listing.title}</h1>
          </div>
            <span className={styles.locationContainer}>
              <img alt = "Location" src = {location} className = {styles.filterIcon}/>
              <p className={styles.detailText} style = {{fontSize: "1.05em", fontWeight:"650"}}>{listing.location.description.split(',')[0].trim()}</p>
            </span>
            
            <div className={styles.details}>

            <span className={styles.detailContainer}>
            <img alt = "Clock" src = {time} className = {styles.filterIcon}/>
              <p className={styles.detailText}>{(listing.duration.hours != 0) ? ((listing.duration.hours < 2) ? 1 + " hour" : listing.duration.hours + " hours") : ""} {(listing.duration.minutes != 0) ? ((listing.duration.minutes < 2) ? 1 + " minute" : listing.duration.minutes + " minutes") : ""}</p>
            </span>

            <span className={styles.detailContainer}>
              <p className={styles.detailText}>{listing.type}</p>
            </span>

            <span className={styles.detailContainer}>
              <p className={styles.detailText}>{(currency == "$")? currency + listing.price : listing.price + currency}</p>
            </span>
          </div>
          <img src = {threeDots} alt = 'More' className={styles.threeDots} onClick = {openMoreOptions}/>
          {/* More options button */}
          <div className = {styles.hidden} ref = {moreButtonRef}>

            <div className = {styles.optionContainer} onClick = {handleShare}>
              <img src = {shareIcon} alt = 'Share' className={styles.shareIcon}/>
              <p>Share this listing</p>
            </div>
            
            {user.id != listing.uid && <div className = {styles.optionContainer} onClick = {handleBan}>
              <img src={banIcon} alt='Ban' className={styles.banIcon}/>
              <p>Not interested</p>
            </div>}
          </div>
            <div className = {styles.dateBookmarkContainer}>
            {/* <img alt = "Calendar" src = {calendar} className = {styles.calendar}/> */}

              <p className={styles.desiredDate}>Needed for {getDate()} at {getCurrentTime(true)}</p>
              {user.id != listing.uid && <img
                  src={bookmark ? bookmarkSelected : bookmarkOutline}
                  alt='Bookmark'
                  className={styles.bookmark}
                  onClick={handleSave}
              />}
            </div>
          <div className={`${styles.descriptionContainer}`}
          ref = {descriptionContainerRef}>
            <div className = {styles.descriptionLine}/>
            <span>{listing.description}</span>
            { user.id != listing.uid && <button className={(!hasApplied)?styles.applyButton:styles.appliedButton} onClick={handleApply} ref = {applyButtonRef}>{(!hasApplied)?"Apply now":"Applied"}</button>}
          </div>
          <div className={styles.viewDescription}>
          </div>
        </div>)}

        {/*----------Banned----------*/}
        {gotBanned.current && <div className = {styles.bannedListing}>
        <p>This listing is now hidden</p>
        <button onClick={handleUnban}>Revert</button>
        </div>}
    </div>
    )}
  </>
  );
};

export default ListedTask;
