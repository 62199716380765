import React, {useRef, useState, useContext} from 'react';
import styles from './UpdateStatus.module.css';
import UserContext from '../../../../Firebase Contexts/UserDataContext';

const UpdateStatus = ({disableStatusWindow, requestTaskCompletionByUser, task})=>{

    const isMobile = window.targetPlatform == "mobile";

    const {user} = useContext(UserContext);

    const buttonWasPressed = useRef(false);

    const confirmationButtonRef = useRef(null);

    const backgroundRef = useRef(null);

    const exitWindow = ()=>{
        if(!buttonWasPressed.current){
            disableStatusWindow();
        }
    }

    const handleConfirmation = async()=>{
        
        if(!buttonWasPressed.current){
            buttonWasPressed.current = true;
            confirmationButtonRef.current.classList.add(styles.disabledButton);
            //roomId
            const result = await requestTaskCompletionByUser(task.uid, user.id, user.name, user.profilePhoto, task.id, task.listingId, task.title, task.roomId);
            if(result!=null){
                // console.log("confirmation is not null")
                disableStatusWindow();
            }else {
                console.log("confirmation is null")
            confirmationButtonRef.current.classList.remove(styles.disabledButton);
            buttonWasPressed.current = false;
            }
        }
    }



    return(
        <>
        {/* {console.log(task)} */}
        <div className = {styles.background} ref = {backgroundRef} onClick = {exitWindow}/>
        <div className={styles.container}>
                <div className = {styles.connectConfirmation}>
                <p className={styles.titleText}>Have you completed the task?</p>

                <p className = {styles.confirmationMessage}>Make sure the client is satisfied before confirming</p>
                <span className = {styles.connectConfirmationButtons}>
                <button ref = {confirmationButtonRef} className = {styles.confirmationButtonEnabled} onClick = {handleConfirmation}>Send completion request</button>
                <button className = {styles.notNowButton} onClick = {exitWindow}>Not now</button>
                </span>
            </div>
        </div>
        </>
    )
}

export default UpdateStatus;