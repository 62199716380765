import React, { useContext, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import {createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged,
  signInWithEmailAndPassword, setPersistence, browserLocalPersistence, signOut, linkWithPopup, sendPasswordResetEmail,
  EmailAuthProvider, linkWithCredential} from 'firebase/auth';
import {getFirestore, collection, onSnapshot, addDoc, doc, setDoc, getDoc, deleteDoc, getDocs, where, query, updateDoc,
  Timestamp, GeoPoint, orderBy, limit, serverTimestamp, writeBatch, startAfter, and, or,
  runTransaction} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import UserContext from './Firebase Contexts/UserDataContext';
import shortid from 'shortid';
import * as GeoFire from 'geofire-common';
import Resizer from "react-image-file-resizer";

//#region Firebase initialization
const firebaseConfig = {
  apiKey: "AIzaSyCz5pp5lX18XyCy_6gR7MDuW2mbPv6wqbM",
  authDomain: "xelpie-47bb1.firebaseapp.com",
  projectId: "xelpie-47bb1",
  storageBucket: "xelpie-47bb1.appspot.com",
  messagingSenderId: "548292106597",
  appId: "1:548292106597:web:893bcde3e4f19c4aeb698c",
  measurementId: "G-EN79KV4PYG"
};

export const apiKey = firebaseConfig.apiKey;

// In firebase.js or your frontend code
async function initializePlacesAPIKey() {
  try {
    // Fetch the API key from the Cloud Function
    const response = await fetch('https://us-central1-xelpie-47bb1.cloudfunctions.net/placesAPIKey'); // Replace with the actual URL of your Cloud Function
    const data = await response.json();
    const placesAPIKey = data.apiKey;

    return placesAPIKey;

    // Initialize Firebase or perform other actions with the API key
    // ...
  } catch (error) {
    console.error('Error fetching API key:', error);
  }
}

// Call the function to initialize Firebase when your application starts
const placesAPIKey = await initializePlacesAPIKey();



const app = initializeApp(firebaseConfig);
//#endregion

const auth = getAuth(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);



//#region Check user auth
export const CheckIfAuthListenerCompleted = () => {
  const {setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, authListenerCompleted, checkIfPersitenceIsEnded, setUserLocation } = useContext(UserContext);

  const test = async() => {
    await setPersistence(auth, browserLocalPersistence);

    await setupAuthListener(setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, authListenerCompleted, checkIfPersitenceIsEnded, setUserLocation)
  };

  useEffect(() => {
    test();
  }, []);
  return null;
}

const setupAuthListener = async (setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, authListenerCompleted, checkIfPersitenceIsEnded, setUserLocation) => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {

    if (user) {

      const userDocRef = doc(firestore, 'users', user.uid);
      userDocSnapshot = await getDoc(userDocRef);

      if(userDocSnapshot.exists()){
        const accountCreationStep = userDocSnapshot.data().accountCreationStep;
        if(accountCreationStep){
          if(accountCreationStep=="completed"){
            const name = userDocSnapshot.data().name;
            const surname = userDocSnapshot.data().surname;
            const phoneNumber = userDocSnapshot.data().phoneNumber;
            const profilePicture = userDocSnapshot.data().profilePicture;
            const email = userDocSnapshot.data().email;
            const preferredCountry = userDocSnapshot.data().preferredCountry;
            const preferredCountryCode = userDocSnapshot.data().preferredCountryCode;
            const preferredTypesOfTasks = userDocSnapshot.data().preferredTypesOfTasks;
            const preferredMeasurementSystem = userDocSnapshot.data().preferredMeasurementSystem;
            setUserData(user.uid, name, surname, phoneNumber, profilePicture, email);
            setUserPreferredCountry({ preferredCountry: preferredCountry, preferredCountryCode: preferredCountryCode,});
            setUserPreferredMeasurementSystem(preferredMeasurementSystem);
            setUserSelectedTypes(preferredTypesOfTasks);
          }
      }
    }

    authListenerCompleted.current = true;
    } else {
      // User is signed out
      getUserLocation().then((location) => {
        // Access the latitude and longitude from the location object
        setUserLocation({
          latitude: location.latitude,
          longitude: location.longitude
        });
      }).catch((error) => {
        console.error("Error getting user location:", error.message);
      });
      
      authListenerCompleted.current = true;
      // console.log("No user", authListenerCompleted.current)
    }
    
    checkIfPersitenceIsEnded();

    if(window.listedTasksShouldReRender!=undefined){window.setListedTasksShouldReRender(!window.listedTasksShouldReRender);}
  });
  
  await getUserLocation().then((location) => {
    // Access the latitude and longitude from the location object
    setUserLocation({
      latitude: location.latitude,
      longitude: location.longitude
    });
  }).catch((error) => {
    console.error("Error getting user location:", error.message);
  });
  
  unsubscribe();
}
//#endregion


let userId;
let userName;
let userSurname;
let profilePhotoURL;
let userEmail;
let userDocSnapshot;
let userPreferredCountry;
let userPreferredCountryCode;

//#region Notifications handling

// Check if user.id is defined
export const startListeningForTasks = async (userId, preventFirstListener, sendNotification) => {
  if (!userId) {
    console.error('userId is undefined. Ensure it has a valid value.');
    return; // Exit the function if userId is undefined.
  }

  const userDocRef = doc(firestore, 'users', userId);
  const notificationsCollectionRef = collection(userDocRef, 'notifications');

  const unsubscribeAllNotifications = onSnapshot(notificationsCollectionRef, async (notificationsSnapshot) => {
    if (preventFirstListener.current) {
      notificationsSnapshot.docChanges().forEach(async (change) => {
        if (change.type === 'added') {
          // Introduce a delay of one second before fetching the data
          await new Promise(resolve => setTimeout(resolve, 0));
  
          const documentSnapshot = await getDoc(doc(notificationsCollectionRef, change.doc.id));
          let newNotification = documentSnapshot.data();
          newNotification = {...newNotification, timesSeen: 0}
          // console.log(newNotification);
          sendNotification(newNotification);
        }
      });
    } else {
      preventFirstListener.current = true;
    }
  });
};

export const getUnreadNotifications = async (userId) => {
  if (!userId) {
    console.error('userId is undefined. Ensure it has a valid value.');
    return; // Exit the function if userId is undefined.
  }

  const userDocRef = doc(firestore, 'users', userId);
  const notificationsCollectionRef = collection(userDocRef, 'notifications');

  try {
    const querySnapshot = await getDocs(query(notificationsCollectionRef, where('status', '==', 'unread')));

    const unreadNotifications = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      // Adding the 'timesSeen' key with a value of 0 to each notification object
      return { ...data, timesSeen: 0 };
    });
    return unreadNotifications;
  } catch (error) {
    console.error('Error fetching unread notifications:', error);
    throw error;
  }
};

export const deleteNotifications = async (userId, listingIds, type) => {
try{  
  if (!userId) {
      console.error('userId is undefined. Ensure it has a valid value.');
      return;
    }

    const userDocRef = doc(firestore, 'users', userId);
    const notificationsCollectionRef = collection(userDocRef, 'notifications');
    const batch = writeBatch(firestore);

    if (type === 'newApplicant') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('type', '==', 'newApplicant'))
      );

      querySnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
    } else if (type === 'acceptedApplication') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId)))
      );
      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === 'acceptedApplication') {
          batch.delete(doc.ref);
        }
      });
    } else if (type === 'vendorAbandonedTask') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId)))
      );

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === 'vendorAbandonedTask') {
          batch.delete(doc.ref);
        }
      });
    } else if (type === 'clientAbandonedTask') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId)))
      );

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === 'clientAbandonedTask') {
          batch.delete(doc.ref);
        }
      });
    } else if (type === 'confirmationRequestTask') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId)))
      );

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === 'confirmationRequestTask') {
          batch.delete(doc.ref);
        }
      });
    } else if (type === 'taskCompletionConfirmed') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId)))
      );

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === 'taskCompletionConfirmed') {
          batch.delete(doc.ref);
        }
      });
    }
     else if (type === 'newMessage') {
      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId)))
      );

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.type === 'newMessage') {
          batch.delete(doc.ref);
        }
      });
    }

    // const remainingDocs = await getDocs(notificationsCollectionRef);
    // remainingDocs.forEach(doc => {
    //   console.log('Document remaining:', doc.id, doc.data());
    // });
    
    
    // Commit the batch to perform the deletion
    await batch.commit();

    return true;
  }catch(error){
    console.log(error);
  }
};

export const sendApplicationNotification = async (userId, userProfilePicture, listingId, listingUid, listingTitle) => {
  const usersCollectionRef = collection(firestore, 'users');
  const userDocRef = doc(usersCollectionRef, listingUid);
  const notificationsCollectionRef = collection(userDocRef, 'notifications');

  // Create a new document in the "notifications" subcollection
  const newNotificationDocRef = doc(notificationsCollectionRef);

  // Set data in the new document with userId and listingId
  const data = {
    applicant: userId,
    listingId: listingId,
    type: "newApplicant",
    status: "unread",
    timestamp: serverTimestamp(),
    listingTitle: listingTitle,
    applicantPhoto: userProfilePicture,
  };

  await setDoc(newNotificationDocRef, data);

}

export const sendApplicationAcceptanceNotification = async (applicantId, userProfilePicture, listingId, listingTitle) => {
  const usersCollectionRef = collection(firestore, 'users');
  const userDocRef = doc(usersCollectionRef, applicantId);
  const notificationsCollectionRef = collection(userDocRef, 'notifications');

  // Create a new document in the "notifications" subcollection
  const newNotificationDocRef = doc(notificationsCollectionRef);

  // Set data in the new document with userId and listingId
  const data = {
    applicant: applicantId,
    listingId: listingId,
    type: "acceptedApplication",
    status: "unread",
    timestamp: serverTimestamp(),
    listingTitle: listingTitle,
    applicantPhoto: userProfilePicture,
  };

  await setDoc(newNotificationDocRef, data);

}

export const sendAbandonTAskNotification = async (clientId, vendorName, vendorProfilePicture, listingId, listingTitle, roomId) => {
  const usersCollectionRef = collection(firestore, 'users');
  const userDocRef = doc(usersCollectionRef, clientId);
  const notificationsCollectionRef = collection(userDocRef, 'notifications');

  // Create a new document in the "notifications" subcollection
  const newNotificationDocRef = doc(notificationsCollectionRef);
  // Set data in the new document with userId and listingId
  const data = {
    roomId: roomId,
    listingId: listingId,
    type: "vendorAbandonedTask",
    status: "unread",
    timestamp: serverTimestamp(),
    listingTitle: listingTitle,
    vendorName: vendorName,
    vendorPhoto: vendorProfilePicture,
  };

  await setDoc(newNotificationDocRef, data);

}

export const sendConfirmationTaskRequestNotification = async (
  clientId,
  vendorId,
  vendorName,
  vendorProfilePicture,
  assignedListingId,
  listingId,
  listingTitle,
  roomId) => {
  try {
    await runTransaction(firestore, async (transaction) => {
      const usersCollectionRef = collection(firestore, 'users');
      const userDocRef = doc(usersCollectionRef, clientId);
      const notificationsCollectionRef = collection(userDocRef, 'notifications');

      // Create a new document in the "notifications" subcollection
      const newNotificationDocRef = doc(notificationsCollectionRef);

      // Set data in the new document with userId and listingId
      const notificationData = {
        roomId: roomId,
        vendorId: vendorId,
        listingId: listingId,
        type: 'confirmationRequestTask',
        status: 'unread',
        timestamp: serverTimestamp(),
        listingTitle: listingTitle,
        vendorName: vendorName,
        vendorPhoto: vendorProfilePicture,
      };

      transaction.set(newNotificationDocRef, notificationData);

      const assignedListingsCollection = collection(firestore, 'assignedListings');
      const listingDocRef = doc(assignedListingsCollection, assignedListingId);

      // Update the "pendingConfirmation" field in the "assignedListings" collection
      transaction.update(listingDocRef, {
        pendingConfirmation: true,
      });
    });

    return true;
  } catch (error) {
    console.error('Error sending confirmation task request notification:', error);
    return false;
  }
};


export const sendTaskCompletionConfirmationNotification = async (vendorId, clientProfilePicture, clientName, listingId, listingTitle, roomId) => {
  try{
    const usersCollectionRef = collection(firestore, 'users');
    const userDocRef = doc(usersCollectionRef, vendorId);
    const notificationsCollectionRef = collection(userDocRef, 'notifications');

    // Create a new document in the "notifications" subcollection
    const newNotificationDocRef = doc(notificationsCollectionRef);
    console.log(roomId);
    // Set data in the new document with userId and listingId
    const data = {
      roomId: roomId,
      listingId: listingId,
      type: "taskCompletionConfirmed",
      status: "unread",
      timestamp: serverTimestamp(),
      listingTitle: listingTitle,
      clientPhoto: clientProfilePicture,
      clientName: clientName,
    };

    await setDoc(newNotificationDocRef, data);

    return true;
  }catch(error){
    console.log(error, "There has been an error")
  }
}

export const deleteMessageNotifications = async (userId, messagesId) => {
  try {
    if (!userId || messagesId.length === 0) {
      return;
    }

    const userDocRef = doc(firestore, 'users', userId);
    const notificationsCollectionRef = collection(userDocRef, 'notifications');
    const chunks = chunkArray(messagesId, 30); // Chunk array into groups of 30

    for (const chunk of chunks) {
      const batch = writeBatch(firestore);

      const querySnapshot = await getDocs(
        query(notificationsCollectionRef, where('id', 'in', chunk.map(obj => obj.id)))
      );

      querySnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Commit the batch to perform the deletion for this chunk
      await batch.commit();
    }

    // console.log('messages deleted');
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Helper function to chunk array into groups of a specified size
function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}


export const sendMessageNotification = async (userId, receiverId, roomId) => {
  const usersCollectionRef = collection(firestore, 'users');
  const userDocRef = doc(usersCollectionRef, receiverId);
  const notificationsCollectionRef = collection(userDocRef, 'notifications');

  // Create a new document in the "notifications" subcollection
  const newNotificationDocRef = doc(notificationsCollectionRef);

  // Set data in the new document with userId and listingId
  const data = {
    roomId: roomId,
    senderId: userId,
    type: "newMessage",
    status: "unread",
    id: shortid(),
  };

  await setDoc(newNotificationDocRef, data);

}

//#endregion

//#region Messaging System

export const startListeningForMessages = async (roomId, preventFirstListenerOfRooms, sendMessage) => {
  if (!roomId) {
    console.error('userId is undefined. Ensure it has a valid value.');
    return; // Exit the function if userId is undefined.
  }

  const messagesDocRef = doc(firestore, 'rooms', roomId);
  
  const conversationCollectionRef = collection(messagesDocRef, "conversation");

  const unsubscribeAllMessages = onSnapshot(conversationCollectionRef, async (messageSnapshot) => {
    const roomIndex = preventFirstListenerOfRooms.current.findIndex((room) => room.roomId === roomId);
    if (!preventFirstListenerOfRooms.current[roomIndex].firstRenderDone) {
      messageSnapshot.docChanges().forEach(async (change) => {
        if (change.type === 'added') {
          await new Promise(resolve => setTimeout(resolve, 100));
          const documentSnapshot = await getDoc(doc(conversationCollectionRef, change.doc.id));
          let newMessage = documentSnapshot.data();
          newMessage = {...newMessage, roomId: roomId, timesSeen: 0}
          sendMessage(newMessage);
        }
      });
    } else {
      preventFirstListenerOfRooms.current[roomIndex].firstRenderDone = false;
    }
  });
};

export const createNewConversationRoom = async (clientId, clientProfilePicture, vendorId, vendorProfilePicture, listingId) => {
  try {
    // Create a new conversation room
    const roomsCollectionRef = collection(firestore, "rooms");

    // Add a new document to the "rooms" collection
    const newRoomDocRef = await addDoc(roomsCollectionRef, {
      clientId: clientId,
      vendorId: vendorId,
    });

    // Get the ID of the newly created room
    const roomId = newRoomDocRef.id;

    // Update "rooms" collection for the client
    const clientDocRef = doc(firestore, "users", clientId);
    const clientRoomsCollectionRef = collection(clientDocRef, "rooms");

    await addDoc(clientRoomsCollectionRef, {
      vendorId: vendorId,
      vendorProfilePicture: vendorProfilePicture,
      conversationId: roomId,
      // Add other fields as needed
    });

    // Update "rooms" collection for the vendor
    const vendorDocRef = doc(firestore, "users", vendorId);
    const vendorRoomsCollectionRef = collection(vendorDocRef, "rooms");

    await addDoc(vendorRoomsCollectionRef, {
      clientId: clientId,
      clientProfilePicture: clientProfilePicture,
      conversationId: roomId,
      // Add other fields as needed
    });

    const listingDocRef = doc(firestore, 'listings', listingId);

    await setDoc(listingDocRef, {
      roomId: roomId,
    }, { merge: true });

    return roomId;
  } catch (error) {
    console.error("Error adding document: ", error);
    // Handle the error as needed
  }
};

export const deleteConversationRoom = async (roomId, clientId, vendorId) => {
  try {
    const roomsCollectionRef = collection(firestore, 'rooms');

    // Delete the "conversation" collection inside the document
    const conversationCollectionRef = collection(roomsCollectionRef, roomId, 'conversation');
    const conversationQuerySnapshot = await getDocs(conversationCollectionRef);

    conversationQuerySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Delete the document in the "rooms" collection with the specified roomId
    await deleteDoc(doc(roomsCollectionRef, roomId));

    // Delete the corresponding documents in the "users" collection
    const clientDocRef = doc(firestore, 'users', clientId);
    const vendorDocRef = doc(firestore, 'users', vendorId);

    // Delete the document in the "rooms" collection inside the client's document
    const clientRoomsCollectionRef = collection(clientDocRef, 'rooms');
    const clientRoomQuery = query(clientRoomsCollectionRef, where('conversationId', '==', roomId));
    const clientRoomQuerySnapshot = await getDocs(clientRoomQuery);

    clientRoomQuerySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Delete the document in the "rooms" collection inside the vendor's document
    const vendorRoomsCollectionRef = collection(vendorDocRef, 'rooms');
    const vendorRoomQuery = query(vendorRoomsCollectionRef, where('conversationId', '==', roomId));
    const vendorRoomQuerySnapshot = await getDocs(vendorRoomQuery);

    vendorRoomQuerySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Delete the documents in the "notifications" subcollection for the client
    const clientNotificationsCollectionRef = collection(clientDocRef, 'notifications');
    const clientNotificationsQuery = query(clientNotificationsCollectionRef, where('roomId', '==', roomId));
    const clientNotificationsQuerySnapshot = await getDocs(clientNotificationsQuery);

    clientNotificationsQuerySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Delete the documents in the "notifications" subcollection for the vendor
    const vendorNotificationsCollectionRef = collection(vendorDocRef, 'notifications');
    const vendorNotificationsQuery = query(vendorNotificationsCollectionRef, where('roomId', '==', roomId));
    const vendorNotificationsQuerySnapshot = await getDocs(vendorNotificationsQuery);

    vendorNotificationsQuerySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // console.log('Conversation room, conversation, and notifications deleted successfully');
  } catch (error) {
    console.error('Error deleting conversation room: ', error);
    // Handle the error as needed
  }
};


export const getUserRooms = async (userId) => {
  try {
    await new Promise(resolve => setTimeout(resolve, 2000));
    // Get the reference to the user document in the "users" collection
    const userDocRef = doc(firestore, "users", userId);

    // Access the "rooms" subcollection inside the user document
    const roomsCollectionRef = collection(userDocRef, "rooms");

    // Get all documents from the "rooms" subcollection
    const roomsSnapshot = await getDocs(roomsCollectionRef);

    // Map the documents to an array of objects
    const rooms = roomsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    // Return the array of rooms
    return rooms;
  } catch (error) {
    console.error("Error getting user rooms: ", error);
    // Handle the error as needed
  }
};

export const getInitialConversation = async (roomId) => {
  try {
    // Get the reference to the room document in the "rooms" collection
    const roomDocRef = doc(firestore, "rooms", roomId);

    // Access the "conversations" subcollection inside the room
    const conversationsCollectionRef = collection(roomDocRef, "conversation");

    // Get all documents from the "conversations" subcollection
    const conversationsSnapshot = await getDocs(conversationsCollectionRef);

    // Map the documents to an array of objects
    const conversation = conversationsSnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));

    // Return the array of conversation documents
    return conversation;
  } catch (error) {
    console.error("Error getting initial conversation: ", error);
    // Handle the error as needed
  }
};

export const sendMessage = async (roomId, senderId, receiverId, messageText, textId) => {
  try {
    // Get the reference to the specific room document using the provided roomId
    const roomDocRef = doc(firestore, "rooms", roomId);

    // Check if the room exists
    const roomSnapshot = await getDoc(roomDocRef);
    if (!roomSnapshot.exists()) {
      throw new Error("Room not found");
    }

    // Access the "conversations" subcollection inside the room
    const conversationCollectionRef = collection(roomDocRef, "conversation");

    // Add a new document to the "conversation" subcollection
    await addDoc(conversationCollectionRef, {
      timestamp: serverTimestamp(),
      senderId: senderId,
      receiverId: receiverId,
      text: messageText,
      id: textId,
      status: "unread" + " " + senderId,
      roomId: roomId,
    });

    // You can return any relevant information if needed
    return true;
  } catch (error) {
    console.error("Error sending message: ", error);
    // Handle the error as needed
  }
};

//#endregion

const detachNameAndSurname = (googleUserName) =>{
  const [firstName, lastName] = googleUserName.split(" ");
  userName = firstName;
  userSurname = lastName;
}

//#region Email and password Sign up
export const signUp = async (email, password, setUserData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    userId = user.uid;
    userEmail = user.uid;
    // console.log(userId);

    // Create a reference to the user's document using the UID as the document ID
    const userDocRef = doc(firestore, 'users', userId);

    // Add user data to the document
    await setDoc(userDocRef, {
      uid: user.uid,
      email: user.email,
      accountCreationStep: 2,
      stars: 0,
      topTasks: [],
      // You can add more fields here if needed
    });

    // console.log('User is signed up');
    window.shouldStepBeDisabled(false);
    window.setStep(2);
    signInWithEmailAndPassword(auth, email, password);
    setUserData(user.uid, null, null, null, null, email);
    // console.log("Signed in with email and password");
  } catch (error) {
    console.log('Error during sign up:', error);
    // Handle the error appropriately
    window.shouldStepBeDisabled(false);
    const errorCode = error.code;
    const errorMessage = error.message;
    window.signUphandleWarningError(errorCode);

    // ...
  }
};
//#region User Details

//#region Name Surname Process
export const updateUserNameSurname = async (name, surname) => {
  try {
    // Reference to the user's document in Firestore
    const userDocRef = doc(firestore, 'users', userId);

    // Update the user's name and surname in the document
    await setDoc(userDocRef, {
      name: name,
      surname: surname,
      accountCreationStep: 3,
    },
      { merge: true }
      );

    // console.log('User info updated successfully');
    window.shouldStepBeDisabled(false);
    // console.log(userId);
    window.setStep(3);
    // Proceed with your next steps

  } catch (error) {
    console.error('Error updating user info:', error);
    // Handle the error appropriately
    window.shouldStepBeDisabled(false);
  }
};
//#endregion

//#region Phone number process

// Function to normalize and validate a phone number
const normalizePhoneNumber = (phoneNumberCountryCode, phoneNumber) => {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, phoneNumberCountryCode); // Replace 'US' with your default country code
    if (parsedNumber && parsedNumber.isValid()) {
      return parsedNumber.formatInternational();
    } else {
      window.signUphandleWarningError("Invalid phone number")
      throw new Error('Invalid phone number');
    }
  } catch (error) {
    window.signUphandleWarningError("Invalid phone number")
    throw new Error('Invalid phone number');
  }
};

// Function to update user's phone number

export const checkIfPhoneNumberExists = async (phoneNumberCountryCode, phoneNumber) => {
  try {
    const normalizedPhoneNumber = await normalizePhoneNumber(phoneNumberCountryCode, phoneNumber)

    // Access the 'users' collection in Firestore
    const usersCollection = collection(firestore, 'users');

    // Create a query to find documents where 'phoneNumber' field is equal to the provided phoneNumber
    const q = query(usersCollection, where('phoneNumber', '==', normalizedPhoneNumber));

    // Execute the query and get the snapshot
    const querySnapshot = await getDocs(q);

    // If any documents are retrieved, return true; otherwise, return false
    if(querySnapshot.size>0){
      //phone number exists
      return {status: false, message: "used phone number"}
    }else{
      return {status: true, message: normalizedPhoneNumber};
    }
  } catch (error) {
    // Handle any errors that occur during the process
    console.error('Error checking phone number existence:', error);
    return {status: false, message: error.code}; // You may want to handle errors based on your application's needs
  }
};


export const updateUserPhoneNumber = async (phoneNumberCountry, phoneNumberCountryCode, phoneNumber) => {
  try {
    // Reference to the user's document in Firestore
    const userDocRef = doc(firestore, 'users', userId);
    // Update the user's phone number in the document
    await setDoc(userDocRef, {
      phoneNumber: phoneNumber,
      preferredCountry: phoneNumberCountry,
      preferredCountryCode: phoneNumberCountryCode,
      preferredMeasurementSystem: (phoneNumberCountryCode != "US")?"metric":"miles",
      preferredTypesOfTasks:[],
      accountCreationStep: 4,
    },
    { merge: true }
    );

    // console.log('User phone number updated successfully');
    window.setStep(4);
    // Proceed with your next steps
    window.shouldStepBeDisabled(false);

  } catch (error) {
    // console.error('Error updating user phone number:', error);
    // Handle the error appropriately

    window.shouldStepBeDisabled(false);
  }
};
//#endregion

//#region ProfilePicture process

export const updateUserProfilePicture = async (profilePicture, isGoogleProfile) => {
  try {
    if(profilePicture!=null && !isGoogleProfile){
      profilePicture = await resizeFile(profilePicture, 300, 300, 50000);
    }

    // Reference to the user's document in Firestore
    const userDocRef = doc(firestore, 'users', userId);

    // Update the user's name and surname in the document
    await setDoc(userDocRef, {
      profilePicture: profilePicture,
      accountCreationStep: "completed",
    }, { merge: true });
    // console.log("User profile picture step is completed")
    // Proceed with your next steps
    window.shouldStepBeDisabled(false);
    window.setStep("completed");

    return true;
  } catch (error) {
    console.error('Error updating user info:', error);
    // Handle the error appropriately
    window.shouldStepBeDisabled(false);
    return false;
  }
};
//#endregion
//#endregion

//#region Email and password Sign-in

export const signIn = async(email, password, setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes)=>{
    try{
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Signed in 
      // console.log('user is signed in');
      const user = userCredential.user;
      userId = user.uid;
      // Retrieve user's document data from Firestore
      const userDocRef = doc(firestore, 'users', user.uid);
      userDocSnapshot = await getDoc(userDocRef);

      directUser(setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, "sign in");
      return true;
  } catch(error){
    const errorCode = error.code;
    const errorMessage = error.message;
    window.disableSignIn(false);
    window.signInhandleWarningError(errorCode);
    return false;
  };
}

//#region Google Sign-In

export const signInWithGoogle = async (setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, sign) => {
  try {
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(auth, provider);
    // Signed in 
    const user = userCredential.user;
    userId = user.uid;
    userEmail = user.email;
    profilePhotoURL = user.photoURL;

    detachNameAndSurname(user.displayName);

    // Retrieve user's document data from Firestore
    const userDocRef = doc(firestore, 'users', user.uid);
    userDocSnapshot = await getDoc(userDocRef);
    
    //if step exists, then proceed, else create account
    if(userDocSnapshot.exists()){
      // console.log("Signed in with Google")
      directUser(setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, sign);
      //getUserReviews();
    }else {
      await setDoc(userDocRef, {
        uid: user.uid,
        email: user.email,
        accountCreationStep: 2,
        stars: 0,
        topTasks: [],
        // You can add more fields here if needed
      });
      setUserData(user.uid, userName, userSurname, null, user.photoURL, userEmail)
      // console.log("Signed up with Google", sign )
      if(sign == "sign in"){
        window.handleSignInExit();
        window.setSignInHidden(true);
        window.setSignUpHidden(false);
      }
      //window.shouldStepBeDisabled(false);
      window.setStep(2);
    }
    return true;
  } catch (error) {
    // Handle the error appropriately
    console.error('Error during Google sign-in:', error);
      return false;
  }
};
//#endregion

const directUser = (setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, sign)=>{
  //Check if user didn't complete their steps
  const step = userDocSnapshot.data().accountCreationStep;
  const docName = userDocSnapshot.data().name;
  const docSurname = userDocSnapshot.data().surname;
  const docPhoneNumber = userDocSnapshot.data().phoneNumber;
  const docEmail = userDocSnapshot.data().email;
  const docPreferredCountry = userDocSnapshot.data().preferredCountry;
  const docPreferredCountryCode = userDocSnapshot.data().preferredCountryCode;
  const preferredTypesOfTasks = userDocSnapshot.data().preferredTypesOfTasks;
  const preferredMeasurementSystem = userDocSnapshot.data().preferredMeasurementSystem;
  // console.log(sign);
  if(step != "completed"){
    //initial load of values once the user continues the sign up process
    if(step==2){
      setUserData( userId, userName, userSurname, null, profilePhotoURL, docEmail);
    } else if(step == 3){
      setUserData( userId, docName, docSurname, null, profilePhotoURL, docEmail);
    } else if(step==4){
      setUserData( userId, docName, docSurname, docPhoneNumber, profilePhotoURL, docEmail);
      setUserPreferredCountry({ preferredCountry: docPreferredCountry, preferredCountryCode: docPreferredCountryCode});
      setUserPreferredMeasurementSystem(preferredMeasurementSystem);
    }

    if(sign =="sign in"){
      window.handleSignInExit();
      window.setSignInHidden(true);
      window.setSignUpHidden(false);
    }

    if(sign =="sign up"){

    }
    window.setStep(step);
  }else{
    // console.log("steps are completed", sign)
    setUserData(userId, userDocSnapshot.data().name, userDocSnapshot.data().surname, userDocSnapshot.data().phoneNumber, userDocSnapshot.data().profilePicture, userDocSnapshot.data().email)
    setUserPreferredCountry({ preferredCountry: docPreferredCountry, preferredCountryCode: docPreferredCountryCode});
    setUserPreferredMeasurementSystem(preferredMeasurementSystem);
    setUserSelectedTypes(preferredTypesOfTasks);
    if(sign =="sign in"){
      window.handleSignInExit();
      window.setSignInHidden(true);
    }else if(sign =="sign up"){
      window.handleSignUpExit()
      window.setSignUpHidden(true);
    }
  }
}


//#endregion


export const signOutUser = () => {
  signOut(auth)
    .then(() => {
      // console.log('User signed out successfully');
      // Handle any additional actions after sign-out
      window.location.reload(); // Reload the web page
    })
    .catch((error) => {
      // console.error('Sign-out error:', error);
    });
};

export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    if (email) {
      // Send a password reset email to the user's email address
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent successfully
          resolve({ result: true });
        })
        .catch((error) => {
          console.log("Error", error);
          // Handle errors, e.g., if the email address is not found
          reject({ result: false, error: error.code });
        });
    }
  });
};


// Get user reviews function
export const getUserReviews = async (userId) => {
  try {
    //Create a reference of the collection
    const reviewsRef = collection(firestore, 'reviews');
    // Query reviews where the revieweeId is equal to the userId
    const filteredReviews = query(reviewsRef, where('reviewee', '==', userId));
    //Get the filtered documents
    const reviewsSnapshot = await getDocs(filteredReviews);
    const reviewsDocs = reviewsSnapshot.docs;

    const reviews = [];
  // Loop through each review document and access its data
  reviewsDocs.forEach((doc) => {
    const reviewData = {
      reviewer: doc.data().reviewer,
      stars: doc.data().stars,
      type: doc.data().type,
      date: doc.data().date,
      description: doc.data().description,
      impressions: doc.data().impressions,
    };
    reviews.push(reviewData);
  });
    return reviews;
  } catch (error) {
    console.error(error);
    // Handle the error in your UI or log it as needed
  }
};

//Get User By Id
export const getUserById = async (userId) => {
  try {

    // Create a reference to the user's document in Firestore
    const userDocRef = doc(firestore, 'users', userId);
    // Retrieve the user's document data
    const userDocSnapshot = await getDoc(userDocRef);


    if (userDocSnapshot.exists()) {
      // User document found, return user data
      const userData = userDocSnapshot.data();
      // console.log(userDocSnapshot.data().name);
      
      return userData;
    } else {
      // User document not found
      return null;
    }
  } catch (error) {
    // Handle any errors, e.g., Firestore request issues
    console.error(error.code);
    return null; // Return null in case of an error
  }
};


export const createNewListing = async (
  userId,
  userLocation,
  setUserLocation,
  title,
  description,
  locationData,
  type,
  date,
  time,
  duration,
  price,
  currency
) => {
  try {
    if (userId) {
      // Reference to the "listings" collection in Firestore
      const listingsCollectionRef = collection(firestore, 'listings');

      // Fetch coordinates using place_id from the Google Geocoding API
      const place_id = locationData.place_id;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${placesAPIKey}`;
      const geocodeResponse = await fetch(geocodeUrl);

      if (!geocodeResponse.ok) {
        throw new Error('Failed to fetch coordinates');
      }

      const geocodeData = await geocodeResponse.json();

      // Check if the Geocoding API response contains valid data
      if (geocodeData && geocodeData.results && geocodeData.results[0] && geocodeData.results[0].geometry) {
        // Extract the latitude and longitude from the Geocoding API response

        // Create a new object with the extracted coordinates
        // const { GeoPoint } = require('@google-cloud/firestore');
        const { lat, lng } = geocodeData.results[0].geometry.location;
        const locationCoordinates = new GeoPoint(lat, lng);

        const userLocationCoordinates = new GeoPoint(userLocation.latitude, userLocation.longitude);
        //Create geohash
        let userGeohash;
        //if user location exists
        if(userLocation.latitude){
          const latitude = userLocationCoordinates._lat;  // Use lat instead of _lat
          const longitude = userLocationCoordinates._long;  // Use long instead of _long
        
          userGeohash = GeoFire.geohashForLocation([latitude, longitude]).substring(0, 5);
        }
        const latitude = locationCoordinates._lat;  // Use lat instead of _lat
        const longitude = locationCoordinates._long
        const listingGeohash = GeoFire.geohashForLocation([latitude, longitude]).substring(0, 5);


        // Add a new document to the "listings" collection with the provided listing data
        const listing = await addDoc(listingsCollectionRef, {
          uid: userId,
          title: title,
          description: description,
          location: locationData,
          locationCoordinates: locationCoordinates,
          // userCoordinates: userLocationCoordinates,
          listingGeohash: listingGeohash,
          // userGeohash: userGeohash,
          selectedApplicant: null,
          type: type,
          date: date,
          time: time,
          duration: duration,
          price: price,
          currency: currency,
        });

        const listingArray = {
          id: listing.id,
          uid: userId,
          title: title,
          description: description,
          location: locationData,
          locationCoordinates: locationCoordinates,
          listingGeohash: listingGeohash,
          selectedApplicant: null,
          // userGeohash: userGeohash,
          type: type,
          date: date,
          time: time,
          duration: duration,
          price: price,
          currency: currency,
        };
        return listingArray;

        // Handle success or any additional actions
      } else {
        throw new Error('Invalid Geocoding API response');
      }
    }
  } catch (error) {
    // Handle errors, e.g., Firestore request issues or Geocoding API errors
    console.error('Error creating a new listing:', error);
    // Handle the error in your UI or log it as needed
  }
};

export const formatJavascriptDateToFirebaseDate = (date) => {
  let formattedDate;

  if (date && date.toDate instanceof Function) {
    // It's a Firebase Date object, no need to change it
    formattedDate = date;
  } else if (typeof date === 'string' || date instanceof Date) {
    // It's a string or JavaScript Date, convert it to Firebase Timestamp
    const jsDate = typeof date === 'string' ? new Date(date) : date;
    formattedDate = Timestamp.fromDate(jsDate);
  } else {
    // Invalid input
    throw new Error('Invalid date input');
  }
  return formattedDate;
};

export const saveEdittedListing = async (listing, title, description, locationData, type, date, time, duration, price, currency) => {
  // Create an object to hold the updated fields
  const updatedFields = {};
  // Compare each field and add it to updatedFields if it has changed
  if (listing.title !== title) {
    updatedFields.title = title;
  }
  if (listing.description !== description) {
    updatedFields.description = description;
  }
  if (listing.location.description !== locationData.description) {
    // Fetch coordinates using place_id from the Google Geocoding API
    const place_id = locationData.place_id;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${placesAPIKey}`;
    const geocodeResponse = await fetch(geocodeUrl);

    if (!geocodeResponse.ok) {
      throw new Error('Failed to fetch coordinates');
    }

    const geocodeData = await geocodeResponse.json();

    // Check if the Geocoding API response contains valid data
    if (geocodeData && geocodeData.results && geocodeData.results[0] && geocodeData.results[0].geometry) {
      // Extract the latitude and longitude from the Geocoding API response

      // Create a new object with the extracted coordinates
      // const { GeoPoint } = require('@google-cloud/firestore');
      const { lat, lng } = geocodeData.results[0].geometry.location;
      const locationCoordinates = new GeoPoint(lat, lng);

      const latitude = locationCoordinates._lat;  // Use lat instead of _lat
      const longitude = locationCoordinates._long
      const listingGeohash = GeoFire.geohashForLocation([latitude, longitude]).substring(0, 5);
      console.log(locationCoordinates, listingGeohash);


    updatedFields.location = locationData;
    updatedFields.locationCoordinates = locationCoordinates;
    updatedFields.listingGeohash = listingGeohash;
  }
  }
  if (listing.type !== type) {
    updatedFields.type = type;
  }
  if (listing.date !== date) {
    updatedFields.date = date;
  }
  if (listing.time !== time) {
    updatedFields.time = time;
  }
  if (listing.duration !== duration) {
    updatedFields.duration = duration;
  }
  if (listing.price !== price) {
    updatedFields.price = price;
  }
  const listingData = updatedFields;

  try {
    // Check if there are any updated fields
    if (Object.keys(updatedFields).length > 0) {
      // Use the Firebase Firestore update method to update the document
      const listingsCollectionRef = collection(firestore, 'listings');
      const listingDocRef = doc(listingsCollectionRef, listing.id);
      await updateDoc(listingDocRef, updatedFields);
    }

    return listingData; // Successfully updated
  } catch (error) {
    console.error('Error updating listing:', error);
    return false; // Failed to update
  }
};

const getGeolocationFromPlaceId = async (place_id) => {
  try {
    // Fetch coordinates using place_id from the Google Geocoding API
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${placesAPIKey}`;
    const geocodeResponse = await fetch(geocodeUrl);
    
    if (!geocodeResponse.ok) {
      throw new Error('Failed to fetch coordinates');
    }

    const geocodeData = await geocodeResponse.json();
    // Check if the Geocoding API response contains valid data
    if (geocodeData && geocodeData.results && geocodeData.results[0] && geocodeData.results[0].geometry) {
      // Extract the latitude and longitude from the Geocoding API response
      const { lat, lng } = geocodeData.results[0].geometry.location;

      // Create a new GeoPoint with the extracted coordinates

      return new GeoPoint(lat, lng);
    } else {
      throw new Error('Invalid Geocoding API response');
    }
  } catch (error) {
    console.error('Error fetching geolocation:', error);
    throw error;
  }
};

export const getSearchedListings = async (calledFromFilters, calledFromListings, fetchedListings,
  setFetchedListings, searchedTask, searchFilters, setFiltersIdentifiedBySearch, userLocation,
  setSearchedListings, stoppedSearchingTasks, savedListings, appliedListings, bannedListings,
  lastDocumentListingFetched, typesOfTasks, radiusIsMiles) => {
  try{
    let typeFound;

    //Will be used in case the fetchedListings is empty
    let listingsDatabase;
    //Will be an array to store the words user searched
    let searchedTaskWordsArray;
    //Will be altered by fitlering
    let listingsData
    
    if(!calledFromFilters && !calledFromListings && !typeFound || typeFound ==""){
      searchFilters.type = "";

      setFiltersIdentifiedBySearch((prevFilters)=>({
        ...prevFilters,
        type: searchFilters.type,
      }))
    }

    //If user typed something
    if(searchedTask){
      //split the searched sentence into an array of words excluding the extra spaces
      searchedTaskWordsArray = searchedTask.split(' ').filter((word) => word !== '');      
    }

  // #region Location

    let locationSelectedListings;

    // Filter based on user's set filter location

  if(!calledFromFilters){
      if(searchedTask && !calledFromListings){
        typeFound = checkForType(searchedTaskWordsArray, typesOfTasks);
        if(typeFound!=null){
          searchFilters.type = typeFound;
        }
      }
      
    if(searchFilters.location.description!=""){
      // console.log(searchFilters.location);
      const geoPoints = await getGeolocationFromPlaceId(searchFilters.location.place_id);
      locationSelectedListings = await getListingsBasedOnLocation(geoPoints, lastDocumentListingFetched, searchFilters, radiusIsMiles);

      //Use city coordinates to as a center point with a default radius if not specified
    }else if(userLocation.latitude!=null){
      // console.log(userLocation);
      const userGeoPoint = new GeoPoint(userLocation.latitude, userLocation.longitude);
      locationSelectedListings = await getListingsBasedOnLocation(userGeoPoint, lastDocumentListingFetched, searchFilters, radiusIsMiles);
      // console.log(locationSelectedListings);
    }
    
    //#region pass data to listings
    const listingsDatabase = locationSelectedListings
      .filter((doc) => !bannedListings.some((banned) => banned.id === doc.id))
      .map((doc) => {
        return {
          id: doc.id,
          uid: doc.uid,
          title: doc.title,
          description: doc.description, // Split and trim description
          location: doc.location,
          duration: doc.duration,
          type: doc.type,
          date: doc.date,
          time: doc.time,
          price: doc.price,
          currency: doc.currency,
          locationCoordinates: doc.locationCoordinates,
          userCoordinates: doc.userCoordinates,
          isSaved: savedListings.some((savedListing) => savedListing.id === doc.id),
          hasApplied: appliedListings.some((appliedListing) => appliedListing.id === doc.id),
        };
      });
      // console.log(listingsDatabase);

    //if called from filters
    // console.log(calledFromListings);
    if(calledFromListings){
      // console.log(fetchedListings)
      listingsData = [...fetchedListings, ...listingsDatabase];
      setFetchedListings([...fetchedListings, ...listingsDatabase]);
    } else if(!calledFromListings){//if called from filters
      listingsData = listingsDatabase;
      setFetchedListings(listingsDatabase);
    }
    setFiltersIdentifiedBySearch(prev=>({
      ...prev,
      //If user selected a type then prioritise that, else put the identified one
      type: (!calledFromFilters && !calledFromListings && typeFound && typeFound!="")?typeFound : searchFilters.type,
    }))

  }else{
    listingsDatabase = fetchedListings;
    listingsData = fetchedListings;
  }

  //#endregion
  //#endregion
  
  //#region Title
  //If user typed something
  let listingsDataFilteredByTitle = [];
  if(searchedTask){
    listingsDataFilteredByTitle = await getListingsByTitle(listingsData, searchedTaskWordsArray, typeFound);
  }
    //#endregion

  //#region Description
  let listingsFilteredByDescription = [];
  if(searchedTask){
    listingsFilteredByDescription = await getListingsByDescription(listingsData, searchedTaskWordsArray);
  }
  //#endregion

  //#region Type
  
  let listingsFilteredByType = [];
  // consosle.log(typeFound, searchFilters.type);
  if(searchFilters.type){
    listingsFilteredByType = await getListingsByType(searchedTaskWordsArray, listingsData, searchFilters, typeFound, calledFromFilters);
  }
  //#endregion

  //Set the listingsData by adding the keywords matching doc Ids and then type Ids
  if(searchedTask!="" || searchFilters.type!=""){
    let newlistingsData = [];
    // listingsData = [];

    if(searchFilters.type!="" || calledFromFilters || (calledFromListings && searchFilters.type!="")){
      newlistingsData = [...new Set([ ...listingsFilteredByType, ...listingsDataFilteredByTitle, ...listingsFilteredByDescription])];
    }else{
      newlistingsData = [...new Set([...listingsDataFilteredByTitle, ...listingsFilteredByDescription])];
    }

    if((calledFromFilters || calledFromListings) && searchFilters.type!=""){
      listingsData = newlistingsData;
    }else{
      listingsData = (newlistingsData.length>0?newlistingsData:listingsData);
    }
  }

  
  //#region Get filteredIds results
  setSearchedListings((listingsData.length>0)?listingsData:[]);

  stoppedSearchingTasks.current = true;
  if(!calledFromFilters){
    return locationSelectedListings.length;
  }
  //#endregion
  } catch (error) {
    // Handle errors, e.g., Firestore request issues
    console.error('Error fetching listings:', error);
    // Handle the error in your UI or log it as needed
  }
};

const getListingsBasedOnLocation = async (locationCenter, lastDocumentListingFetched, searchFilters, radiusIsMiles) => {
  const listingsCollectionRef = collection(firestore, 'listings');

  // console.log(searchFilters);

  // Access latitude and longitude directly from GeoPoint
  const latitude = locationCenter._lat;
  const longitude = locationCenter._long;

  // Define a larger geohash range (rectangle) for a 5km radius
  const selectedLocationGeohash = GeoFire.geohashForLocation([latitude, longitude]).substring(0, 5);
  const ngeohash = require('ngeohash');
  const neighbors = ngeohash.neighbors(selectedLocationGeohash);

  // Filter neighbors based on distance (within 5km radius)
  let radiusInKm = (searchFilters.radius=="" || searchFilters.radius==null)?1:searchFilters.radius;
  radiusInKm = (radiusIsMiles == "miles")?radiusInKm * 1.60934 : radiusInKm;
  const validNeighbors = neighbors.filter((neighbor) => {
    const neighborLocation = ngeohash.decode(neighbor);
    const distance = calculateHaversineDistance(
      latitude,
      longitude,
      neighborLocation.latitude,
      neighborLocation.longitude
    );
    return distance <= radiusInKm;
  });

  // Include the selected location's geohash in the list
  const geohashesToQuery = [selectedLocationGeohash, ...validNeighbors];
  // console.log('Geohashes to query:', geohashesToQuery);


  // Function to fetch multiple documents
  const fetchDocuments = async (geohash) => {
    let baseQuery;
    if(searchFilters.type==""){
      baseQuery = query(listingsCollectionRef,
        where('listingGeohash', '==', geohash),
        where('selectedApplicant', '==', null),
        orderBy('listingGeohash', 'asc'),
        orderBy('date', 'asc'),
        limit(10)
      );
    }else if(searchFilters.type!=""){
      baseQuery = query(listingsCollectionRef,
        where('listingGeohash', '==', geohash),
        where('selectedApplicant', '==', null),
        where('type', '==', searchFilters.type),
        orderBy('listingGeohash', 'asc'),
        orderBy('date', 'asc'),
        limit(10)
      );
    }
  
    if (lastDocumentListingFetched.current) {
      baseQuery = query(
        baseQuery,
        startAfter(
          lastDocumentListingFetched.current.listingGeohash,
          lastDocumentListingFetched.current.date,
        )
      );
    }
  
    const querySnapshot = await getDocs(baseQuery);
  
    const documents = querySnapshot.docs.map((doc) => {
      const docData = doc.data();
      docData.id = doc.id;
      return docData;
    });
  
    return documents;
  };
  
  // Fetch documents for each geohash
  const mergedResults = [];
  for (const geohash of geohashesToQuery) {
    const documents = await fetchDocuments(geohash);
    
    if (documents.length > 0) {
      mergedResults.push(...documents);
      lastDocumentListingFetched.current = documents[documents.length - 1];
    }
  }

  // Return the merged results
  return mergedResults;
};

// Function to calculate Haversine distance
const calculateHaversineDistance = (lat1, lon1, lat2, lon2) => {
  // Implementation of Haversine formula
  // Calculate the distance between two points on the Earth given their latitude and longitude in decimal degrees
  // This function returns the distance in kilometers
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

// Function to convert degrees to radians
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const getListingsByTitle = async (listingsData, searchedTaskWordsArray, typeFound) => {
  // Initialize an array to store matching document IDs along with their common word count
  const matchingListings = [];

  // Convert searchedTaskWordsArray to lowercase
  const lowercaseKeywords = searchedTaskWordsArray.map((keyword) => keyword.toLowerCase());

  // Iterate through each document in listingsData
  for (const listing of listingsData) {
    // Get the title field and split it into words, then convert to lowercase
    const titleWords = listing.title.split(' ').map((word) => word.toLowerCase());

    // Count the number of common words with the lowercase keywords
    const commonWordCount = titleWords.filter((word) =>
      lowercaseKeywords.includes(word)
    ).length;

    // Check if the typeFound parameter matches the listing type (case-insensitive)
    let typeMatches;
    if(typeFound){
      typeMatches = listing.type.toLowerCase() === typeFound.toLowerCase();
    }
    // Add the docId to matchingListings if commonWordCount is greater than zero or typeMatches
    if (commonWordCount > 0 || typeMatches) {
      matchingListings.push({
        listingData: listing,
        commonWordCount,
      });
    }
  }
  // Sort matchingListings based on the common word count in descending order
  matchingListings.sort((a, b) => b.commonWordCount - a.commonWordCount);

  // Extract the sorted document IDs from matchingListings
  const sortedDocIds = matchingListings.map((item) => item.listingData);

  // Now, sortedDocIds contains the document IDs of listings sorted by the number of common words
  return sortedDocIds;
};

const getListingsByDescription = async (listingsData, searchedTaskWordsArray) => {
  // Initialize an array to store matching document IDs along with their common word count
  const matchingListings = [];

  // Convert searchedTaskWordsArray to lowercase
  const lowercaseKeywords = searchedTaskWordsArray.map((keyword) => keyword.toLowerCase());

  // Iterate through each document in listingsData
  for (const listing of listingsData) {
    // Get the description field and split it into words, then convert to lowercase
    const descriptionWords = listing.description.split(' ').map((word) => word.toLowerCase());

    // Count the number of common words with the lowercase keywords
    const commonWordCount = descriptionWords.filter((word) =>
      lowercaseKeywords.includes(word)
    ).length;

    // Add the listing data to matchingListings if commonWordCount is greater than zero
    if (commonWordCount > 0) {
      matchingListings.push({
        listingData: listing,
        commonWordCount,
      });
    }
  }

  // Sort matchingListings based on the common word count in descending order
  matchingListings.sort((a, b) => b.commonWordCount - a.commonWordCount);

  // Extract the sorted listings data from matchingListings
  const sortedListingsData = matchingListings.map((item) => item.listingData);

  // Now, sortedListingsData contains the listings data sorted by the number of common words in the description
  return sortedListingsData;
};

const getListingsByType = async (searchedTaskWordsArray, listingsData, searchFilters, typeFound, calledFromFilters) => {
  const filteredListingIds = [];
  //if the script was called from the searchbar and a new type was found
  const newTypeFilter = searchFilters.type;

  for (const listing of listingsData) {
    // Check if the type field in the document matches either searchFilters.type or typeFound
    if (
      listing.type &&
      listing.type &&
      (listing.type.toLowerCase() === newTypeFilter.toLowerCase())
    ) {
      // If it matches, add this document ID to the filteredListingIds array
      filteredListingIds.push(listing);
    }
  }

  // Check if searchedTaskWordsArray has at least one word
  if (searchedTaskWordsArray && searchedTaskWordsArray.length > 0) {
    // Sort filteredListingIds based on common words in titles
    filteredListingIds.sort((a, b) => {
      const titleWordsA = a.title.split(' ').map((word) => word.toLowerCase());
      const titleWordsB = b.title.split(' ').map((word) => word.toLowerCase());

      const commonWordCountA = titleWordsA.filter((word) =>
        searchedTaskWordsArray.includes(word)
      ).length;

      const commonWordCountB = titleWordsB.filter((word) =>
        searchedTaskWordsArray.includes(word)
      ).length;

      // Sort by the number of common words (descending order)
      return commonWordCountB - commonWordCountA;
    });
  }

  return filteredListingIds; // Return the array of document IDs sorted by common words
};

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      // Attempt to get the user's location without prompting for permission
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Successfully obtained the user's location
          const userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(userLocation);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // User denied permission; Ask for permission via the browser's geolocation API
            navigator.geolocation.getCurrentPosition(
              (position) => {
                // Successfully obtained the user's location after asking for permission
                const userLocation = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                };
                resolve(userLocation);
              },
              (error) => {
                // Handle geolocation errors after asking for permission
                reject(error);
              }
            );
          } else {
            // Handle other geolocation errors
            reject(error);
          }
        }
      );
    } else {
      // Geolocation is not supported in this browser
      reject(new Error("Geolocation is not supported in this browser."));
    }
  });
};

export const checkForType = (wordsArray, typesOfTasks) => {

  for (const type of typesOfTasks) {
    const lowercaseType = type.toLowerCase();
    // Join the input words into a single string and check if it includes the lowercase type
    const inputText = wordsArray.join(' ').toLowerCase();
    
    if (inputText.includes(lowercaseType)) {
      return type;
    }
  }

  return null;
};

export const getListings = async () => {
  try {
    // Reference to the "listings" collection in Firestore
    const listingsCollectionRef = collection(firestore, 'listings');

    // Retrieve all documents in the "listings" collection
    const listingsSnapshot = await getDocs(listingsCollectionRef);

    // Extract the data from each document
    const listingsData = listingsSnapshot.docs.map((doc) => ({
      id: doc.id, // Document ID
      ...doc.data(), // Listing data
    }));

    // Now, listingsData contains an array of all listings
    // console.log(listingsData);

    return listingsData;
  } catch (error) {
    // Handle errors, e.g., Firestore request issues
    console.error('Error fetching listings:', error);
    // Handle the error in your UI or log it as needed
  }
};

export const getListingById = async (listingId) => {
  try {
    const listingsCollectionRef = collection(firestore, 'listings');
    const listingDocRef = doc(listingsCollectionRef, listingId);
    // Extract the data from the document
    const listingDataDoc = await getDoc(listingDocRef);

    if (listingDataDoc.exists()) {
      const listingData = {
        id: listingId,
        ...listingDataDoc.data()
      };
      return listingData;
    } else {
      return false;
    }

  } catch (error) {
    // Handle errors, e.g., Firestore request issues
    console.error('Error fetching listing:', error);
    // Handle the error in your UI or log it as needed
  }
};


export const getAppliedListings = async (listingsIds) => {
  const listingsCollectionRef = collection(firestore, 'listings');
  
  const listingsQuery = query(
    listingsCollectionRef,
    where('__name__', 'in', listingsIds.map(id => id.id)) // Assuming each item in listingsIds has an 'id' property
  );

  const listings = [];

  try {
    const querySnapshot = await getDocs(listingsQuery);

    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        const listingData = doc.data();
        listings.push({ id: doc.id, ...listingData, hasApplied: true});
      }
    });

    // console.log(listings);

    return listings;
  } catch (error) {
    console.error("Error fetching applied listings: ", error);
    throw error;
  }
};

export const getMineListings = async (userId) => {
  try {
    // Reference to the "listings" collection in Firestore
    const listingsCollectionRef = collection(firestore, 'listings');

    // Create a query to filter listings by the "uid" field
    const mineQuery = query(listingsCollectionRef, where('uid', '==', userId));

    // Retrieve all documents that match the query
    const listingsQuerySnapshot = await getDocs(mineQuery);

    // Extract the data from each document
    const listingsData = listingsQuerySnapshot.docs.map((doc) => ({
      id: doc.id, // Document ID
      ...doc.data(), // Listing data
    }));

    // Now, listingsData contains an array of listings where "uid" matches userId
    return listingsData;
  } catch (error) {
    // Handle errors, e.g., Firestore request issues
    console.error('Error fetching listings:', error);
    // Handle the error in your UI or log it as needed
    throw error; // Rethrow the error to propagate it to the caller if necessary
  }
};

export const getHistoryListings = async (userId) => {
  try {
    // Reference to the "listings" collection in Firestore
    const listingsCollectionRef = collection(firestore, 'historyListings');

    // Create a query to filter listings by the "clientId" or "vendorId" field
    const query1 = query(listingsCollectionRef, where('clientId', '==', userId));
    const query2 = query(listingsCollectionRef, where('vendorId', '==', userId));

    // Execute both queries concurrently
    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    // Extract the data from both query results
    const listingsData1 = querySnapshot1.docs.map((doc) => ({
      historyListingId: doc.id,
      ...doc.data(), // Listing data
    }));

    const listingsData2 = querySnapshot2.docs.map((doc) => ({
      historyListingId: doc.id,
      ...doc.data(), // Listing data
    }));

    // Combine the results from both queries
    const combinedListingsData = [...listingsData1, ...listingsData2];

    const usersCollectionRef = collection(firestore, 'users');
    // Retrieve the profile picture of the other user for each listing
    
    const finalListingsData = await Promise.all(combinedListingsData.map(async (listing) => {
      const userDocRef = doc(usersCollectionRef, (listing.clientId !=userId)?listing.clientId:listing.vendorId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const profilePicture = userData.profilePicture;

        return { ...listing, profilePicture };
      } else {
        // Handle the case where the document doesn't exist
        return { ...listing, profilePicture: null }; // or some default value
      }
    }));

    // Now, finalListingsData contains an array of listings where "clientId" or "vendorId" matches userId
    return finalListingsData;
  } catch (error) {
    // Handle errors, e.g., Firestore request issues
    console.error('Error fetching listings:', error);
    // Handle the error in your UI or log it as needed
    throw error; // Rethrow the error to propagate it to the caller if necessary
  }
};

export const deleteListing = async (listingId) => {
  try {
    // Reference to the "listings" collection in Firestore
    const listingsCollectionRef = collection(firestore, 'listings');

    // Create a reference to the specific document to delete using the provided listingId
    const listingDocRef = doc(listingsCollectionRef, listingId);

    // Reference to the "applicants" collection inside the listing
    const applicantsCollectionRef = collection(listingDocRef, 'applicants');

    // Perform the deletion within a transaction
    await runTransaction(firestore, async (transaction) => {
      // Fetch all documents from the "applicants" collection within the transaction
      const applicantsQuery = query(applicantsCollectionRef);
      const applicantsSnapshot = await getDocs(applicantsQuery);

      // Delete each document from the "applicants" collection within the transaction
      applicantsSnapshot.forEach((doc) => {
        transaction.delete(doc.ref);
      });

      // Delete the main listing document within the transaction
      transaction.delete(listingDocRef);
    });

    return true;
  } catch (error) {
    console.error('Error deleting listing:', error);
    throw error;
  }
};

export const createReview = async (clientId, vendorId, reviewer, reviewee, stars, type, date, description, impressions, historyListingId) => {
  try {
    const reviewsCollectionRef = collection(firestore, "reviews");

    const newReviewData = {
      description: description,
      stars: stars,
      type: type,
      reviewee: reviewee,
      reviewer: reviewer,
      date: date,
      impressions: impressions,
    };

    await addDoc(reviewsCollectionRef, newReviewData);

    // console.log("Review document added with ID: ", docRef.id);
    calculateNewUserStarRating(reviewee, stars)
  //#region mark listing as reviewed to prevent future re-review
    const historyCollectionRef = collection(firestore, "historyListings");
    //retrieve the correct document based on its id
    const historyDocumentRef = doc(historyCollectionRef, historyListingId);
    
      // Update the document with the new data
      if(reviewer == clientId){
        await updateDoc(historyDocumentRef, {clientReviewSubmitted: true});
      }else if(reviewer == vendorId){
        await updateDoc(historyDocumentRef, {vendorReviewSubmitted: true});
      }
  //#endregion
    return true; // Return the ID of the newly created document if needed
  } catch (error) {
    console.error("Error adding review document: ", error);
    throw error;
  }
};

export const reportUser = async (clientId, vendorId, accuser, accused, reasonOfReport, description, historyListingId) => {
  try {
    const reportsCollectionRef = collection(firestore, "reports");

    const newReportData = {
      reasonOfReport: reasonOfReport,
      description: description,
      accused: accused,
      accuser: accuser,
    };

    await addDoc(reportsCollectionRef, newReportData);

  //#region mark listing as reviewed to prevent future re-review
    const historyCollectionRef = collection(firestore, "historyListings");
    //retrieve the correct document based on its id
    const historyDocumentRef = doc(historyCollectionRef, historyListingId);
    
    // Update the document with the new data
    if(accuser == clientId){
      await updateDoc(historyDocumentRef, {clientReportSubmitted: true});
    }else if(accuser == vendorId){
      await updateDoc(historyDocumentRef, {vendorReportSubmitted: true});
    }
  //#endregion
    return true; // Return the ID of the newly created document if needed
  } catch (error) {
    console.error("Error adding review document: ", error);
    throw error;
  }
};

//#region Saved listings
export const saveListing = async (userId, listingId) => {
  try {
    // Reference to the user's document in the "users" collection
    const userDocRef = doc(firestore, 'users', userId);

    // Reference to the "savedListings" subcollection within the user's document
    const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

    // Create a reference to the specific saved listing document
    const savedListingDocRef = doc(savedListingsCollectionRef, listingId);

    // Check if the document exists
    const savedListingDocSnapshot = await getDoc(savedListingDocRef);

    if (savedListingDocSnapshot.exists()) {
      // If the document exists, merge the existing data with isSaved: true
      await setDoc(savedListingDocRef, { isSaved: true }, { merge: true });
    } else {
      // If the document doesn't exist, create a new document with isSaved: true
      await setDoc(savedListingDocRef, { isSaved: true });
    }

    // Return true to indicate successful saving (if needed)
    return true;
  } catch (error) {
    // Handle errors
    console.error('Error saving listing:', error);
    throw error;
  }
};

export const applyListing = async (userId, userProfilePicture, listingUid, listingId, listingTitle) => {
  try {
      await runTransaction(firestore, async (transaction) => {
      // Reference to the user's document in the "users" collection
      const userDocRef = doc(firestore, 'users', userId);

      // Reference to the "savedListings" subcollection within the user's document
      const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

      // Create a reference to the specific saved listing document
      const savedListingDocRef = doc(savedListingsCollectionRef, listingId);

      // Check if the document exists
      const savedListingDocSnapshot = await transaction.get(savedListingDocRef);

      if (savedListingDocSnapshot.exists()) {
        // If the document exists, merge the existing data with hasApplied: true
        transaction.set(savedListingDocRef, { hasApplied: true }, { merge: true });
      } else {
        // If the document doesn't exist, create a new document with hasApplied: true
        transaction.set(savedListingDocRef, { hasApplied: true });
      }

      // Reference to the client's document in the "users" collection
      const clientDocRef = doc(firestore, 'users', listingUid);
      const notificationsCollectionRef = collection(clientDocRef, 'notifications');

      // Create a new document in the "notifications" subcollection
      const newNotificationDocRef = doc(notificationsCollectionRef);

      // Set data in the new document with userId and listingId
      const data = {
        applicant: userId,
        listingId: listingId,
        type: 'newApplicant',
        status: 'unread',
        timestamp: serverTimestamp(),
        listingTitle: listingTitle,
        applicantPhoto: userProfilePicture,
      };

      transaction.set(newNotificationDocRef, data);
    });

    // Return true to indicate successful saving
    return true;
  } catch (error) {
    // Handle errors
    console.error('Error saving listing:', error);
    throw error;
  }
};

export const deleteUserApplicationToListing = async (userId, listingId) => {
  // Reference to the user's document in the "users" collection
  const userDocRef = doc(firestore, 'users', userId);

  // Reference to the "savedListings" subcollection within the user's document
  const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

  // Create a reference to the specific saved listing document
  const savedListingDocRef = doc(savedListingsCollectionRef, listingId);

  await deleteDoc(savedListingDocRef);

}

export const banListing = async (userId, listingId, isBanned) => {
  try {
    // Reference to the user's document in the "users" collection
    const userDocRef = doc(firestore, 'users', userId);

    // Reference to the "savedListings" subcollection within the user's document
    const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

    // Create a reference to the specific saved listing document
    const savedListingDocRef = doc(savedListingsCollectionRef, listingId);

    // Check if the document exists
    const savedListingDocSnapshot = await getDoc(savedListingDocRef);
    // Check if isBanned is false and the document only contains isBanned
    if (isBanned && savedListingDocSnapshot.exists() && Object.keys(savedListingDocSnapshot.data()).length === 1 && savedListingDocSnapshot.data().hasOwnProperty('isBanned')) {
      // Delete the document
      await deleteDoc(savedListingDocRef);
      // console.log(`Deleted listing document ${listingId}`);
    } else {
      // If the document exists or contains additional fields, update the isBanned field
      await setDoc(savedListingDocRef, { isBanned: !isBanned });
    }

    // Check if isBanned is false, and proceed to delete the applicant
      if (!isBanned) {
        const listingsDocRef = doc(firestore, "listings", listingId);
        const applicantsCollectionRef = collection(listingsDocRef, "applicants");
        const applicantsSnapshot = await getDocs(applicantsCollectionRef);

        // Query for the applicant document that matches the userId
        const querySnapShot = query(applicantsCollectionRef, where("applicantId", "==", userId));
        const querySnapshot = await getDocs(querySnapShot);

        if (!querySnapshot.empty) {
          // Assuming there's only one matching document, you can simply delete it
          const docToDelete = querySnapshot.docs[0];
          await deleteDoc(docToDelete.ref);
          // console.log(`Deleted applicant document for user ${userId}`);
        } else {
          // console.log(`No applicant document found for user ${userId}`);
        }
      }

    // Return true to indicate successful saving (if needed)
    return true;
  } catch (error) {
    // Handle errors
    console.error('Error saving listing:', error);
    throw error;
  }
};

export const deleteSavedListing = async (userId, listingId) => {
  try {
    // Reference to the user's document in the "users" collection
    const userDocRef = doc(firestore, 'users', userId);

    // Reference to the "savedListings" subcollection within the user's document
    const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

    // Create a reference to the specific saved listing document to delete
    const savedListingDocRef = doc(savedListingsCollectionRef, listingId);
    const savedListingDocSnapshot = await getDoc(savedListingDocRef);

     // Check if the document exists
     if (savedListingDocSnapshot.exists()) {
      const data = savedListingDocSnapshot.data();
      
      // Check if the document contains only the isSaved field
      if (Object.keys(data).length === 1 && data.isSaved === true) {
        // Delete the saved listing document from Firestore
        await deleteDoc(savedListingDocRef);
      } else {
        // If it contains additional fields, set isSaved to false
        await updateDoc(savedListingDocRef, { isSaved: false });
      }
    }

    // Return true to indicate successful deletion
    return false;
  } catch (error) {
    // Handle errors
    console.error('Error deleting saved listing:', error);
    throw error;
  }
};

export const getAppliedSavedBannedListings = async (userId) => {
  try {
    // Reference to the user's document in the "users" collection
    const userDocRef = doc(firestore, 'users', userId);

    // Reference to the "savedListings" subcollection within the user's document
    const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

    const listingsCollectionRef = collection(firestore, 'listings');

    // Retrieve the documents in the "savedListings" subcollection
    const listingsSnapshot = await getDocs(savedListingsCollectionRef);

    // Create an array to store the data of existing listings
    let existingListingsData = [];

    if(listingsSnapshot.docs.length>0){

      // Iterate through the existing listings and add their data to the array
      for (const doc of listingsSnapshot.docs) {
        const currentListing = doc.data();
        const flattenedData = {
          id: doc.id,
          ...currentListing,
        };
        existingListingsData.push(flattenedData);
      }

      // Fetch data from the "listings" collection for the existing listings
      const savedListingIds = existingListingsData.map((listing) => listing.id);
      const existingListingsQuery = query(listingsCollectionRef, where('__name__', 'in', savedListingIds));
      const existentListingsSnapshot = await getDocs(existingListingsQuery);

      // Create an object to store listings data with document IDs as keys
      const listingsDataById = {};
      
      // Populate the listingsDataById object with data from the "listings" collection
      existentListingsSnapshot.forEach((doc) => {
        const listingData = doc.data();
        listingsDataById[doc.id] = listingData;
      });

      // Merge the listings data into the existingListingsData array
      existingListingsData.forEach((listing) => {
        if (listingsDataById[listing.id]) {
          Object.assign(listing, listingsDataById[listing.id]);
        }
      });

      // Delete savedListings documents for IDs that don't exist in listings
      const missingListingIds = savedListingIds.filter((id) => !existentListingsSnapshot.docs.some((doc) => doc.id === id));
      // console.log('missing:', missingListingIds);

      if (missingListingIds.length > 0) {
        const deletePromises = missingListingIds.map(async (id) => {
          const savedListingDocRef = doc(savedListingsCollectionRef, id);
          await deleteDoc(savedListingDocRef);
        });

        // Wait for all delete operations to complete
        await Promise.all(deletePromises);

        // Filter the deleted documents from existingListingsData
        existingListingsData = existingListingsData.filter((listing) => !missingListingIds.includes(listing.id));

        // console.log('Deleted:', missingListingIds);
      }

      // Filter saved listings based on existence in the "listings" collection
      const savedListings = existingListingsData
        .filter((doc) => doc.isSaved)
        .map((doc) => doc);

      // Filter applied listings based on existence in the "listings" collection
      const appliedListings = existingListingsData
        .filter((doc) => doc.hasApplied)
        .map((doc) => ({ id: doc.id }));

      // Filter banned listings based on existence in the "listings" collection
      const bannedListings = existingListingsData
        .filter((doc) => doc.isBanned)
        .map((doc) => ({ id: doc.id }));

      // Return the filtered arrays
      // console.log(savedListings, appliedListings, bannedListings);

      return { saved: savedListings, applied: appliedListings, banned: bannedListings };
    }else { return { saved: [], applied: [], banned: [] };}
  } catch (error) {
    // Handle errors
    console.error('Error fetching saved listings:', error);
    throw error;
  }
};

//#endregion

//#endregion

export const calculateNewUserStarRating = async (reviewee, stars) => {
  try {
    const usersCollectionRef = collection(firestore, "users");
    const userQuery = query(usersCollectionRef, where('uid', '==', reviewee));
    const userQuerySnapshot = await getDocs(userQuery);

    const userDocRef = userQuerySnapshot.docs[0].ref;

    // Instead of fetching the updated numberOfReviews again, store the value to this let
    let tempNumberOfReviews;

    // Specify the fields you want to retrieve.
    const fieldsToRetrieve = ['numberOfReviews', 'stars'];

    // Get the document data with only the specified fields.
    const userData = userQuerySnapshot.docs[0].data({ fields: fieldsToRetrieve });
    // Check if the 'numberOfReviews' field exists.
    if (userData.hasOwnProperty('numberOfReviews')) {
      // If it exists, increment its value by 1.
      const currentNumberOfReviews = userData.numberOfReviews;
      //Calculate the new stars average for the user stars field
      tempNumberOfReviews = currentNumberOfReviews + 1;

      const oldAverage = userData.stars*userData.numberOfReviews+stars;
      const newAverage =oldAverage/tempNumberOfReviews;
      await updateDoc(userDocRef, { numberOfReviews: currentNumberOfReviews + 1, stars: newAverage });
    
    } else {
      // If it doesn't exist, set it to 1.
      const currentStars = stars;
      await updateDoc(userDocRef, { numberOfReviews: 1 , stars: currentStars});  
      tempNumberOfReviews = 1;
    }

  } catch (error) {
    console.log(error);
  }
}

//#region Handle applicants
export const addApplicantToListing = async (listingId, applicantData) => {
  // Reference to the listing document
  const listingDocumentRef = doc(firestore, 'listings', listingId);

  // Reference to the "applicants" subcollection within the listing
  const applicantsCollectionRef = collection(listingDocumentRef, 'applicants');

  try {
    // Check if the "applicants" subcollection exists by fetching its documents
    const querySnapshot = await getDocs(applicantsCollectionRef);

    // Check if any document in the subcollection has the same applicantId
    const doesApplicantExist = querySnapshot.docs.some(doc => doc.data().applicantId === applicantData);

    if (doesApplicantExist) {
      // console.log('Applicant with the same applicantId already exists.');
    } else {
      // The "applicants" subcollection exists, and the applicantId is unique, so add a new document
      const docRef = await addDoc(applicantsCollectionRef, { applicantId: applicantData });
      // console.log(`Applicant added with ID: ${docRef.id}`);
    }
    return true;
  } catch (error) {
    console.error('Error adding applicant:', error);
  }
};


export const getListingApplicants = async (listingId) => {
  try {
    // Reference to the listing's document in the "listings" collection
    const listingDocRef = doc(firestore, 'listings', listingId);

    // Reference to the "applicants" subcollection within the listing's document
    const applicantsListingCollectionRef = collection(listingDocRef, 'applicants');

    // Retrieve the documents in the "applicants" subcollection
    const applicantsListingsSnapshot = await getDocs(applicantsListingCollectionRef);

    // Extract the applicants IDs (document IDs)
    const applicantIds = applicantsListingsSnapshot.docs.map((doc) => doc.data().applicantId);

    return applicantIds; // Return the array of applicantIds

  } catch (error) {
    // Handle errors
    console.error('Error fetching applicants for listing:', error);
    throw error;
  }
};


export const connectApplicantToMineList = async (
  listingId,
  applicantId,
  userProfilePicture,
  listingTitle,
  clientId,
  candidateProfilePhoto
) => {
  try {
    let roomId;
    await runTransaction(firestore, async (transaction) => {
      const listingDocRef = doc(firestore, 'listings', listingId);
      // Update the listing document with the selectedApplicant field
      transaction.set(listingDocRef, { selectedApplicant: applicantId }, { merge: true });

      // Add assigned task
      const assignedListingsCollectionRef = collection(firestore, 'assignedListings');
      const initialAssignedData = { listingId: listingId, clientId: clientId, applicantId: applicantId, pendingConfirmation: false };
      transaction.set(doc(assignedListingsCollectionRef), initialAssignedData, { merge: true });

      // Send application acceptance notification
      const usersCollectionRef = collection(firestore, 'users');
      const userDocRef = doc(usersCollectionRef, applicantId);
      const notificationsCollectionRef = collection(userDocRef, 'notifications');
      const newNotificationDocRef = doc(notificationsCollectionRef);
      const notificationData = {
        applicant: applicantId,
        listingId: listingId,
        type: 'acceptedApplication',
        status: 'unread',
        timestamp: serverTimestamp(),
        listingTitle: listingTitle,
        applicantPhoto: userProfilePicture,
      };
      transaction.set(newNotificationDocRef, notificationData);

      // Create a new conversation room
      const roomsCollectionRef = collection(firestore, 'rooms');
      const newRoomDocRef = await addDoc(roomsCollectionRef, { clientId, vendorId: applicantId });
      roomId = newRoomDocRef.id;

      // Update "rooms" collection for the client
      const clientDocRef = doc(firestore, 'users', clientId);
      const clientRoomsCollectionRef = collection(clientDocRef, 'rooms');
      const clientRoomData = {
        vendorId: applicantId,
        vendorProfilePicture: candidateProfilePhoto,
        conversationId: roomId,
        // Add other fields as needed
      };
      transaction.set(doc(clientRoomsCollectionRef, roomId), clientRoomData);

      // Update "rooms" collection for the vendor
      const vendorDocRef = doc(firestore, 'users', applicantId);
      const vendorRoomsCollectionRef = collection(vendorDocRef, 'rooms');
      const vendorRoomData = {
        clientId: clientId,
        clientProfilePicture: userProfilePicture,
        conversationId: roomId,
        // Add other fields as needed
      };
      transaction.set(doc(vendorRoomsCollectionRef, roomId), vendorRoomData);

      // Update the listing document with the roomId
      transaction.set(listingDocRef, { roomId: roomId }, { merge: true });

      const savedListingsCollectionRef = collection(userDocRef, 'savedListings');
      const savedListingDocRef = doc(savedListingsCollectionRef, listingId);

      const savedListingSnapshot = await getDoc(savedListingDocRef);
      if (savedListingSnapshot.exists()) {
        transaction.delete(savedListingDocRef);
      }
    });

    return {result: true, roomId: roomId};
  } catch (error) {
    console.error('Error connecting applicant to the listing:', error);
  }
};

export const getUserProfilePicture = async(userId)=>{
  try {
    const userDocRef = doc(firestore, 'users', userId);

    const docSnapshot = await getDoc(userDocRef);

    const userData = docSnapshot.data();
    if (userData.hasOwnProperty('profilePicture')) {
      return userData.profilePicture;
    } else {
      // Handle the case when profilePicture field doesn't exist.
      return null;
    }
  } catch (error) {
    // Handle any errors that might occur during the Firestore operation.
    console.error('Error fetching user profile picture:', error);
    return null;
  }
}
//#endregion
//Add a task to the accepted applicant
export const addAssignedTask = async (listingId, applicantId) => {
  try {
    // Check if the "assignedListings" collection exists
    const assignedListingsCollectionRef = collection(firestore, 'assignedListings');

    // Create an initial document in the collection with the "applicantId" field
    const initialData = {
      listingId: listingId,
      applicantId: applicantId,
      pendingConfirmation: false,
    };

    // Use setDoc with merge: true to create the collection and initial document
    await setDoc(doc(assignedListingsCollectionRef),
     initialData,
     { merge: true });

  } catch (error) {
    console.log("Error:", error);
  }
}

export const getAssignedTasks = async (userId) => {
  try {
    const assignedListingsCollectionRef = collection(firestore, 'assignedListings');
    const q = query(assignedListingsCollectionRef, where('applicantId', '==', userId));
    const querySnapshot = await getDocs(q);
    const listingIds = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data && data.listingId) {
        listingIds.push({
          id: doc.id,
          listingId: data.listingId,
          pendingConfirmation: data.pendingConfirmation // Include pendingConfirmation
        });
      }
    });

    const listingsCollectionRef = collection(firestore, 'listings');
    const assignedTasks = [];

    for (const listing of listingIds) {
      const listingDocRef = doc(listingsCollectionRef, listing.listingId);
      const listingDocSnapshot = await getDoc(listingDocRef);

      if (listingDocSnapshot.exists()) {
        const listingData = listingDocSnapshot.data();
        assignedTasks.push({
          id: listing.id,
          listingId: listing.listingId,
          pendingConfirmation: listing.pendingConfirmation, // Include pendingConfirmation in assignedTasks
          ...listingData,
        });
      }
    }
    return assignedTasks;
  } catch (error) {
    console.log("Error:", error);
    return [];
  }
};


export const markTaskCompletedViaClient = async (
  taskId, userId, listingIds, type, roomId, vendorId, clientProfilePicture, clientName,
  listingTitle, currentRoomId) => {
  try {
    let historyTaskData;
    await runTransaction(firestore, async (transaction) => {
      //#region createHistoryTask
      const listingDocRef = doc(firestore, 'listings', taskId);
      const listingDocSnapshot = await getDoc(listingDocRef);
      const listingData = listingDocSnapshot.data();
      const updatedListingData = {
        vendorId: listingData.selectedApplicant,
        clientId: listingData.uid,
        date: listingData.date,
        duration: listingData.duration,
        location: listingData.location,
        price: listingData.price,
        currency: listingData.currency,
        time: listingData.time,
        title: listingData.title,
        type: listingData.type,
        clientReviewSubmitted: false,
        vendorReviewSubmitted: false,
        clientReportSubmitted: false,
        vendorReportSubmitted: false,
        profilePicture: await getUserProfilePicture((userId != listingData.uid) ? listingData.uid : listingData.selectedApplicant)
      };

      const historyListingsCollection = collection(firestore, 'historyListings');
      const historyDocRef = doc(historyListingsCollection);
      transaction.set(historyDocRef, updatedListingData);
      const historyDocId = historyDocRef.id;
      const updatedDataWithId = { ...updatedListingData, historyTaskId: historyDocId };
      transaction.set(historyDocRef, updatedDataWithId);
      historyTaskData = updatedDataWithId;
      //#endregion

      //#region deleteVendorTask
      const assignedListingsCollection = collection(firestore, 'assignedListings');
      const q = query(assignedListingsCollection, where('listingId', '==', taskId), where('applicantId', '==', historyTaskData.vendorId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });
      //#endregion

      //#region deleteListing
      const listingsCollectionRef = collection(firestore, 'listings');

      const taskListingDocRef = doc(listingsCollectionRef, taskId);
      const applicantsCollectionRef = collection(taskListingDocRef, 'applicants');
      const applicantsQuery = query(applicantsCollectionRef);
      const applicantsSnapshot = await getDocs(applicantsQuery);

      applicantsSnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      transaction.delete(taskListingDocRef);
      //#endregion

      //#region calculateTopTypesOfTasks
      const vendorRef = doc(firestore, 'users', historyTaskData.vendorId);
      const typesOfTasksRef = collection(vendorRef, 'typesOfTasks');
      const snapshot = await getDocs(typesOfTasksRef);

      let data = [];

      if (!snapshot.empty) {
        // If 'typesOfTasks' collection exists, update the existing document
        const docRef = doc(typesOfTasksRef, snapshot.docs[0].id);
        const docSnapshot = await getDoc(docRef);
      
        const fetchedData = Object.entries(docSnapshot.data() || []).map(([key, value]) => ({ [key]: value }));
        data = fetchedData;
      
        const objectWithSameKeyExists = data.some(obj => obj.hasOwnProperty(historyTaskData.type));
      
        if (objectWithSameKeyExists) {
          const existingObjectIndex = data.findIndex(obj => obj.hasOwnProperty(historyTaskData.type));
      
          if (existingObjectIndex !== -1) {
            data[existingObjectIndex][historyTaskData.type] += 1;
            const existingObject = data[existingObjectIndex];
            transaction.update(docRef, { [historyTaskData.type]: existingObject[historyTaskData.type] });
          }
        } else {
          transaction.set(docRef, { [historyTaskData.type]: 1 }, { merge: true });
          data.push({ [historyTaskData.type]: 1 });
        }
      } else {
        // If 'typesOfTasks' collection doesn't exist, create it and add the transaction
  // If 'typesOfTasks' collection doesn't exist, create it and add the transaction
      const newDocRef = doc(typesOfTasksRef);
      transaction.set(newDocRef, { [historyTaskData.type]: 1 });
      data = [{ [historyTaskData.type]: 1 }];
      }


      //updateUserTopTasks
      if (data.length > 0) {
        // Sort the data based on values in descending order
        data.sort((a, b) => {
          const valueA = Object.values(a)[0];
          const valueB = Object.values(b)[0];
          return valueB - valueA;
        });
  
        // Get up to three top keys from the sorted data
        const topTasksArray = data.slice(0, 3).map((obj, index) => {
          const key = Object.keys(obj)[0];
          return key;
        });
  
        // Update the 'topTasks' field in the vendorRef document as an array
        transaction.update(vendorRef, { topTasks: topTasksArray });
        // console.log("Top tasks have been updated:", topTasksArray);
      }
      //#endregion

      // Reference to the user's document in the "users" collection
      const userDocRef = doc(firestore, 'users', userId);
      const notificationsCollectionRef = collection(userDocRef, 'notifications');
      const roomsCollectionRef = collection(firestore, 'rooms');

      // Delete notifications based on the specified type and listingIds
      // console.log(listingIds, listingIds.length)

      if(listingIds!=null){
        if (type === 'newApplicant') {
          const querySnapshot = await getDocs(query(notificationsCollectionRef, where('type', '==', 'newApplicant')));
          querySnapshot.forEach(doc => {
            transaction.delete(doc.ref);
          });
        } else if (type === 'acceptedApplication' || type === 'vendorAbandonedTask' || type === 'clientAbandonedTask' || type === 'confirmationRequestTask' || type === 'taskCompletionConfirmed' || type === 'newMessage') {
            const querySnapshot = await getDocs(query(notificationsCollectionRef, where('listingId', 'in', listingIds.map(obj => obj.listingId))));
            querySnapshot.forEach(doc => {
            const data = doc.data();
            
            if (data.type === type) {
              transaction.delete(doc.ref);
            }
          });
        }
      }
      // Delete the "conversation" collection inside the document
      const conversationCollectionRef = collection(roomsCollectionRef, roomId, 'conversation');
      const conversationQuerySnapshot = await getDocs(conversationCollectionRef);

      conversationQuerySnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Delete the document in the "rooms" collection with the specified roomId
      transaction.delete(doc(roomsCollectionRef, roomId));

      // Delete the corresponding documents in the "users" collection
      const clientDocRef = doc(firestore, 'users', userId);
      const vendorDocRef = doc(firestore, 'users', vendorId);

      // Delete the document in the "rooms" collection inside the client's document
      const clientRoomsCollectionRef = collection(clientDocRef, 'rooms');
      const clientRoomQuery = query(clientRoomsCollectionRef, where('conversationId', '==', roomId));
      const clientRoomQuerySnapshot = await getDocs(clientRoomQuery);

      clientRoomQuerySnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Delete the document in the "rooms" collection inside the vendor's document
      const vendorRoomsCollectionRef = collection(vendorDocRef, 'rooms');
      const vendorRoomQuery = query(vendorRoomsCollectionRef, where('conversationId', '==', roomId));
      const vendorRoomQuerySnapshot = await getDocs(vendorRoomQuery);

      vendorRoomQuerySnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Delete the documents in the "notifications" subcollection for the client
      const clientNotificationsCollectionRef = collection(clientDocRef, 'notifications');
      const clientNotificationsQuery = query(clientNotificationsCollectionRef, where('roomId', '==', roomId));
      const clientNotificationsQuerySnapshot = await getDocs(clientNotificationsQuery);

      clientNotificationsQuerySnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Delete the documents in the "notifications" subcollection for the vendor
      const vendorNotificationsCollectionRef = collection(vendorDocRef, 'notifications');
      const vendorNotificationsQuery = query(vendorNotificationsCollectionRef, where('roomId', '==', roomId));
      const vendorNotificationsQuerySnapshot = await getDocs(vendorNotificationsQuery);

      vendorNotificationsQuerySnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Create a new document in the "notifications" subcollection
      const newNotificationDocRef = doc(vendorNotificationsCollectionRef);
      // Set data in the new document with userId and listingId
      const notificationData = {
        roomId: currentRoomId,
        listingId: taskId,
        type: "taskCompletionConfirmed",
        status: "unread",
        timestamp: serverTimestamp(),
        listingTitle: listingTitle,
        clientPhoto: clientProfilePicture,
        clientName: clientName,
      };

      transaction.set(newNotificationDocRef, notificationData);
    });

    return historyTaskData;

  } catch (error) {
    console.log(error);
    return false;
  }
}


const createHistoryTask = async (taskId) => {
  try {
    // Reference to the specific document in the "listings" collection using the taskId
    const listingDocRef = doc(firestore, 'listings', taskId);

    // Retrieve the data from the source document
    const listingDocSnapshot = await getDoc(listingDocRef);
    
    // Data from the source document
    const listingData = listingDocSnapshot.data();

    // Replace field names in the data
    const updatedListingData = {
      vendorId: listingData.selectedApplicant,
      clientId: listingData.uid,
      date: listingData.date,
      duration: listingData.duration,
      location: listingData.location,
      price: listingData.price,
      currency: listingData.currency,
      time: listingData.time,
      title: listingData.title,
      type: listingData.type,
      clientReviewSubmitted: false,
      vendorReviewSubmitted: false,
      clientReportSubmitted: false,
      vendorReportSubmitted: false,
      profilePicture: await getUserProfilePicture((userId != listingData.uid) ? listingData.uid : listingData.selectedApplicant)
    };

    // Reference to the "historyListings" collection
    const historyListingsCollection = collection(firestore, 'historyListings');

    // Use setDoc to create a new document in "historyListings" with the updated data
    const historyDocRef = doc(historyListingsCollection); // Create a reference to the new document
    await setDoc(historyDocRef, updatedListingData);

    // Include the ID of the created history task document in the data
    const historyDocId = historyDocRef.id;
    const updatedDataWithId = { ...updatedListingData, historyTaskId: historyDocId };

    // Update the document with the added ID field
    await setDoc(historyDocRef, updatedDataWithId);

    return updatedDataWithId;
  } catch (error) {
    console.error('Error creating a new history task document:', error);
  }
};

const calculateTopTypesOfTasks = async (vendorId, type) => {
  try {
    // Reference to the vendor's document in the users collection
    const vendorRef = doc(firestore, 'users', vendorId);

    // Reference to the vendor's 'typesOfTasks' subcollection
    const typesOfTasksRef = collection(vendorRef, 'typesOfTasks');

    // Check if the vendor's 'typesOfTasks' subcollection exists
    const snapshot = await getDocs(typesOfTasksRef);

    let data = [];

if (!snapshot.empty) {
  // If the subcollection exists, get the first (and potentially only) document
  const docRef = doc(typesOfTasksRef, snapshot.docs[0].id);
  const docSnapshot = await getDoc(docRef);

  // Get the data from the document
  const fetchedData = Object.entries(docSnapshot.data() || []).map(([key, value]) => ({
    [key]: value,
  }));
  data = fetchedData;

  const objectWithSameKeyExists = data.some(obj => obj.hasOwnProperty(type));

  // Check if there is a field with the name equal to the 'type' parameter
  if (objectWithSameKeyExists) {
    // If the field exists, increment its value by 1

    const existingObjectIndex = data.findIndex(obj => obj.hasOwnProperty(type));
//#region EXPERIMENTAL
    if (existingObjectIndex !== -1) {
      // If an object with the key exists, increment its value by 1
      data[existingObjectIndex][type] += 1;
  
      // Update the Firestore document with the new value
      const existingObject = data[existingObjectIndex];
      const docRef = doc(typesOfTasksRef, snapshot.docs[0].id);
      await updateDoc(docRef, { [type]: existingObject[type] });
    }
//#endregion
  } else {
    // If the field doesn't exist, set it to 1
    await setDoc(docRef, { [type]: 1 }, { merge: true });

    // Push a new object with the 'type' key and value into the 'data' array
    data.push({[type]: 1}); // Corrected line to update the 'type' field
    console.log("The " + type + " just got added with the value of 1", data);
  }
} else {
  // If the subcollection doesn't exist, create it and add the initial 'type' field
  await addDoc(typesOfTasksRef, { [type]: 1 });

  // Initialize 'data' as an array with a single object containing the 'type' key and value
  data = [{ [type]: 1 }];
  console.log("This a new data array", data);
}

    await updateUserTopTasks(vendorRef, data);

  } catch (error) {
    console.error('Error updating top task type:', error);
  }
};

const updateUserTopTasks = async (vendorRef, data) => {
  try {
    if (data.length > 0) {
      // Sort the data based on values in descending order
      data.sort((a, b) => {
        const valueA = Object.values(a)[0];
        const valueB = Object.values(b)[0];
        return valueB - valueA;
      });

      // Get up to three top keys from the sorted data
      const topTasksArray = data.slice(0, 3).map((obj, index) => {
        const key = Object.keys(obj)[0];
        return key;
      });

      // Update the 'topTasks' field in the vendorRef document as an array
      await updateDoc(vendorRef, { topTasks: topTasksArray });

      // console.log("Top tasks have been updated:", topTasksArray);
    }
  } catch (error) {
    console.error("Error updating top tasks:", error);
  }
};

export const deleteApplicantFromListing = async (listingId, userId) => {
  try {
    // Reference to the specific document in the "listings" collection using the taskId
    const listingDocRef = doc(firestore, 'listings', listingId);

    // Create a query to find documents where "applicantId" equals the userId
    const queryResult = query(collection(listingDocRef, 'applicants'), where('applicantId', '==', userId));

    // Get the query results
    const querySnapshot = await getDocs(queryResult);

    // Delete the documents that match the query
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  } catch (error) {
    console.error('Error deleting applicant from listing:', error);
  }
}

export const abandonTaskViaVendor = async (
  clientUid,
  vendorName,
  userProfilePicture,
  taskId,
  vendorId,
  taskTitle,
  roomId,
  taskAbandonedViaVendor
) => {
  try {
    await runTransaction(firestore, async (transaction) => {

      const listingDocRef = doc(firestore, 'listings', taskId);
      // Update the document to remove the "selectedApplicant" field
      transaction.update(listingDocRef, {
        selectedApplicant: null,
      });

      // Query the "applicants" subcollection to find documents with the specified vendorId
      const applicantsQuery = query(collection(listingDocRef, 'applicants'), where('applicantId', '==', vendorId));

      // Get the documents that match the query
      const applicantDocs = await getDocs(applicantsQuery);

      // Loop through the documents and delete them
      applicantDocs.forEach(async (applicantDoc) => {
        transaction.delete(applicantDoc.ref);
      });

      // Delete task from vendor's assigned tasks
      const assignedListingsCollection = collection(firestore, 'assignedListings');
      const q = query(assignedListingsCollection, where('listingId', '==', taskId), where('applicantId', '==', vendorId));

      // Execute the query to get the matching documents
      const querySnapshot = await getDocs(q);

      // Iterate through the matching documents and delete them
      querySnapshot.forEach(async (doc) => {
        transaction.delete(doc.ref);
      });
      // Delete the "hasApplied" flag from the user

      const savedListingUserDocRef = doc(firestore, 'users', vendorId);
      const savedListingsCollectionRef = collection(savedListingUserDocRef, 'savedListings');
      const savedListingDocRef = doc(savedListingsCollectionRef, taskId);
      const savedListingDocSnapshot = await getDoc(savedListingDocRef);

      if (savedListingDocSnapshot.exists()) {
        const savedListingData = savedListingDocSnapshot.data();
        const fieldsCount = Object.keys(savedListingData).length;
        if (fieldsCount === 1) {
          // If the document has only one field, delete the document
          transaction.delete(savedListingDocRef);
        } else {
          // If the document has more than one field, update 'hasApplied' to false
          transaction.set(savedListingDocRef, { hasApplied: false }, { merge: true });
        }
      }

      // Send an "Abandon Task" notification
      const usersCollectionRef = collection(firestore, 'users');
      const userDocRef = doc(usersCollectionRef, (taskAbandonedViaVendor)?clientUid:vendorId);
      const notificationsCollectionRef = collection(userDocRef, 'notifications');
      const newNotificationDocRef = doc(notificationsCollectionRef);
      const data = {
        roomId: roomId,
        listingId: taskId,
        type: (taskAbandonedViaVendor)?'vendorAbandonedTask':'clientAbandonedTask',
        status: 'unread',
        timestamp: serverTimestamp(),
        listingTitle: taskTitle,
        vendorName: vendorName,
        vendorPhoto: userProfilePicture,
      };
      transaction.set(newNotificationDocRef, data);
      // Delete the conversation room and associated documents
      const roomsCollectionRef = collection(firestore, 'rooms');
      const conversationCollectionRef = collection(roomsCollectionRef, roomId, 'conversation');
      const conversationQuerySnapshot = await getDocs(conversationCollectionRef);
      // console.log("lol");

      conversationQuerySnapshot.forEach(async (doc) => {
        transaction.delete(doc.ref);
      });

      transaction.delete(doc(roomsCollectionRef, roomId));

      const clientDocRef = doc(firestore, 'users', clientUid);
      const vendorDocRef = doc(firestore, 'users', vendorId);

      const clientRoomsCollectionRef = collection(clientDocRef, 'rooms');
      const clientRoomQuery = query(clientRoomsCollectionRef, where('conversationId', '==', roomId));
      const clientRoomQuerySnapshot = await getDocs(clientRoomQuery);

      clientRoomQuerySnapshot.forEach(async (doc) => {
        transaction.delete(doc.ref);
      });

      const vendorRoomsCollectionRef = collection(vendorDocRef, 'rooms');
      const vendorRoomQuery = query(vendorRoomsCollectionRef, where('conversationId', '==', roomId));
      const vendorRoomQuerySnapshot = await getDocs(vendorRoomQuery);

      vendorRoomQuerySnapshot.forEach(async (doc) => {
        transaction.delete(doc.ref);
      });

      const clientNotificationsCollectionRef = collection(clientDocRef, 'notifications');
      const clientNotificationsQuery = query(clientNotificationsCollectionRef, where('roomId', '==', roomId));
      const clientNotificationsQuerySnapshot = await getDocs(clientNotificationsQuery);

      clientNotificationsQuerySnapshot.forEach(async (doc) => {
        transaction.delete(doc.ref);
      });

      const vendorNotificationsCollectionRef = collection(vendorDocRef, 'notifications');
      const vendorNotificationsQuery = query(vendorNotificationsCollectionRef, where('roomId', '==', roomId));
      const vendorNotificationsQuerySnapshot = await getDocs(vendorNotificationsQuery);

      vendorNotificationsQuerySnapshot.forEach(async (doc) => {
        transaction.delete(doc.ref);
      });
    });

    return true;
  } catch (error) {
    console.error('Error abandoning task:', error);
    return false; // Return false to indicate an error occurred
  }
};


export const deleteAppliedFlagFromUser = async (taskId, vendorId) =>{
// Reference to the user's document in the "users" collection
const userDocRef = doc(firestore, 'users', vendorId);

// Reference to the "savedListings" subcollection within the user's document
const savedListingsCollectionRef = collection(userDocRef, 'savedListings');

// Create a reference to the specific saved listing document
const savedListingDocRef = doc(savedListingsCollectionRef, taskId);

// Check if the document exists
const savedListingDocSnapshot = await getDoc(savedListingDocRef);

if (savedListingDocSnapshot.exists()) {
  const savedListingData = savedListingDocSnapshot.data();
  const fieldsCount = Object.keys(savedListingData).length;
  if (fieldsCount == 1) {
    // If the document has only one field, delete the document
    await deleteDoc(savedListingDocRef);
  } else {
    // If the document has more than one field, update 'hasApplied' to false
    await setDoc(savedListingDocRef, { hasApplied: false }, { merge: true });
  }
  return true;
}

}

const deleteVendorTask = async (taskId, vendorId) => {
  try {
    // Reference to the 'assignedListings' collection
    const assignedListingsCollection = collection(firestore, 'assignedListings');

    // Create a query to filter documents
    const q = query(assignedListingsCollection, where('listingId', '==', taskId), where('applicantId', '==', vendorId));

    // Execute the query to get the matching documents
    const querySnapshot = await getDocs(q);

    // Iterate through the matching documents and delete them
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      // console.log(`Deleted document with taskId: ${taskId} and vendorId: ${vendorId}`);
    });

    return true;

  } catch (error) {
    console.log(error);
  }
}

//#region Account settings

const resizeFile = (base64String, maxWidth, maxHeight, targetSize) =>
  new Promise(async (resolve) => {
    let quality = 1.0;

    const resizeLoop = async () => {
      // Remove the data URL prefix
      const base64WithoutPrefix = base64String.replace(/^data:image\/jpeg;base64,/, '');

      // Convert the base64 string to a Uint8Array
      const uint8Array = Uint8Array.from(atob(base64WithoutPrefix), (c) => c.charCodeAt(0));

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: 'image/jpeg' });

      // Resize the Blob
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        let width = image.width;
        let height = image.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob(
          async (resizedBlob) => {
            // Convert the resized Blob back to base64
            const reader = new FileReader();
            reader.readAsDataURL(resizedBlob);

            reader.onloadend = () => {
              const base64Resized = reader.result;

              // Check if the size is within the target range
              if (resizedBlob.size < targetSize) {
                resolve(base64Resized);
              } else {
                // Adjust the quality and resize again
                quality -= 0.1;
                if (quality > 0) {
                  resizeLoop();
                } else {
                  resolve(null);
                }
              }
            };
          },
          'image/jpeg',
          quality
        );
      };

      image.onerror = (error) => {
        console.error('Error loading image:', error);
        resolve(null); // Handle the error gracefully or provide a default value
      };

      image.src = URL.createObjectURL(blob);
    };

    // Start the resizing loop
    resizeLoop();
});

export const changeUserProfilePicture = async (userId, profilePicture) => {
  const userDocRef = doc(firestore, "users", userId);

  try {
    const resizedProfilePicture = await resizeFile(profilePicture, 300, 300, 50000);

    await updateDoc(userDocRef, {
      profilePicture: resizedProfilePicture,
    });

    return true;
  } catch (error) {
    console.error("Error updating document: ", error.message);
    return false;
  }
};

export const changeUserCountry = async (userId, countryData) => {
  const userDocRef = doc(firestore, "users", userId);

  try {
    await updateDoc(userDocRef, {
      preferredCountry: countryData.name,
      preferredCountryCode: countryData.isoCode,
    });

    return true;
  } catch (error) {
    console.error("Error updating document: ", error.message);
    return false;
  }
};

export const savePreferredTypeChanges = async (userId, preferredTypesOfTasks) => {
  const userDocRef = doc(firestore, "users", userId);

  try {
    await updateDoc(userDocRef, {
      preferredTypesOfTasks: preferredTypesOfTasks,
    });

    return true;
  } catch (error) {
    console.error("Error updating document: ", error.message);
    return false;
  }
};

export const saveMeasurementSystemChanges = async (userId, preferredSystem) => {
  const userDocRef = doc(firestore, "users", userId);

  try {
    await updateDoc(userDocRef, {
      preferredMeasurementSystem: preferredSystem
    });

    return true;
  } catch (error) {
    console.error("Error updating document: ", error.message);
    return false;
  }
};

export const initiateSendReport = async (reportText) => {
  const reportsCollectionRef = collection(firestore, "bugReports");

  try {
    await addDoc(reportsCollectionRef, {
      reportText: reportText,
      timestamp: serverTimestamp(), // Optionally, include a timestamp for when the report was created
    });

    return true;
  } catch (error) {
    console.error("Error adding document: ", error.message);
    return false;
  }
};

//#endregion

export const sendContactMessage = async (firstName, lastName, email, subject, message) => {
  try {
    const contactCollectionRef = collection(firestore, 'contacts');

    // Create a new document with the provided data
    const newContactDoc = await addDoc(contactCollectionRef, {
      firstName,
      lastName,
      email,
      subject,
      message,
    });

    // If the document is added successfully, you can log its ID or perform other actions

    return true;
  } catch (error) {
    console.error('Error adding contact document:', error);
    return false;
  }
};