import React, { useRef, useState, useEffect, useContext } from 'react';
import SearchContext from './SearchContext';
import { getListingById } from '../../Firebase';

function SearchContextProvider({ children }) {
  //#region Listing link
  const currentURL = window.location.href;
  const isListingLink = (currentURL.includes("/task/")? true : false);

  const [listing, setListing] = useState(null);
  const [listingFetchingEnded, setListingFetchingEnded] = useState(false);

  const fetchListing = async() => {
    let listingId;
    if(isListingLink){
      const url = new URL(currentURL);
      const pathnameParts = url.pathname.split('/'); // Split the pathname by "/"
      listingId = pathnameParts[pathnameParts.length - 1]; // Get the last part
      const listingData = await getListingById(listingId);
      if(listingData){
      setListing(listingData)
      }else{
        setListing({id:null})
      }
      setListingFetchingEnded(true);
    }
  }

  useEffect(()=>{
    fetchListing();
  },[])

  //#endregion

  //#region Home page state
  //Turns true from the moment it starts to transition
  const [transitioned, setTransitioned] = useState((!isListingLink)?false:true);

  const [enterPressed, setEnterPressed] = useState((!isListingLink)?false:true);

  const [endedTransition, setEndedTransition] = useState((!isListingLink)?false:true);
  const [transitionedAlreadyHappened, setTransitionedAlreadyHappened] = useState((!isListingLink)?false:true);
  const transitionInitiatedFromDesktop = useRef((!isListingLink)?false:true);
  const typeIsSelected = useRef((!isListingLink)?false:true);
  //#endregion

  //Listings fetched based on location. Will be used as database from which the filtering happens
  const [fetchedListings, setFetchedListings] = useState([]);
  //Selected listings after filtering
  const [searchedListings, setSearchedListings] = useState([]);
  //Blocks mobile execution in searchBar
  const preventMobileExecution = useRef(false);

  //User searched sentence
  const [searchInput, setSearchInput] = useState("");

  const [prevSearchInput, setPrevSearchInput] = useState(null);

  const [prevLocation, setPrevLocation] = useState(null);

  const [currentLocation, setCurrentLocation] = useState("");

  const [locationNotFound, setLocationNotFound] = useState(null);

  const [wrongLocationName, setWrongLocationName] = useState(null);

  const [mobileSuggestedLocations, setMobileSuggestedLocations] = useState([]); // Use state to store suggestions

  const [suggestedLocationAlternative, setSuggestedLocationAlternative] = useState(null); // Use state to store suggestions

  const maxRadiusSearched = useRef(1);

  // user set filters

  const [searchFilters, setSearchFilters] = useState({
    location: {description:"", place_id:""},
    radius: "",
    type: "",
    date: "",
    price: ""
  })
  
  //Stores the current value of the filters identified from the search input
  const [filtersIdentifiedBySearch, setFiltersIdentifiedBySearch] = useState({
    location: {description:"", place_id:""},
    radius: "",
    type: "",
    date: "",
    price: ""
  })


  const setNewSearchFilters = (location, radius, type, date, price) => {
    setSearchFilters(prevFilters => ({
       location: location,
       radius: radius,
       type: type,
       date: date,
       price: price,
      }))
  };

  const initiateFilterBasedSearch = useRef(false);
  //Turns true when there is a result from the searching
  const stoppedSearchingTasks = useRef(false);
  //Triggered when user searched something
  const [searchedSomething, setSearchedSomething] = useState(false);
  //Flag for when search is happening
  const [isSearching, setIsSearching] = useState(true)
  //Stores the value scrolled in search to be remembered
  const scrollPosition = useRef(0);

const typesOfTasks = [
  {
    name: 'Home',
    tasks: [
      'House cleaning',
      'Electrician',
      'Plumber',
      'Painting',
      'Repairs',
      'Pest control',
      'Organising',
      'Home decoration',
    ],
  },
  {
    name: 'Garden',
    tasks: [
      'Lawn maintenance',
      'Pool maintenance',
      'Pruning',
      'Outdoor system installation',
    ],
  },
  {
    name: 'Care',
    tasks: [
      'Babysitting',
      'Elder care',
      'Special needs',
      'Nursing care',
      'Therapy',
    ],
  },
  {
    name: 'Tech',
    tasks: [
      'Software guidance',
      'Technology help',
      'Technology installation',
      'Electronics repairs',
    ],
  },
  {
    name: 'Transportation',
    tasks: [
      'Vehicle maintenance',
      'Vehicle cleaning',
    ],
  },
  {
    name: 'Pet care',
    tasks: [
      'Pet sitting',
      'Dog walking',
      'Pet Grooming',
    ],
  },
  {
    name: 'Education and Tutoring',
    tasks: [
      'Language learning',
      'Tutoring',
      'Skill development',
      'Test preparation',
    ],
  },
  {
    name: 'Health and Fitness',
    tasks: [
      'Personal training',
      'Health coaching',
    ],
  },
  {
    name: 'Event Planning',
    tasks: [
      'Party planning',
      'Photography',
      'Wedding planning',
    ],
  },
  {
    name: 'Moving help',
    tasks: [
      'Moving',
      'Packing/unpacking assistance',
    ],
  },
  {
    name: 'Security',
    tasks: [
      'Home security installation',
      'Fire safety inspections',
      'Childproofing services',
    ],
  },
  {
    name: 'Other',
    tasks: [
      'General'
    ],
  },
];

  const findSimilarLocations = (alternativeLocations, searchedLocation) => {
    // Split searchedLocation into words
    const searchedWords = searchedLocation.split(/\s+/);
  
    // Use filter to keep only objects with description containing any word from searchedLocation
    const filteredPredictions = alternativeLocations.filter(prediction => {
      // Split description into words
      const descriptionWords = prediction.description.split(/\s+/);
  
      // Check if any word in description includes any word in searchedLocation
      return searchedWords.some(searchedWord =>
        descriptionWords.some(descriptionWord =>
          descriptionWord.includes(searchedWord)
        )
      );
    });
  
    // Update the state with the filtered predictions
    setSuggestedLocationAlternative(filteredPredictions);
  
    // Log the filtered predictions
    // console.log(filteredPredictions);
  }

  
  return (
    <SearchContext.Provider value={{transitioned, setTransitioned,
      enterPressed, setEnterPressed, endedTransition, setEndedTransition,
      transitionedAlreadyHappened, setTransitionedAlreadyHappened,
      transitionInitiatedFromDesktop, typeIsSelected, scrollPosition,

      searchInput, setSearchInput, searchFilters, setSearchFilters, currentLocation,
      setCurrentLocation, prevSearchInput, setPrevSearchInput, prevLocation, setPrevLocation,
      setNewSearchFilters, filtersIdentifiedBySearch, setFiltersIdentifiedBySearch,
      searchedListings, setSearchedListings, initiateFilterBasedSearch, locationNotFound,
      setLocationNotFound, stoppedSearchingTasks, searchedSomething, setSearchedSomething,
      wrongLocationName, setWrongLocationName, fetchedListings, setFetchedListings,
       isSearching, setIsSearching, isListingLink, currentURL, listing, setListing,
       listingFetchingEnded, setListingFetchingEnded, mobileSuggestedLocations,
       setMobileSuggestedLocations, typesOfTasks, maxRadiusSearched,
       suggestedLocationAlternative, setSuggestedLocationAlternative, preventMobileExecution,
       findSimilarLocations}}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContextProvider;