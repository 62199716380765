import styles from './Notifications.module.css';
import {useRef, useState, useEffect, useLayoutEffect, useContext} from 'react';
import goBackImg from '../../../assets/images/Arrow.png'
import profilePicture from '../../../assets/images/ProfileSample.png';
import loadingIcon from '../../../assets/images/loading.png';
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';
import { deleteNotifications, markTaskCompletedViaClient, getListingById,
sendTaskCompletionConfirmationNotification, deleteConversationRoom } from '../../../Firebase';
import UserContext from '../../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
const Notifications = ()=>{

const isMobile = window.targetPlatform == "mobile";

const {notifications, setNotifications, numberOfUnreadNotifications,
  setNumberOfUnreadNotifications, reRenderNotificationComponentOnNewApplicant,
  setReRenderNotificationComponentOnNewApplicant, reRenderNotificationComponentOnTaskConfirmation, 
  setReRenderNotificationComponentOnTaskConfirmation,setReviewData,
  setThereIsSelectedDiscussion, setRooms,
  setProfilePicturesId, setCurrentRoomIndex} = useContext(UIContext);

const {user} = useContext(UserContext);
const {appliedListings, setAppliedListings, mineListings ,setMineListings, assignedTasks, setAssignedTasks, historyListings, setHistoryListings} = useContext(ListingsContext);
//#region Tab slide
  const notificationsTabRef = useRef(null);
  
  useEffect(()=>{
    if(isMobile){
    notificationsTabRef.current.classList.add(styles.slideRight);
    }
  },[])

  const initializeMobileComponentExit = ()=>{
      window.mobileHandleNavButtonsOnClick("Search");
      window.mobileSetSearchScrollPosition();
      window.setTypeShouldBeVisible(true);
      notificationsTabRef.current.classList.add(styles.slideLeft);
  }

    const handleAnimEnd = (event) => {
      if (event.animationName.includes("slideLeft")) {
        window.setActiveComponent("Search");
        window.setPrevActiveComponent("Search");
      } else if (event.animationName.includes("slideRight")) {
        if(notificationsTabRef.current){
          setTimeout(() => {        
            notificationsTabRef.current.classList.add(styles.containerAbsolute);
          }, 0);
        }
        // window.searchScrollToTop();
        window.setPrevActiveComponent("Notifications");
        window.setTypeShouldBeVisible(false);
      } 
    };
    
  
      const handleAnimStart = (event) => {
        if(window.prevActiveComponent == "Tasks"){
          window.changeTasksIndex();
        } else if(window.prevActiveComponent == "Saved"){
          window.changeSavedIndex();
        }else if(window.prevActiveComponent == "Profile"){
          window.changeProfileIndex();
        }else if(window.prevActiveComponent == "Account Settings"){
          window.changeAccountSettingsIndex();
        }else if(window.prevActiveComponent == "Applied Tasks"){
          window.changeAppliedTasksIndex();
        }else if(window.prevActiveComponent == "Messages"){
          window.changeMessagesIndex();
        }

        if(event.animationName.includes("slideRight")){
            notificationsTabRef.current.classList.add(styles.highContainerIndex);
        }else if(event.animationName.includes("slideLeft")){
            notificationsTabRef.current.classList.remove(styles.containerAbsolute);
        }
      }
  
      const changeNotificationsIndex = () => {
        notificationsTabRef.current.classList.remove(styles.highContainerIndex);
        notificationsTabRef.current.classList.remove(styles.containerAbsolute);
        notificationsTabRef.current.classList.add(styles.lowContainerIndex);
      }
      window.changeNotificationsIndex = changeNotificationsIndex;
//#endregion


const notificationRef = useRef([]);

const currentNumberOfUnreadNotifications = useRef(0);
const handleMouseEnter = (index)=>{
  if(!notificationRef.current[index].classList.contains(styles.isSelected)){
    notificationRef.current[index].classList.add(styles.isSelected);
  }
}

const handleMouseLeave = (index)=>{
  if(notificationRef.current[index].classList.contains(styles.isSelected)){
    notificationRef.current[index].classList.remove(styles.isSelected);
  }
}

//#region Timestamp handling
const handleNotificationTimestamp = (notification) => {
  if (!notification.timestamp) {
    return "Invalid timestamp"; // Handle the case where the timestamp is not present
  }

  const timestamp = notification.timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date

  const now = new Date();
  const timeDifference = now - timestamp;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return years === 1 ? "a year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "a month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "a day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "an hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
  } else {
    return seconds === 1 ? "a second ago" : `${seconds} seconds ago`;
  }
};
//#endregion


useLayoutEffect(()=>{
    markNewApplicantNotificationsAsRead();
},[reRenderNotificationComponentOnNewApplicant])

const [backgroundIsLocked, setBackgroundIsLocked] = useState(false);

const isMyListing = useRef(true);


const markNewApplicantNotificationsAsRead = () => {
  let unreadNotifications=0;
  setNotifications((prev) => {
    let newApplicantNotifications = [];
    const updatedNotifications = prev.map(notification => {
      if (notification.type === "newApplicant") {
        if(notification.status == "unread"){
          newApplicantNotifications = [...newApplicantNotifications, notification];
          unreadNotifications++;
          return {...notification, timesSeen: notification.timesSeen + 1, status: "read"}
        }else{
          return { ...notification, timesSeen: notification.timesSeen + 1 };
        }
      }
      return notification;
    });

    // Notify the user only if there's a change in the count of unread 'newApplicant' notifications
    if(unreadNotifications){
      const updatedNumber = numberOfUnreadNotifications - unreadNotifications;
      setNumberOfUnreadNotifications(updatedNumber);
      window.notifyUserOnNewNotifications(updatedNumber);
    }
    if(newApplicantNotifications.length>0){
      deleteNotifications(user.id, newApplicantNotifications, "newApplicant");
    }
    return updatedNotifications;
  });
  setReRenderNotificationComponentOnNewApplicant(false);
};

const NewApplicantNotification = ({ notification, index, handleNotificationClick }) => {
  return (
    <div
      ref={(ref) => (notificationRef.current[index] = ref)}
      key={index}
      className={styles.notificationContainer}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
      onClick={handleNotificationClick}
    >
      <span className={styles.headContainer}>
        <img src={notification.applicantPhoto ? notification.applicantPhoto : profilePicture} className={styles.profilePicture} alt = "Applicant Profile Picture"/>
        <span>
          <p className={styles.title}>You've got an applicant, check them out! </p>
          <p className={styles.listingTitle}>"{notification.listingTitle}"</p>
          <p className={styles.timeElapsedFromNotification}>{handleNotificationTimestamp(notification)}</p>
        </span>
        {notification.timesSeen < 3 && <div className={styles.newNotificationIcon}><p>●</p></div>}
      </span>
    </div>
  );
};

const ApplicantAcceptedNotification = ({ notification, index, handleNotificationClick }) => {
  
  const markApplicantAcceptedNotificationAsRead = (index) => {
    setNotifications((prev) => {
      let notificationToDelete = [];
      const updatedNotifications = prev.map((notification, i) => {
        if (i === index && notification.type === "acceptedApplication") {
          if (notification.status === "unread") {
            notificationToDelete = [notification];
            deleteNotifications(user.id, notificationToDelete, "acceptedApplication");

            const updatedNumber = numberOfUnreadNotifications - 1;
            setNumberOfUnreadNotifications(updatedNumber);
            window.notifyUserOnNewNotifications(updatedNumber);
            
            return { ...notification, timesSeen: notification.timesSeen + 1, status: "read" };
          }
        }
        return notification;
      });
  
      // Notify the user only if there's a change in the count of unread 'newApplicant' notifications
  
      return updatedNotifications;
    });
  };
  

  return (
    <div
      ref={(ref) => (notificationRef.current[index] = ref)}
      key={index}
      className={styles.notificationContainer}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
      onClick={()=>{
        handleNotificationClick();
        markApplicantAcceptedNotificationAsRead(index);
      }}
    >
      <span className={styles.headContainer}>
        <img src={notification.applicantPhoto ? notification.applicantPhoto : profilePicture} className={styles.profilePicture} alt = "Applicant Profile Picture"/>
        <span>
          <p className={styles.title}> You've got a new task! Head to your assigned tasks to get started!</p>
          <p className={styles.listingTitle}>"{notification.listingTitle}"</p>
          <p className={styles.timeElapsedFromNotification}>{handleNotificationTimestamp(notification)}</p>
        </span>
        {notification.timesSeen < 1 && <div className={styles.newNotificationIcon}><p>●</p></div>}
      </span>
    </div>
  );
};

const VendorAbandonedTaskNotification = ({ notification, index, handleNotificationClick }) => {

  const markAbandonedTaskNotificationAsRead = (index) => {
    setNotifications((prev) => {
      let notificationToDelete = [];
      const updatedNotifications = prev.map((notification, i) => {
        if (i === index && notification.type === "vendorAbandonedTask") {
          if (notification.status === "unread") {
            notificationToDelete = [notification];
            
            const updatedNumber = numberOfUnreadNotifications - 1;  
            setNumberOfUnreadNotifications(updatedNumber);
            window.notifyUserOnNewNotifications(updatedNumber);
            deleteNotifications(user.id, notificationToDelete, "vendorAbandonedTask");
            return { ...notification, timesSeen: notification.timesSeen + 1, status: "read" };
          }
        }
        return notification;
      });     
  
      return updatedNotifications;
    });
  };


  return (
    <div
      ref={(ref) => (notificationRef.current[index] = ref)}
      key={index}
      className={styles.notificationContainer}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
      onClick={()=>{
        handleNotificationClick();
        markAbandonedTaskNotificationAsRead(index);
      }}
    >
      <span className={styles.headContainer}>
        <img src={notification.vendorPhoto ? notification.vendorPhoto : profilePicture} className={styles.profilePicture} alt = "Applicant Profile Picture"/>
        <span>
          <p className={styles.title}><span style = {{fontWeight:"700"}}>{notification.vendorName}</span> abandoned the task. You can select a new applicant from "Mine" task.</p>
          <p className={styles.listingTitle}>"{notification.listingTitle}"</p>
          <p className={styles.timeElapsedFromNotification}>{handleNotificationTimestamp(notification)}</p>
        </span>
        {notification.timesSeen < 1 && <div className={styles.newNotificationIcon}><p>●</p></div>}
      </span>
    </div>
  );
};

const ClientAbandonedTaskNotification = ({ notification, index, handleNotificationClick }) => {

  const markAbandonedTaskNotificationAsRead = (index) => {
    setNotifications((prev) => {
      let notificationToDelete = [];
      const updatedNotifications = prev.map((notification, i) => {
        if (i === index && notification.type === "clientAbandonedTask") {
          if (notification.status === "unread") {
            notificationToDelete = [notification];
            
            const updatedNumber = numberOfUnreadNotifications - 1;  
            setNumberOfUnreadNotifications(updatedNumber);
            window.notifyUserOnNewNotifications(updatedNumber);
            deleteNotifications(user.id, notificationToDelete, "clientAbandonedTask");
            return { ...notification, timesSeen: notification.timesSeen + 1, status: "read" };
          }
        }
        return notification;
      });     
  
      return updatedNotifications;
    });
  };


  return (
    <div
      ref={(ref) => (notificationRef.current[index] = ref)}
      key={index}
      className={styles.notificationContainer}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
      onClick={()=>{
        handleNotificationClick();
        markAbandonedTaskNotificationAsRead(index);
      }}
    >
      <span className={styles.headContainer}>
        <img src={notification.vendorPhoto ? notification.vendorPhoto : profilePicture} className={styles.profilePicture} alt = "Applicant Profile Picture"/>
        <span>
          <p className={styles.title}><span style = {{fontWeight:"700"}}>{notification.vendorName}</span> abandoned the task.</p>
          <p className={styles.listingTitle}>"{notification.listingTitle}"</p>
          <p className={styles.timeElapsedFromNotification}>{handleNotificationTimestamp(notification)}</p>
        </span>
        {notification.timesSeen < 1 && <div className={styles.newNotificationIcon}><p>●</p></div>}
      </span>
    </div>
  );
};

const isConfirmationButtonLocked = useRef(false);
const [loadingIconIsEnabled, setLoadingIconIsEnabled] = useState(false);


const ConfirmationRequestTaskNotification = ({ notification, index}) => {

    const completeTask = async(listingId) =>{
      if(!isConfirmationButtonLocked.current){
        isConfirmationButtonLocked.current = true;
        setBackgroundIsLocked(true);
        //#region get listing from mine section if it exists, else get it from firebase before it gets deleted
        let currentListing = mineListings.find((mineListing) => mineListing.id == listingId && mineListing.roomId);
        if(!currentListing){
          currentListing = await getListingById(listingId);
        }
        //#endregion
        let notificationToDelete = [notifications[index]];
        setLoadingIconIsEnabled(true);
        const historyTaskData = await markTaskCompletedViaClient(listingId, user.id, notificationToDelete, "confirmationRequestTask", notification.roomId, notification.vendorId,
        user.profilePhoto, user.name, currentListing.title, currentListing.roomId);
        
        if(historyTaskData){
          //#region handle message
          setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== notification.roomId));
          setRooms((prev) => prev.filter(item => item.roomId !== notification.roomId));
          setCurrentRoomIndex("");
          setThereIsSelectedDiscussion(false);
          //#endregion

          setNotifications((prevNotifications) => {
            // Use filter to create a new array without the task to be removed
            const updatedMineListing = prevNotifications.filter((notification) => {
              return notification.listingId !== notifications[index].listingId;
            });
          
            // Return the updated array to set the state
            return updatedMineListing;
          });
          const updatedNumber = numberOfUnreadNotifications - 1;
      
          setNumberOfUnreadNotifications(updatedNumber);
          window.notifyUserOnNewNotifications(updatedNumber);
          
          setMineListings((prevMineListings) => {
            //Find the current listing
            const updatedMineListing = prevMineListings.filter((mineListing) => {
              return mineListing.id !== listingId;
            });
            // Update the state with the filtered array
            return updatedMineListing;
          });

          setHistoryListings([...historyListings, historyTaskData]);
        
          const reviewedListing = {
            clientId: currentListing.uid,
            vendorId: currentListing.selectedApplicant,
            historyListingId: historyTaskData.historyTaskId,
            type: currentListing.type,
            date: currentListing.date,
          };

          setReviewData({
            listing: reviewedListing,
            user: user,
            isMine: isMyListing,
            reviewAfterConfirmation: true,
          })

          window.setOpenReviewForm(true);
          window.openNotificationsWindow(false);
        }else{
          isConfirmationButtonLocked.current = false;
          console.log("Task not completed")
        }
        setBackgroundIsLocked(false);
        setLoadingIconIsEnabled(false);
      }
  }

  return (
    <div
      ref={(ref) => (notificationRef.current[index] = ref)}
      key={index}
      className={styles.notificationContainer}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <span className={styles.headContainer}>
        <img src={notification.vendorPhoto ? notification.vendorPhoto : profilePicture} className={styles.profilePicture} alt = "Applicant Profile Picture"/>
        <span>
          <p className={styles.title}><span style = {{fontWeight:"700"}}>{notification.vendorName}</span> completed the task. Your confirmation is now needed</p>
          <p className={styles.listingTitle}>"{notification.listingTitle}"</p>
          <div className = {styles.timeAndButtonPosition}>
            <p className={styles.timeElapsedFromNotification}>{handleNotificationTimestamp(notification)}</p>
            {loadingIconIsEnabled && <img src = {loadingIcon} className = {styles.loadingIcon} alt = "Loading"/>}
            <button className = {styles.confirmationButton} onClick = {()=>completeTask(notification.listingId)}>Confirm</button>
          </div>
        </span>
        {notification.timesSeen < 1 && <div className={styles.newNotificationIcon}><p>●</p></div>}
      </span>
    </div>
  );
};

useLayoutEffect(()=>{
  markCompletedTaskNotificationAsRead();
},[reRenderNotificationComponentOnTaskConfirmation])

const markCompletedTaskNotificationAsRead = () => {
  let unreadNotifications=0;
  setNotifications((prev) => {
    let newApplicantNotifications = [];
    const updatedNotifications = prev.map(notification => {
      if (notification.type === "taskCompletionConfirmed") {
        if(notification.status == "unread"){
          newApplicantNotifications = [...newApplicantNotifications, notification];
          unreadNotifications++;
          return {...notification, timesSeen: notification.timesSeen + 1, status: "read"}
        }else{
          return { ...notification, timesSeen: notification.timesSeen + 1 };
        }
      }
      return notification;
    });

    // Notify the user only if there's a change in the count of unread 'newApplicant' notifications
    if(unreadNotifications){
      const updatedNumber = numberOfUnreadNotifications - unreadNotifications;
      setNumberOfUnreadNotifications(updatedNumber)
      window.notifyUserOnNewNotifications(updatedNumber);
    }
    if(newApplicantNotifications.length>0){
      deleteNotifications(user.id, newApplicantNotifications, "taskCompletionConfirmed");
    }
    return updatedNotifications;
  });
  setReRenderNotificationComponentOnTaskConfirmation(false);
};

const ConfirmTaskCompletionNotification = ({ notification, index}) => {

  return (
    <div
      ref={(ref) => (notificationRef.current[index] = ref)}
      key={index}
      className={styles.notificationContainer}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <span className={styles.headContainer}>
        <img src={notification.clientPhoto ? notification.clientPhoto : profilePicture} className={styles.profilePicture} alt = "Client Profile Picture"/>
        <span>
          <p className={styles.title}>Good job! <span style = {{fontWeight:"700"}}>{notification.clientName}</span> marked their task as completed!</p>
          <p className={styles.listingTitle}>"{notification.listingTitle}"</p>
          <p className={styles.timeElapsedFromNotification}>{handleNotificationTimestamp(notification)}</p>
        </span>
        {notification.timesSeen < 3 && <div className={styles.newNotificationIcon}><p>●</p></div>}
      </span>
    </div>
  );
};

return (
  <>
    {backgroundIsLocked && <div className = {styles.blocker}/>}
    <div ref={notificationsTabRef} className={isMobile ? styles.container : styles.desktopContainer} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
      {/* Other parts of your component */}
      {isMobile && <div style = {{height:"3em"}}/>}
      {!isMobile && <p className={styles.componentTitle}>Notifications</p>}
      {isMobile && (
        <img src={goBackImg} style={{ width: "20px", marginTop: "1em", marginLeft: "1em", transform: "rotate(180deg)" }} onClick={initializeMobileComponentExit} alt = "Go back Arrow"/>
      )}

      {notifications.map((notification, index) => {
      if (notification.type === "newApplicant") {
      return (
        <NewApplicantNotification
          key={index}
          notification={notification}
          index={index}
          handleNotificationClick={() => {
            if (!isMobile) {
              window.desktopGoToSelectedTask("mine");
              window.openNotificationsWindow(false);
            } else {
              window.openSelectedTaskCategory("mine", true);
            }
          }}
        />
      );
      } else if (notification.type === "acceptedApplication") {
        return (
          <ApplicantAcceptedNotification
            key={index}
            notification={notification}
            index={index}
            handleNotificationClick={() => {
              if (!isMobile) {
                window.desktopGoToSelectedTask("assigned");
                window.openNotificationsWindow(false);
              } else {
                window.openSelectedTaskCategory("assigned", true);
              }
            }}
          />
        );
      } else if (notification.type === "vendorAbandonedTask") {
        return (
          <VendorAbandonedTaskNotification
            key={index}
            notification={notification}
            index={index}
            handleNotificationClick={() => {
              if (!isMobile) {
                window.desktopGoToSelectedTask("mine");
                window.openNotificationsWindow(false);
              } else {
                window.openSelectedTaskCategory("mine", true);
              }
            }}
          />
        );
      } else if (notification.type === "clientAbandonedTask") {
        return (
          <ClientAbandonedTaskNotification
            key={index}
            notification={notification}
            index={index}
            handleNotificationClick={() => {
              if (!isMobile) {
                window.desktopGoToSelectedTask("assigned");
                window.openNotificationsWindow(false);
              } else {
                window.openSelectedTaskCategory("assigned", true);
              }
            }}
          />
        );
      } else if (notification.type === "confirmationRequestTask") {
        return (
          <ConfirmationRequestTaskNotification
            key={index}
            notification={notification}
            index={index}
            handleNotificationClick={() => {
              if (!isMobile) {
                window.desktopGoToSelectedTask("mine");
                window.openNotificationsWindow(false);
              } else {
                window.openSelectedTaskCategory("mine", true);
              }
            }}
          />
        );
      }else if (notification.type === "taskCompletionConfirmed") {
        return (
          <ConfirmTaskCompletionNotification
            key={index}
            notification={notification}
            index={index}
          />
        );
      }

      return null; // or handle other notification types as needed
      })}

      {notifications.length === 0 && <p className={styles.noNotificationsMessage}>You're all caught up</p>}
    </div>
  </>
);
}

export default Notifications;