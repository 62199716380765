import styles from './AlternativeLocations.module.css';
import locationIcon from '../../../../../assets/images/location.png';
import { useRef, useEffect, useContext } from 'react';
import SearchContext from '../../../../../Firebase Contexts/SearchContext/SearchContext';
import ListingsContext from '../../../../../Firebase Contexts/Listings Context/ListingsContext';


const AlternativeLocations = ()=>{

    const {suggestedLocationAlternative, setSuggestedLocationAlternative,
        preventMobileExecution} = useContext(SearchContext);
    const {setDifferentSearchIsLoading} = useContext(ListingsContext);

    const shouldExecuteNewSearch = useRef(false);
    const isMobile = window.targetPlatform == "mobile";

    useEffect(()=>{
        if(shouldExecuteNewSearch.current){
            shouldExecuteNewSearch.current = false;
            window.initializeNewSearch(false, true);
            window.setShowAlternativeLocations(false);
        }
    },[suggestedLocationAlternative])

    const searchForAlternativeWord = (index) => {
        shouldExecuteNewSearch.current = true;
        setSuggestedLocationAlternative([suggestedLocationAlternative[index+1]]);
        setDifferentSearchIsLoading(true)
        if(isMobile){preventMobileExecution.current = true}
    }
return(
    <>
    <div className = {styles.backgroundAlternativeLocations} onClick = {()=>window.setShowAlternativeLocations(false)}/>
        <div className={styles.alterlativeLocationsStyle}>
            {suggestedLocationAlternative.slice(1).map((location, index) => (
            <div key={index} style = {{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <img src={locationIcon} alt={`Location ${index + 1}`} className={styles.locationIcon} onClick = {()=>searchForAlternativeWord(index)}/>
                <p className={styles.alterlativeLocationsText} onClick = {()=>searchForAlternativeWord(index)}>
                {location.description.split(',').slice(0, -1).join(',')}
                </p>
            </div>
            ))} 
        </div>
    </>
    )
}

export default AlternativeLocations;