import styles from './TypesSuggestions.module.css';
import { useRef, useState, useEffect, useLayoutEffect, useContext } from 'react';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import SearchContext from '../../../../Firebase Contexts/SearchContext/SearchContext';
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';
import locationIMG from '../../../../assets/images/location.png';
import babyBottleImg from '../../../../assets/images/babyBottle.png';
import lawnMaintenanceIcon from '../../../../assets/images/lawnMaintenanceIcon.png';
import elderlyCareIcon from '../../../../assets/images/elderlyCareIcon.png';
import tutoringIcon from '../../../../assets/images/tutoringIcon.png';
import houseCleaningIcon from '../../../../assets/images/houseCleaningIcon.png';
import electricianIcon from '../../../../assets/images/electricianIcon.png';
import plumberIcon from '../../../../assets/images/plumberIcon.png';
import pawIMG from '../../../../assets/images/paw.png';
import movingIMG from '../../../../assets/images/movingIcon.png';
import repairsIMG from '../../../../assets/images/repairsIcon.png';
import tickIMG from '../../../../assets/images/tickIcon.png';
import typesArrowIMG from '../../../../assets/images/Arrow.png';
import typesArrowLeftIMG from '../../../../assets/images/arrowLeft.png';

const TypesSuggestions = ({startSearchTransition, transitioned, endedTransition})=>{

    const isMobile = window.targetPlatform == "mobile";
    const {userPersistenceChecked, user, setRedirectURL} = useContext(UserContext);
    const {initiateFilterBasedSearch, typesOfTasks} = useContext(SearchContext);

    const {savedListingsLoaded} = useContext(ListingsContext);

    const handleStartTransition = (type)=>{
        let readyToShowResults;

        if(userPersistenceChecked && user.id){
            readyToShowResults = (savedListingsLoaded.current)?true:false;
          }else if(userPersistenceChecked && user.id==null){
            readyToShowResults = true;
        }
          
        if(userPersistenceChecked && readyToShowResults){
            initiateFilterBasedSearch.current = true;
            window.initializeNewSearch(false, false, type);
        }
    }

    const [typesSuggetionsContainerWidth, setTypesSuggetionsContainerWidth] = useState();

    window.setTypesSuggetionsContainerWidth = setTypesSuggetionsContainerWidth;

    const containerRef = useRef(null);
    const appMessageContainerRef = useRef(null);
    const innerAppMessageContainerRef = useRef(null);

    useLayoutEffect(() => {
      const handleResize = () => {
        if (appMessageContainerRef.current && innerAppMessageContainerRef.current) {
          const  innerAppMessageContainer = `${innerAppMessageContainerRef.current.clientHeight}px`;
          if(innerAppMessageContainer != appMessageContainerRef.current.style.height){
            appMessageContainerRef.current.style.height = innerAppMessageContainer;
          }
       }
      };
  
      // Initial setup
      handleResize();
  
      // Listen for window resize events
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    //#region Left and Right arrows
    const [scrollLeft, setScrollLeft] = useState(0);
    const scrollableContainerRef = useRef(null);
    
    const handleScroll = (direction) => {
        const container = scrollableContainerRef.current;
        const scrollDistance = 250; // Adjust the scroll distance as needed
        const scrollDuration = 300; // Adjust the scroll duration in milliseconds
      
        const startTime = performance.now();
        const startScrollLeft = container.scrollLeft;
      
        let targetScrollLeft;
        if (direction === 'left') {
          targetScrollLeft = startScrollLeft - scrollDistance;
        } else if (direction === 'right') {
          targetScrollLeft = startScrollLeft + scrollDistance;
        }
      
        const scroll = (currentTime) => {
          const elapsedTime = currentTime - startTime;
      
          if (elapsedTime < scrollDuration) {
            const progress = elapsedTime / scrollDuration;
            container.scrollLeft = startScrollLeft + progress * (targetScrollLeft - startScrollLeft);
            requestAnimationFrame(scroll);
          } else {
            container.scrollLeft = targetScrollLeft;
            setScrollLeft(targetScrollLeft);
          }
        };
      
        requestAnimationFrame(scroll);
      };
    //#endregion

      const categoriesRef = useRef(null);

      const transitionToCategories = () => {
        if (categoriesRef.current) {
          window.scrollTo({
            top: categoriesRef.current.offsetTop + 50,
            behavior: 'smooth',
          });
        }
      };
      
      const redirectUser = (currentButton) => {

        let listingURL;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            listingURL = "http://localhost:3000/"+currentButton;
        } else {
            listingURL = "https://www.taskare.com/"+currentButton;
        }
        window.open(listingURL);
      };

    return(
        <div ref = {containerRef} className = {styles.container}>

            <div className={styles.appMessageInnerContainer}>

              {!transitioned && <div>
                    <h2 className={styles.popularServicesMessage} style = {{textAlign: "left", marginTop: "0em", marginBottom: "2em"}}>Popular tasks</h2>
                    
                        <ul className = {styles.featuredCategoriesDesktop}>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Babysitting")}>
                                    <img src = {babyBottleImg} alt = "Baby Bottle"/>
                                    <p>Babysitting</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Elder care")}>
                                    <img src = {elderlyCareIcon} alt = "Walking Stick"/>
                                    <p>Elder care</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Dog walking")}>
                                    <img src = {pawIMG} alt = "Paw"/>
                                    <p>Dog walking</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("House cleaning")}>
                                    <img src = {houseCleaningIcon} alt = "Spray"/>
                                    <p>House cleaning</p>
                                </li>

                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Electrician")}>
                                    <img src = {electricianIcon} alt = "Lightbulb"/>
                                    <p>Electrician</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Plumber")}>
                                    <img src = {plumberIcon} alt = "Faucet"/>
                                    <p>Plumber</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Tutoring")}>
                                    <img src = {tutoringIcon} alt = "Book"/>
                                    <p>Tutoring</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Lawn maintenance")}>
                                    <img src = {lawnMaintenanceIcon} alt = "Lawn Mower"/>
                                    <p>Lawn maintenance</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Moving")}>
                                    <img src = {movingIMG} alt = "Moving" />
                                    <p>Moving</p>
                                </li>
                                <li className = {styles.featuredCategory} onClick = {()=>handleStartTransition("Repairs")}>
                                    <img src = {repairsIMG} alt = "Repairs"/>
                                    <p>Repairs</p>
                                </li>
                        </ul>
                    <div className = {styles.viewMoreButton} onClick = {transitionToCategories}>
                        <button>View more</button>
                     </div>
              </div>}
                
            {!transitioned && (
                <div>
                    <div className={styles.appMessageContainer} ref={appMessageContainerRef} />
                    <div className={isMobile ? styles.innerAppMessageContainer : styles.innerAppMessageContainerDesktop} ref={innerAppMessageContainerRef}>
                        <h2 className={styles.appMessageTitle}>Unlock your potential</h2>

                        <section>
                            <ul>
                                <li>
                                    <span className={styles.subtitleContainer}>
                                        <img src={tickIMG} className={styles.tickIMGStyle} alt = "Tick"/>
                                        <h3 className={styles.appMessageSubtitle}>Discover opportunities near you </h3>
                                    </span>
                                    <p className={styles.appMessageText}>Explore opportunities right in your community, whether you're offering a service or seeking help. Support local businesses and neighbors.</p>
                                </li>
                                <li>
                                    <span className={styles.subtitleContainer}>
                                        <img src={tickIMG} className={styles.tickIMGStyle} alt = "Tick"/>
                                        <h3 className={styles.appMessageSubtitle}>Simplify your experience</h3>
                                    </span>
                                    <p className={styles.appMessageText}>Skip the client search hassle. Connect effortlessly with those who need you, and focus on your priorities.</p>
                                </li>
                                <li>
                                    <span className={styles.subtitleContainer}>
                                        <img src={tickIMG} className={styles.tickIMGStyle} alt = "Tick"/>
                                        <h3 className={styles.appMessageSubtitle}>Make your time count</h3>
                                    </span>
                                    <p className={styles.appMessageText}>Maximize your free time with tasks tailored to your interests and seamlessly fit them into your schedule.</p>
                                </li>
                                <li>
                                    <span className={styles.subtitleContainer}>
                                        <img src={tickIMG} className={styles.tickIMGStyle} alt = "Tick"/>
                                        <h3 className={styles.appMessageSubtitle}>Grow your reach</h3>
                                    </span>
                                    <p className={styles.appMessageText}>Connect with clients actively seeking your services. Expand your reach and business opportunities effortlessly.</p>
                                </li>
                            </ul>
                        </section>
                </div>
            </div>
            )}

              {!transitioned && <>
                  <h2 className={styles.popularServicesMessage}>Categories</h2>
                  <div className = {(isMobile)?styles.trendingTasksContainer:styles.trendingTasksContainerDesktop} ref = {categoriesRef}>
                       {!isMobile && <span className={styles.leftArrow} onClick={() => handleScroll('left')}>
                          <img src = {typesArrowLeftIMG} className={styles.typesArrowStyle} alt = "Left Arrow"/>
                      </span>}
                      <div className = {(isMobile)?styles.categoriesContainer:styles.categoriesContainerDesktop} ref = {scrollableContainerRef}>
                      {typesOfTasks.map((category, index) => (
                          <div className = {styles.typesStyle} key = {index}>
                              <h3 className={styles.popularTitle}>{category.name}</h3>
                              {category.tasks.map((task, taskIndex) => (
                              <button
                                  key={taskIndex}
                                  className={styles.popularCategories}
                                  onClick={() => handleStartTransition(task)}
                              >
                                  {task}
                              </button>
                              ))}
                          </div>
                      ))}
                      </div>
                      {!isMobile &&
                      <span className={styles.rightArrow} onClick={() => handleScroll('right')}>
                          <img src = {typesArrowIMG} className={styles.typesArrowStyle} alt = "Right Arrow"/>
                      </span>}
                  </div>
              </>}
            </div>

            {endedTransition && !isMobile && <div className = {styles.topCategoriesContainer}>
                {typesOfTasks.map((category, index) => (
                <div key={index}>
                    <span className = {styles.typesStyle}>
                        <p className={styles.categoriesMessage} style = {{marginTop:(index!=0)?"2em":"0em"}}>{category.name}</p>
                        {category.tasks.map((task, taskIndex) => (
                        <button
                            key={taskIndex}
                            className={styles.categoryHomepage}
                            onClick={() => handleStartTransition(task)}
                        >
                            {task}
                        </button>
                        ))}
                    </span>
                </div>
                ))}
            </div>
            }

            {!transitioned && isMobile && 
            <div className = {styles.moreInfoContainer}>
                {!user.id && <p className = {styles.moreInfoText} onClick = {()=>window.setSignInHidden(false)}>Sign in</p>}
                <p className = {styles.moreInfoText} onClick={()=>redirectUser("aboutus")}>About us</p>
                <p className = {styles.moreInfoText} onClick={()=>redirectUser("contactus")} style = {{borderBottom: "1px solid rgb(222, 222, 222)"}}>Contact us</p>
            </div>
            }

           {!transitioned && isMobile && <div className = {styles.footerContainer}>
                <p>©2024 Taskare | <span className = {styles.selectedFooterButton} onClick={()=>redirectUser("termsofservice")}> Terms of Service </span> | <span className = {styles.selectedFooterButton} onClick={()=>redirectUser("privacypolicy")}>Privacy Policy</span> | <span className = {styles.selectedFooterButton} onClick={()=>redirectUser("cookiepolicy")}>Cookies</span></p>
            </div>}

            {!transitioned && !isMobile && (
                <>
                    <div className={styles.divideLine} />
                    <footer className={styles.footerContainerDesktop}>

                    <div className={styles.moreInfoContainerDesktop} style={{ marginRight: "3em" }}>
                        {!user.id && <p className={styles.moreInfoTextDesktop} onClick={() => window.setSignInHidden(false)}>Sign in</p>}
                        <a href="https://www.taskare.com/aboutus" target="_blank" rel="noopener noreferrer">About us</a>
                        <a href="https://www.taskare.com/contactus" target="_blank" rel="noopener noreferrer">Contact us</a>
                    </div>

                    <div className={styles.moreInfoContainerDesktop}>
                        <p style={{ textDecoration: "none", cursor: "auto" }}>©2024 Taskare </p>
                        <a href="https://www.taskare.com/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                        <a href="https://www.taskare.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <a href="https://www.taskare.com/cookiepolicy" target="_blank" rel="noopener noreferrer">Cookies</a>
                    </div>

                    </footer>
                </>
            )}

        </div>
    )
}

export default TypesSuggestions;