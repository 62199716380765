import styles from './Candidates.module.css';
import React, {useRef, useState, useEffect} from 'react';
import OtherProfiles from '../../Core Components/Profile/OtherProfiles';
import StarsRating from '../StarsRating/StarRating'
import goBackImg from '../../../assets/images/Arrow.png'
import sampleProfile from '../../../assets/images/ProfileSample.png'
import RatedTask from '../RatedTask';
import {getUserById, getUserReviews} from '../../../Firebase'
import { isDOMComponent } from 'react-dom/test-utils';

const Candidates = ({usersIds, listingId, listingTitle}) => {

  const isMobile = window.targetPlatform == "mobile";

const candidatesContainerRef = React.useRef(null);
const candidateContainerRef = React.useRef(null);

const [candidatesInfo, setCandidatesInfo] = useState([]); // Initialize test state

const [backgroundIsEnabled, setBackgroundIsEnabled] = useState(true); // Initialize test state
const [candidatesLoaded, setCandidatesLoaded] = useState(false)


const profileJustClosed = useRef(true);

const candidatesShouldBeAbleToClose = ()=>{
  if(window.isConnectWindowEnabled==false){
    profileJustClosed.current = true;
  }
}

const loadCandidates = async () => {
  try {
    // Use Promise.all to concurrently fetch user data for all user IDs
    const userDataArray = await Promise.all(usersIds.map(async (userId) => {
      const userData = await getUserById(userId);
      return userData;
    }));

    // Filter out any null values if needed
    const filteredUserDataArray = userDataArray.filter(userData => userData !== null);

    // Update candidatesData with the filtered user data
    setCandidatesInfo(filteredUserDataArray);

    setCandidatesLoaded(true)
    // Handle the user data as needed
  } catch (error) {
    // Handle any errors
    console.error(error);
  }
};

useEffect(() => {
  // Load candidates and set test state when the component mounts
  loadCandidates();
}, [usersIds]);

useEffect(() => {
  window.shouldScroll(false);
},[]);

  //Get user reviews
  const loadCandidatesReviews = async (uid) => {
    try {
      // Fetch user data for the single user ID
      const reviews = await getUserReviews(uid);
      return reviews;
    } catch (error) {
      // Handle any errors
      console.error(error.message);
    }
  };

//#endregion

  const exitCandidates = () => {
    window.setOpenCandidates(false);
    window.shouldScroll(true);

    backgroundRef.current.classList.remove(styles.fadeIn);
    backgroundRef.current.classList.add(styles.fadeOut);
  };

  const exitCandidatesMobile = ()=>{
    backgroundRef.current.classList.remove(styles.fadeIn);
    backgroundRef.current.classList.add(styles.fadeOut);
    candidatesContainerRef.current.classList.remove(styles.slideUp);
    candidatesContainerRef.current.classList.add(styles.slideDown);
  }

  const openOtherProfiles = async (profilePhoto, name, lastName, stars, topTasks, uid) => {
    profileJustClosed.current = false;
    window.showProfile(profilePhoto, name, lastName, stars, topTasks, await loadCandidatesReviews(uid), uid);
  };

  const handleAnimationEnd = (event) => {
    if(event.animationName.includes("slideDown")){
      window.setOpenCandidates(false);
      window.shouldScroll(true);
    }
  };
  
//#region Background
const backgroundRef = useRef(null);

const handleBackgroundAnimationEnd = (event) => {
  if(event.animationName.includes("fadeOut")){
    setBackgroundIsEnabled(false);
  }
}

//#endregion
  return (
    <>
    {!isMobile && usersIds.length ==0 && <p className = {(isMobile)? styles.noCandidates : styles.noCandidatesDesktop} >There are currently no candidates for this task</p>}

    {backgroundIsEnabled && <div className = {`${styles.background} ${styles.fadeIn}`}
    ref = {backgroundRef} onAnimationEnd = {handleBackgroundAnimationEnd} onClick = {(isMobile)?exitCandidatesMobile:exitCandidates}/>}

    { candidatesLoaded &&
    <div className={(isMobile)?`${styles.mobileContainer} ${styles.slideUp}`:styles.candidatesContainer} ref = {candidatesContainerRef} onAnimationEnd={handleAnimationEnd}>
      {isMobile && <img alt = "Arrow" src = {goBackImg} style = {{width: "20px", marginTop:"1.5em", marginLeft:"1em", transform: "rotate(180deg)", position:"relative", zIndex: "5"}} onClick = {(isMobile)?exitCandidatesMobile:exitCandidates}/>}
      {usersIds.length == 0 && <>
        <p className = {styles.noCandidates} >There are currently no candidates for this task</p>
      </>}
      {usersIds.length > 0 && <>
      {candidatesInfo.map((candidate, index) => (
        <div key={index} className= {styles.candidateContainer} ref = {candidateContainerRef}
        style={{
          marginTop: index === 0 ? '0em' : 0,
          marginBottom: index === candidatesInfo.length - 1 ? '0em' : "1em",
        }}
        >
          <div className= {styles.candidateDetails}>
          <img src = {(candidate.profilePicture != null)?candidate.profilePicture:sampleProfile} className = {styles.candidateProfile} alt = "Applicant Profile Picture"/>
          <div>
            <p className={styles.candidateName}>{candidate.name}</p>
            <span className = {styles.candidateRating}><StarsRating starRating = {candidate.stars}/></span>
          </div>
          <p className= {styles.viewProfileButton} onClick = {()=>
          openOtherProfiles(candidate.profilePicture ,candidate.name,
            candidate.surname, candidate.stars, candidate.topTasks,
            candidate.uid)}>View profile</p>
          </div>
        </div>
      ))}
      </>}
    </div>}

    <OtherProfiles
    isCandidatesVisible = {true}
    profileOpenedFromCandidate = {true}
    candidatesShouldBeAbleToClose = {candidatesShouldBeAbleToClose}
    listingId = {listingId}
    listingTitle = {listingTitle}
    exitCandidates = {exitCandidates}
    exitCandidatesMobile = {exitCandidatesMobile}
    />
    </>
  );
};

export default Candidates;
