import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './Type.module.css';
import SearchContext from '../../../../../Firebase Contexts/SearchContext/SearchContext';
import ListingsContext from '../../../../../Firebase Contexts/Listings Context/ListingsContext';

const Type = ({setTypeFilterSubmit, inputRef, typeVisibility }) => {
  const isMobile = window.targetPlatform === 'mobile';

  const { initiateFilterBasedSearch, searchFilters, setSearchFilters, typesOfTasks,
    setFiltersIdentifiedBySearch, setSearchedListings, setFetchedListings, } = useContext(SearchContext);
  const {lastDocumentListingFetched} = useContext(ListingsContext);

  const [thereAreSuggestions, setThereAreSuggestions] = useState(true);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(null);
  const [filter, setFilter] = useState(''); // Changed the variable name here
  const [visibleSuggestions, setVisibleSuggestions] = useState([]);

  const suggestedTypes = typesOfTasks;
  const selectedSuggestionRefs = useRef(Array(suggestedTypes.length).fill(null));

  useEffect(() => {
    // Focus on the input field when the component is rendered
    inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    // Flatten the tasks array from the structured data
    const allTasks = typesOfTasks.flatMap(category => category.tasks);
  
    // Filter and sort the tasks
    const filteredAndSortedTasks = allTasks
      .filter(task => task.toLowerCase().includes(filter.toLowerCase()))
      .sort((a, b) => {
        // Calculate the index of the typed letters in each task
        const indexA = a.toLowerCase().indexOf(filter.toLowerCase());
        const indexB = b.toLowerCase().indexOf(filter.toLowerCase());
  
        // Sort by the index (earlier occurrence comes first)
        if (indexA !== indexB) {
          return indexA - indexB;
        }
  
        // If the index is the same, sort alphabetically
        return a.localeCompare(b);
      });
    setVisibleSuggestions(filteredAndSortedTasks);
  }, [filter]);
  
  

  const handleTypeChange = (event) => {
    event.preventDefault();
    setFilter(event.target.value);
  };

  const handleOnEnterKey = (event) => {
    event.preventDefault();

    if (selectedSuggestionIndex !== null) {
      // Check if a suggestion is highlighted
      handleSuggestionClick(selectedSuggestionIndex);
    } else {
      // Convert filter to lowercase for case-insensitive comparison
      const lowercaseFilter = filter.toLowerCase();

      // Find the matched suggested type in lowercase
      const matchedSuggestedType = suggestedTypes.find((suggestion) =>
        suggestion.toLowerCase() === lowercaseFilter
      );

      if (matchedSuggestedType) {
        initiateFilterBasedSearch.current = true;
        window.setShouldRunFilterWidthCheck(true);

        // Use the matched suggested type for submission
        setTypeFilterSubmit(matchedSuggestedType);
        typeVisibility();
      }
    }
  };

  const handleSuggestionClick = (index) => {
    
    //If there was no type previously
    if(searchFilters.type != null && searchFilters.type !== "" && visibleSuggestions[index] != searchFilters.type){
      lastDocumentListingFetched.current = null;

      setFetchedListings([]);
      setSearchedListings([]);
    }
    
    initiateFilterBasedSearch.current = true;
    setFilter(visibleSuggestions[index]);
    setTypeFilterSubmit(visibleSuggestions[index]);

    setTimeout(()=>{
      window.fetchMoreListings();
    },[200])

    typeVisibility();
  };

  const handleArrowKey = (event) => {
    if (event.key === 'ArrowUp') {
      // Move selection up
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex === null ? visibleSuggestions.length - 1 : Math.max(0, prevIndex - 1)
      );
    } else if (event.key === 'ArrowDown') {
      // Move selection down
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.min(visibleSuggestions.length - 1, prevIndex + 1)
      );
    }
  };

  const highlightedFilter = useRef('');

  useEffect(() => {
    if (visibleSuggestions[selectedSuggestionIndex] !== undefined) {
      highlightedFilter.current = visibleSuggestions[selectedSuggestionIndex];
    }
    if (
      selectedSuggestionIndex !== null &&
      selectedSuggestionRefs.current[selectedSuggestionIndex]
    ) {
      selectedSuggestionRefs.current[selectedSuggestionIndex].classList.add(
        styles.selectedOption
      );
    }
  }, [selectedSuggestionIndex, visibleSuggestions]);

  return (
    <div className={isMobile ? styles.mobileContainer : styles.desktopContainer}>
      <div>
        <span
          className={
            isMobile
              ? `${styles.container} ${styles.containerMobile}`
              : `${styles.container} ${styles.containerDesktop}`
          }
          style={
            isMobile
              ? { width: `${(window.filterWidth.current / 16) + 2}em` }
              : {width: window.filterWidth.current}
          }
          
        >
          <input
            type="text"
            placeholder="e.g. babysitting.."
            onChange={handleTypeChange}
            value={filter}
            className={
              isMobile
                ? styles.mobileLocationInputContainer
                : styles.desktopLocationInputContainer
            }
            ref={inputRef}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault(); // Prevent default form submission
                handleOnEnterKey(event);
              } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.preventDefault(); // Prevent scrolling
                handleArrowKey(event);
              }
            }}
          />
        </span>
        {thereAreSuggestions && (
          <div className={isMobile ? styles.mobileOptionsContainer : styles.desktopOptionsContainer}>
            {visibleSuggestions.map((x, index) => (
              <p
                key={index}
                ref={(el) => (selectedSuggestionRefs.current[index] = el)} // Store the ref in the array
                className={`${styles.option} ${
                  selectedSuggestionIndex === index ? styles.selected : ''
                }`}
                onClick={() => handleSuggestionClick(index)}
              >
                {x}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Type;
