import React, {useState, useRef} from 'react';
import styles from './DeleteConfirmation.module.css';
import {deleteListing} from '../../../../Firebase';

const DeleteConfirmation = ({setDeletePressed, listing})=>{

    const deleteButtonPressed = useRef(false);

    const deleteButtonRef = useRef(null);
    
    const handleCancelButton = ()=>{
        if(!deleteButtonPressed.current){
            console.log()
            deleteButtonPressed.current = false;
            setDeletePressed(false);
        }
    }

    const handleDeleteButton = async()=>{
        deleteButtonPressed.current = true;
        deleteButtonRef.current.classList.remove(styles.deleteConfirmationButton);
        deleteButtonRef.current.classList.add(styles.pressedDeleteConfirmationButton);
        const result = await deleteListing(listing.id)
        if(result){
            handleContinue();
        }else{
        }
    }

    const handleContinue = ()=>{
        deleteButtonPressed.current = false;
        setDeletePressed(false);
        window.exitEditDeletedListing();
    }

    return(
        <>
            <div className={styles.container}>
                <div className = {styles.background}/>
                <div className = {styles.deleteConfirmation}>
                <p>Are you sure?</p>
                <p className = {styles.deleteConfirmationMessage}>This action can not be undone</p>
                <span className = {styles.deleteConfirmationButtons}>
                    <button onClick = {handleCancelButton} className = {styles.cancelButton}>Cancel</button>
                    <button onClick = {handleDeleteButton} ref = {deleteButtonRef} className = {styles.deleteConfirmationButton}>Delete</button>
                </span>
            </div>
        </div>
        </>
    )
}

export default DeleteConfirmation;