import styles from './AboutUs.module.css'
import { useEffect } from 'react';
import logo from '../../../assets/images/LogoTwo.png'
const AboutUs = ()=>{

    const isMobile = window.targetPlatform == "mobile";

    useEffect(() => {
            window.scrollTo({
              top: 0,
              behavior: 'auto'
            });
      }, []);


    return(
    <div className = {(isMobile)?styles.contentContainerMobile:styles.contentContainerDesktop}>
        <div className = {(isMobile)?styles.logoContainerMobile:styles.logoContainerDesktop} onClick={() => window.location.href = "https://www.taskare.com"}>
            <img src = {logo} className={styles.logo} alt="Taskare Logo"/>
        </div>
        <p className = {styles.aboutUsText}>About us</p>
        <p className = {styles.simpleTextDesktop}>Welcome to Taskare, the community-driven platform where individuals come together to lend a helping hand and find solutions. Our mission is to connect people, allowing them to share their skills and expertise to make a positive impact on each other's lives.</p>
        <p className = {styles.categoryTextTitle}>Who We Are</p>
        <p className = {styles.simpleTextDesktop}>At Taskare, we believe in the power of collaboration and the strength that comes from a supportive community. Our platform is a space where individuals can share their unique talents and offer assistance to those in need.</p>
        <p className = {styles.categoryTextTitle}>Our Story:</p>
        <p className = {styles.simpleTextDesktop}>Founded in 2024, Taskare was born out of a simple idea – to create a platform that enables people to connect through the exchange of tasks and skills. Whether you have a specific need or a skill to offer, we provide a space where users can come together to help each other thrive.</p>
        <p className = {styles.simpleTextDesktop}></p>
        <p className = {styles.categoryTextTitle}>How It Works:</p>
        <p className = {styles.simpleTextDesktop}><span className={styles.textTitle}>Task Upload:</span> Users can easily upload task listings, detailing the skills they have to offer or the specific assistance they require.</p>
        <p className = {styles.simpleTextDesktop}><span className={styles.textTitle}>Browse and Connect:</span> Visitors can explore a diverse range of task listings, connecting with individuals who can provide the help they need or whom they can assist.</p>
        <p className = {styles.simpleTextDesktop}><span className={styles.textTitle}>Build a Community:</span> Taskare is not just a platform; it's a community. Engage with like-minded individuals, forge connections, and create a network of support.</p>
        <p className = {styles.categoryTextTitle}>We're here to help</p>
        <p className = {styles.simpleTextDesktop}>Wether you look for somebody to help you or need an extra income, we can help you achieve your goal.</p>

    </div>
    )
}

export default AboutUs;