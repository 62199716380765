import React, { useRef, useState, useEffect, useContext } from 'react';
import UIContext from './UIContext';
import UserContext from '../UserDataContext';
import { getUnreadNotifications, startListeningForTasks,
  startListeningForMessages, sendMessage, getUserRooms, deleteMessageNotifications,
  changeUserCountry, savePreferredTypeChanges, initiateSendReport,
  changeUserProfilePicture} from '../../Firebase';
import { reload } from 'firebase/auth';
import ListingsContext from '../Listings Context/ListingsContext';

function UIContextProvider({ children }) {
  
  const {user, userPersistenceChecked, alreadyCheckedForTasks} = useContext(UserContext);

  //#region Mine
    //Stores the data of the editForm in listing creation
    const [editListingData, setEditListingData] = useState({})

    //Stores the data of the candidates from Mine component
    const [candidatesData, setCandidatesData] = useState({})
  //#endregion

  //#region Assigned
    const [completeTaskData, setCompleteTaskData] = useState({})

    const [abandonTaskData, setAbandonTaskData] = useState({})
  //#endregion

  //#region History
  const [reviewData, setReviewData] = useState({})
  //#endregion

  //#region User Profile content
  const [reviews, setReviews] = useState([]);
  const [userProfileInfo, setUserProfileInfo] = useState([]);

  const userProfileLoaded = useRef(false);

  const measurementSystemButtonPressed = useRef(false);
  //#endregion

  //#region ShareListing

  const [sharedListingData, setSharedListingData] = useState({});
  //#endregion
  
  //#region SearchInput

  const [searchOrLocation, setSearchOrLocation] = useState({search: "", location:""});

  //#endregion
  
  //#region Image crop
  const [imageDataCrop, setImageDataCrop] = useState({latestCroppedImage: user.profilePhoto, initialUploaded: user.profilePhoto, uploadedPhoto: null, newProfilePicture: null, photoIsSaved: false});
  const [shouldUploadNewImage, setShouldUploadNewImage] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(false);

  useEffect(()=>{
    if(shouldUploadNewImage){
      setShouldUploadNewImage(false);
      updateUserProfilePicture();
    }
  },[shouldUploadNewImage])

  const [fileLimitReachedError, setFileLimitReachedError] = useState(false);

  const updateUserProfilePicture = async()=>{
   setImageIsLoading(true);
   const result = await changeUserProfilePicture(user.id, imageDataCrop.newProfilePicture);
   if(result){
   setImageIsLoading(false);
   }else{
    setImageIsLoading(false);
   }
  }
  //#endregion

  //#region Handle Country change

  const countryCodes = [
    {name: "United States", code: 1, isoCode: "US"},
    {name: "Canada", code: 1, isoCode: "CA"},
    {name: "United Kingdom", code: 44, isoCode: "GB"},
    {name: "Ireland", code: +353, isoCode: "IE"},
    {name: "Australia", code: 61, isoCode: "AU"},
    {name: "New Zealand", code: 64, isoCode: "NZ"},
    {name: "Denmark", code: 45, isoCode: "DK"},
    {name: "Sweden", code: 46, isoCode: "SE"},
    {name: "Norway", code: 47, isoCode: "NO"},
    {name: "Greece", code: 30, isoCode: "GR"},
    {name: "South Africa", code: +27, isoCode: "ZA"},
  ]

  const [newlySelectedCountry, setNewlySelectedCountry] = useState("");
  const [newlySelectedCountryIsoCode, setNewlySelectedCountryIsoCode] = useState("");
  const initializeCountryChange = async(index) => {
    const result = await changeUserCountry(user.id, countryCodes[index]);

    if(result){
      setNewlySelectedCountry(countryCodes[index].name);
      setNewlySelectedCountryIsoCode(countryCodes[index].isoCode);
      return true;
    }else{
      return false;
    }
  }
  //#endregion

  //#region Handle User Preferred Type of Tasks
  const { userSelectedTypes } = useContext(UserContext);
  const [prevSelectedTypes, setPrevInitialSelectedTypes] = useState(userSelectedTypes);
  const [currentlySelectedTypes, setCurrentlySelectedTypes] = useState(userSelectedTypes);
  const [shouldConfirmationBeVisible, setShouldConfirmationBeVisible] = useState(false);

    const handleNewTypeChanges = async(preferredTypeOfTasks) => {
      const result = await savePreferredTypeChanges(user.id, preferredTypeOfTasks);
      if(result){
        setPrevInitialSelectedTypes(preferredTypeOfTasks);
        return true;
      }else{
        return false;
      }
    }
  //#endregion

  //#region Handle Report
  const sendReport = async(reportText)=>{
    const result = await initiateSendReport(reportText);

    if(result){
      return true;
    }else{
      return false;
    }
  }
  //#endregion

  //#region Handle Notifications
  
    //Set listings states here
    const mineListingsLoaded = useRef(false)
    const assignedListingsLoaded = useRef(false)
    const historyListingsLoaded = useRef(false)
  
  const isMobile = window.targetPlatform == "mobile";

  const [notifications, setNotifications] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);

  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState(0);
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0);
  
  const [reRenderNotificationComponentOnNewApplicant, setReRenderNotificationComponentOnNewApplicant] = useState(true);

  const [reRenderNotificationComponentOnTaskConfirmation, setReRenderNotificationComponentOnTaskConfirmation] = useState(true);

  const [newMessageReceived, setNewMessageReceived] = useState("");
  
  const preventFirstListener = useRef(false);

  const listenForTaskUpdates = async () => {
      alreadyCheckedForTasks.current = true;
      await startListeningForTasks(user.id, preventFirstListener, sendNotification);
  }
  
  const sendNotification = async (newNotification) => {
    // Use the functional update form of setNotifications to ensure you're working with the latest state
    if(newNotification.type == "newApplicant"){
      setReRenderNotificationComponentOnNewApplicant(true);

    }else if(newNotification.type == "acceptedApplication"){
      //if messages are opened;
      window.removeSavedListing(newNotification.listingId);
      window.deleteAppliedListing(newNotification.listingId);
      roomsAlreadyFetched.current = false;
      await getProfilePhotos(unreadMessages);
         
      assignedListingsLoaded.current = false;
      if(window.activeComponent == "Tasks" && window.selectedTask.current == "assigned"){
        window.showSelectedTasks("assigned", true);
      }
    }else if(newNotification.type == "vendorAbandonedTask"){
      mineListingsLoaded.current = false;
      if(window.activeComponent == "Tasks" && window.selectedTask.current == "mine"){
        window.showSelectedTasks("mine", true);
      }

      setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== newNotification.roomId));
      setRooms((prev) => prev.filter(item => item.roomId !== newNotification.roomId));
      setCurrentRoomIndex("");
      setThereIsSelectedDiscussion(false);

    }else if(newNotification.type == "clientAbandonedTask"){
      assignedListingsLoaded.current = false;
      if(window.activeComponent == "Tasks" && window.selectedTask.current == "assigned"){
        window.showSelectedTasks("assigned", true);
      }

      setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== newNotification.roomId));
      setRooms((prev) => prev.filter(item => item.roomId !== newNotification.roomId));
      setCurrentRoomIndex("");
      setThereIsSelectedDiscussion(false);

    }else if(newNotification.type == "taskCompletionConfirmed"){
      setReRenderNotificationComponentOnTaskConfirmation(true);
      assignedListingsLoaded.current = false;
      if(window.activeComponent == "Tasks" && window.selectedTask.current == "assigned"){
        window.showSelectedTasks("assigned", true);
      }
      setProfilePicturesId((prev) => prev.filter(item => item.conversationId !== newNotification.roomId));
      setRooms((prev) => prev.filter(item => item.roomId !== newNotification.roomId));
      setCurrentRoomIndex("");
      setThereIsSelectedDiscussion(false);
    }else if(newNotification.type == "newMessage"){
      setNewMessageReceived(newNotification);
    }
    setNotifications(prevNotifications => {
      // Create a new array with the new notification
      const updatedNotifications = [...prevNotifications, newNotification];
      // Sort the new array by timestamp
      updatedNotifications.sort((a, b) => b.timestamp - a.timestamp);
      // Return the updated array
      return updatedNotifications;
    });
    //Update the number of notifications
    if(newNotification.type != "newMessage"){
      setNumberOfUnreadNotifications(prev=>{
        const updatedNumberOfNotifications = prev + 1;
        return updatedNumberOfNotifications
      })
    }
  };
  
  useEffect(()=>{
    if(newMessageReceived){
      checkIfNewMessageShouldBeDeleted(newMessageReceived);
    }
  },[newMessageReceived])

  //Checks if the new message received is viewed
  const checkIfNewMessageShouldBeDeleted = (newNotification)=>{
      //If user is in messenges
    if((window.messagesIsExpanded || window.activeComponent == "Messages")){
      // console.log("new notification coming");
      if(currentlySelectedProfileIndex!==""){
      //If user is on the active bubble the message came
      if(profilePicturesId[currentlySelectedProfileIndex].conversationId == newNotification.roomId){
          // console.log("user is in bubble the message came");
          //Delete it
          deleteMessageNotifications(user.id, [newNotification]);
        }else{
          // console.log("user is in another bubble");
          setUnreadMessages(prev => [...prev, newNotification]);
          
          setNumberOfUnreadMessages(prev=>{
            const updatedNumberOfMessages = prev + 1;
            return updatedNumberOfMessages;
          })

          setProfilePicturesId((prev) => {
            const updatedPrev = prev.map((conversation) => {
              if (conversation.conversationId === newNotification.roomId){
                const unreadMessages = conversation.unreadMessages
                let updatedUnreadNumberOfMessages;
                if(unreadMessages && unreadMessages >=0){
                  updatedUnreadNumberOfMessages = unreadMessages + 1
                }else{
                  updatedUnreadNumberOfMessages = 1;
                }
                return { ...conversation, unreadMessages: updatedUnreadNumberOfMessages };
              }
              return conversation;
            });
          
            return updatedPrev;
          });
        }
      }else{
        // console.log("user is not in messages")
        setUnreadMessages(prev => [...prev, newNotification]);
          
        setNumberOfUnreadMessages(prev=>{
          const updatedNumberOfMessages = prev + 1;
          return updatedNumberOfMessages;
        })

        setProfilePicturesId((prev) => {
          const updatedPrev = prev.map((conversation) => {
            if (conversation.conversationId === newNotification.roomId){
              const unreadMessages = conversation.unreadMessages
              let updatedUnreadNumberOfMessages;
              if(unreadMessages && unreadMessages >=0){
                updatedUnreadNumberOfMessages = unreadMessages + 1
              }else{
                updatedUnreadNumberOfMessages = 1;
              }
              return { ...conversation, unreadMessages: updatedUnreadNumberOfMessages };
            }
            return conversation;
          });
        
          return updatedPrev;
        });
        }
    }else{
      //if not in messages
      setUnreadMessages(prev => [...prev, newNotification]);
          
      setNumberOfUnreadMessages(prev=>{
        const updatedNumberOfMessages = prev + 1;
        return updatedNumberOfMessages;
      })

      setProfilePicturesId((prev) => {
        const updatedPrev = prev.map((conversation) => {
          if (conversation.conversationId === newNotification.roomId){
            const unreadMessages = conversation.unreadMessages
            let updatedUnreadNumberOfMessages;
            if(unreadMessages && unreadMessages >=0){
              updatedUnreadNumberOfMessages = unreadMessages + 1
            }else{
              updatedUnreadNumberOfMessages = 1;
            }
            return { ...conversation, unreadMessages: updatedUnreadNumberOfMessages };
          }
          return conversation;
        });
      
        return updatedPrev;
      });
    }
  }
  

  useEffect(()=>{
    if(numberOfUnreadNotifications > 0){
      window.notifyUserOnNewNotifications(numberOfUnreadNotifications);
    }
  },[numberOfUnreadNotifications])
  
  useEffect(()=>{
    if(numberOfUnreadMessages > 0){
      if(isMobile){
        window.notifyUserOnNewMessageMobile(numberOfUnreadMessages);
      }else if(!isMobile){
        window.notifyUserOnNewMessageDesktop(numberOfUnreadMessages);
      }
    }
  },[numberOfUnreadMessages])
  
  useEffect(()=>{
    if(userPersistenceChecked && user.id){
      fetchUnreadNotifications();
      listenForTaskUpdates();
    }
  },[userPersistenceChecked])

  const fetchUnreadNotifications = async() => {
    const results = await getUnreadNotifications(user.id);
    const newMessages = results.filter(obj => obj.type === 'newMessage');
    const notifiticationsWithoutMessages = results.filter(obj => obj.type !== 'newMessage');
    const newNumberOfNotifications = results.length - newMessages.length;

  // Sort notifiticationsWithoutMessages only if timestamp is present
  notifiticationsWithoutMessages.sort((a, b) => {
    if (a.timestamp && b.timestamp) {
      return b.timestamp - a.timestamp;
    } else {
      // If timestamp is missing for any object, maintain the current order
      return 0;
    }
  });

  // Sort newMessages only if timestamp is present
  newMessages.sort((a, b) => {
    if (a.timestamp && b.timestamp) {
      return b.timestamp - a.timestamp;
    } else {
      // If timestamp is missing for any object, maintain the current order
      return 0;
    }
  });

    setNotifications(notifiticationsWithoutMessages);
    setUnreadMessages(newMessages);
    setNumberOfUnreadNotifications(newNumberOfNotifications);
    setNumberOfUnreadMessages(newMessages.length);
    await getProfilePhotos(newMessages);
  }
  //#endregion

  //#region Handle Messages
  const [receiverId, setReceiverId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [rooms, setRooms] = useState([]);
  const preventFirstListenerOfRooms = useRef([]);

  const [profilePicturesId, setProfilePicturesId] = useState([]);
  const [profilePicturesExistenceChecked, setProfilePicturesExistenceChecked] = useState(false);
  const [currentRoomIndex, setCurrentRoomIndex] = useState("");

  const [thereIsSelectedDiscussion, setThereIsSelectedDiscussion] = useState(false);

  const roomsAlreadyFetched = useRef(false);

  const [currentlySelectedProfileIndex, setCurrentlySelectedProfileIndex] = useState("");

  const sendNewMessage = async(roomId, senderId, receiverId, messageText, textId) => {
    await sendMessage(roomId, senderId, receiverId, messageText, textId);
  }
  const [numberOfMessageNotifications, setNumberOfMessageNotifications] = useState(0);

  // const numberOfMessageNotifications = useRef(0);


  const [newlyFetchedText, setNewlyFetchedText] = useState("")

  useEffect(()=>{
    if(newlyFetchedText!=""){

      setRooms((prev) => {
        // Find the index of the room with the specified roomId
        const roomIndex = prev.findIndex((room) => room.roomId === newlyFetchedText.roomId);
      
        if (roomIndex !== -1) {
          // Room with roomId found, update its conversations
          return prev.map((room, index) => {
            if (index === roomIndex) {
              const { conversation } = room;
      
              // Check if there are conversations
              if (Array.isArray(conversation) && conversation.length > 0) {
                // Find the index of the conversation with a matching id
                const matchingIndex = conversation.findIndex((conv) => conv.id === newlyFetchedText.id);
      
                if (matchingIndex !== -1) {
                  // Replace the conversation at the matching index
                  const updatedConversations = [
                    ...conversation.slice(0, matchingIndex),
                    newlyFetchedText,
                    ...conversation.slice(matchingIndex + 1),
                  ];

                  updatedConversations.sort((a, b) => {
                    if (a && a.timestamp && a.timestamp.seconds && b && b.timestamp && b.timestamp.seconds) {
                      return a.timestamp.seconds - b.timestamp.seconds;
                    }
                    // Handle the case where either a or b doesn't have a valid timestamp
                    return 0;
                  });
                  return {
                    ...room,
                    conversation: updatedConversations,
                  };
                }
              }
      
              // If the conversation with the specified id doesn't exist, add the new conversation
              const updatedConversations = Array.isArray(conversation)
                ? [...conversation, newlyFetchedText]
                : [newlyFetchedText];
              return { ...room, conversation: updatedConversations };
            }
            return room;
          });
        }
      
        // If the room with roomId doesn't exist, return the previous state with the new room
        return [...prev, { roomId: newlyFetchedText.roomId, conversation: [newlyFetchedText] }];
      });
      
    }
  },[newlyFetchedText])

  const listenForMessage = async () => {
    await startListeningForMessages(roomId, preventFirstListenerOfRooms, sendAMessage);
}

const getProfilePhotos = async (unreadMessages) => {
  const result = await getUserRooms(user.id);
    // console.log("fuck");
  if (result) {
    roomsAlreadyFetched.current = true;
    if (unreadMessages.length > 0) {
      const updatedResult = result.map(resultItem => {
          const matchingCount = unreadMessages.filter(unreadMessage => {
              return unreadMessage.roomId === resultItem.conversationId;
          }).length;

          // Create a new object with the existing properties of resultItem and add the x key
          return {
              ...resultItem,
              unreadMessages: matchingCount,
          };
      });
      // Set the updated result array to profilePicturesId
      setProfilePicturesId(updatedResult);
    } else {
        setProfilePicturesId(result);
    }

    setProfilePicturesExistenceChecked(true);
    } else {
    console.log("something went wrong");
  }
}


const sendAMessage = (newMessage) => {
    setNewlyFetchedText(newMessage);
};

  // useEffect(()=>{
  //   console.log(profilePicturesId);
  // },[profilePicturesId])

  useEffect(()=>{
    if(roomId!=""){
      const roomIndex = preventFirstListenerOfRooms.current.findIndex((room) => room.roomId === roomId);

      if(preventFirstListenerOfRooms.current[roomIndex].firstRenderDone){
        console.log()
        listenForMessage();
      }
    }
  },[roomId])

  const deleteReadMessagesNotifications = async(messageNotificationsToDelete)=>{
    // console.log("going to delete", messageNotificationsToDelete, unreadMessages);
    const result = await deleteMessageNotifications(user.id, messageNotificationsToDelete);
    if(result){
      const filteredUnreadMessages = unreadMessages.filter(unreadMessage =>
        !messageNotificationsToDelete.some(deletedMessage => deletedMessage.id === unreadMessage.id)
      );
      if(isMobile){
        window.notifyUserOnNewMessageMobile(filteredUnreadMessages.length);
      }else if(!isMobile){
        window.notifyUserOnNewMessageDesktop(filteredUnreadMessages.length);
      }
      setUnreadMessages(filteredUnreadMessages);
      setNumberOfUnreadMessages(filteredUnreadMessages.length);

      const updatedResult = profilePicturesId.map(profileId => {
        const matchingCount = unreadMessages.filter(unreadMessage => {
            return unreadMessage.roomId === profileId.conversationId;
        }).length;

        // If the conversationId is in messageNotificationsToDelete, set x to 0
        if (messageNotificationsToDelete.some(deleteItem => deleteItem.roomId === profileId.conversationId)) {
            return {
                ...profileId,
                unreadMessages: 0,
            };
        }

        return {
            ...profileId,
            x: matchingCount,
        };
    });
    // Set the updated result array to profilePicturesId
    setProfilePicturesId(updatedResult);
    }
  }

  useEffect(()=>{

  },[unreadMessages, numberOfMessageNotifications])


  //#endregion
  
  return (
    <UIContext.Provider value={{editListingData, setEditListingData,
    candidatesData, setCandidatesData, completeTaskData, setCompleteTaskData,
    abandonTaskData, setAbandonTaskData, reviewData, setReviewData,
    sharedListingData, setSharedListingData, searchOrLocation, setSearchOrLocation,
    notifications, setNotifications, numberOfUnreadNotifications, setNumberOfUnreadNotifications,
    reRenderNotificationComponentOnNewApplicant, setReRenderNotificationComponentOnNewApplicant,
    reRenderNotificationComponentOnTaskConfirmation, setReRenderNotificationComponentOnTaskConfirmation,
    mineListingsLoaded, assignedListingsLoaded, historyListingsLoaded, sendNewMessage,
    receiverId, setReceiverId, roomId, setRoomId, preventFirstListenerOfRooms,
    profilePicturesId, profilePicturesExistenceChecked, setProfilePicturesExistenceChecked, setProfilePicturesId, roomsAlreadyFetched, rooms, setRooms,
    currentRoomIndex, setCurrentRoomIndex, thereIsSelectedDiscussion, setThereIsSelectedDiscussion,
    currentlySelectedProfileIndex, setCurrentlySelectedProfileIndex, unreadMessages, setUnreadMessages, deleteReadMessagesNotifications,
    getProfilePhotos, numberOfMessageNotifications, setNumberOfMessageNotifications,
    imageDataCrop, setImageDataCrop, initializeCountryChange, newlySelectedCountry,
    countryCodes, newlySelectedCountryIsoCode, setNewlySelectedCountryIsoCode,
    handleNewTypeChanges, prevSelectedTypes, currentlySelectedTypes, setCurrentlySelectedTypes,
    shouldConfirmationBeVisible, setShouldConfirmationBeVisible, updateUserProfilePicture,
    sendReport, imageIsLoading, setImageIsLoading, fileLimitReachedError, setFileLimitReachedError,
    reviews, setReviews, userProfileInfo, setUserProfileInfo, userProfileLoaded,
    shouldUploadNewImage, setShouldUploadNewImage, measurementSystemButtonPressed}}>
      {children}
    </UIContext.Provider>
  );
}

export default UIContextProvider;