import React, {useRef, useState, useEffect, useContext} from "react";
import styles from './Saved.module.css';
import ListedTask from "../../../Tasks/ListedTask";
import ListingsContext from '../../../../Firebase Contexts/Listings Context/ListingsContext';
import goBackImg from '../../../../assets/images/Arrow.png'
const Saved = ()=>{
    const isMobile = window.targetPlatform == "mobile";
    const {savedListingsLoaded, savedListings, appliedListings, bannedListings} = useContext(ListingsContext);

    const savedListingsLoadedInSavedTab = useRef(false)
    const [listings, setListings] = useState([]);

    useEffect(() => {
      if (savedListingsLoaded.current && savedListingsLoadedInSavedTab.current === false) {
        // Filter savedListings based on whether their ID is not in bannedListings
        const filteredListings = savedListings.filter(savedListing => {
          return !bannedListings.some(bannedListing => bannedListing.id === savedListing.id);
        });
    
        setListings(filteredListings);
        savedListingsLoadedInSavedTab.current = true;
      }
    }, [savedListingsLoaded.current]);

    const setNewListings = (listingId) => {
      setListings((prevListings) => {
        return prevListings.filter((listing) => listing.id !== listingId);
      });
    }

    window.setNewListings = setNewListings;

    //#region Slide animation
    const savedTabRef = useRef(null);

    useEffect(()=>{
      if(isMobile){
        savedTabRef.current.classList.add(styles.slideRight);
      }
    },[])

    const initializeComponentExit = ()=>{
      window.mobileHandleNavButtonsOnClick("Search")
      window.mobileSetSearchScrollPosition();
      window.setTypeShouldBeVisible(true);
      savedTabRef.current.classList.add(styles.slideLeft);
    }
  
    const handleAnimEnd = (event)=>{
        if(event.animationName.includes("slideLeft")){
            window.setActiveComponent("Search");
            window.setPrevActiveComponent("Search");
        } else if(event.animationName.includes("slideRight")){
          if(savedTabRef.current){
            setTimeout(() => {        
              savedTabRef.current.classList.add(styles.containerAbsolute);
            }, 0);
          }
          window.setPrevActiveComponent("Saved");
          window.setTypeShouldBeVisible(false);
        } 
    }

    const handleAnimStart = (event) => {
      if(window.prevActiveComponent == "Tasks"){
        window.changeTasksIndex();
      } else if(window.prevActiveComponent == "Notifications"){
        window.changeNotificationsIndex();
      }else if(window.prevActiveComponent == "Profile"){
        window.changeProfileIndex();
      }else if(window.prevActiveComponent == "Account Settings"){
        window.changeAccountSettingsIndex();
      }else if(window.prevActiveComponent == "Applied Tasks"){
        window.changeAppliedTasksIndex();
      }else if(window.prevActiveComponent == "Messages"){
        window.changeMessagesIndex();
      }

      if(event.animationName.includes("slideRight")){
        savedTabRef.current.classList.add(styles.highContainerIndex);
      }else if(event.animationName.includes("slideLeft")){
        savedTabRef.current.classList.remove(styles.containerAbsolute);
      }
    }

    const changeSavedIndex = () => {
      savedTabRef.current.classList.remove(styles.highContainerIndex);
      savedTabRef.current.classList.remove(styles.containerAbsolute);
      savedTabRef.current.classList.add(styles.lowContainerIndex);
    }
    window.changeSavedIndex = changeSavedIndex;

    //#endregion

    useEffect(()=>{
      if(!isMobile){
          window.hideSearch();
      }
  },[])

    return(
    <div ref = {savedTabRef} className = {isMobile? styles.container : styles.desktopContainer} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
      {isMobile && <div style = {{height:"3em"}}/>}
      {isMobile && <img src = {goBackImg} style = {{width: "20px", marginTop:"1em", marginLeft:"1em", transform: "rotate(180deg)"}} onClick = {initializeComponentExit} alt = "Go Back Arrow" />}
      {!isMobile && <p className = {styles.title}>My saved tasks</p>}
      {listings.length>0 &&
        <div className = {isMobile ? styles.mobileSavedTasksLayout : styles.desktopSavedTasksLayout}>
          {listings.map((listing, index) => (
              <ListedTask key={index}
                          listing={listing}
                          isSaved = {true}
                          hasApplied={appliedListings.some(appliedListing => appliedListing.id === listing.id)?true : false}
                          /> // Provide a unique key and pass the listing as a prop
          ))}
        </div>}

      {listings.length==0 && savedListingsLoaded.current &&
        <div>
          <p className = {styles.noSavedTasksMessage}>You haven't saved anything yet</p>
        </div>
      }
    </div>
    );
};

export default React.memo(Saved);