import styles from './Profile.module.css';
import RatedTask from '../../Tasks/RatedTask';
import StarRating from '../../Tasks/StarsRating/StarRating';
import ConnectWindow from './Connect Corfimation/ConnectWindow';
import goBackImg from '../../../assets/images/Arrow.png'
import sampleProfile from '../../../assets/images/ProfileSample.png'
import { useRef, useState, useEffect } from 'react';

const Profile = ({isCandidatesVisible, profileRenderedFromProfileTab, checkIfConnectionWindowIsEnabled, candidateProfilePhoto,
    candidateName, candidateLastName, candidateRating, candidateTopTasks,
    candidateReviews: reviews, candidateId, listingId, exitOtherProfiles,
    candidatesShouldBeAbleToClose, exitCandidates, exitCandidatesMobile, handleApplicantConfirmation,
    listingTitle, showAbandonOption, isVendor})=>{

    const [connectPressed, setConnectPressed] = useState(false);

    const [isProfile, setIsProfile] = useState(window.activeComponent == "Profile");

    const isMobile = window.targetPlatform == "mobile";

    const openConnectWindow = ()=>{
        checkIfConnectionWindowIsEnabled(true);
        setConnectPressed(true);
    }

    //#region Tab slide
    const profileTabRef = useRef(null);

    useEffect(()=>{
        if(isMobile && profileRenderedFromProfileTab){
        profileTabRef.current.classList.add(styles.slideRight)
    }
      },[])

    const initializeComponentExit = ()=>{
    window.mobileHandleNavButtonsOnClick("Search")
    window.mobileSetSearchScrollPosition();
    window.setTypeShouldBeVisible(true);
    profileTabRef.current.classList.add(styles.slideLeft);
    }

    const handleAnimEnd = (event)=>{
        if(event.animationName.includes("slideLeft")){
            window.setActiveComponent("Search");
            window.setPrevActiveComponent("Search");
        } else if(event.animationName.includes("slideRight")){
            if(profileTabRef.current){
                setTimeout(() => {        
                    profileTabRef.current.classList.add(styles.containerAbsolute);
                }, 0);
            }
        window.setPrevActiveComponent("Profile");
        window.setTypeShouldBeVisible(false);
        }
    }

    const handleAnimStart = (event) => {
    if(window.prevActiveComponent == "Tasks"){
        window.changeTasksIndex();
    }else if(window.prevActiveComponent == "Saved"){
        window.changeSavedIndex();
    }else if(window.prevActiveComponent == "Notifications"){
        window.changeNotificationsIndex();
    }else if(window.prevActiveComponent == "Account Settings"){
        window.changeAccountSettingsIndex();
    }else if(window.prevActiveComponent == "Applied Tasks"){
        window.changeAppliedTasksIndex();
    }else if(window.prevActiveComponent == "Messages"){
        window.changeMessagesIndex();
    }

    if(event.animationName.includes("slideRight")){
        profileTabRef.current.classList.add(styles.highContainerIndex);
    }else if(event.animationName.includes("slideLeft")){
        profileTabRef.current.classList.remove(styles.containerAbsolute);
      }
    }

    const changeProfileIndex = () => {
    profileTabRef.current.classList.remove(styles.highContainerIndex);
    profileTabRef.current.classList.remove(styles.containerAbsolute);
    profileTabRef.current.classList.add(styles.lowContainerIndex);
    }
    window.changeProfileIndex = changeProfileIndex;
//#endregion

    useEffect(()=>{
        if(!isMobile && profileRenderedFromProfileTab){
            window.hideSearch();
        }
    },[])
      
    return(
    <>
        <div ref = {profileTabRef} className = {isMobile? (!isProfile)?styles.otherProfiles:styles.container: styles.desktopContainer} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
            {isMobile && isProfile && <div style = {{height:"3em"}}/>}
            {isMobile && <img src = {goBackImg} style = {{width: "20px", marginTop:"1.5em", marginLeft:"1em", transform: "rotate(180deg)", position:"relative", zIndex: "5"}} onClick = {()=>profileRenderedFromProfileTab?initializeComponentExit():exitOtherProfiles()} alt = "Go Back Arrow"/>}
            {isMobile && (isProfile) && <div className = {styles.profileBackgroundStyle}/>}
            <div className = {isMobile ? styles.mobileProfileContainer : styles.desktopProfileContainer}>
                <div className={styles.profiledetails}>
                    <img src={(candidateProfilePhoto != null)?candidateProfilePhoto:sampleProfile} alt="Applicant Profile Picture" className={(isMobile)?((!isProfile)? styles.candidateProfilePicture : styles.profilePicture):((!isProfile)? styles.candidateProfilePicture :styles.profilePictureDesktop)}/>
                    <div className={(isMobile)?((!isProfile)?styles.candidateDetails:styles.details):styles.detailsDesktop}>
                        <p className={styles.nameSurname}>{candidateName} {candidateLastName}</p>
                        <div className={styles.ratingsContainer}>
                            <span className = {styles.star}><StarRating starRating={candidateRating}/></span>
                            <p className={styles.ratingsCounter}>{reviews? reviews.length: 0} {reviews.length==1? "review":"reviews"}</p>
                        </div>
                    </div>
                </div>
                {showAbandonOption && <p className = {styles.abandonStyle} style = {{right: (isMobile)?"1em":"0em"}} onClick = {()=>(isVendor)?window.openAbandonWindowViaVendor():window.openAbandonWindowViaClient()}>Unmatch</p>}
                <p className = {styles.frequentTasksTitle}>Most frequent tasks</p>
                <div className = {styles.topTypesOfTasks}>
                    { candidateTopTasks!=null && candidateTopTasks.length >= 1 && (
                        <p className={styles.typeoftask}>• {candidateTopTasks[0]}</p>
                    )}
                    {candidateTopTasks.length >= 2 && (
                        <p className={styles.typeoftask}>• {candidateTopTasks[1]}</p>
                    )}
                    {candidateTopTasks.length >= 3 && (
                        <p className={styles.typeoftask}>• {candidateTopTasks[2]}</p>
                    )}
                </div>
                
                {connectPressed && <ConnectWindow
                checkIfConnectionWindowIsEnabled = {checkIfConnectionWindowIsEnabled}
                connectPressed = {connectPressed}
                setConnectPressed = {setConnectPressed}
                listingId={listingId}
                candidateName={candidateName}
                candidateId = {candidateId}
                exitOtherProfiles = {exitOtherProfiles}
                candidatesShouldBeAbleToClose = {candidatesShouldBeAbleToClose}
                exitCandidates = {exitCandidates}
                exitCandidatesMobile = {exitCandidatesMobile}
                handleApplicantConfirmation = {handleApplicantConfirmation}
                listingTitle = {listingTitle}
                candidateProfilePhoto = {candidateProfilePhoto}
                />}

                {isCandidatesVisible &&
                    <div>                       
                        {!connectPressed && <p className= {styles.connectButton} onClick = {()=>openConnectWindow()}>Connect with {candidateName}</p>}
                    </div>
                }
                <p className= {styles.reviewsTitle}>{reviews && reviews.length>0 ? "Reviews" : <span style = {{color: "rgb(180,180,180)"}}>There are no reviews yet</span>} </p>
                {reviews && reviews.length>0 && <div className = {(isMobile)?styles.ratings:styles.desktopRatings}>
                    {reviews.map((review, index) => (
                    <RatedTask
                        key={index}
                        reviewer={review.reviewer} // Pass the reviewer information as a prop
                        stars={review.stars}
                        type={review.type}
                        date={review.date}
                        description={review.description}
                        impressions={review.impressions}
                    />
                    ))}
                </div>}
            </div>
        </div>
    </>
    )
}

export default Profile;