import styles from './Policies.module.css';
import React, {useEffect} from 'react';
import logo from '../../assets/images/LogoTwo.png';

const PrivacyPolicy = () => {
    const isMobile = window.targetPlatform == "mobile";

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto' // You can use 'auto' instead of 'smooth' for instant scrolling
        });
  }, []);

    return(
        <div className = {(isMobile)?styles.contentContainerMobile:styles.contentContainerDesktop}>

            <div className = {(isMobile)?styles.logoContainerMobile:styles.logoContainerDesktop} onClick={() => window.location.href = "https://www.taskare.com"}>
                <img src = {logo} className={styles.logo} alt = "Taskare Logo"/>
            </div>

            <p className={styles.categoryTextTitle}>Privacy Policy</p>

            <p className={styles.textTitle}>1. Overview</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>This Privacy Policy outlines how we collect, use, and protect the personal information of users of our website and mobile application.</p>

            <p className={styles.textTitle}>2. Information We Collect</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> Account Information: We collect your name, surname, phone number, email, and a profile photo when you create an account.</p>
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Task Information: Information related to tasks you create, including descriptions and location.</p>
            <p className={styles.textTitle}>3. How We Use Your Information</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> User Authentication: We use your account information for user authentication and to maintain your session between visits.</p>
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Task Processing: Your task information is used to facilitate the posting, application, and completion of tasks within the platform.</p>
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>c.</span> Communication: We use your contact information to facilitate communication between users who engage in tasks.</p>
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>d.</span> Service Fee: Information about your location may be used to calculate and apply varying service fees based on your location.</p>
            <p className={styles.textTitle}>4. Cookies and Tracking Technologies</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We use essential cookies for user authentication and session persistence. </p>

            <p className={styles.textTitle}>5. User Choices and Controls</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>Cookie Preferences: Users can manage non-essential cookie preferences through their browser settings. Essential cookies cannot be opted out of.</p>
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>b. Account Settings: Users can manage account settings, including profile information and task details, within the application.</p>
            <p className={styles.textTitle}>6. Data Security</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>

            <p className={styles.textTitle}>7. Third-Party Services</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We do not use third-party services for specific functionalities.</p>

            <p className={styles.textTitle}>8. Data Retention</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We retain your personal information for as long as necessary for the purposes outlined in this Privacy Policy or as required by law.</p>

            <p className={styles.textTitle}>9. Updates to Privacy Policy</p>

            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>We may update this Privacy Policy to reflect changes in our practices. Users will be notified of significant changes.</p>
    </div>
    )
}

export default PrivacyPolicy;