import React, { useState } from 'react';
import styles from './StarRating.module.css';
import halfStar from '../../../assets/images/halfstar.png';
import emptyStar from '../../../assets/images/EmptyStar.png';
import fullStar from '../../../assets/images/FullStar.png';

const StarRating = ({starRating, isReviewForm, onRatingChange}) => {

  const fixedStars = ()=>{

    const roundedRating = Math.round(starRating * 2) / 2;
    const hasHalfStar = roundedRating % 1 === 0.5; // Check if the roundedRating has a half star

    return (
      <>
        <div className={styles.starContainer}>
          {[1, 2, 3, 4, 5].map((star) => (
            <img
              className={styles.star}
              key={star}
              src={(star <= parseInt(roundedRating.toString()[0])) ?
                fullStar : (hasHalfStar && parseInt(roundedRating.toString()[0])+1==star)?
                halfStar:emptyStar}
              alt={`Star ${star}`}
            />
          ))}
        </div>
      </>
    );
  }

  //#region
  const ReviewFormStars = () => {
    const [hoverRating, setHoverRating] = useState(null); // Track hover state
    const [starsRating, setsStarRating] = useState(starRating); // Track selected stars
  
    const handleStarClick = (star) => {
      onRatingChange(star);
      setsStarRating(star);
    };
  
    return (
      <div className={styles.reviewStarContainer}>
        {[1, 2, 3, 4, 5].map((star) => {
          const isStarSelected = star <= starsRating;
          const isStarHoverable = hoverRating !== null && (!isStarSelected || hoverRating > starsRating);
          
          return (
            <img
              className={styles.reviewStar}
              key={star}
              src={(star <= (hoverRating || starsRating)) ? fullStar : emptyStar}
              alt={`Star ${star}`}
              onClick={() => handleStarClick(star)}
              onMouseEnter={() => isStarHoverable && setHoverRating(star)} // Track hover state
              onMouseLeave={() => setHoverRating(null)} // Clear hover state
            />
          );
        })}
      </div>
    );
  };

//#endregion


  return (
      <>
      {!isReviewForm ? fixedStars() : <ReviewFormStars />}
      </>
  );
};

export default StarRating;