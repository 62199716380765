import styles from "./MessagingSystem.module.css";
import React, {useRef, useState, useEffect, useLayoutEffect, useContext} from "react";
import exitButton from "../../../assets/images/XGrey.png";
import sampleProfile from "../../../assets/images/ProfileSample.png";
import goBackImg from "../../../assets/images/Arrow.png";
import arrowIMG from "../../../assets/images/arrowEnter.png";
import UIContext from "../../../Firebase Contexts/UIContext/UIContext";
import UserContext from "../../../Firebase Contexts/UserDataContext";
import { getUserRooms, getInitialConversation, sendMessageNotification } from "../../../Firebase";
import shortid from "shortid";

const MessagingSystemMobile = ()=>{

    const {sendNewMessage, receiverId, setReceiverId,
        roomId, setRoomId, profilePicturesId, profilePicturesExistenceChecked,
        setProfilePicturesExistenceChecked, setProfilePicturesId, roomsAlreadyFetched,
        rooms, setRooms, currentRoomIndex, setCurrentRoomIndex, preventFirstListenerOfRooms, thereIsSelectedDiscussion,
        setThereIsSelectedDiscussion, currentlySelectedProfileIndex, setCurrentlySelectedProfileIndex, unreadMessages,
        setUnreadMessages, deleteReadMessagesNotifications} = useContext(UIContext);
        
    const {user} = useContext(UserContext);
    
    const messagingSystemContainerRef = useRef(null);
    const currentTextRef = useRef(null);

    const currentScrollPosition = useRef(0);

    const [currentText, setCurrentText] = useState("");

    const discussionContainerRef = useRef(null);

    const handleTextInput = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setCurrentText(value);
    };

    useEffect(() => {
        const handleScroll = () => {
          if (discussionContainerRef.current) {
            currentScrollPosition.current = discussionContainerRef.current.scrollTop;
            // You can log or perform any action with the currentScrollPositionRef.current here
          }
        };
    
        if (discussionContainerRef.current) {
          discussionContainerRef.current.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          // Clean up the event listener when the component unmounts
          if (discussionContainerRef.current) {
            discussionContainerRef.current.removeEventListener('scroll', handleScroll);
          }
        };
    }, []);

    const handleMessage = async(roomId, senderId, receiverId, messageText) => {

        setCurrentText("");
        if(messageText!=""){
          const textId = shortid.generate();
          const newText = { id: textId, senderId: user.id, text: messageText };

          setRooms((prev) => {
              const updatedRooms = prev.map((room) => {
                if (room.roomId === roomId) {
                  const updatedConversations = Array.isArray(room.conversation)
                    ? [...room.conversation, newText]
                    : [newText];
            
                  // Sort conversations based on timestamp in descending order
                  updatedConversations.sort((a, b) => {
                      if (a && a.timestamp && a.timestamp.seconds && b && b.timestamp && b.timestamp.seconds) {
                        return a.timestamp.seconds - b.timestamp.seconds;
                      }
                      // Handle the case where either a or b doesn't have a valid timestamp
                      return 0;
                    });
                                      
                  return { ...room, conversation: updatedConversations };
                }
                return room;
              });
            
              // If the room with roomId doesn't exist, create a new room
              if (!updatedRooms.some((room) => room.roomId === roomId)) {
                updatedRooms.push({ roomId, conversation: [newText] });
              }
            
              // Sort rooms based on the latest message timestamp in descending order
              updatedRooms.sort((a, b) => {
                const latestMessageA = a.conversation[0]?.timestamp.seconds || 0;
                const latestMessageB = b.conversation[0]?.timestamp.seconds || 0;
                return latestMessageB - latestMessageA;
              });
            
              return updatedRooms;
            });
          
            setTimeout(() => {
              // discussionContainerRef.current.scrollTop = discussionContainerRef.current.scrollHeight;
              if(discussionContainerRef.current){
                discussionContainerRef.current.scrollTo({
                  top: discussionContainerRef.current.scrollHeight,
                  behavior: "smooth",
                });
              }
            }, 0);
            
          await sendNewMessage(roomId, senderId, receiverId, messageText, textId);
          await sendMessageNotification(user.id, receiverId, roomId);
        }

        currentTextRef.current.focus();
    }

    const fetchInitialRoomConversation = async (roomId) => {
      const isRoomIdMatch = rooms.some(room => room.roomId === roomId);

      if(!isRoomIdMatch){
        const conversation = await getInitialConversation(roomId);
        conversation.sort((a, b) => a.timestamp - b.timestamp);

        if(conversation){
            const updatedRooms = [...rooms, { roomId, conversation }]
            setRooms(updatedRooms);
        }

        setTimeout(() => {
          if(discussionContainerRef.current){
              discussionContainerRef.current.scrollTo({
              top: discussionContainerRef.current.scrollHeight,
              behavior: "instant",
            });
          }
        }, 1);

        // const messageNotificationsToDelete = unreadMessages.filter(obj => obj.roomId === roomId);
        // deleteReadMessagesNotifications(messageNotificationsToDelete);
      }
    }

    useEffect(()=>{
        if(rooms.length>0 && rooms.some(room => room.roomId === roomId)){
            setCurrentRoomIndex(rooms.findIndex((room) => room.roomId === roomId));
        }

        if(roomId){
          setTimeout(() => {
            if(discussionContainerRef.current){
              discussionContainerRef.current.scrollTo({
              top: discussionContainerRef.current.scrollHeight,
              behavior: "instant",
            });
          }
        }, 10);
      }
    },[roomId, rooms])

    useLayoutEffect(() => {
        if (discussionContainerRef.current) {
          // Scroll to the current scroll position
            discussionContainerRef.current.scrollTop = currentScrollPosition.current//discussionContainerRef.current.scrollHeight;
          // Set firstRender to false after scrolling
        }

        const messageNotificationsToDelete = unreadMessages.filter(obj => obj.roomId === roomId);
        deleteReadMessagesNotifications(messageNotificationsToDelete);
      }, [roomId]);

      useLayoutEffect(() => {
        if (currentRoomIndex.current) {
          // Scroll to the end of the container
            discussionContainerRef.current.scrollTop = discussionContainerRef.current.scrollHeight;
          // Set firstRender to false after scrolling
        }
      }, [currentRoomIndex]);

//#region Slide animation

    useEffect(()=>{
            messagingSystemContainerRef.current.classList.add(styles.slideRight);
    },[])

    const initializeComponentExit = ()=>{
      window.mobileHandleNavButtonsOnClick("Search")
      window.mobileSetSearchScrollPosition();
      window.setTypeShouldBeVisible(true);
      messagingSystemContainerRef.current.classList.add(styles.slideLeft);
    }
  
    const handleAnimEnd = (event)=>{
        if(event.animationName.includes("slideLeft")){
            window.setActiveComponent("Search");
            window.setPrevActiveComponent("Search");
        }else if(event.animationName.includes("slideRight")){
          if(messagingSystemContainerRef.current){
            setTimeout(() => {        
              messagingSystemContainerRef.current.classList.add(styles.containerAbsolute);
            }, 0);
          }
          window.setPrevActiveComponent("Messages");
          window.setTypeShouldBeVisible(false);
        } 
    }
  
    const handleAnimStart = (event) => {
      if(window.prevActiveComponent == "Tasks"){
        window.changeTasksIndex();
      } else if(window.prevActiveComponent == "Saved"){
        window.changeSavedIndex();
      } else if(window.prevActiveComponent == "Notifications"){
        window.changeNotificationsIndex();
      } else if(window.prevActiveComponent == "Profile"){
        window.changeProfileIndex();
      }else if(window.prevActiveComponent == "Applied Tasks"){
        window.changeAppliedTasksIndex();
      }else if(window.prevActiveComponent == "Account Settings"){
        window.changeAccountSettingsIndex();
      }
  
      if(event.animationName.includes("slideRight")){
        messagingSystemContainerRef.current.classList.add(styles.highContainerIndex);
      }else if(event.animationName.includes("slideLeft")){
        messagingSystemContainerRef.current.classList.remove(styles.containerAbsolute);
      }
    }
  
    const changeMessagesIndex = () => {
        messagingSystemContainerRef.current.classList.remove(styles.highContainerIndex);
        messagingSystemContainerRef.current.classList.remove(styles.containerAbsolute);
        messagingSystemContainerRef.current.classList.add(styles.lowContainerIndex);
    }
    window.changeMessagesIndex = changeMessagesIndex;

    //#endregion

    return(
        <div ref = {messagingSystemContainerRef} className = {styles.container} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
            <div style = {{height:"3em"}}/>
            <img src = {goBackImg} style = {{position: "fixed", width: "20px", height: "20px", marginTop:"1em", marginLeft:"1em", transform: "rotate(180deg)"}} onClick = {initializeComponentExit} alt = "Go back Arrow"/>
            {profilePicturesId.length > 0 && <div className={styles.profilesLine}/>}
            <div className = {styles.headerContainer}>
                {profilePicturesId.length > 0 && <div className = {styles.profilesContainerMobile}>
                {profilePicturesId.map((x, index) => (
                <div key = {index} style = {{position: "relative"}}>
                  <span key={index} className={currentlySelectedProfileIndex !== "" &&
                  currentlySelectedProfileIndex === index
                    ? `${styles.profileStyle} ${styles.testCircle}`
                    : styles.profileStyle}>
                    <img key={index}
                    alt = "User Profile Picture"
                    src={(x.vendorId)?(x.vendorProfilePicture!=null)?x.vendorProfilePicture:sampleProfile : (x.clientProfilePicture!=null)?x.clientProfilePicture:sampleProfile}
                    className = {
                      currentlySelectedProfileIndex !== "" &&
                      currentlySelectedProfileIndex === index
                        ? `${styles.profilePicture} ${styles.selectedProfile}`
                        : styles.profilePicture
                    }
                    onClick = {()=>{
                        preventFirstListenerOfRooms.current = [...preventFirstListenerOfRooms.current, {roomId: x.conversationId, firstRenderDone: true}]
                        setRoomId(x.conversationId);
                        setReceiverId((x.vendorId)? x.vendorId : x.clientId);
                        setThereIsSelectedDiscussion(true);
                        fetchInitialRoomConversation(x.conversationId);
                        setCurrentlySelectedProfileIndex(index);
                    }}
                    />
                  </span>
                    {x.unreadMessages>0 && <p key = {index} className= {styles.unreadMessages}>{x.unreadMessages}</p>}
                    </div>
                ))}
                </div>}
            </div>
            
            <div className={styles.messagingContainer}>
                {!thereIsSelectedDiscussion && profilePicturesExistenceChecked && <p className = {styles.noDiscussionMessageMobile}>{(profilePicturesId.length>0)?"Select a person to start a discussion":"You will need to connect with people to start a discussion"}</p>}
            </div>

            <div className={styles.discussionContainerMobile} ref = {discussionContainerRef}>
                {currentRoomIndex !== "" && rooms[currentRoomIndex].conversation.map((message, index) => (
                    <p
                        key={index}
                        className={
                          (user.id === message.senderId)?styles.userTextMobile : styles.interlocutorTextMobile}>
                        {message.text}
                    </p>
                ))}
            </div>

            {thereIsSelectedDiscussion && <div className={styles.inputContainerMobile}>
                <textarea className = {styles.textBarMobile}
                placeholder="Aa"
                onChange = {handleTextInput}
                value = {currentText}
                ref = {currentTextRef}
                // onInput={adjustTextareaHeight}
                style = {{
                    display: "block",
                    overflow: "hidden",
                    resize: "none",
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent default form submission
                        handleMessage(roomId, user.id, receiverId, currentText);
                    }
                }}
                />

                <img  src = {arrowIMG} className = {styles.sendMessageButton} onClick = {()=>handleMessage(roomId, user.id, receiverId, currentText)} alt = "Send Message Arrow"/>
            </div>}
        
            <div className = {styles.profileSeparatorLine}/>
        </div>
    )
}

export default MessagingSystemMobile;