import styles from "./CopiedShareListing.module.css";
import UIContext from "../../../Firebase Contexts/UIContext/UIContext";
import { useRef, useContext, useEffect } from "react";

const CopiedShareListing = ({isCopied, setIsCopied})=>{

    const {sharedListingData} = useContext(UIContext);
    const isMobile = window.targetPlatform == "mobile";

//#region Handle share
    const copiedMessageRef = useRef(null);

    useEffect(() => {
    if (isCopied) {
        // Set a timeout to hide the message after 3 seconds
        const timeoutId = setTimeout(() => {
        if(isMobile){
            copiedMessageRef.current.classList.remove(styles.fadeIn);
            copiedMessageRef.current.classList.add(styles.fadeOut);
        }else{
            setIsCopied(false);
        }
        }, 3000);

        // Clean up the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }
    }, [isCopied]);

    const handleAnimationEnd = (event) => {
        if(isMobile && event.animationName.includes("fadeOut")){
            copiedMessageRef.current.classList.remove(styles.fadeOut);
            setIsCopied(false);
        }
    }

    useEffect(()=>{
        // Generate the shareable link for the current listing
        let listingURL;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            listingURL = "localhost:3000/task/"+sharedListingData.listingId;
        } else {
            listingURL = "https://www.taskare.com/task/"+sharedListingData.listingId;
        }
    
        // Copy the listing URL to the clipboard
        navigator.clipboard.writeText(listingURL)
            .then(() => {
            setIsCopied(true)
            })
            .catch((error) => {
            console.error('Failed to copy link: ', error);
            });
    },[])
    //#endregion

    return(
        <>
        {isCopied && <div className={
    isMobile
      ? `${styles.mobileCopiedToClipboardMessageContainer} ${styles.fadeIn}`
      : styles.desktopCopiedToClipboardMessageContainer
  } ref = {copiedMessageRef} onAnimationEnd={handleAnimationEnd}>
            <p className = {styles.copiedToClipboardMessage}>Copied to clipboard</p>
        </div>}
        </>
    )
}

export default CopiedShareListing;