import styles from './Policies.module.css';
import React, {useEffect} from 'react';
import logo from '../../assets/images/LogoTwo.png';

const TermsOfService = () => {

    const isMobile = window.targetPlatform == "mobile";

    useEffect(() => {
            window.scrollTo({
              top: 0,
              behavior: 'auto' // You can use 'auto' instead of 'smooth' for instant scrolling
            });
      }, []);

    return(
        <div className = {(isMobile)?styles.contentContainerMobile:styles.contentContainerDesktop}>
            <div className = {(isMobile)?styles.logoContainerMobile:styles.logoContainerDesktop} onClick={() => window.location.href = "https://www.taskare.com"}>
              <img src = {logo} className={styles.logo} alt = "Taskare Logo"/>
            </div>

            <p className={styles.categoryTextTitle}>Terms of Service</p>
    
            <p className={styles.textTitle}>1. Acceptance of Terms</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>By using this website, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use the service.</p>
    
            <p className={styles.textTitle}>2. User Registration</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}} ><span style = {{fontWeight: "bold"}}>a.</span> Users must provide accurate and complete information during the registration process.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Users are responsible for maintaining the confidentiality of their account credentials.</p>
    
            <p className={styles.textTitle}>3. Profile Information</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> Users may upload a profile photo and provide personal information, including name, surname, phone number, and email.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Users agree to provide accurate and current information.</p>
    
            <p className={styles.textTitle}>4. Task Creation and Service Fee</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> Users can create tasks and request assistance from their local community.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> The website charges a service fee for each task, with the fee varying based on the location.</p>
    
            <p className={styles.textTitle}>5. Connecting with Task Applicants</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> Users can view and connect with individuals who apply to their task listings.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Communication between users is allowed through the website's messaging system.</p>
    
            <p className={styles.textTitle}>6. Task Completion and Reviews</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> Once a task is completed, users have the option to mark it as fulfilled.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Users can leave reviews for each other based on their interactions.</p>
    
            <p className={styles.textTitle}>7. Privacy and Data Security</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>The collection and use of personal information are governed by the Privacy Policy.</p>
    
            <p className={styles.textTitle}>8. Prohibited Activities</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> Users must not engage in any illegal, harmful, or abusive activities on the platform.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Users must not upload inappropriate content or violate the rights of others.</p>
    
            <p className={styles.textTitle}>9. Termination</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>The website reserves the right to suspend or terminate user accounts for violations of these terms.</p>
    
            <p className={styles.textTitle}>10. Changes to Terms</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}>The website reserves the right to update these terms at any time. Changes will be effective immediately upon posting.</p>
    
            <p className={styles.textTitle}>11. Limitation of Liability</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>a.</span> The website is not responsible for the quality or safety of completed tasks.</p>
    
            <p className={(isMobile)?styles.simpleTextMobile:styles.simpleTextDesktop} style = {{marginLeft:"0.5em"}}><span style = {{fontWeight: "bold"}}>b.</span> Users engage in the platform at their own risk.</p>
        </div>
      )
}

export default TermsOfService;