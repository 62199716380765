import styles from './SearchStatusMessage.module.css';
import { useRef, useEffect, useContext } from 'react';
import SearchContext from '../../../../../Firebase Contexts/SearchContext/SearchContext';
import ListingsContext from '../../../../../Firebase Contexts/Listings Context/ListingsContext';

const SearchStatusMessage = () => {
    const { wrongLocationName, suggestedLocationAlternative, preventMobileExecution
     } = useContext(SearchContext);

    const {differentSearchIsLoading, setDifferentSearchIsLoading} = useContext(ListingsContext);

    const isMobile = window.targetPlatform == "mobile";
    useEffect(() => {
    }, [wrongLocationName]);

    const searchForSuggestion = ()=>{
        //Located in SearchBar
        setDifferentSearchIsLoading(true)
        if(isMobile){preventMobileExecution.current = true}
        window.initializeNewSearch(false);
    }

    return (
        <>
            {/* {console.log(suggestedLocationAlternative)} */}
            {suggestedLocationAlternative!=null && suggestedLocationAlternative.length>0 && <p className={styles.alternativeRecommendation} style = {(isMobile)?{marginTop:"-2em", marginLeft:"0.5em"}:{marginTop:"-1em"}}
            >Are you looking for <span style={{ fontWeight: "400", color: "rgb(0,0,150)", cursor:"pointer" }} onClick = {searchForSuggestion}>"{suggestedLocationAlternative[0].description.split(',').slice(0, -1).join(',')}"</span>?</p>}

            <p className={styles.errorTitle} style = {(isMobile)?{marginTop:(suggestedLocationAlternative!=null && suggestedLocationAlternative.length>0)?"0em":"-2em", marginLeft:"1em", marginRight:"1em"}:{marginTop:(suggestedLocationAlternative!=null && suggestedLocationAlternative.length>0)?"0em":"-2em"}}
            >We couldn't find any location named <span style={{ fontWeight: "bold" }}>"{wrongLocationName}"</span>.</p>
            <p className={(!isMobile)?styles.errorText:styles.errorTextMobile}>-Check if the spelling of the location is correct.</p>
            <p className={(!isMobile)?styles.errorText:styles.errorTextMobile}>-Select a different country from the account settings.</p>
            <div className={styles.resolveSteps}>
            </div>
        </>
    );
}

export default SearchStatusMessage;