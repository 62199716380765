import React, {useState, useEffect, useRef, useLayoutEffect, useContext} from 'react';
import styles from './Create.module.css';
import LocationSelectionWindow from './LocationSelectionWindow/LocationSelectionWindow'
import x from '../../../assets/images/Arrow.png'
import calendarIMG from '../../../assets/images/Calendar.png'
import timeIMG from '../../../assets/images/time.png'
import UserContext from '../../../Firebase Contexts/UserDataContext';
import {createNewListing, saveEdittedListing, formatJavascriptDateToFirebaseDate} from "../../../Firebase"
import DeleteConfirmation from './DeleteConfirmation/DeleteConfirmation';
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
import ExitUnsavedChangesConfirmation from './ExitUnsavedChangesConfirmation/ExitUnsavedChangesConfirmation';
import EmptyFieldsMessage from './EmptyFieldsMessage/EmptyFieldsMessage';
import SearchContext from '../../../Firebase Contexts/SearchContext/SearchContext';
import Payment from './Payment/Payment';

const Create = ({listing, editTitle, editDescription, editLocation,
                editType, editDate, editTime ,editDuration, editPrice,
                isEdit, deleteListing, index}) => {

  const {user, userLocation, setUserLocation, userPreferredCountry} = useContext(UserContext);
  const {typesOfTasks} = useContext(SearchContext);
  const {mineListings, setMineListings} = useContext(ListingsContext);
  //Stores the current scroll position of the creation window
  const [creationCurrentScroll, setCreationCurrentScroll] = useState(0);
  const [step, setStep] = useState(1);

  const [deletePressed, setDeletePressed] = useState(false);
  //Empty fields state

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [typeOption, setTypeOption] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [price, setPrice] = useState("");

  const [shouldCreateContainerRender, setShouldCreateContainerRender] = useState(true);
  const [shouldSuccessContainerRender, setShouldSuccessContainerRender] = useState(false);

  const [locationData, setLocationData] = useState(null);

  // Creation values to be sent to database on submit

  const backgroundRef = useRef(null);
  useEffect(() => {
    setLocationData(isEdit? editLocation: null)
    setSelectedDate(isEdit? editDate : "")
    if (isEdit) {
      setTitle(editTitle);
      setDescription(editDescription);
      setLocation(editLocation.description);
      setTypeOption(editType);
      setSelectedTime(editTime);
      setHours(editDuration.hours);
      setMinutes(editDuration.minutes);
      setPrice(editPrice);
    }
  },[]);


  const isMobile = window.targetPlatform == "mobile"
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const containerRef = useRef(null);

  const shouldBeAbleToExit = useRef(true);

  //#region HandleInputChanges
  const handleTitleChange = (event) => {
    // Limit the input length
    const limit = 80;
    if (event.target.value.length <= limit) {
      setTitle(event.target.value);
    }

    if(description != "" && event.target.value!=""){
      setNextButtonIsEnabled(true);
    }else{
      setNextButtonIsEnabled(false);
    }

  };
  const handleDescriptionChange = (event) => {
    // Limit the input length
    const limit = 2000;
    if (event.target.value.length <= limit) {
      setDescription(event.target.value);
    }
    if(event.target.value != "" && title!=""){
      setNextButtonIsEnabled(true);
    }else{
      setNextButtonIsEnabled(false);
    }

  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setNextButtonIsEnabled(((hours != 0 || minutes != 0) && selectedTime!="")? true : false);
  }

  const handleTimeChange = (event)=>{
    setSelectedTime(event.target.value);
    setNextButtonIsEnabled(((hours != 0 || minutes != 0) && selectedDate!="")? true : false);
  }

  const submitLocationInput = (selectedLocation)=>{
    setLocation(selectedLocation.description);
    setLocationData(selectedLocation);
    setNextButtonIsEnabled(true);
  }
  
  const deleteLocationInput = ()=>{
    setNextButtonIsEnabled(false);
    setLocation("");
    setLocationData(null);
  }

const handleHoursChange = (event) => {
  const newValue = event.target.value;
  let onlyNumbersAndSymbols = newValue.replace(/[a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, ''); // Remove letters

  // If the first character is '0', replace the entire value with the new input
  if (onlyNumbersAndSymbols.length > 0 && onlyNumbersAndSymbols[0] === '0') {
    onlyNumbersAndSymbols = onlyNumbersAndSymbols.slice(1);
  }

  if (/^\d*$/.test(onlyNumbersAndSymbols) || onlyNumbersAndSymbols === '') {
    if (onlyNumbersAndSymbols !== "") {
      const newHoursValue = parseInt(onlyNumbersAndSymbols, 10);
      if (newHoursValue < 1001) {
        if ((newHoursValue !== 0 || minutes !== 0)) {
          setNextButtonIsEnabled((selectedDate != '' && selectedTime != "")?true:false);
          setHours(onlyNumbersAndSymbols);
        } else {
          setNextButtonIsEnabled(false);
          setHours(0);
        }
      } else {
        setNextButtonIsEnabled(false);
        setHours("");
      }
    } else {
      // If the input is empty, set the total value to '0'
      setNextButtonIsEnabled((selectedDate != '' && selectedTime != "" && (onlyNumbersAndSymbols != "" || minutes != 0))?true:false);
      setHours(0);
    }
  }
};

  
  
  const handleMinutesChange = (event) => {
    const newValue = event.target.value;
    let onlyNumbersAndSymbols = newValue.replace(/[a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, ''); // Remove letters
  
    // If the first character is '0', replace it with the new input
    if (onlyNumbersAndSymbols.length > 0 && onlyNumbersAndSymbols[0] === '0') {
      onlyNumbersAndSymbols = onlyNumbersAndSymbols.slice(1);
    }

    if (/^\d*$/.test(onlyNumbersAndSymbols) || onlyNumbersAndSymbols === '') {
      if (onlyNumbersAndSymbols !== "") {
        const newMinutesValue = parseInt(onlyNumbersAndSymbols, 10);
        if (newMinutesValue < 60) {
          if((newMinutesValue != 0 || hours != 0)){
            setNextButtonIsEnabled((selectedDate != '' && selectedTime !="")?true:false);
            setMinutes(onlyNumbersAndSymbols);
          }else{            
            setNextButtonIsEnabled(false);
            setMinutes(0);
          }
        }
      } else {
        // If the input is empty, set the total value to '0'
        setNextButtonIsEnabled((selectedDate != '' && selectedTime != "" && (onlyNumbersAndSymbols != "" || hours != 0))?true:false);
        setMinutes(0);
      }
    }
  };
  
  const handlePriceChange = (event) => {
    const newValue = event.target.value;
    const onlyNumbersAndSymbols = newValue.replace(/[a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, ''); // Remove letters
  
    if (event.target.value > 0) {
      if(onlyNumbersAndSymbols[0] != 0){
        setPrice(onlyNumbersAndSymbols);
    
        if (onlyNumbersAndSymbols >= 0) {
          setNextButtonIsEnabled(true);
        } else {
          setNextButtonIsEnabled(false);
        }
      }
    } else {
      setNextButtonIsEnabled(false);
      setPrice("");
    }
  };

  const getCurrencySign = (isoCode) => {
    const currencySignMap = {
      US: '$',
      CA: '$',
      GB: '£',
      GR: '€',
      AU: '$',
      NZ: '$',
      DK: 'kr',
      SE: 'kr',
      NO: 'kr',
      ZA: 'R',
      // Add more country codes and corresponding signs as needed
    };
  
    return currencySignMap[isoCode] || '';
  };

  const getCurrency = (isoCode) => {
    const currencyMap = {
      US: "usd",
      CA: "cad",
      GB: "gbp",
      IE: "eur",
      AU: "aud",
      NZ: "nzd",
      DK: "dkk",
      SE: "sek",
      NO: "nok",
      GR: "eur",
      ZA: "zar",
      // Add more country codes and corresponding currency codes as needed
    };
    return currencyMap[isoCode] || null;
  };

  const currency = getCurrency(userPreferredCountry.preferredCountryCode);
  
  const currencySign = getCurrencySign(userPreferredCountry.preferredCountryCode);

//#endregion
  
const adjustTextareaHeight = (textareaRef) => {
  textareaRef.target.style.height = "0em";
  textareaRef.target.style.height = `${textareaRef.target.scrollHeight}px`;
  if (textareaRef.target.scrollHeight > 250) {
    textareaRef.target.style.height = "250px";
  }
};

//#region Date
const formatFirebaseDateToJSDate = () => {
  const seconds = editDate.seconds;
  const nanoseconds = editDate.nanoseconds;

  // Convert nanoseconds to milliseconds (divide by 1,000,000)
  const milliseconds = nanoseconds / 1000000;

  // Create a JavaScript Date object
  const date = new Date(seconds * 1000 + milliseconds);

  return date; // Return a Date object
};

const previousDateRef = useRef(null);

const currentDate = isEdit ? formatFirebaseDateToJSDate() : new Date();
const today = new Date();
const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

//Handle if the selected date is within a certain date range
const handleDateInput = (event) => {
  const selectedDate = new Date(event.target.value);
  // setSelectedDate(selectedDate);
  nextYear.setHours(0, 0, 0, 0);
  //if the selected date is earlier or after the limit, put it 
  if (selectedDate < today || selectedDate > nextYear) {
    event.target.value = previousDateRef.today || today.toISOString().split('T')[0];
  } else {
    previousDateRef.current = event.target.value;
  }
};

const formattedNextYear = nextYear.toISOString().split('T')[0];
const dateInputRef = useRef(null);

useEffect(() => {
  if (dateInputRef.current) {
    dateInputRef.current.min = today;
    dateInputRef.current.max = formattedNextYear;
    dateInputRef.current.value = previousDateRef.current || currentDate.toISOString().split('T')[0];
  }
}, []);
//#endregion

//#region Time
  useEffect(() => {
    if(step == 4){
      // Function to format the current time as 'HH:mm'
      const getCurrentTime = () => {
        if(selectedTime==""){
          const now = new Date();
          const hours = now.getHours().toString().padStart(2, '0');
          const minutes = now.getMinutes().toString().padStart(2, '0');
          const formattedTime = `${hours}:${minutes}`;
          return formattedTime;
        }else{
          return selectedTime;
        }
      }
      // Set the initial time for the time input
      const timeInput = document.querySelector('#timeInput');
      
      timeInput.value = isEdit ? editTime : getCurrentTime();

      if(isEdit){
        timeInput.value = editTime;
      } else {
        timeInput.value = getCurrentTime();
        setSelectedTime(timeInput.value);
      }
    }
  }, [step]);
//#endregion

//#region Submit
const submitShouldBePressed = useRef(true);

const publishListing =  async() => {
  if(submitShouldBePressed.current){
    //Change the values to false to block further submission clicks
    submitShouldBePressed.current = false;
    shouldBeAbleToExit.current = false;
    //turn date to its final form upon submission
    const formattedDate = formatJavascriptDateToFirebaseDate(selectedDate);
    const duration = {hours: hours, minutes: minutes}

    //If not in edit mode
    if(!isEdit){
      const listingData = await createNewListing(user.id, userLocation, setUserLocation, title, description, locationData, typeOption, formattedDate, selectedTime, duration, price, currency);
      if(listingData){ 
        if(!isMobile){
        setShouldCreateContainerRender(false);
        setShouldSuccessContainerRender(true);
        } else {
          initializeExitProcessMobile()
        }

        const updatedListings = [...mineListings, listingData];
        setMineListings(updatedListings);

      }else{//if submission failed
        submitShouldBePressed.current = true;
      }
      shouldBeAbleToExit.current = true;
    } else if(isEdit){
      const listingData = await saveEdittedListing(listing, title, description, locationData, typeOption, formattedDate, selectedTime, duration, price);
      // if submission was successful
      if(listingData){
        updateEditedMineListing(listingData, listing.id);
        shouldBeAbleToExit.current = true;
        exitCreateWindow();
      }else{//if submission failed
        submitShouldBePressed.current = true;
        shouldBeAbleToExit.current = true;
      }
    }
  }
};
//#endregion

//#region Exit Message
const [showDiscardChangesMessage, setShowDiscardChangesMessage] = useState(false);

const checkAccidentalClick = () => {
  if(!isEdit){
    if(title!=""||description!=""||location!=""|| typeOption !=""|| hours !=""|| minutes !=""|| price !=""){
      //show exit confirmation message
      return false;
    }else{
      //Exit
      return true;
    }
  }else if(isEdit){//if in edit mode, check if prev ,editHours
    if(title!=editTitle || description!=editDescription || location!=editLocation.description || typeOption !=editType || hours != editDuration.hours|| minutes != editDuration.minutes || price !=editPrice){
      //show exit confirmation message
      return false;
    }else{
      //Exit
      return true;
    }
  }
}
//#endregion
const updateEditedMineListing = (listingData, listingId) => {
  // Find the index of the listing with matching id
  const indexToUpdate = mineListings.findIndex((listing) => listing.id === listingId);

  if (indexToUpdate !== -1) {
    // If a matching listing is found by id, update the specific fields
    const updatedListing = { ...mineListings[indexToUpdate], ...listingData };

    // Create a copy of the mineListings array to avoid modifying the original array
    const updatedMineListings = [...mineListings];
    
    // Replace the listing at the found index with the updated listing
    updatedMineListings[indexToUpdate] = updatedListing;

    // Now, updatedMineListings contains the updated listing at the specified index
    setMineListings(updatedMineListings);
  }
};

const createWindowRef = useRef(null);
//#region Animations and window handling
const initializeExitProcessMobile = ()=>{
  createWindowRef.current.classList.remove(styles.slideUp);
  createWindowRef.current.classList.add(styles.slideDown);
}

const handleSlideDownAnimationEnd = (event)=>{
  if(event.animationName.includes("slideDown")){
    setShouldSuccessContainerRender(true);
    setShouldCreateContainerRender(false)
  }else if(event.animationName.includes("slideUp")){
    setExitButtonIsVisible(true);
    if(isEdit){
    setDeleteListingEnabled(true);
    setNextButtonIsEnabled(true)
    }
  }

}
//Called in deleteConfirmation when the deletion is completed
const shouldDeleteFromMineListing = useRef(false);
const exitEditDeletedListing = ()=>{
  shouldDeleteFromMineListing.current = true;
  backgroundRef.current.classList.remove(styles.fadeIn);
  backgroundRef.current.classList.add(styles.fadeOut);
  if(isMobile){
    createWindowRef.current.classList.remove(styles.slideUp);
    createWindowRef.current.classList.add(styles.slideDown);
    }else{
      setShouldCreateContainerRender(false);
    }
}
window.exitEditDeletedListing = exitEditDeletedListing

const exitCreateWindow = ()=>{
  if(!deletePressed && shouldBeAbleToExit.current){
    if(showDiscardChangesMessage == true){setShowDiscardChangesMessage(false);}
    backgroundRef.current.classList.remove(styles.fadeIn);
    backgroundRef.current.classList.add(styles.fadeOut);
    setNextButtonIsEnabled(false);
    setDeleteListingEnabled(false);
    setExitButtonIsVisible(false);
      if(createWindowRef){
        createWindowRef.current.classList.remove(styles.slideUp);
        createWindowRef.current.classList.add(styles.slideDown);
        }
    }
}

const handleCreateExit = (event) => {
  if(event.animationName.includes("fadeOut")){
    //if in edit mode, just quit
    if (isEdit){
      stopComponentRender();
    }
    //else if is not edit and is not submitted
    else if(!isEdit && !shouldSuccessContainerRender){
      stopComponentRender();
    }

    if(shouldDeleteFromMineListing.current){
      deleteListing(index);
    }
  }
};

const stopComponentRender = () => {

  window.shouldScroll(true);

  if(isEdit){
    //stops the component from rendering
    window.setEditForm(false);
  } else {
    window.setCreationForm(false);
  }
}

const exitCreateWindowFromMessages = ()=>{
    backgroundRef.current.classList.remove(styles.fadeIn);
    backgroundRef.current.classList.add(styles.fadeOut);
    setShouldSuccessContainerRender(false);
}

const initializeExitFromBackground = ()=>{
  //Check if the success message is active
  if(!shouldSuccessContainerRender){
    //Check if values on the form are different
    if(checkAccidentalClick()){
      exitCreateWindow();
    }else{
      setShowDiscardChangesMessage(true)
    }
  }
}
//#endregion

//#region Handle Creation step process

const [nextButtonIsEnabled, setNextButtonIsEnabled] = useState(false);
const [deleteListingEnabled, setDeleteListingEnabled] = useState(false);
const [exitButtonIsVisible, setExitButtonIsVisible] = useState(false);

const handleNextStep = (add)=>{

  if(add == true && step<6){
    if((step + 1 == 2 && title!="" && description!="") || (step + 1 == 3 && locationData!=null && location != "") || 
      (step + 1 == 4 && (hours!="" || minutes!="") && selectedDate != "" && selectedTime != "") || (step + 1 == 5 && price >= 0) || step + 1 == 6){
      setNextButtonIsEnabled(true);
    } else {
      setNextButtonIsEnabled(false);
    }
    setStep(step + 1);
  }else if(add == false && step>1){
    setNextButtonIsEnabled(true);
    setStep(step - 1);
  }
}

//#region Step 1
const countryRefs = useRef([]);


const storeCreationPosition = ()=>{
  setCreationCurrentScroll(createWindowRef.current.scrollTop)
  createWindowRef.current.scrollTop = 0
}

const suggestedTypes = typesOfTasks;
const [selectedType, setSelectedType] = useState((!isEdit)?"":editType);

const handleSelectedTypeChange = (type) => {
  setNextButtonIsEnabled(true);
  setTypeOption(type);
  setSelectedType(type);
};

//#endregion

//#endregion

return (
  <>
    <div onClick = {initializeExitFromBackground}className = {`${styles.background} ${styles.fadeIn}`} ref = {backgroundRef} onAnimationEnd={handleCreateExit}/>
    {shouldCreateContainerRender && <div className = {styles.createContainer} ref = {containerRef}>
    <div className = {isMobile ? `${styles.mobileContainer} ${styles.slideUp}`:`${styles.mobileContainer} ${styles.slideUp}`}
    ref = {createWindowRef} onAnimationEnd={handleSlideDownAnimationEnd}>
      {<img src = {x} className = {styles.exitButton} onClick = { ()=> (step == 1)? initializeExitFromBackground() : handleNextStep(false) } alt = "Exit"/>}
      {<div style = {{padding: "1em"}}>
          <p className= {styles.stepStyle}>{step} of 6 steps</p>
          {/*---------- Type ----------*/}
          {step == 1 && <span className = {styles.type}>
            <p className = {styles.textTitle}>I'm looking for ...</p>
          
          <div className = {styles.typesContainer}>
            {/* Display tasks for the category */}
            {suggestedTypes.map((category, index) => (
              <div key={index}>
                {/* Display category name */}
                <p className={styles.categoryName}>{category.name}</p>

                {/* Display tasks for the category */}
                {category.tasks.map((task, taskIndex) => (
                  <p
                    key={taskIndex}
                    ref={(ref) => (countryRefs.current[taskIndex] = ref)}
                    className={task != selectedType ? styles.suggestedTypesOptions : styles.selectedTypeOption}
                    onClick={() => handleSelectedTypeChange(task)}
                  >
                    {task}
                  </p>
                ))}
              </div>
            ))}
          </div>
          </span>}

          {/*---------- Title ----------*/}
          {step == 2 && <div className = {styles.stepTwoContainer}>
            <span>
              <p className = {styles.textTitle}>Name and describe your listing</p>
              <p className = {styles.textDescription} style = {{marginTop: "2em"}}>Create a title that speaks to the right people</p>
              <span className = {styles.titleContainer}>
                <textarea
                  id="titleTextArea"
                  value={title}
                  onChange={handleTitleChange}
                  onInput={adjustTextareaHeight}
                  className={styles.titleTextarea}
                  placeholder="e.g. Seeking a chef for a special dinner event."
                  ref={titleRef}
                  style={{ height: '2.5em' }} // Corrected the syntax for applying inline style
                ></textarea>
              </span>
          </span>
            {/*---------- Description ----------*/}
            {<span>
            <p className = {styles.textDescription} style = {{marginTop: "2em"}}>Be descriptive, clear descriptions lead to better results</p>
            <span className = {styles.titleContainer}>
              <textarea
                id="descriptionTextArea"
                value = {description}
                onChange={handleDescriptionChange}
                onInput={adjustTextareaHeight}
                className={styles.titleTextarea}
                placeholder="I need a creative chef for a memorable dining experience..."
                ref = {descriptionRef}
                style={{ height: '8em' }} // Corrected the syntax for applying inline style
              />
              </span>
            </span>}
          </div>}
        {/*---------- Location ----------*/}
        {step == 3 && <span className = {styles.location}>
          <p className = {styles.textTitle}>Let's pick your desired location</p>
          <p className = {styles.textDescription} style = {{marginTop: "2em"}}>Where?</p>

          {/* <p className ={styles.selectedTypeButton}>{(location!="")?location.split(',')[0].trim():"Select a location"}</p> */}

            <LocationSelectionWindow
              creationLocation = {location}
              submitLocationInput = {submitLocationInput} 
              deleteLocationInput = {deleteLocationInput} 
              isMobile = {isMobile}
            />

          <p className = {(isMobile)?styles.textNote:styles.textNoteDesktop}>*If the location you're looking for is not showing up, try changing your account's location to the country you're interested in.</p>

        </span>}
        {/*---------- Date ----------*/}
        {step == 4 && <div className = {styles.stepTwoContainer}>
        <div>
          <p className = {styles.textTitle}>Set your desired date</p>
            <span>
              <p className = {styles.textDescription} style = {{marginTop: "2em"}}>Date</p>
              <span className= {styles.priceInputContainer}>
                <img src = {calendarIMG} style = {{width: "18px", height: "18px"}}/>
                <input
                ref = {dateInputRef}
                type="date"
                value = {selectedDate}
                onChange = {handleDateChange}
                onInput = {handleDateInput}
                className={styles.date}
                placeholder="Add a date.." />
              </span>

            </span>
          {/*---------- Time ----------*/}
            <span>
            <p className = {styles.textDescription} style = {{marginTop: "2em"}}>Time</p>
              <span className= {styles.priceInputContainer}>
              <img src = {timeIMG} style = {{width: "18px", height: "18px"}}/>
                <input
                id="timeInput"
                onChange={handleTimeChange}
                type="time"
                className={styles.time}
                placeholder="Add time.." 
                />
              </span>
            </span>
        </div>
        {/*---------- Hours ----------*/}
        {<span>
          <p className = {styles.textDescription} style = {{marginTop:"2em"}}>How long will the work approximately last?</p>
          <span className= {styles.priceInputContainer}>
            <p className = {styles.hoursStyle}>Hours:</p>
            <input
            value = {hours}
            onChange = {handleHoursChange}
            type="text"
            className={styles.hours}
            placeholder="e.g. 2"
            inputMode = "numeric"
            />

            <p className = {styles.hoursStyle}>Minutes:</p>
            <input
            value = {minutes}
            onChange = {handleMinutesChange}
            type="text"
            className={styles.hours}
            placeholder="e.g. 30"
            inputMode = "numeric"
            />

          </span>
        </span>}
        </div>}
        {/*---------- Price ----------*/}
        {step == 5 && <div className = {styles.stepTwoContainer}>
        <span className = {styles.addPrice}>
        <p className = {styles.textTitle}>Set your price</p>
        <p className = {styles.textDescription} style = {{marginTop: "2em"}}>How much would you like to pay?</p>
        <span className = {styles.priceInputContainer}>
          <p className = {styles.priceSymbol}>{currencySign}</p>
          <input
          value = {price}
          onChange = {handlePriceChange}
          type="text"
          className={styles.price}
          placeholder="e.g. 20"
          inputMode = "numeric"
          />
          </span>
        </span></div>}

        {/*----------Payment Process----------*/}
        {step == 6 && !isEdit && <span>
        <p className = {styles.textTitle}  style = {{marginTop:"1.5em"}}>Proceed to payment</p>
        <p className = {styles.textDescription} style = {{marginTop: "1.5em"}}>The payment process is secured by Stripe. We do not collect your card details in any way.</p>
        <Payment price = {price} publishListing = {publishListing}/>
        </span>}

        {step == 6 && isEdit && <span>
          <p className = {styles.textTitle}  style = {{marginTop:"1.5em"}}>Save changes?</p>
          <p className = {styles.textDescription} style = {{marginTop: "1.5em"}}>Your changes will be immediately visible. </p>
          <button className = {(isMobile)? styles.publishMobileButton : styles.publishButtonDesktop} onClick={publishListing}>{(!isEdit)?"Publish listing":"Yes, save my changes"}</button>
        </span>
        }

      </div>}

      {/*----------Next - Submit Button----------*/}
      {nextButtonIsEnabled && <div className={(isMobile)?styles.nextButtonContainer:styles.nextButtonContainerDesktop}>
        {step != 6 && <button className = {(isMobile)? styles.nextButton : (step!=6)? styles.nextButtonDesktop:styles.publishButtonDesktop} onClick={()=>(step < 6)?handleNextStep(true):publishListing()}>Next</button>}
      </div>}
      {/*----------Delete Button----------*/}
      {isEdit && deleteListingEnabled && (<div className={styles.deleteContainer}>
        <button onClick={()=>setDeletePressed(true)}>Delete listing</button>
      </div>)}
      {/*----------Exit Button----------*/}
      { !isEdit && exitButtonIsVisible && (<div className={styles.deleteContainer}>
        <button onClick={initializeExitFromBackground}>Exit</button>
      </div>)}

    </div>
    </div>}

    {/* Successful Dekstop Message */}
    {shouldSuccessContainerRender && !isMobile && !isEdit && <div className = {styles.postSuccessMessageContainer}>
    <p className = {styles.submittedPostTitle}>Great job, your request is up!</p>
      <p className = {styles.submittedPostMessage}>You will be notified when people apply. In the mean time
      you can check or update your post in the <span className = {styles.taskText}>Tasks</span> tab.</p>
    <button onClick = {exitCreateWindowFromMessages} className = {styles.gotItButton}>Got it</button>
    </div>}

    {/* Successful Mobile Message */}
    {shouldSuccessContainerRender && isMobile && !isEdit && <div className = {styles.mobilePostSuccessMessageContainer}>
      <p className = {styles.mobileSubmittedPostTitle}>Great job, your request is up!</p>
      <p className = {styles.mobileSubmittedPostMessage}>You will be notified when people apply. In the mean time
      you can check or update your post in the <span className = {styles.taskText}>Tasks</span> tab.</p>
    <button onClick = {exitCreateWindowFromMessages} className = {styles.mobileGotItButton}>Got it</button>
    </div>}

    {showDiscardChangesMessage && <ExitUnsavedChangesConfirmation exitCreateWindow = {exitCreateWindow} showDiscardChangesMessage = {showDiscardChangesMessage} setShowDiscardChangesMessage = {setShowDiscardChangesMessage}/>}
    {deletePressed && <DeleteConfirmation listing = {listing} setDeletePressed = {setDeletePressed}/>}
  </>

);
};

export default Create;
