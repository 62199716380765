import styles from './AccountSettingsWindow.module.css';
import React, {useRef, useState, useEffect, useContext} from "react";
import profileIconButtonUnselected from '../../../assets/images/ProfileSelectedOutline.png';
import sampleProfile from '../../../assets/images/ProfileSample.png';
import profileIconButtonSelected from '../../../assets/images/ProfileSelected.png';
import tasksAppliedUnselected from '../../../assets/images/tasksAppliedUnselected.png';
import tasksAppliedSelected from '../../../assets/images/tasksAppliedSelected.png';
import taskUnselected from '../../../assets/images/TaskUnselected.png';
import taskSelected from '../../../assets/images/TaskSelected.png';
import bookmarkUnselected from '../../../assets/images/BookmarkOutline.png';
import bookmarkSelected from '../../../assets/images/BookmarkSelected.png';
import arrow from '../../../assets/images/Arrow.png'
import moon from '../../../assets/images/darkThemeIcon.png'
import sun from '../../../assets/images/sun.png'

const AccountSettingsWindow = ({mobileHandleNavButtonsOnClick, changeAccountSettingsWindowClass,
    OpenCreationForm, user})=>{

        
//#region Button actions

    const openSelectedTaskCategory = (chosenTask, openFromNotification)=>{
        if(window.selectedTask.current==""){
            window.selectedTask.current = chosenTask;
            mobileHandleNavButtonsOnClick('Tasks');
        }else{
            window.selectedTask.current = chosenTask;
            window.showSelectedTasks(window.selectedTask.current)
        }
        if(openFromNotification == true){}else{changeAccountSettingsWindowClass();}
        swapImage('Tasks');
    }
    window.openSelectedTaskCategory = openSelectedTaskCategory;
    const openSaved = ()=>{
        mobileHandleNavButtonsOnClick('Saved');
        changeAccountSettingsWindowClass();
        swapImage('Saved');
    }    
    const openProfile = ()=>{
        mobileHandleNavButtonsOnClick('Profile');
        changeAccountSettingsWindowClass();
        swapImage('Profile');
    }
    const openTasksApplied = ()=>{
        mobileHandleNavButtonsOnClick('Applied Tasks');
        changeAccountSettingsWindowClass();
        swapImage('Tasks applied');
    }
    const openSettings = ()=>{
        mobileHandleNavButtonsOnClick('Account Settings');
        changeAccountSettingsWindowClass();
    }
//#endregion
//#region Tasks Animation

const tasksArrow = useRef(null);

const [tasksIsExpanded, setTasksIsExpanded] = useState(false);

 const openTasks = ()=>{
        setTasksIsExpanded(!tasksIsExpanded);
    
 }
//#endregion

//#region Account settings window animation
    const accountSettingsWindowRef = useRef(null);
    const backgroundRef = useRef(null);

    const handleAccountWindowAnimation = ()=>{
        window.shouldScroll(true);
        if(accountSettingsWindowRef.current.classList.contains(styles.slideRight)){
            accountSettingsWindowRef.current.classList.remove(styles.slideRight);
            accountSettingsWindowRef.current.classList.add(styles.slideLeft);

            backgroundRef.current.classList.remove(styles.fadeIn);
            backgroundRef.current.classList.add(styles.fadeOut);
        }else{
            window.shouldScroll(false);
            accountSettingsWindowRef.current.classList.remove(styles.hidden);
            accountSettingsWindowRef.current.classList.remove(styles.slideLeft);
            accountSettingsWindowRef.current.classList.add(styles.slideRight);

            backgroundRef.current.classList.remove(styles.hidden);
            backgroundRef.current.classList.remove(styles.fadeOut);
            backgroundRef.current.classList.add(styles.fadeIn);
        }
    }
    window.handleAccountWindowAnimation = handleAccountWindowAnimation;

    const handleBackgroundAndAccountWindowVisibility = (event)=>{
        if(event.animationName.includes("fadeOut")){
            accountSettingsWindowRef.current.classList.add(styles.hidden);
            backgroundRef.current.classList.add(styles.hidden);
        }
    }
//#endregion

//#region Image buttons swap
const activeButtonRef = useRef("")
const prevButtonName = useRef("")
const swapImage = (currentButton)=> {
    
    activeButtonRef.current = currentButton;
    if(activeButtonRef.current != prevButtonName.current){
      if(activeButtonRef.current === "Tasks"){
        document.getElementById('tasksIcon').src = taskSelected;
      }else if(activeButtonRef.current === "Saved"){
        document.getElementById('savedIcon').src = bookmarkSelected;
      }else if(activeButtonRef.current === "Profile"){
        document.getElementById('profileIcon').src = profileIconButtonSelected;
      }else if(activeButtonRef.current === "Tasks applied"){
        document.getElementById('tasksAppliedIcon').src = tasksAppliedSelected;
      }

      if(prevButtonName.current === "Tasks"){
        document.getElementById('tasksIcon').src = taskUnselected;
      }else if(prevButtonName.current === "Saved"){
        document.getElementById('savedIcon').src = bookmarkUnselected;
      }else if(prevButtonName.current === "Profile"){
        document.getElementById('profileIcon').src = profileIconButtonUnselected;
      }else if(prevButtonName.current === "Tasks applied"){
        document.getElementById('tasksAppliedIcon').src = tasksAppliedUnselected;
      }
      prevButtonName.current = activeButtonRef.current;
    }
}
window.swapImage = swapImage;
//#endregion

//#region Handle theme
const handleTheme = ()=>{
    window.setIsLightTheme(!window.isLightTheme);
  }
//#endregion

return(
    <>
        <div ref = {backgroundRef} className= {`${styles.background} ${styles.hidden}`} onClick = {handleAccountWindowAnimation} onAnimationEnd={handleBackgroundAndAccountWindowVisibility}/>
        <div className = {styles.accountSettingsWindow} ref = {accountSettingsWindowRef}>
            {/* <img src = {window.isLightTheme ? sun : moon} alt="Theme Icon" className = {styles.themeIcon} onClick = {handleTheme}/> */}
            <img className = {styles.userProfile} src = {(user.userProfilePhoto!=null)?user.userProfilePhoto:sampleProfile} alt="User Profile Picture"/>
            <p className = {styles.userNameSurname}>{user.userName} {user.userSurname}</p>
            <></>
            <span style = {{display:"flex",  flexDirection: "column"}}>
                <span style = {{display: "flex", alignItems:"center"}} onClick = {openTasks}>
                    <img id ="tasksIcon" src = {taskUnselected} className = {styles.icon} alt="Unselected task icon"/>
                    <p className = {styles.buttonsText} style = {{paddingRight: "0em"}}>Tasks</p>
                    <img src = {arrow} ref = {tasksArrow}
                    className={`${styles.arrowIcon} ${tasksIsExpanded ? styles.rotatedArrow : styles.notRotatedArrow}`}
                    alt="Tasks Arrow"
                    />
                </span>
                <span className = {!tasksIsExpanded ? styles.taskCategoriesCollapsed:styles.taskCategoriesExpanded}>
                    <p className = {styles.category} onClick = {()=>openSelectedTaskCategory("mine")}>Mine</p>
                    <p className = {styles.category} onClick = {()=>openSelectedTaskCategory("assigned")}>Assigned</p>
                    <p className = {styles.category} onClick = {()=>openSelectedTaskCategory("history")}>History</p>
                </span>
            </span>

            <span className = {styles.buttonContainer} onClick = {openSaved}>
                <img id = "savedIcon" src = {bookmarkUnselected} className = {styles.icon} alt="Saved Icon"/>
                <p className = {styles.buttonsText}>Saved</p>
            </span>
            
            <span className = {styles.buttonContainer} onClick = {openProfile}>
                <img id = "profileIcon" src = {profileIconButtonUnselected} className = {styles.icon} alt="Profile Icon"/>
                <p className = {styles.buttonsText}>Profile</p>
            </span>

            <span className = {styles.buttonContainer} onClick = {openTasksApplied}>
            <img id = "tasksAppliedIcon" src = {tasksAppliedUnselected} className = {styles.icon} alt="Tasks Applied Icon"/>
                <p className = {styles.buttonsText}>Tasks applied</p>
            </span>

            <div className = {styles.line}/>

            <span className = {styles.buttonContainer}>
                {/* <img id = "accountSettingsIcon" src = {profileIconButtonUnselected} className = {styles.icon}/> */}
                <p className = {styles.accountSettingsText} onClick = {openSettings}>Settings and account</p>
            </span>

            <p className = {styles.createNewTask} onClick = {()=>OpenCreationForm()}>Create new task</p>
        </div>
    </>
    )
}

export default AccountSettingsWindow;