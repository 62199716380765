import React, { useRef, useState, useEffect, useContext } from 'react';
import ListingsContext from './ListingsContext';
import {getAppliedSavedBannedListings} from '../../Firebase';
import UserContext from '../../Firebase Contexts/UserDataContext';
import SearchContext from '../SearchContext/SearchContext';

function ListingsContextProvider({ children }) {

  const {user, userPersistenceChecked} = useContext(UserContext);
  const {searchedListings, setSearchedListings, fetchedListings, setFetchedListings} = useContext(SearchContext);

  const [mineListings, setMineListings] = useState([]);

  const [assignedTasks, setAssignedTasks] = useState([]);

  const [selectedMineTask, setSelectedMineTask] = useState(null);
  const [selectedAssignedTask, setSelectedAssignedTask] = useState(null);


  const [historyListings, setHistoryListings] = useState([]);
  const [isLoadingForTasks, setIsLoadingForTasks] = useState(false);
  const [noLocationAvailable, setNoLocationAvailable] = useState(false);
  const [differentSearchIsLoading, setDifferentSearchIsLoading] = useState(false);

  const lastDocumentListingFetched = useRef(null);

  //#region Saved Listings
  //"Saved" listings will be saved here
  //tempSavedListingsLoaded will act as flag for Search listings
  const savedListingsLoaded = useRef(false)

  const [savedListings, setSavedListings] = useState([]);

  const removeSavedListing = (listingId) => {
    setSavedListings((prevListings) => {
      return prevListings.filter((listing) => listing.id !== listingId);
    });

    // window.setNewListings(listingId);
  }

  window.removeSavedListing = removeSavedListing;

  const [appliedListings, setAppliedListings] = useState([]);
  
  const deleteAppliedListing = (listingId) => {
    setAppliedListings((prevListings) => {
      return prevListings.filter((listing) => listing.id !== listingId);
    });

    setFetchedListings((prevListings) => {
      return prevListings.filter((listing) => listing.id !== listingId);
    });

    setSearchedListings((prevListings) => {
      return prevListings.filter((listing) => listing.id !== listingId);
    });
  };
  

  window.deleteAppliedListing = deleteAppliedListing;


  const [bannedListings, setBannedListings] = useState([]);

    const fetchSavedListings = async () => {
        try {
          if(user.id && userPersistenceChecked){
            const listings = await getAppliedSavedBannedListings(user.id);
            setSavedListings(listings.saved);
            setAppliedListings(listings.applied);
            setBannedListings(listings.banned);

            savedListingsLoaded.current = true;
          }
        } catch (error) {
          // Handle errors if necessary
          console.error('Error fetching listings:', error);
        }
      }

    useEffect(() => {
        if(!savedListingsLoaded.current){
          fetchSavedListings();
        }
      }, []);
  //#endregion
  

  return (
    <ListingsContext.Provider value={{mineListings, setMineListings,
      assignedTasks, setAssignedTasks, historyListings, setHistoryListings, savedListingsLoaded, savedListings, setSavedListings,
      appliedListings, setAppliedListings, bannedListings, setBannedListings, lastDocumentListingFetched,
      isLoadingForTasks, setIsLoadingForTasks, differentSearchIsLoading, setDifferentSearchIsLoading,
      noLocationAvailable, setNoLocationAvailable, selectedMineTask, setSelectedMineTask,
      selectedAssignedTask, setSelectedAssignedTask}}>
      {children}
    </ListingsContext.Provider>
  );
}

export default ListingsContextProvider;
