import styles from './ImageCropTest.module.css';
import { useState, useCallback, useRef, useContext } from 'react';
import Cropper from 'react-easy-crop';
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';

const SignUpImageCrop = ({ image }) => {
  
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const imageRef = image;

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
  
    if (croppedAreaPixels) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
  
      // Use the image prop directly here
      const imageObj = new Image();
      imageObj.src = image; // Assuming image is a base64 data URL or a direct image URL
  
      // Wait for the image to load before drawing
      await new Promise((resolve) => {
        imageObj.onload = resolve;
      });
  
      ctx.drawImage(
        imageObj,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
  
      const dataUrl = canvas.toDataURL('image/jpeg');
      setCroppedImage(dataUrl);
    }
  }, [image, setCroppedImage]);

    const saveCroppedImage = ()=>{
        window.saveCroppedImage(croppedImage);
    }

  return (
    <div className={styles.container}>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={1 / 1}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        cropShape='round'
        onCropComplete={onCropComplete}
        innerRef={imageRef}
        />
            <p className={styles.desktopCropButton} onClick = {saveCroppedImage} >Crop</p>
            <p className={styles.cancelCropButton} onClick = {()=>window.setIsImageCropperVisible(false)}>Cancel</p>
    </div>
  );
};

export default SignUpImageCrop;