import styles from './AppliedTasks.module.css'
import {useRef, useState, useEffect, useContext} from 'react';
import { getAppliedListings, deleteAppliedFlagFromUser,
  applyListing, addApplicantToListing, deleteApplicantFromListing } from '../../../Firebase';
import goBackImg from '../../../assets/images/Arrow.png'
import UserContext from '../../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
import SearchContext from '../../../Firebase Contexts/SearchContext/SearchContext';

const AppliedTasks = ()=>{

  const {user} = useContext(UserContext);
    const {appliedListings, setAppliedListings} = useContext(ListingsContext);
    const {setSearchedListings, setFetchedListings} = useContext(SearchContext);

    const [listings, setListings] = useState([]);

    const isMobile = window.targetPlatform == "mobile";

    const appliedTasksTabRef = useRef(null);
    
    const [loadingEnded, setLoadingEnded] = useState(false);

    const applyButtonIsLocked = useRef(false);


    useEffect(() => {
      if (appliedListings.length) {
        const listingsWithTitles = appliedListings.filter(obj => obj.hasOwnProperty('title'));
        const listingsWithoutTitle = appliedListings.filter(obj => !obj.hasOwnProperty('title'));
        //if the listings contain not-yet-fetched listings, then fetch those
        if (listingsWithoutTitle.length !== 0) {
          fetchAppliedListings(listingsWithoutTitle, listingsWithTitles);
        }else{
          setListings(appliedListings);
          setLoadingEnded(true);
        }
      }else{
        setLoadingEnded(true);
      }
    }, []);
  
    const fetchAppliedListings = async (listingsWithoutTitle, listingsWithTitles) => {
      const resultsWithoutTitle = await getAppliedListings(listingsWithoutTitle);
      const mergedResults = [...resultsWithoutTitle, ...listingsWithTitles];
      setAppliedListings(mergedResults);
      setListings(mergedResults);
      setLoadingEnded(true);
    }
    //#region Animation process

    useEffect(()=>{
        if(isMobile){
            appliedTasksTabRef.current.classList.add(styles.slideRight);
        }
      },[])
  
      const initializeComponentExit = ()=>{
        window.mobileHandleNavButtonsOnClick("Search")
        window.mobileSetSearchScrollPosition();
        window.setTypeShouldBeVisible(true);
        appliedTasksTabRef.current.classList.add(styles.slideLeft);
      }
    
      const handleAnimEnd = (event)=>{
          if(event.animationName.includes("slideLeft")){
              window.setActiveComponent("Search");
              window.setPrevActiveComponent("Search");
          } else if(event.animationName.includes("slideRight")){
            if(appliedTasksTabRef.current){
              setTimeout(() => {        
                appliedTasksTabRef.current.classList.add(styles.containerAbsolute);
              }, 0);
            }
            window.setPrevActiveComponent("Applied Tasks");
            window.setTypeShouldBeVisible(false);
          } 
      }
  
      const handleAnimStart = (event) => {
        if(window.prevActiveComponent == "Tasks"){
          window.changeTasksIndex();
        } else if(window.prevActiveComponent == "Saved"){
          window.changeSavedIndex();
        }else if(window.prevActiveComponent == "Notifications"){
          window.changeNotificationsIndex();
        }else if(window.prevActiveComponent == "Profile"){
          window.changeProfileIndex();
        }else if(window.prevActiveComponent == "Account Settings"){
          window.changeAccountSettingsIndex();
        }else if(window.prevActiveComponent == "Messages"){
          window.changeMessagesIndex();
        }
          
        if(event.animationName.includes("slideRight")){
            appliedTasksTabRef.current.classList.add(styles.highContainerIndex);
        }else if(event.animationName.includes("slideLeft")){
          appliedTasksTabRef.current.classList.remove(styles.containerAbsolute);
        }
      }
  
      const changeAppliedTasksIndex = () => {
        appliedTasksTabRef.current.classList.remove(styles.highContainerIndex);
        appliedTasksTabRef.current.classList.remove(styles.containerAbsolute);
        appliedTasksTabRef.current.classList.add(styles.lowContainerIndex);
      }
      window.changeAppliedTasksIndex = changeAppliedTasksIndex;
  
      //#endregion
  
      useEffect(()=>{
        if(!isMobile){
            window.hideSearch();
        }
    },[])

    const deleteApplication = async (listingId)=>{
      if(!applyButtonIsLocked.current){
        applyButtonIsLocked.current = true;
        await deleteApplicantFromListing(listingId, user.id);
        const result = await deleteAppliedFlagFromUser(listingId, user.id);
        if(result){
          setAppliedListings((prev) => prev.filter(item => item.id !== listingId));
          setSearchedListings((prev) =>
          prev.map((item) =>
            item.id === listingId && item.hasApplied === true
              ? { ...item, hasApplied: false } : item
          )
        );
        setFetchedListings((prev) =>
          prev.map((item) =>
            item.id === listingId && item.hasApplied === true
              ? { ...item, hasApplied: false } : item
          )
        );
        setListings((prev) =>
          prev.map((item) =>
            item.id === listingId && item.hasApplied === true
              ? { ...item, hasApplied: false } : item
          )
        );
        applyButtonIsLocked.current = false;
        };
      }
    }

    const applyToListing = async (listingUid, listingId, listingTitle) => {
      if(!applyButtonIsLocked.current){
        await addApplicantToListing(listingId, user.id);
        const result = await applyListing(user.id, user.profilePhoto, listingUid, listingId, listingTitle);
        if(result){
          setAppliedListings((prev) => [...prev, { id: listingId }]);
          
          setSearchedListings((prev) =>
            prev.map((item) =>
              item.id === listingId && item.hasApplied === false
                ? { ...item, hasApplied: true } : item
            )
          );
          
          setFetchedListings((prev) =>
            prev.map((item) =>
              item.id === listingId && item.hasApplied === false
                ? { ...item, hasApplied: true } : item
            )
          );
        setListings((prev) =>
          prev.map((item) =>
            item.id === listingId && item.hasApplied === false
              ? { ...item, hasApplied: true } : item
          )
        );
        applyButtonIsLocked.current = false;
      }
      }
    }

    return(
        <div ref = {appliedTasksTabRef} className = {isMobile? styles.container : styles.desktopContainer} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
          {isMobile && <div style = {{height:"3em"}}/>}
          {isMobile && <img src = {goBackImg} style = {{width: "20px", marginTop:"1em", marginLeft:"1em", transform: "rotate(180deg)"}} onClick = {initializeComponentExit} alt = "Back Arrow"/> }
          {!isMobile && <p className = {styles.componentTitle}>My applications</p>}
          {loadingEnded && listings.length != 0 && listings.map((x, index) => (
            <div className={styles.applicationContainer} key={index}>
                <p className={styles.listingTitle}>{x.title}</p>
                <p className={styles.applicationButton} onClick = {()=>(x.hasApplied)?deleteApplication(x.id):applyToListing(x.uid, x.id, x.title)}>{x.hasApplied?"Cancel":"Apply"}</p>
            </div>
          ))}
          {loadingEnded && listings.length == 0 && <p className = {styles.noAppliedTasksMessage}>You haven't apply to any task yet</p>}           
        </div>
    )
}
export default AppliedTasks;