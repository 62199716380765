import UIContext from '../../../Firebase Contexts/UIContext/UIContext';
import styles from './CountryCodes.module.css';
import {useState, useEffect, useRef, useContext} from 'react'

const CountryCodes = ()=>{

    const [countryCodesIsHidden, setCountryCodesIsHidden] = useState(true);
    window.setCountryCodesIsHidden = setCountryCodesIsHidden;

    const containerRef = useRef(null);

    const countryCodes = [
        {name: "United States", code: 1, isoCode: "US"},
        {name: "Canada", code: 1, isoCode: "CA"},
        {name: "United Kingdom", code: 44, isoCode: "GB"},
        {name: "Ireland", code: +353, isoCode: "IE"},
        {name: "Australia", code: 61, isoCode: "AU"},
        {name: "New Zealand", code: 64, isoCode: "NZ"},
        {name: "Denmark", code: 45, isoCode: "DK"},
        {name: "Sweden", code: 46, isoCode: "SE"},
        {name: "Norway", code: 47, isoCode: "NO"},
        {name: "Greece", code: 30, isoCode: "GR"},
        {name: "South Africa", code: +27, isoCode: "ZA"},
    ]
    

    let initialClick = false;
    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                if(initialClick){
                    initialClick = false;
                    setCountryCodesIsHidden(true);
                } else {initialClick=true}
            }
        }

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    });

    const handleItemOnClick = (index)=>{
        window.phoneNumberCountryCode.current.innerText = "+"+ countryCodes[index].code;
        window.phoneNumberCountryIsoCode.current = countryCodes[index].isoCode;
        window.phoneNumberCountry.current = countryCodes[index].name;
        setCountryCodesIsHidden(true);
    }

    return (
        <div>
            {!countryCodesIsHidden && <div className = {styles.background}></div>}
            {!countryCodesIsHidden && (
                <div className = {styles.container}>
                    <div className={styles.countryCodesContainer} ref={containerRef}>
                        {countryCodes.map((countryInfo, index) => (
                            <div key={index} className={styles.countryCodeItem}
                            onClick={() => handleItemOnClick(index)}>
                                <p className = {styles.items}>
                                    +{countryInfo.code} {countryInfo.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default CountryCodes;