import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import styles from './LocationSelectionWindow.module.css';
import x from '../../../../assets/images/x.png';
import UserContext from '../../../../Firebase Contexts/UserDataContext';
import UIContext from '../../../../Firebase Contexts/UIContext/UIContext';
import locationIMG from '../../../../assets/images/location.png';

const LocationSelectionWindow = ({ creationLocation, submitLocationInput, deleteLocationInput, isMobile}) => {
  const [suggestedLocations, setSuggestedLocations] = useState([]);
  const selectedSuggestionRefs = useRef([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(null);
  const [thereAreSuggestions, setThereAreSuggestions] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState(null);

  const [location, setLocation] = useState((creationLocation)?creationLocation.split(',')[0].trim():'');
  const [selectedLocationInfo, setSelectedLocationInfo] = useState(null);
  const [fetchedPredictions, setFetchedPredictions] = useState([]);
  const [inputIsInFocus, setInputIsInFocus] = useState(true);
  const locationInputRef = useRef(null);

  const {userPreferredCountry} = useContext(UserContext);
  const {newlySelectedCountryIsoCode} = useContext(UIContext);

  useEffect(() => {
    if (location !== '') {
      setThereAreSuggestions(true);
    } else {
      setThereAreSuggestions(false);
    }
  }, [location]);

  const handleLocationChange = (event) => {
    setSelectedSuggestionIndex(null);
    deleteLocationInput();
    event.preventDefault();
    const value = event.target.value;
    const onlyLetters = value.replace(/[^a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, '');

    if (onlyLetters || value === '') {
      setLocation(value);

      if (autocompleteService && value) {
        // Fetch location predictions based on user input
        autocompleteService.getPlacePredictions(
          {
            input: value,
            types: ["(cities)"],
            componentRestrictions: { country: (newlySelectedCountryIsoCode!="")?newlySelectedCountryIsoCode:userPreferredCountry.preferredCountryCode },
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setSuggestedLocations(predictions.map((prediction) => prediction));
            } else {
              // console.log("Error fetching predictions:", status);
            }
          }
        );
      }
      
    }
  };

  useEffect(() => {
    // Initialize Google Places Autocomplete service
    setAutocompleteService(new window.google.maps.places.AutocompleteService());

    if (locationInputRef.current) {
      locationInputRef.current.focus(); // Focus on the input element
    }
  }, []);

  useEffect(() => {
    selectedSuggestionRefs.current = new Array(suggestedLocations.length).fill(null);
  }, [suggestedLocations]);

  const handleArrowKey = useCallback((event) => {
    if (suggestedLocations.length === 0) return;

    if (event.key === 'ArrowUp') {
      // Move selection up
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex === null ? suggestedLocations.length - 1 : Math.max(0, prevIndex - 1)
      );
    } else if (event.key === 'ArrowDown') {
      // Move selection down
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex === null
          ? 0
          : Math.min(suggestedLocations.length - 1, prevIndex + 1)
      );
    }

  }, [suggestedLocations]);

  useEffect(() => {
    if (suggestedLocations.length === 0) return;

    const handleLocationSuggestionChange = () => {
      const newValue = suggestedLocations[selectedSuggestionIndex].description.split(',')[0].trim();
      setLocation(newValue);

      // Get the DOM element from the ref
      const inputElement = selectedSuggestionRefs.current[selectedSuggestionIndex];
    
      if (inputElement && inputElement instanceof HTMLInputElement) {
        // Check if it's an input element
        inputElement.setSelectionRange(newValue.length, newValue.length);
        inputElement.focus();
      }
    };
    

    if (selectedSuggestionIndex !== null) {
      handleLocationSuggestionChange();
    }
  }, [selectedSuggestionIndex, suggestedLocations]);

  useEffect(() => {
    if (suggestedLocations.length === 0) {
      setSelectedSuggestionIndex(null);
    }
  }, [suggestedLocations]);

  const handleOnEnterKey = () => {
    if (location && suggestedLocations.length > 0) {
      // Check if the location matches a description from suggestedLocations
      const matchingDescription = suggestedLocations.find((prediction) => {
        const predictionFirstWord = prediction.description.split(',')[0].trim().toLowerCase().replace(/\s/g, '');
        const locationWithoutSpaces = location.toLowerCase().replace(/\s/g, '');
        
        return predictionFirstWord === locationWithoutSpaces;
      });
      
  
      if (matchingDescription) {
        // Extract the desired properties from matchingDescription
        const { description, place_id } = matchingDescription;
  
        // Create a new object with the desired properties
        const selectedLocation = {
          description,
          place_id,
        };
  
        // Pass the selectedLocation object to submitLocationInput
        setLocation(selectedLocation.description.split(',')[0].trim());
        submitLocationInput(selectedLocation);
      } else {
        // Handle the case where the location doesn't match any suggestion
        // console.log("Location doesn't match any suggestion.");
      }
    }
  };

  const inputContainer = useRef(false);
  const optionPressed = useRef(false);

  const test = (description)=>{
    optionPressed.current = true;
    setLocation(description);
    setInputIsInFocus(false);
  }

  useEffect(()=>{
    if(optionPressed.current){
      optionPressed.current = false;
      handleOnEnterKey();
    }
  },[location])
  
  const handleClearButton = (event)=>{
    setLocation("");
    deleteLocationInput();
    locationInputRef.current.focus();
}

const addHighlightedBorder = () => {
  inputContainer.current.classList.remove(styles.unselectedInputLocation);
  inputContainer.current.classList.add(styles.selectedInputLocation);
  setInputIsInFocus(true);
};

const removeHighlightedBorder = () => {
  inputContainer.current.classList.remove(styles.selectedInputLocation);
  inputContainer.current.classList.add(styles.unselectedInputLocation);
};

  return (
    <>
      <div className = {styles.container}>
        <div className = {`${styles.inputLocation} ${styles.selectedInputLocation}`} ref = {inputContainer}>
        <img src = {locationIMG} style = {{width: "18px", height: "18px", marginLeft: "0.5em"}} alt = "Location"/>
          <input
            ref = {locationInputRef}
            value={location}
            onChange={handleLocationChange}
            className={styles.locationInput}
            placeholder='Select a location..'
            onFocus={addHighlightedBorder}
            onBlur={removeHighlightedBorder}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault(); // Prevent default form submission
                handleOnEnterKey(event);
                locationInputRef.current.blur();
                setInputIsInFocus(false);
                setSelectedSuggestionIndex(null);
              } else if (
                event.key === 'ArrowUp' ||
                event.key === 'ArrowDown'
              ) {
                event.preventDefault(); // Prevent scrolling
                handleArrowKey(event);
              }
            }}
          />
          {location !="" &&
          <img onClick = {handleClearButton}
            src = {x}
            className = {styles.clearButton}
          />}
        </div>
      </div>
      {thereAreSuggestions && inputIsInFocus &&  (
        <div
          className={
            isMobile
              ? styles.mobileOptionsContainer
              : styles.desktopOptionsContainer
          }
        >
          {suggestedLocations.map((prediction, index) => (
            <div className = {(selectedSuggestionIndex != index && !isMobile)?styles.suggestedLocations:(isMobile)?styles.suggestedLocationsMobile:`${styles.suggestedLocations} ${styles.selectedOption}`}>
              <img src = {locationIMG} style = {{width: "18px", height: "18px"}}/>
              <p
                key={index}
                ref={(el) => (selectedSuggestionRefs.current[index] = el)}
                className= {styles.option}
                onClick={() => {
                  test(prediction.description.split(',')[0].trim())
                }}
              >
                {prediction.description.split(',')[0].trim()}
              </p>
            </div>
          ))}

        </div>
      )}

      <div className={styles.submitContainer}>
      </div>
    </>
  );
};

export default LocationSelectionWindow;
