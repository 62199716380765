import React, { useState, useRef, useEffect } from 'react';
import styles from './OtherProfiles.module.css';
import Profile from './Profile';
import x from '../../../assets/images/x.png'
const OtherProfiles = ({isCandidatesVisible, profileOpenedFromCandidate,
    candidatesShouldBeAbleToClose, listingId, exitCandidates, exitCandidatesMobile, listingTitle})=>{
    
    const isMobile = window.targetPlatform == "mobile";
    const [showOtherProfiles, setShowOtherProfiles] = useState(false);

    const [isBackgroundActive, setIsBackgroundActive] = useState(false);
    const backgroundRef = useRef(null);
    const desktopContainerRef = useRef(null)
    const mobileContainerRef = useRef(null)

    const isConnectWindowEnabled = useRef(false)
    window.isConnectWindowEnabled = isConnectWindowEnabled.current

    const checkIfConnectionWindowIsEnabled = (isEnabled)=>{
        isConnectWindowEnabled.current = isEnabled;
        window.isConnectWindowEnabled = isEnabled;
    }

    const applicantConfirmationPressed = useRef(false);

    const handleApplicantConfirmation = ()=>{
        applicantConfirmationPressed.current = true;
    }

    const candidateProfilePhoto = useRef(null);
    const candidateName = useRef(null);
    const candidateLastName = useRef(null);
    const candidateStars = useRef(null);
    const candidateTopsTasks = useRef(null);
    const candidateReviews = useRef(null);

    const candidateUid = useRef(null);

    const secondaryDesktopContainerRef = useRef(null);

    const showAbandonOption = useRef(null);
    const isVendorMode = useRef(null);


    const showProfile = (profilePhoto, name, lastName, stars, topTasks, reviews, applicant, abandonOption, isVendor)=>{
        candidateProfilePhoto.current = profilePhoto;
        candidateName.current = name;
        candidateLastName.current = lastName;
        candidateStars.current = stars;
        candidateTopsTasks.current = topTasks;
        candidateReviews.current = reviews;
        candidateUid.current = applicant;
        showAbandonOption.current = abandonOption;
        isVendorMode.current = isVendor;
        setShowOtherProfiles(true);
        window.shouldScroll(false);
        setIsBackgroundActive(true);
    }
    window.showProfile = showProfile;


//#region On Exit
let blockInitialExitClick = false;

const exitOtherProfiles = ()=>{
    if(blockInitialExitClick==false){
        //If
        if(!isConnectWindowEnabled.current || applicantConfirmationPressed.current){
            if(!isMobile){
                setShowOtherProfiles(false);
            }
            if(profileOpenedFromCandidate){
                setIsBackgroundActive(false);
            } else{
                backgroundRef.current.classList.remove(styles.fadeIn);
                backgroundRef.current.classList.add(styles.fadeOut);

            }
            if(isMobile){
                mobileContainerRef.current.classList.remove(styles.slideUp);
                mobileContainerRef.current.classList.add(styles.slideDown);
            }
            window.shouldScroll(true);
        }
    } else{
        blockInitialExitClick = false
    }
    if(profileOpenedFromCandidate){
        candidatesShouldBeAbleToClose();
    }
}

window.exitOtherProfiles = exitOtherProfiles;
    
//#endregion

//#region Desktop
const desktopVersion = ()=>{
    return(
        <div className={styles.createContainer} ref = {desktopContainerRef}>
            <div className= {styles.desktopContainer} ref = {secondaryDesktopContainerRef}>
                <Profile
                isCandidatesVisible = {isCandidatesVisible}
                checkIfConnectionWindowIsEnabled = {checkIfConnectionWindowIsEnabled}
                candidateProfilePhoto = {candidateProfilePhoto.current}
                candidateName = {candidateName.current}
                candidateLastName = {candidateLastName.current}
                candidateRating = {candidateStars.current}
                candidateTopTasks = {candidateTopsTasks.current}
                candidateReviews = {candidateReviews.current}
                listingId = {listingId}
                candidateId = {candidateUid.current}
                exitOtherProfiles = {exitOtherProfiles}
                exitCandidates = {exitCandidates}
                handleApplicantConfirmation = {handleApplicantConfirmation}
                listingTitle = {listingTitle}
                showAbandonOption = {showAbandonOption.current}
                isVendor = {isVendorMode.current}
                />
            </div>
        </div>
    )
}
//#endregion

//#region mobile
    const handleMobileAnimationEnd = (event) => {
        if(event.animationName.includes("slideDown")){
            setShowOtherProfiles(false)
            setShowOtherProfiles(false);
        }
      };
    
    const mobileVersion = ()=>{
        window.shouldScroll(false);

        return(
            <>
                <div className = {`${styles.mobileContainer} ${styles.slideUp}`} ref = {mobileContainerRef}
                    onAnimationEnd={handleMobileAnimationEnd}>
                    <img src = {x} className = {styles.exitButton} onClick = {exitOtherProfiles} alt = "Exit"/>
                    <Profile
                    isCandidatesVisible = {isCandidatesVisible}
                    checkIfConnectionWindowIsEnabled = {checkIfConnectionWindowIsEnabled}
                    candidateProfilePhoto = {candidateProfilePhoto.current}
                    candidateName = {candidateName.current}
                    candidateLastName = {candidateLastName.current}
                    candidateRating = {candidateStars.current}
                    candidateTopTasks = {candidateTopsTasks.current}
                    candidateReviews = {candidateReviews.current}
                    listingId = {listingId}
                    candidateId = {candidateUid.current}
                    exitOtherProfiles = {exitOtherProfiles}
                    exitCandidatesMobile = {exitCandidatesMobile}
                    handleApplicantConfirmation = {handleApplicantConfirmation}
                    listingTitle = {listingTitle}
                    showAbandonOption = {showAbandonOption.current}
                    isVendor = {isVendorMode.current}
                    />
                </div>
            </>
        )
    }
//#endregion
    

const handleBackgroundAnimationEnd = (event) => {
    if(event.animationName.includes("fadeOut")){
        setIsBackgroundActive(false);
    }
  };

return(
    <>
        {isBackgroundActive && <div className = {(profileOpenedFromCandidate)?styles.background:`${styles.background} ${styles.fadeIn}`} onClick = {exitOtherProfiles}
        onAnimationEnd={handleBackgroundAnimationEnd} ref={backgroundRef}/>}
        {showOtherProfiles && (isMobile ? mobileVersion() : desktopVersion())}
    </>
    )
}

export default OtherProfiles;