import {React, useEffect, useRef,useLayoutEffect, useState, useContext} from 'react';
import styles from './MainContent.module.css';
import Header from '../Header/Header';
import Profile from "../Core Components/Profile/Profile";
import AccountSettings from "../Core Components/AccountSettings/AccountSettings";
import Tasks from "../Core Components/Tasks/Tasks";
import Create from "../Core Components/Create/Create"
import Search from "../Core Components/Search/Search/Search";
import Saved from '../Core Components/Saved/Saved/Saved'
import Notifications from "../Core Components/Notifications/Notifications";
import UserContext from '../../Firebase Contexts/UserDataContext';
import {getUserById ,getUserReviews } from "../../Firebase";
import ListingsContextProvider from '../../Firebase Contexts/Listings Context/ListingsContextProvider';
import OtherProfiles from '../Core Components/Profile/OtherProfiles';
import UIContext from "../../Firebase Contexts/UIContext/UIContext"
import Candidates from '../Tasks/Mine/Candidates';
import UpdateStatus from '../Tasks/Assigned/UpdateStatus/UpdateStatus';
import AbandonTask from '../Tasks/Assigned/AbandonTask/AbandonTask';
import ReviewForm from '../Tasks/History/ReviewForm/ReviewForm';
import CopiedShareListing from '../Tasks/Mine/CopiedShareListing';
import SearchAndLocationComponent from '../Core Components/Search/SearchBar/SearchAndLocationComponent/SearchAndLocationComponent';
import AppliedTasks from '../Core Components/AppliedTasks/AppliedTasks';
import MessagingSystemDesktop from '../Core Components/MessagingSystem/MessagingSystemDesktop';
import MessagingSystemMobile from '../Core Components/MessagingSystem/MessagingSystemMobile';
import ReportUser from '../Tasks/History/ReportUser/ReportUser';
import AlternativeLocations from '../Core Components/Search/Search/AlternativeLocations/AlternativeLocations';
import ImageCrop from '../Core Components/ImageCrop/ImageCrop';
import x from '.././../assets/images/XBlack.png'

//Will return user reviews on the user's profile
const loadUserReviews = async (userId) => {
  try {
      const reviewsDocs = await getUserReviews(userId); // Wait for the promise to resolve
  
      return reviewsDocs; // Return the array of review objects
    } catch (error) {
      console.error(error);
      return []; // Return an empty array if there's an error
    }
  }
//Will return user stars on the user's profile
const loadUserData = async (userId) => {
  const currentUser = await getUserById(userId);
  // console.log(currentUser.stars)
  return currentUser;
}

const MainContent = ()=>{
  const {user, userPersistenceChecked} = useContext(UserContext);

  const [activeComponent, setActiveComponent] = useState("Search");
  const [prevActiveComponent, setPrevActiveComponent] = useState("Search");
  
  window.activeComponent = activeComponent;
  window.setActiveComponent = setActiveComponent;

  window.prevActiveComponent = prevActiveComponent;
  window.setPrevActiveComponent = setPrevActiveComponent;
  
  const [creationForm, setCreationForm] = useState(false);
  window.creationForm = creationForm;
  window.setCreationForm = setCreationForm;
  
  const isMobile = window.targetPlatform == "mobile";
  const shouldProfileReRender = useRef(true);
  //#region Get user reviews
  const {reviews, setReviews, userProfileInfo, setUserProfileInfo, userProfileLoaded} = useContext(UIContext);

  const selectedTask = useRef("");
  window.selectedTask = selectedTask;
  //Used to track the width of the client
  const filterWidth = useRef("");
  window.filterWidth = filterWidth;
  // Enables and disables the EditForm component
  const [editForm, setEditForm] = useState(false);
  window.setEditForm = setEditForm;

  const [openCandidates, setOpenCandidates] = useState(false);
  window.setOpenCandidates = setOpenCandidates;
  
  const [openUpdateTaskStatus, setUpdateTaskStatus] = useState(false);
  window.setUpdateTaskStatus = setUpdateTaskStatus;
  const [openAbandonTask, setOpenAbandonTask] = useState(false);
  window.setOpenAbandonTask = setOpenAbandonTask;
  
  const [openReviewForm, setOpenReviewForm] = useState(false);
  window.setOpenReviewForm = setOpenReviewForm;
  
  const [openReportForm, setOpenReportForm] = useState(false);
  window.setOpenReportForm = setOpenReportForm;

  const [enableSearchOrLocationInput, setEnableSearchOrLocationInput] = useState(false);
  window.setEnableSearchOrLocationInput = setEnableSearchOrLocationInput;

  const [showAlternativeLocations, setShowAlternativeLocations] = useState(false);
  window.setShowAlternativeLocations = setShowAlternativeLocations;

  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
  window.setIsImageCropperVisible = setIsImageCropperVisible;
  window.isImageCropperVisible = isImageCropperVisible;


  useEffect(() => {
    if(activeComponent == "Profile" && shouldProfileReRender.current && !userProfileLoaded.current){
      shouldProfileReRender.current = false;
      userProfileLoaded.current = true;
      // Call the test function when the component mounts
      loadUserReviews(user.id)
      .then((reviewsData) => {
          setReviews(reviewsData); // Store reviews data in state
      })
      .catch((error) => {
          console.error('Error:', error);
      });

      loadUserData(user.id)
      .then((info) => {
        setUserProfileInfo(info); // Store reviews data in state
      })
      .catch((error) => {
          console.error('Error:', error);
      });
    }else if (activeComponent != "Profile"){
      shouldProfileReRender.current=true;
    }
  });

  //#endregion

  //#region Edit creation  
  const EditForm = ()=>{

    const {editListingData} = useContext(UIContext);

    return(
      <>
        {/* {console.log(editListingData)} */}
        <Create
        //Pass listing edited values
        listing = {editListingData.listing}
        editTitle = {editListingData.editTitle}
        editDescription = {editListingData.editDescription}
        editLocation = {editListingData.editLocation}
        editType = {editListingData.editType}
        editDate = {editListingData.editDate}
        editTime = {editListingData.editTime}
        editDuration = {editListingData.editDuration}
        editPrice = {editListingData.editPrice}
        isEdit = {editListingData.isEdit}
        
        //Pass listing deletion data
        index = {editListingData.index}
        deleteListing = {editListingData.deleteListing}
      />
    </>
    )
  }
  //#endregion

  const ListingCandidates = ()=>{

    const {candidatesData} = useContext(UIContext);
    
    return(
      <>
      <Candidates usersIds = {candidatesData.usersIds} listingId = {candidatesData.listingId} listingTitle = {candidatesData.listingTitle}/>
      </>
    )
  }

  const UpdateTaskStatus = ()=>{

    const {completeTaskData} = useContext(UIContext);

    return(
    <>
      <UpdateStatus
      disableStatusWindow = {completeTaskData.disableStatusWindow}
      requestTaskCompletionByUser = {completeTaskData.requestTaskCompletionByUser}
      abandonTask = {completeTaskData.abandonTask}
      task = {completeTaskData.task}
      />
    </>
    )
  }
  
  const OpenAbanonTask = ()=>{

    const {abandonTaskData} = useContext(UIContext);

    return(
    <>
      <AbandonTask
        disableAbandonWindow = {abandonTaskData.disableAbandonWindow}
        abandonTask = {abandonTaskData.abandonTask}
        taskId = {abandonTaskData.taskId}
      />
    </>
    )
  }

  const OpenUserReview = ()=>{
    
    const {reviewData} = useContext(UIContext);
    return(
    <>
      <ReviewForm 
      listing = {reviewData.listing}
      user = {reviewData.user}
      isMine = {reviewData.isMine}
      reviewAfterConfirmation = {reviewData.reviewAfterConfirmation}
      />
    </>
    )
  }
  
  const OpenUserReport = ()=>{
    
    const {reviewData} = useContext(UIContext);
    return(
    <>
      <ReportUser
      listing = {reviewData.listing}
      user = {reviewData.user}
      isMine = {reviewData.isMine}
      />
    </>
    )
  }

  const [isCopied, setIsCopied] = useState(false);
  window.setIsCopied = setIsCopied;

  const [notificationsAreEnabled, setNotificationsAreEnabled] = useState(false);

  const openNotificationsWindow = ()=>{
    if(!notificationsAreEnabled){
      setNotificationsAreEnabled(true);
    }else{
      setNotificationsAreEnabled(false);
      window.disableNotificationsIcon();
    }
  }
  window.openNotificationsWindow = openNotificationsWindow;

  const OpenImageCrop = ()=>{
    const {imageDataCrop} = useContext(UIContext);



    return(
      <>
      <div className= {styles.imageCropbackground}/>
        <div className = {styles.imageCropPositioning}>
          <ImageCrop image = {imageDataCrop.uploadedPhoto}/>
        </div>
      </>
    )
  }

  
  return(
    <>
      {<Header /> }
      <div className={isMobile ? styles.container : styles.desktopContainer}>
          <ListingsContextProvider>
          <Search />
          {enableSearchOrLocationInput && <SearchAndLocationComponent setEnableSearchOrLocationInput = {setEnableSearchOrLocationInput}/>}

          {(activeComponent == "Profile" || prevActiveComponent == "Profile") &&
          <Profile
          candidateProfilePhoto = {user.profilePhoto}
          profileRenderedFromProfileTab = {true}
          candidateName = {user.name}
          candidateLastName = {user.surname}
          candidateRating = {userProfileInfo.stars}
          candidateTopTasks = {userProfileInfo.topTasks? userProfileInfo.topTasks : 0}
          candidateReviews = {reviews}
          />}
          {(activeComponent == "Account Settings" || prevActiveComponent == "Account Settings") && <AccountSettings />}
          {(activeComponent == "Tasks" || prevActiveComponent == "Tasks") && <Tasks />}
          {creationForm && <Create />}
          {(activeComponent == "Saved" || prevActiveComponent == "Saved") && <Saved />}
          {(activeComponent == "Notifications" || prevActiveComponent == "Notifications") && <Notifications />}
          {!isMobile && notificationsAreEnabled && 
            <div>
              <div className= {styles.accountSettingsWindowBackground} onClick = {openNotificationsWindow} />
              <Notifications />
            </div>
          }
          {(activeComponent == "Applied Tasks" || prevActiveComponent == "Applied Tasks") && <AppliedTasks />}
          
          {/* Mine */}
          {editForm && <EditForm />}
          {openCandidates && <ListingCandidates />}
          {/* Assign */}
          {openUpdateTaskStatus && <UpdateTaskStatus />}
          {openAbandonTask && <OpenAbanonTask />}
          {/* History */}
          {openReviewForm && <OpenUserReview />}
          <OtherProfiles />
          {isCopied && <CopiedShareListing isCopied = {isCopied} setIsCopied = {setIsCopied}/>}
          {(activeComponent == "Messages" || prevActiveComponent == "Messages") && isMobile && <MessagingSystemMobile />}
          {!isMobile && user.id && userPersistenceChecked && <MessagingSystemDesktop />}
          {openReportForm && <OpenUserReport />}
          {showAlternativeLocations && <AlternativeLocations />}
          {isImageCropperVisible && <OpenImageCrop />}
          </ListingsContextProvider>
      </div>
    </>
  )
}

export default MainContent;