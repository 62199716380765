import styles from './SignUp.module.css';
import {useState, useRef, useContext, useEffect} from 'react';
import { signUp, updateUserNameSurname,
updateUserPhoneNumber, updateUserProfilePicture, userId,
userData, accountCreationStep, signIn, signInWithGoogle,
checkIfPhoneNumberExists} from '../../../Firebase';

import x from '../../../assets/images/XBlack.png'
import openEyeIMG from '../../../assets/images/openEyeIMG.png'
import closedEyeIMG from '../../../assets/images/closedEyeIMG.png'
import googleLogo from '../../../assets/images/googleLogo.png'
import appleLogo from '../../../assets/images/LogoTwo.png'
import loadingIcon from '../../../assets/images/loading.png'
import CountryCodes from './CountryCodesWindow';
import sampleProfile from '../../../assets/images/ProfileSample.png'
import addProfilePicture from '../../../assets/images/addProfilePicture.png'
import confirmationIMG from '../../../assets/images/confirmation.png'
import UserContext from '../../../Firebase Contexts/UserDataContext';
import SignUpCrop from '../../Core Components/ImageCrop/SignUpImageCrop';

const SignUp = ()=>{
    const {user, setUserData, setUserPreferredMeasurementSystem, setUserPreferredCountry, setUserSelectedTypes} = useContext(UserContext)

    const [signUpHidden, setSignUpHidden] = useState(true);
    window.signUpHidden = signUpHidden;
    window.setSignUpHidden = setSignUpHidden;

    const isMobile = window.targetPlatform == "mobile";

    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [nameInput, setNameInput] = useState(user.name);
    const [surnameInput, setSurnameInput] = useState(user.surname);
    const [phoneNumberInput, setPhoneNumberInput] = useState("");

    const [isPasswordEyeVisible, setIsPasswordEyeVisible] = useState(true);
    const [signupImageIsLoading, setSignupImageIsLoading] = useState(false);

    const [step, setStep] = useState(1);
    window.setStep = setStep;

    useEffect(() => {
        setNameInput(user.name);
        setSurnameInput(user.surname);
        setImageSrc(user.profilePhoto);
      }, [user]);
      
      useEffect(() => {
        //Auto select input field
        if(step==2){
            nameInputRef.current.select();
        } else if(step==3){
            phoneNumberInputRef.current.select();
        }
      }, [step]);


    const emailContainerRef = useRef(null);
    const phoneNumberContainerRef = useRef(null);

    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const nameInputRef = useRef(null);
    const surnameInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    const phoneNumberCountryCode = useRef(null);
    const normalizedPhoneNumber = useRef("");
    window.phoneNumberCountryCode = phoneNumberCountryCode;

    const guidanceMessageRef = useRef(null);
    const visibilityIMGRef = useRef(null)
    const warningMessageRef = useRef(null)

    const handleSignUpExit = ()=>{
        setStep(1);
        setEmailInput("")
        setPasswordInput("")
        setNameInput("")
        setSurnameInput("")
        setPhoneNumberInput("");
        window.shouldScroll(true);
        setSignUpHidden(true);
        setImageSrc(null);
    }
    window.handleSignUpExit = handleSignUpExit;

    const handleSignIn = ()=>{
        setEmailInput("")
        setPasswordInput("")
        setNameInput("")
        setSurnameInput("")
        setPhoneNumberInput("");
        setStep(1);
        setSignUpHidden(true);
        window.setSignInHidden(false);
    }

    //Changes the title of the sign up text depending on the step
    const handleGuidanceText = ()=>{
        let text;
        if(step==1){
            text = "Let's create your account";
        }else if(step==2){
            text = "What's your name?";
        }else if(step==3){
            text = "What's your phone number?";
        }else if(step==4){
            text = "Add a profile picture";
        }
        return text;
    }
    const handleWarningError = (errorCode) => {
        
        if(errorCode == "auth/invalid-email"){
            warningMessageRef.current.innerText = 'Invalid email';
            emailContainerRef.current.classList.add(styles.errorBorder);
        } else if(errorCode == "auth/email-already-in-use"){
            warningMessageRef.current.innerText = 'An account with this email already exists';
        }else if(errorCode == "Invalid phone number"){
            warningMessageRef.current.innerText = 'Invalid phone number';
            phoneNumberContainerRef.current.classList.add(styles.errorBorder);
        }else if(errorCode == "used phone number"){
            warningMessageRef.current.innerText = 'This phone number has already been used';
            phoneNumberContainerRef.current.classList.add(styles.errorBorder);
        }else if(errorCode=="No error"){
            warningMessageRef.current.classList.remove(styles.warningMessageText);
            warningMessageRef.current.classList.add(styles.warningMessageTextDisabled);
        }else{
            warningMessageRef.current.innerText = 'Something went wrong, please try again';
        }
        if(errorCode!="No error"){
            warningMessageRef.current.classList.remove(styles.warningMessageTextDisabled);
            warningMessageRef.current.classList.add(styles.warningMessageText);
        }
    }
    window.signUphandleWarningError = handleWarningError;
    //#region handle Input Fields

    const handleEmailChange = (event)=>{
        setEmailInput(event.target.value);
    }

    const handlePasswordChange = (event)=>{
        setPasswordInput(event.target.value);
    }

    const handleNameChange = (event)=>{
        
        const newValue = event.target.value;
        const onlyLetters = newValue.replace(/[^a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, '');
        setNameInput(onlyLetters);
    }

    const handleSurnameChange = (event)=>{
        const newValue = event.target.value;
        const onlyLetters = newValue.replace(/[^a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, '');
        setSurnameInput(onlyLetters);
    }
    
    const handlePhoneNumberChange = (event)=>{
        const newValue = event.target.value;
        const onlyNumbersAndSymbols = newValue.replace(/[a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, ''); // Remove letters
        
        setPhoneNumberInput(onlyNumbersAndSymbols);
    }
//#endregion
    
    const handlePasswordVisibility = ()=>{
            if (passwordInputRef.current.type === 'password') {
                passwordInputRef.current.type = 'text';
                visibilityIMGRef.current.src = openEyeIMG;
            } else {
                passwordInputRef.current.type = 'password';
                visibilityIMGRef.current.src = closedEyeIMG;
            }
    }

    //#region Halding input on enter
    const handleOnEnterKey = (event)=>{
    event.preventDefault();
    //if both have a value
    if(handleNextDisabledButton()){
        selectDoneOrNotNowButton();
    }  
    if(step==1){
        if (emailInput!="" && passwordInput=="" && passwordInputRef.current !== document.activeElement){
            passwordInputRef.current.select(); // Focus on password input
        }   else if (emailInput=="" && passwordInput!="" && emailInputRef.current !== document.activeElement){
            emailInputRef.current.select(); // Focus on password input
        }
    }if(step==2){
        if (nameInput!="" && surnameInput=="" && surnameInputRef.current !== document.activeElement){
            surnameInputRef.current.select(); // Focus on password input
        }   else if (nameInput=="" && surnameInput!="" && nameInputRef.current !== document.activeElement){
            nameInputRef.current.select(); // Focus on password input
        }
    }
    }
    //#endregion
    
    //#region Step button styles process
    const nextButtonRef = useRef(true);

    const [disabledButton, setDisabledButton] = useState(false);

    const shouldStepBeDisabled = (disableButton)=>{
        setDisabledButton(disableButton)
        if(disableButton){
            disableNextStep.current = disableButton;
            nextButtonRef.current.classList.remove(styles.nextButton);
            nextButtonRef.current.classList.add(styles.disabledNextButton);
        }else{
            disableNextStep.current = disableButton;
            nextButtonRef.current.classList.remove(styles.disabledNextButton);
            nextButtonRef.current.classList.add(styles.nextButton);
        }
    }
    window.shouldStepBeDisabled = shouldStepBeDisabled;

    //#endregion    
    
    //#region  Button availabilty criteria and execution

    const disableWarnings = (currentInputContainer)=>{
        //remove border styles from the input containers
        if (currentInputContainer.current.classList.contains(styles.errorBorder)) {
            currentInputContainer.current.classList.remove(styles.errorBorder);
        }
        //remove warning message style
        if (warningMessageRef.current.classList.contains(styles.warningMessageText)){
            warningMessageRef.current.classList.remove(styles.warningMessageText);
            warningMessageRef.current.classList.add(styles.warningMessageTextDisabled);
        }
    }

    const disableNextStep = useRef(false);

    const handleNextButton = async() => {
        //Don't allow this code to execute on next click until firebase approves
        if(handleNextDisabledButton() && disableNextStep.current == false){
            //Sets the  theme of the steps to "disabled"
            shouldStepBeDisabled(true);
            if(step==1){
                if (emailInput!="" && passwordInput!=""){
                    disableWarnings(emailContainerRef);
                    initiateDataSaveToFirebase();
                }
                return;
            }
            else if(step==2){
                if (nameInput!="" && surnameInput!=""){
                    initiateDataSaveToFirebase();
                }
                return;
            }
            else if(step==3){
                if(phoneNumberInput!=""){
                    const result = await checkIfPhoneNumberExists(phoneNumberCountryIsoCode.current, phoneNumberInput);

                    if(result.status==false){
                        handleWarningError(result.message);
                        shouldStepBeDisabled(false);
                    }else if(result.status==true){
                        disableWarnings(phoneNumberContainerRef);
                        normalizedPhoneNumber.current = result.message;
                        initiateDataSaveToFirebase();
                    }
                };
                return;
            }
        }
    }

    const handleNextDisabledButton = ()=>{
        let flag;
        
        if(step==1){
            flag = (emailInput!="" && passwordInput.length > 5)? true:false;
        }else if(step==2){
            flag = (nameInput!="" && surnameInput!="")? true:false;
        }else if(step==3){
            flag = (phoneNumberInput!="")? true:false;
        }else if(step==4){
            flag = (imageSrc != null && disableNextStep.current==false)? true:false;
        }
        return flag;
    }
    
    const initiateDataSaveToFirebase = async ()=>{
        if(step==1){
            await signUp(emailInputRef.current.value, passwordInputRef.current.value, setUserData);
        }else if(step==2){
            await updateUserNameSurname(nameInput,surnameInput);
            setUserData(null, nameInput, surnameInput, null, null)
        }else if(step==3){
            await updateUserPhoneNumber(phoneNumberCountry.current, phoneNumberCountryIsoCode.current, normalizedPhoneNumber.current);
            setUserData(null, nameInput, surnameInput, phoneNumberInput, null);
            setUserPreferredCountry({preferredCountry: phoneNumberCountry.current, preferredCountryCode: phoneNumberCountryIsoCode.current});
            setUserPreferredMeasurementSystem((phoneNumberCountryIsoCode.current != "US")?"metric":"miles");
        }
    }
    //#endregion

    //#region Done and Not now buttons handling

    const selectDoneOrNotNowButton = (event) => {
        if (step < 4) {
            handleNextButton();
        } else {
            handleDoneOrNotNowButton(event.target.getAttribute("data-name"));
        }
    };

    //Handles what happens after done or not now press on step 4
    const handleDoneOrNotNowButton = async(buttonPressed)=>{
        //If its the done button and it contains an image and the button hasnt been pressed
        if(buttonPressed=="done" && imageSrc!=null && disableNextStep.current == false){
            //Keep disabled style
            shouldStepBeDisabled(true);
            setSignupImageIsLoading(true);
            const isGoogleProfile = user.profilePhoto == imageSrc?true:false
            const result = await updateUserProfilePicture(imageSrc, isGoogleProfile);
            if(!result){setImageOverSizeLimitErrorIsEnabled(true)}
            setSignupImageIsLoading(false);

            setUserData(null, null, null, null, imageSrc)

            //If its the not now button and it hasn't been pressed
        }else if (buttonPressed=="notNowButton" && disableNextStep.current == false){
            // console.log(buttonPressed + " second")
            shouldStepBeDisabled(true);
            await updateUserProfilePicture(null);
            setUserData(null, null, null, null, sampleProfile)
        }
    }
    //#endregion
    
    //#region Step 3 Phone number
    //Starting selected code
    const phoneNumberCountry = useRef("United States");
    const phoneNumberCountryIsoCode = useRef("US");
    window.phoneNumberCountry = phoneNumberCountry;
    window.phoneNumberCountryIsoCode = phoneNumberCountryIsoCode;

    const handleCountryCodes = ()=>{window.setCountryCodesIsHidden(false)}
    //#endregion
    
    //#region Step 4 Photo Upload

    const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
    window.setIsImageCropperVisible = setIsImageCropperVisible;
    const [initialPhoto, setInitialPhoto] = useState(user.profilePhoto);
    const [imageOverSizeLimitErrorIsEnabled, setImageOverSizeLimitErrorIsEnabled] = useState(false);

    const [imageSrc, setImageSrc] = useState(user.profilePhoto);

    const hiddenFileInputRef = useRef(null);
    const currentImgRef = useRef(null);

    const handleImageClick = () => {
        hiddenFileInputRef.current.click();
    };

    const handleImageUpload = (event) => {
        const selectedImage = event.target.files[0]; // Get the selected image from the input
        const reader = new FileReader();

        reader.onload = (e) => {
            setIsImageCropperVisible(true);
            setImageSrc(e.target.result); // Update the imageSrc state with the selected image data
            setInitialPhoto(e.target.result);
        };

            if (selectedImage) {
                if(selectedImage.size <= 20 * 1024 * 1024){
                    setImageOverSizeLimitErrorIsEnabled(false);
                    reader.readAsDataURL(selectedImage);
            }else{
                setImageOverSizeLimitErrorIsEnabled(true);
            }
        }
    };

    const saveCroppedImage = (croppedImage)=>{
        setImageSrc(croppedImage);
        setIsImageCropperVisible(false);
    }
    window.saveCroppedImage = saveCroppedImage;
    //#endregion

    const googleSignUpButtonIsEnabled = useRef(true);
    const handleSignUpWithGoogle = async() => {
        if(googleSignUpButtonIsEnabled.current){
            const result = await signInWithGoogle(setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, "sign up");
            
            if(result){
                googleSignUpButtonIsEnabled.current = false;
            }else{
                googleSignUpButtonIsEnabled.current = true;
            }
        }
    };

    const editImage = ()=>{
        setIsImageCropperVisible(true);
    }

    const redirectUser = (currentButton) => {
        let listingURL;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            listingURL = "http://localhost:3000/"+currentButton;
        } else {
            listingURL = "https://www.taskare.com/"+currentButton;
        }
        window.open(listingURL);
    };

    return (
        <>
            {!signUpHidden &&
            <div className = {styles.signUpContainer}>
                <div className = {styles.secondSignupContainer}>
                {step == 1 && <img src = {x} className = {styles.exitButton} onClick = { handleSignUpExit } alt = "Exit"/>}
                <p className={styles.signUpText} ref = {guidanceMessageRef}>{handleGuidanceText()}</p>
                {step!="completed"&&<p className={styles.stepsText} ref = {guidanceMessageRef}>Step {step} / 4</p>}

                    <div className={styles.creationContainer}>
                        <div className= {styles.creationForm}>
                            <span className = {styles.inputContainer}>
                            {/*------------- Step One ------------- */}
                                {(step==1) && <p className={styles.guidanceText}
                                ref = {guidanceMessageRef}>Add your email and password</p>}

                                {(step==1) && <span className = {styles.firstInputContainer} ref={emailContainerRef}>
                                    <input
                                    ref = {emailInputRef}
                                    type = 'email'
                                    onChange = {handleEmailChange} 
                                    value = {emailInput}
                                    className = {styles.nameInputfield}
                                    placeholder="Email"
                                    disabled = {disabledButton}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent default form submission
                                            handleOnEnterKey(event);
                                        }
                                    }}
                                    />
                                </span>}
                                {(step==1) && <span className = {styles.warningMessage}>
                                    <p className = {styles.warningMessageTextDisabled} 
                                    ref = {warningMessageRef}>warning message</p>
                                </span>}

                                {(step==1) && (<span className={styles.secondInputContainer}>
                                    <input
                                    ref = {passwordInputRef}
                                    type = "password"
                                    onChange = {handlePasswordChange}
                                    value = {passwordInput}
                                    placeholder="Password"
                                    className = {styles.surnameInputfield}
                                    disabled = {disabledButton}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent default form submission
                                            handleOnEnterKey(event);
                                        }
                                    }}
                                    />
                                    {isPasswordEyeVisible && <img onClick = {handlePasswordVisibility}
                                    src = {closedEyeIMG}
                                    className = {styles.passwordVisibleButton}
                                    ref = {visibilityIMGRef}
                                    alt = "Visibility"
                                    />}
                                </span>)}
                                {/*------------- Step Τwo ------------- */}
                                {(step==2) && <p className={styles.guidanceText} ref = {guidanceMessageRef}>This information will be publicly available</p>}

                                {(step==2) && <span className = {styles.firstInputContainer}>
                                    <input
                                    ref = {nameInputRef}
                                    type = 'text'
                                    onChange = {handleNameChange} 
                                    value = {nameInput}
                                    className = {styles.nameInputfield}
                                    placeholder="Name"
                                    disabled = {disabledButton}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent default form submission
                                            handleOnEnterKey(event);
                                        }
                                    }}
                                    />
                                </span>}

                               {(step==2) && <span className = {styles.warningMessage}>
                                    <p className = {styles.warningMessageTextDisabled} 
                                    ref = {warningMessageRef}>warning message</p>
                                </span>}

                                {(step==2) && (<span className={styles.secondInputContainer}>
                                    <input
                                    ref = {surnameInputRef}
                                    type = "text"
                                    onChange = {handleSurnameChange}
                                    value = {surnameInput}
                                    placeholder="Surname"
                                    className = {styles.surnameInputfield}
                                    disabled = {disabledButton}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent default form submission
                                            handleOnEnterKey(event);
                                        }
                                    }}
                                    />
                                </span>)}
                                {/*------------- Step Three ------------- */}
                                {(step==3) && <p className={styles.guidanceText} ref = {guidanceMessageRef}>Your phone number will stay private and will not be visible to anyone</p>}

                                {(step==3) && (<span className={styles.firstInputContainer} ref={phoneNumberContainerRef}>
                                    <span className= {styles.countryCodeContainer}
                                    onClick = {handleCountryCodes}
                                    ref = {phoneNumberCountryCode}><p className = {styles.countryCode}>+1</p></span>
                                    <input
                                    ref = {phoneNumberInputRef}
                                    type = "tel"
                                    onChange = {handlePhoneNumberChange}
                                    value = {phoneNumberInput}
                                    placeholder="Phone number"
                                    className = {styles.surnameInputfield}
                                    disabled = {disabledButton}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent default form submission
                                            handleOnEnterKey(event);
                                        }
                                    }}
                                    />
                                </span>)}
                                {(step==3) && <span className = {styles.warningMessage}>
                                    <p className = {styles.warningMessageTextDisabled} 
                                    ref = {warningMessageRef}>warning message</p>
                                </span>}
                                <CountryCodes/>
                                {/*------------- Step Four ------------- */}
                                {(step==4) && <p className={styles.guidanceText} ref = {guidanceMessageRef}>Show people who you are</p>}
                                {(step==4) && <p className={styles.profilePictureNote}>*Should not be over 20MB</p>}

                                    {step==4 && 
                                    <div className = {styles.profilePictureContainer}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                            className={styles.hiddenInput}
                                            ref={hiddenFileInputRef}
                                        />
                                        <img
                                            ref = {currentImgRef}
                                            src={(imageSrc)? imageSrc : addProfilePicture}
                                            className={styles.profilePicture}
                                            onClick={handleImageClick}
                                            alt="User Profile Picture"
                                        />
                                        {imageSrc && isImageCropperVisible && <SignUpCrop image = {initialPhoto} />}
                                    </div>}
                                    {(step==4) && imageOverSizeLimitErrorIsEnabled && <p className={styles.errorImageOverSizeLimit}>This image is bigger than 1MB</p>}
                                    {(step==4) && signupImageIsLoading  && <img src = {loadingIcon} className = {styles.loadingIcon}/>}
                                    {(step==4) && initialPhoto && <button onClick = {editImage} className = {styles.editButton}>Edit</button>}

                                {/*------------- Completion ------------- */}
                                {step=="completed" &&
                                <div className = {styles.completionContainer}>
                                    <img className = {styles.confirmationIMG} src = {confirmationIMG} alt = "Confirmation"/>
                                    <p className = {styles.completionText}>Your account has been created</p>
                                    <button
                                        ref = {nextButtonRef}
                                        className={styles.nextButton}
                                        style = {{width: (isMobile?"80%":"100%")}}
                                        onClick = {handleSignUpExit}
                                        >Start exploring
                                    </button>
                                </div>}
                                {/*------------- End ------------- */}

                                {step != "completed" && <button
                                    ref = {nextButtonRef}
                                    className={handleNextDisabledButton() ? styles.nextButton : styles.disabledNextButton}
                                    onClick = {selectDoneOrNotNowButton}
                                    data-name="done"
                                    >{step==4? "Done": "Next"}
                                </button>}
                                {step==4 && <button
                                    ref = {nextButtonRef}
                                    className={styles.disabledNextButton}
                                    onClick = {selectDoneOrNotNowButton}
                                    data-name="notNowButton"
                                    >Not now
                                </button>}
                            </span>
                        </div>


                    {(step==1) && <div className={styles.divider}>
                        <span className={styles.dividerLine}></span>
                        <span className={styles.dividerText}>or</span>
                        <span className={styles.dividerLine}></span>
                    </div>}
                    {(step==1) && <span className={styles.googleAppleContainer}>
                        <button
                            className={styles.googleSignInButton}
                            onClick={handleSignUpWithGoogle}
                            >
                            <img src = {googleLogo}
                            className = {styles.logo}
                            alt = "Google Logo"
                            />Sign up with Google
                        </button>
                    </span>}
                    {(step==1) && <p className = {styles.signInText} onClick = {handleSignIn}>Already have an account? Sign in</p>}
                    {(step==1) && <div className = {styles.termsOfServiceContainer}><p className = {styles.termsOfServiceText}>By signing up, you agree to abide by the <span style = {{color:"rgb(50, 85, 160)"}} onClick={()=>redirectUser("termsofservice")}>Terms of Service</span>.</p></div>}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default SignUp;