import styles from './header.module.css';
import logo from '../../assets/images/LogoTwo.png';

const Header = () => {
  const isMobile = window.targetPlatform == "mobile";

  return (
    <header className={isMobile ? styles.mobileHeader : styles.desktopHeader}>
      {isMobile && window.activeComponent=="Search" &&
      <img
          id = "logoIcon"
          src={logo}
          alt="Taskare Logo"
          className={styles.headerlogo}
          onClick={() => window.location.href = "https://www.taskare.com"}
        />
      }
      {isMobile && <p className={isMobile?styles.category: styles.desktopCategory}>{(window.activeComponent=="Search")?"":window.activeComponent}</p>}
    </header>
  );
};

export default Header;