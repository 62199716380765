import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './AppButtons.module.css';
// Importing Image Assets
import profileIconButtonUnselected from '../../assets/images/ProfileSelectedOutline.png';
import profileIconButtonSelected from '../../assets/images/ProfileSelected.png';
import taskUnselected from '../../assets/images/TaskUnselected.png';
import taskSelected from '../../assets/images/TaskSelected.png'
import magnifierUnselected from '../../assets/images/MagnifierUnselected.png';
import magnifierSelected from '../../assets/images/MagnifierSelected.png';
import notificationUnselected from '../../assets/images/notification outline.png'
import notificationSelected from '../../assets/images/NotificationSelected.png';
import bookmarkUnselected from '../../assets/images/BookmarkOutline.png';
import bookmarkSelected from '../../assets/images/BookmarkSelected.png'
import messages from '../../assets/images/messages.png'
import messagesSelected from '../../assets/images/messagesSelected.png'
import logo from '../../assets/images/LogoTwo.png'
import moon from '../../assets/images/darkThemeIcon.png'
import sun from '../../assets/images/sun.png'
import sampleProfile from '../../assets/images/ProfileSample.png'
import UserContext from '../../Firebase Contexts/UserDataContext';
import AccountSettingsWindow from './AccountSettingsWindow/AccountSettingsWindow';

const AppButtons = () => {
  const {user, setUser, authListenerCompleted, userPersistenceChecked} = useContext(UserContext);
  const isMobile = window.targetPlatform == "mobile";
  const [shouldAppButtonsRerender, setShouldAppButtonsRerender] = useState(()=>{
    const currentURL = window.location.href;
    const isListingLink = (currentURL.includes("task")? false : true);
    return isListingLink;
  });

  const shouldAppButtonsRender = ()=>{
    setShouldAppButtonsRerender(true);
  }

  window.shouldAppButtonsRender = shouldAppButtonsRender;


  const OpenCreationForm = ()=>{
    if(!window.creationForm && user.id){
    window.setCreationForm(true);
    window.shouldScroll(false);
    }else if(user.id==null && authListenerCompleted.current){
      window.setSignInHidden(false);
    }
  }

  window.openCreationForm = OpenCreationForm;

  const handleTheme = ()=>{
    window.setIsLightTheme(!window.isLightTheme);
  }
  /*----------Button styles----------*/

  const prevButtonRef = useRef(null);
  let prevButtonName = "";
  const activeButtonRef = useRef(null);

  const logoButtonRef = useRef(null);
  const searchButtonRef = useRef(null);
  const tasksButtonRef = useRef(null);
  const savedButtonRef = useRef(null);
  const notificationsButtonRef = useRef(null);
  const profileButtonRef = useRef(null);
  const tasksAppliedButtonRef = useRef(null);
  const accountSettingsButtonRef = useRef(null);
  const desktopSignInButtonRef = useRef(null);
  const mobileAccountSettingsButtonRef = useRef(null);
  const mobileSignInButtonRef = useRef(null);
  const notificationsNumberRef = useRef(null);
  const notificationsNumbersContainerRef = useRef(null);
  const messagesNumberRef = useRef(null);
  const messagesNumbersContainerRef = useRef(null);

  const notifyUserOnNewNotifications = (number) =>{
    if(number!=0){
      notificationsNumbersContainerRef.current.classList.remove(styles.hidden);
    }else {
      notificationsNumbersContainerRef.current.classList.add(styles.hidden);
    }
    notificationsNumberRef.current.innerText = number;
  }
  window.notifyUserOnNewNotifications = notifyUserOnNewNotifications;
  
  const notifyUserOnNewMessageMobile = (number) =>{
    // console.log(number);
    if(number!=0){
      messagesNumbersContainerRef.current.classList.remove(styles.hidden);
    }else {
      messagesNumbersContainerRef.current.classList.add(styles.hidden);
    }
    messagesNumberRef.current.innerText = number;
  }
  window.notifyUserOnNewMessageMobile = notifyUserOnNewMessageMobile;

  const handleAccountSettingsOrSignIn = ()=>{
    accountSettingsButtonRef.current.classList.add(styles.selectedButton);
    (user.id)?desktopHandleNavButtonsOnClick('Account Settings', accountSettingsButtonRef):window.setSignInHidden(false);
  }
  
  const handleTasksApplied = ()=>{
    tasksAppliedButtonRef.current.classList.add(styles.selectedButton);
    desktopHandleNavButtonsOnClick('Applied Tasks', tasksAppliedButtonRef);
  }
  
  const handleViewProfile = ()=>{
    profileButtonRef.current.classList.add(styles.selectedButton);
    desktopHandleNavButtonsOnClick('Profile', profileButtonRef);
  }


//#region button logic
  //Set initial button style
  useEffect(()=>{
    if(isMobile){
      mobileHandleNavButtonsOnClick("Search");
    }else {
      desktopHandleNavButtonsOnClick("Search", searchButtonRef);
    }
  },[])

  

let shouldScroll = false;
  
const scrollToTop = (areSameButtons) => {
  if(shouldScroll){
    //if current Button is the same as the previous
    if (areSameButtons){
      //Scroll the body smoothly to top
      window.scrollToTop(true);
      // console.log("Smooth");
    }else {
      //Scroll the body instantly to top
      window.scrollToTop(false);
      // console.log("Instant");
    }
  }
}

  const desktopHandleNavButtonsOnClick = (buttonName, reference) => {
    if(user.id){
      activeButtonRef.current = buttonName;
      const buttonContainer = (prevButtonRef.current=="Notifications")? prevButtonRef.current : reference.current;

      if(buttonName == "Search" && prevButtonRef.current!="Search"){window.unhideSearch();}

      swapImage();
      
      if (buttonContainer != prevButtonRef.current && shouldScroll){
        // If it's different
          scrollToTop(false);
        // console.log("buttons are different");
        prevButtonRef.current.classList.remove(styles.selectedButton);
        prevButtonRef.current.classList.add(styles.deselectedButton);
        }else{
          // If it's the same
            scrollToTop(buttonContainer);
        }
        // Add the selectedButton style to the current button
        buttonContainer.classList.remove(styles.deselectedButton);
        buttonContainer.classList.add(styles.selectedButton);
        // Update the prevButtonRef with the current button container
        prevButtonRef.current = buttonContainer;
        prevButtonName = buttonName;

        window.setActiveComponent(buttonName);

        if(!shouldScroll){
          shouldScroll=true;
        }
    }
  };

  window.desktopHandleNavButtonsOnClick = desktopHandleNavButtonsOnClick;


  const mobileHandleNavButtonsOnClick = (buttonName) => {
    activeButtonRef.current = buttonName;
    //If selection isn't tasks then empty the selectedTask
    if(buttonName !="Tasks"){
      window.selectedTask.current = "";
    }

    swapImage();
    window.swapImage();

    if (buttonName === prevButtonName) {
      // If it's the same button scroll the body smoothly to top
      scrollToTop(true);
    } else {
      // If it's different scroll the body instantly to top
      scrollToTop(false);
    }
    prevButtonName = buttonName;

    if(buttonName!="Search"){
      window.setActiveComponent(buttonName);
    }

    if(!shouldScroll){
      shouldScroll=true;
    }
}
window.mobileHandleNavButtonsOnClick = mobileHandleNavButtonsOnClick;
  
  const swapImage = ()=> {
    if(isMobile){
        if(activeButtonRef.current != prevButtonName){

          if(activeButtonRef.current === "Notifications"){
            document.getElementById('notificationsIcon').src = notificationSelected;
          }else if(activeButtonRef.current === "Messages"){
            document.getElementById('MessagesIcon').src = messagesSelected;
          }
    
        if(prevButtonName === "Notifications"){
            document.getElementById('notificationsIcon').src = notificationUnselected;
          }else if(prevButtonName === "Messages"){
            document.getElementById('MessagesIcon').src = messages;
          }
        }
    }else if(!isMobile){
      if(activeButtonRef.current != prevButtonName){
      if(activeButtonRef.current === "Search"){
        document.getElementById('searchIcon').src = magnifierSelected;
      } else if(activeButtonRef.current === "Notifications"){
        document.getElementById('notificationsIcon').src = notificationSelected;
      } else if(activeButtonRef.current === "Saved"){
        document.getElementById('savedIcon').src = bookmarkSelected;
      } else if(activeButtonRef.current === "Tasks"){
        document.getElementById('tasksIcon').src = taskSelected;
      } /*else if(activeButtonRef.current === "Profile"){
        document.getElementById('profileIcon').src = profileIconButtonSelected;
      }*/
        
        if(prevButtonName === "Search"){
          document.getElementById('searchIcon').src = magnifierUnselected;
        } else if(prevButtonName === "Notifications"){
          document.getElementById('notificationsIcon').src = notificationUnselected;
        } else if(prevButtonName === "Saved"){
          document.getElementById('savedIcon').src = bookmarkUnselected;
        } else if(prevButtonName === "Tasks"){
          document.getElementById('tasksIcon').src = taskUnselected;
        } /*else if(prevButtonName === "Profile"){
          document.getElementById('profileIcon').src = profileIconButtonUnselected;
        }*/
      }
    }
  }

  const desktopCheckIfButtonShouldExecute = (button, buttonRef)=>{
    if (authListenerCompleted.current) {
      if (user.id) {
        desktopHandleNavButtonsOnClick(button, buttonRef);
      } else {
        window.setSignInHidden(false);
      }
    }
}

const mobileCheckIfButtonShouldExecute = (button)=>{
  if (authListenerCompleted.current) {
    if (user.id) {
      mobileHandleNavButtonsOnClick(button);
    } else {
      window.setSignInHidden(false);
    }
  }
}
window.mobileCheckIfButtonShouldExecute = mobileCheckIfButtonShouldExecute;
  //#endregion

//#region Account settings window handling

const accountSettingsWindowRef = useRef(null);

const changeAccountSettingsWindowClass = ()=>{
  window.handleAccountWindowAnimation();
}
  //#endregion


  const DesktopVersion = ()=>{

    const taskSelectionWindowRef = useRef(null);
    const accountSetingsWindowRef = useRef(null);
    const notificationsWindowRef = useRef(null);

    const openAccountSettingsWindow = ()=>{
      if(user.id){
      if(accountSetingsWindowRef.current.classList.contains(styles.hidden)){
        accountSetingsWindowRef.current.classList.remove(styles.hidden);
      }else{
        accountSetingsWindowRef.current.classList.add(styles.hidden);
      }
    }else {
      window.setSignInHidden(false);
    }
    }
    const selectAccountSetting = (tabToOpen)=>{

      if(tabToOpen=="Profile"){
        // desktopCheckIfButtonShouldExecute('Profile', profileButtonRef);
        handleViewProfile('Profile');
      }else if(tabToOpen=="Settings"){
        handleAccountSettingsOrSignIn();
      }else if(tabToOpen=="Applied Tasks"){
        handleTasksApplied();
      }
      accountSetingsWindowRef.current.classList.add(styles.hidden);
    }

    const openNotificationsWindow = ()=>{
      if(notificationsWindowRef.current.classList.contains(styles.hidden)){
        window.openNotificationsWindow();
        document.getElementById('notificationsIcon').src = notificationSelected;
      }else{
        window.openNotificationsWindow();
      }
    }

    const disableNotificationsIcon = ()=>{
      document.getElementById('notificationsIcon').src = notificationUnselected;
    }
    window.disableNotificationsIcon = disableNotificationsIcon;


    const cursorIsHoveringOverTasks = useRef(false);
    const tasksWindowIsOpened = useRef(false);
    const handleMouseEnter = () => {
      cursorIsHoveringOverTasks.current = true;
      // Remove the "hidden" class when the mouse enters
      if(!tasksWindowIsOpened.current){
        tasksWindowIsOpened.current = true;
        taskSelectionWindowRef.current.classList.remove(styles.hidden);
        taskSelectionWindowRef.current.classList.add(styles.taskSelectionWindow);
      }
    };
  
    const handleMouseLeave = () => {
      // Add the "hidden" class when the mouse leaves
      cursorIsHoveringOverTasks.current = false;
      setTimeout(()=>{
        if(!cursorIsHoveringOverTasks.current){
          tasksWindowIsOpened.current = false;
          accountSetingsWindowRef.current.classList.remove(styles.taskSelectionWindow);
          taskSelectionWindowRef.current.classList.add(styles.hidden);
        }
      },[1000])
    };

    const desktopGoToSelectedTask = (chosenTask)=>{
      window.selectedTask.current = chosenTask;
      cursorIsHoveringOverTasks.current = false;
      tasksWindowIsOpened.current = false;
      if(window.activeComponent != "Tasks"){
        desktopCheckIfButtonShouldExecute('Tasks',tasksButtonRef)

      } else if(window.activeComponent == "Tasks"){
        window.showSelectedTasks(window.selectedTask.current);
      }
      taskSelectionWindowRef.current.classList.add(styles.hidden);
    }
    window.desktopGoToSelectedTask = desktopGoToSelectedTask;

    const createTaskLineRef = useRef(null);

    const expandCreationStyleLine = () => {
      createTaskLineRef.current.classList.add(styles.hoveredLine);
    }
    
    const collapseCreationStyleLine = () => {
      createTaskLineRef.current.classList.remove(styles.hoveredLine);
    }

    return(
    <>
      <header className={styles.leftSidenavigationButtonsContainer}>
        <a ref = {logoButtonRef} className={styles.desktopHeaderLogo} href="https://www.taskare.com">
          <img src={logo} alt="Taskare Logo"/>
        </a>
        <section className={styles.createContainer} onClick={OpenCreationForm} onMouseEnter={expandCreationStyleLine} onMouseLeave={collapseCreationStyleLine}>
            <p>Create a new task</p>
            <div className={styles.createTaskLine} ref = {createTaskLineRef}/>
        </section>
      </header>
      
      <header className={styles.rightSidenavigationButtonsContainer}>
      {user.id && <button ref = {searchButtonRef} className = {styles.searchContainer} onClick={() => desktopHandleNavButtonsOnClick('Search' ,searchButtonRef)}>
          <img
            src = {magnifierUnselected}
            id = "searchIcon"
            alt="Search Icon" className={styles.desktopSearchButton}
            />
            <p>Search</p>
        </button>}
        {userPersistenceChecked && user.id && <button ref = {tasksButtonRef} className={`${styles.tasksContainer} ${styles.deselectedButton}`}
        onClick={handleMouseEnter}>
          <img
              id = "tasksIcon"
              src={activeButtonRef === 'Tasks' ? taskSelected : taskUnselected}
              alt="Task Icon"
            />
              <p>Tasks</p>
        </button>}
        {userPersistenceChecked && user.id && <button ref = {savedButtonRef} className={`${styles.savedContainer} ${styles.deselectedButton}`} onClick={() => desktopCheckIfButtonShouldExecute('Saved',savedButtonRef)}>
        <img
            id = "savedIcon"
            src={activeButtonRef === 'Saved' ? bookmarkSelected : bookmarkUnselected}
            alt="Saved Icon"
          />
            <p>Saved</p>
        </button>}

        {userPersistenceChecked && user.id && <button ref = {notificationsButtonRef} className={styles.notificationsContainer} onClick={openNotificationsWindow}>
        <img
            id = "notificationsIcon"
            src={activeButtonRef === 'Notifications' ? notificationSelected : notificationUnselected}
            alt="Notification Icon"
          />
        </button>}

        {userPersistenceChecked && user.id && <div className = {`${styles.notificationNumber} ${styles.hidden}`}
        ref = {notificationsNumbersContainerRef}
        onClick={openNotificationsWindow}>
          <p ref = {notificationsNumberRef}></p>
        </div>}

        {userPersistenceChecked && <div ref = {accountSettingsButtonRef} className = {styles.accountSettingsContainer} onClick={openAccountSettingsWindow}>
          {user.id &&  <img src={(user.profilePhoto != null)?user.profilePhoto:sampleProfile} alt="Settings Icon" />}          
          {!user.id && <button className = {styles.signInTextDesktop}
          ref = {desktopSignInButtonRef}
          >Sign in</button>}
        </div>}
      </header>

      {/* Tasks selection window*/}
      <aside className={styles.hidden} ref = {taskSelectionWindowRef} onMouseLeave={handleMouseLeave} onClick={handleMouseEnter}>
        <p onClick = {()=>desktopGoToSelectedTask("mine")}>Mine</p>
        <p onClick = {()=>desktopGoToSelectedTask("assigned")}>Assigned</p>
        <p onClick = {()=>desktopGoToSelectedTask("history")}>History</p>
      </aside>

      {/* Notifications window*/}
      <aside className = {styles.hidden} ref = {notificationsWindowRef}>
        <div className= {styles.accountSettingsWindowBackground} onClick = {openNotificationsWindow} />
      </aside>

      {/* Account Settings window*/}
      <aside className = {styles.hidden} ref = {accountSetingsWindowRef}>
        <div className= {styles.accountSettingsWindowBackground} onClick = {openAccountSettingsWindow} />
        <div className = {styles.accountSettingsWindow}>
        
          <button ref = {accountSettingsButtonRef} className={styles.accountSettingElement} onClick = {()=>selectAccountSetting("Settings")}>
              <p className={styles.accountSettingsButton} >Account Settings</p>
          </button>
          <button ref = {tasksAppliedButtonRef} className={styles.accountSettingElement} onClick = {()=>selectAccountSetting("Applied Tasks")}>
            <p className={styles.accountSettingsButton} >Tasks Applied</p>
          </button>
        </div>
      </aside>

    </>
    )
  }
  
  const MobileVersion = ()=>{
    return (
      <nav>
       {user.id && <img
          id = "notificationsIcon"
          src={notificationUnselected}
          alt="Notification Icon"
          className={styles.notificationButton}
          onClick={() => mobileCheckIfButtonShouldExecute('Notifications')}
        />}
        {user.id && <img
          id = "MessagesIcon"
          src={messages}
          alt="Messages Icon"
          className={styles.messages}
          onClick={() => mobileCheckIfButtonShouldExecute('Messages')}
        />}
        
        {userPersistenceChecked && user.id && <div className = {`${styles.mobileNotificationNumber} ${styles.hidden}`}
        ref = {notificationsNumbersContainerRef}
        onClick={() => mobileCheckIfButtonShouldExecute('Notifications')}
          ><p ref = {notificationsNumberRef}></p>
        </div>}
        
        {userPersistenceChecked && user.id && <div className = {`${styles.mobileMessagesNumber} ${styles.hidden}`}
        ref = {messagesNumbersContainerRef}
        onClick={() => mobileCheckIfButtonShouldExecute('Messages')}
          ><p ref = {messagesNumberRef}></p>
        </div>}

       {user.id && userPersistenceChecked && <img
          ref = {mobileAccountSettingsButtonRef}
          src={(user.profilePhoto != null)?user.profilePhoto:sampleProfile}
          alt="Settings Icon"
          className={styles.headerSettings}
          onClick={() => changeAccountSettingsWindowClass()}
        />}
        {!user.id && userPersistenceChecked &&
        <div className={styles.signInSignUp}
        ref = {mobileSignInButtonRef}
        onClick = {()=>window.setSignInHidden(false)}
        >
          <p>Sign in</p>
        </div>}
      <div ref = {accountSettingsWindowRef}>
        <AccountSettingsWindow
        mobileHandleNavButtonsOnClick = {mobileHandleNavButtonsOnClick}
        changeAccountSettingsWindowClass = {changeAccountSettingsWindowClass}
        OpenCreationForm = {OpenCreationForm}
        user = {{userProfilePhoto: user.profilePhoto, userName: user.name, userSurname: user.surname}}
        />
      </div>
    </nav>
  )}
  
  return (
    <>
      {isMobile ? <MobileVersion/> : <DesktopVersion />}
    </>
  );
};

export default AppButtons;

