import styles from "./SignIn.module.css";
import {useState, useRef, useEffect, useContext} from 'react';
import { signIn, signInWithGoogle, forgotPassword } from "../../../Firebase";
import closedEyeIMG from '../../../assets/images/closedEyeIMG.png'
import openEyeIMG from '../../../assets/images/openEyeIMG.png'
import x from '../../../assets/images/XBlack.png'
import googleLogo from "../../../assets/images/googleLogo.png";
import appleLogo from "../../../assets/images/LogoTwo.png";
import UserContext from "../../../Firebase Contexts/UserDataContext";
import UIContext from "../../../Firebase Contexts/UIContext/UIContext";

//Sign in renders thourgh App.js
const SignIn = ()=> {

    const {setUserData, setUserPreferredCountry, setUserSelectedTypes, setUserPreferredMeasurementSystem} = useContext(UserContext);

    const isMobile = window.targetPlatform == "mobile";
    const [signInHidden, setSignInHidden] = useState(true);
    window.signInHidden = signInHidden;
    window.setSignInHidden = setSignInHidden;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [disableButton, setDisableButton] = useState(false);
    const [foregroundBlockEnabled, setForegroundBlockEnabled] = useState(false);

    const signInButton = useRef(null);
    const warningMessageRef = useRef(null);
    
    const emailContainerRef = useRef(null);
    const passwordContainerRef = useRef(null);

    const emailInputRef = useRef(null);
    
    const handleSignInExit = ()=>{
        setEmail("");
        setPassword("");
        window.shouldScroll(true);
        window.disableSignIn(false);
        setDisableButton(false);
        setSignInHidden(true);
    }
    window.handleSignInExit = handleSignInExit

    const handleEmailChange = (event)=>{
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event)=>{
        setPassword(event.target.value);
    }

    const handleSignIn = async(event)=>{
        event.preventDefault();
        if(email!="" && password && !disableButton){
            setForegroundBlockEnabled(true);
            //#region remove warnings
            //remove border styles from the input containers
            if (passwordContainerRef.current.classList.contains(styles.errorBorder)) {
                passwordContainerRef.current.classList.remove(styles.errorBorder);
            }
            if (emailContainerRef.current.classList.contains(styles.errorBorder)) {
                emailContainerRef.current.classList.remove(styles.errorBorder);
            }
            //remove warning message style
            if (warningMessageRef.current.classList.contains(styles.warningMessageText)){
                warningMessageRef.current.classList.remove(styles.warningMessageText);
                warningMessageRef.current.classList.add(styles.warningMessageTextDisabled);
            }
            //#endregion

            disableSignIn(true);
            const result = await signIn(email, password, setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes);
            setForegroundBlockEnabled(false);
            
        }
    }

    const handleSignUpMessage = ()=>{
        setEmail("");
        setPassword("");
        //Disable sign in
        setSignInHidden(true);
        //Enable sign up
        window.setSignUpHidden(false);
    }

    //#region Password visibility
    const passwordInputRef = useRef(null);
    const visibilityIMGRef = useRef(null);

    const handlePasswordVisibility = ()=>{
        if (passwordInputRef.current.type === 'password') {
            passwordInputRef.current.type = 'text';
            visibilityIMGRef.current.src = openEyeIMG;
        } else {
            passwordInputRef.current.type = 'password';
            visibilityIMGRef.current.src = closedEyeIMG;
        }
    }
    //#endregion

    //#region warnings
    const handleWarningError = (errorCode, ) => {
        console.log("the error code is " + errorCode);
        console.log(emailContainerRef);
        if(errorCode == "auth/invalid-email"){
            warningMessageRef.current.innerText = 'Invalid email';
            if(emailContainerRef.current!=null){
                emailContainerRef.current.classList.add(styles.errorBorder);
            }else if(forgotPasswordInputContainerRef){
                forgotPasswordInputContainerRef.current.classList.add(styles.errorBorder);
            }
        } else if(errorCode == "auth/network-request-failed"){
            warningMessageRef.current.innerText = 'Something went wrong, please try again';
        }else if(errorCode == "auth/wrong-password"){
            warningMessageRef.current.innerText = 'Wrong password';
            passwordContainerRef.current.classList.add(styles.errorBorder);
        }else if(errorCode == "auth/user-not-found"){
            warningMessageRef.current.innerText = 'This email is not assosiated with an account';
            if(emailContainerRef.current!=null){
                emailContainerRef.current.classList.add(styles.errorBorder);
               }else if(forgotPasswordInputContainerRef){
                forgotPasswordInputContainerRef.current.classList.add(styles.errorBorder);
            }
            }else{
            warningMessageRef.current.innerText = 'Something went wrong, please try again';
        }

        warningMessageRef.current.classList.remove(styles.warningMessageTextDisabled);
        warningMessageRef.current.classList.add(styles.warningMessageText);
    }
    window.signInhandleWarningError = handleWarningError;

    //#endregion
   
    const handleNextDisabledButton = ()=>{
        let flag = false;

        // flag = (email!="" && password!="" || forgotPasswordInput!="")? true:false;

        if(email!="" && password!=""){
            flag = true;
        }else if(forgotPasswordInput!=""){
            flag = true;
        }
        return flag;
    }

    const disableSignIn = (shouldDisable)=>{
        setDisableButton(shouldDisable);

        if(shouldDisable){
            signInButton.current.classList.remove(styles.signInButton);
            signInButton.current.classList.add(styles.disabledSignedInButton);
        }else{
            signInButton.current.classList.remove(styles.disabledSignedInButton);
            signInButton.current.classList.add(styles.signInButton);
        }
    }
    window.disableSignIn = disableSignIn;
    
//#region Automatic input selection

    const handleOnEnterKey = (event)=>{
        event.preventDefault();
        //if both have a value
        if(handleNextDisabledButton()){
            handleSignIn(event);
        }   else if (email!="" && password=="" && passwordInputRef.current !== document.activeElement){
            
            passwordInputRef.current.select(); // Focus on password input
            // passwordContainerRef.current.classList.add(styles.selected);

        }   else if (email=="" && password!="" && emailInputRef.current !== document.activeElement){
            emailInputRef.current.select(); // Focus on password input
            // emailContainerRef.current.classList.add(styles.selected);
        }
    }
    //#endregion

    const googleSignInButtonIsEnabled = useRef(true);

    const handleSignInWithGoogle = async() => {
        if(googleSignInButtonIsEnabled.current){
            setForegroundBlockEnabled(true);
            const result = await signInWithGoogle(setUserData, setUserPreferredCountry, setUserPreferredMeasurementSystem, setUserSelectedTypes, "sign in");
            if(result){
                googleSignInButtonIsEnabled.current = false;
            }else {
                googleSignInButtonIsEnabled.current = true;
            }
            setForegroundBlockEnabled(false);
        }
    };

//#region Forgot password
    const [forgotPasswordInput, setForgotPasswordInput] = useState("");
    const [forgotPasswordIsEnabled, setForgotPasswordIsEnabled] = useState(false);
    const [passwordEmailSent, setPasswordEmailSent] = useState(false);

    const forgotPasswordInputRef = useRef(null);
    const forgotPasswordInputContainerRef = useRef(null);
    const forgotPasswordButtonRef = useRef(null);
    const forgotPasswordCancelButtonRef = useRef(null);

    //Reset the values of forgot my password UI
    const resetForgotPasswordValues = ()=>{
        setForgotPasswordInput("");
        setForgotPasswordIsEnabled(false);
        setPasswordEmailSent(true);
        disableForgotPassword();
    }
    window.resetForgotPasswordValues = resetForgotPasswordValues;

    const handleForgotPasswordChange = (event)=>{
        setForgotPasswordInput(event.target.value);
    }

    //Enable forgot my password UI
     const handleForgotPassword = () => {
        //Reset the input fields when opening password reset
        setEmail("");
        setPassword("");
        //Enable RestPassword UI
        setForgotPasswordIsEnabled(true);
     }

     //Disable forgot my password on cancel button UI
     const handleForgotPasswordCancel = () => {
        if(!disableButton){
            setForgotPasswordInput("")
            setForgotPasswordIsEnabled(false);
        }
     }

     //Check if email should be sent
     const handleForgotPasswordSubmit = () => {
        if(forgotPasswordInput!="" && !disableButton){
            removeForgotPassWordErrors();
            disableForgotPassword(true);
            handleForgotPasswordSendEmail();
        }
     }

    const removeForgotPassWordErrors = ()=>{
        if(forgotPasswordInputContainerRef.current.classList.contains(styles.errorBorder)){
            forgotPasswordInputContainerRef.current.classList.remove(styles.errorBorder);
        } 
        //Remove errors from warning text
        if (warningMessageRef.current.classList.contains(styles.warningMessageText)){
            warningMessageRef.current.classList.remove(styles.warningMessageText);
            warningMessageRef.current.classList.add(styles.warningMessageTextDisabled);
        }
    }
    
    const disableForgotPassword = (shouldDisable)=>{
        setDisableButton(shouldDisable);
        if(shouldDisable){
            forgotPasswordButtonRef.current.classList.remove(styles.forgotPasswordSendEmailButton);
            forgotPasswordButtonRef.current.classList.add(styles.disabledForgotPasswordButton);

            forgotPasswordCancelButtonRef.current.classList.remove(styles.forgotPasswordCancel);
            forgotPasswordCancelButtonRef.current.classList.add(styles.disabledForgotPasswordButton);
        }else{
            forgotPasswordButtonRef.current.classList.remove(styles.disabledForgotPasswordButton);
            forgotPasswordButtonRef.current.classList.add(styles.forgotPasswordSendEmailButton);

            forgotPasswordCancelButtonRef.current.classList.remove(styles.disabledForgotPasswordButton);
            forgotPasswordCancelButtonRef.current.classList.add(styles.forgotPasswordCancel);
        }
    }
    window.disableForgotPassword = disableForgotPassword;

    //Initialize firebase forgotPassword
    const handleForgotPasswordSendEmail = async () => {
        const result = await forgotPassword(forgotPasswordInput);

        if(result.result){
            resetForgotPasswordValues();
        }else {
            window.signInhandleWarningError(result.error);
            disableForgotPassword();
        }
    }

    const handlePasswordResetContinueButton = ()=>{

        setPasswordEmailSent(false);
    }
    //#endregion

    return(
    <>
        {!signInHidden && 
        <div className = {isMobile ? styles.mobileSignContainer : styles.desktopSignContainer}>
            {window.shouldScroll(false)}
            {!forgotPasswordIsEnabled && !passwordEmailSent && <div>
                <div className = {styles.signInForm}>
                <img src = {x} className = {styles.exitButton} onClick = {handleSignInExit} alt = "Exit"/>
                <p className = {styles.welcomeText}>Join Taskare</p>
                <p className={styles.signText}>Sign in</p>

                    <span className = {styles.inputContainer}>
                        <span className = {styles.emailContainer} ref = {emailContainerRef}>
                            <input                        
                            ref = {emailInputRef}
                            type = 'email'
                            onChange = {handleEmailChange}
                            value = {email}
                            className = {styles.emailOrPhoneInputfield}
                            placeholder="Email"
                            disabled = {disableButton}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Prevent default form submission
                                    handleOnEnterKey(event);
                                }
                            }}
                            />
                        </span>

                        <span className = {styles.warningMessage}>
                            <p className = {styles.warningMessageTextDisabled} 
                            ref = {warningMessageRef}>warning message</p>
                        </span>
                        
                        <span className={styles.passwordContainer} ref = {passwordContainerRef}>
                            <input
                            ref = {passwordInputRef}
                            type = "password"
                            onChange = {handlePasswordChange}
                            value = {password}
                            placeholder="Password"
                            className = {styles.passwordInputfield}
                            disabled = {disableButton}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Prevent default form submission
                                    handleOnEnterKey(event);
                                }
                            }}
                            />
                            <img onClick = {handlePasswordVisibility}
                            src = {closedEyeIMG}
                            className = {styles.passwordVisibleButton}
                            ref = {visibilityIMGRef}
                            alt = "Visibility"
                            />
                        </span>
                        <p className = {styles.forgotPassword} onClick={handleForgotPassword}>I forgot my password</p>
                        <button onClick = {handleSignIn} ref = {signInButton}
                            className={handleNextDisabledButton() ? styles.signInButton : styles.disabledSignedInButton}
                            >Sign in
                        </button>
                    </span>
                </div>
                <span className={styles.googleAppleContainer}>
                    <button
                        className={styles.googleSignInButton}
                        onClick={handleSignInWithGoogle}
                        >
                            <img src = {googleLogo}
                            className = {styles.logo}
                            alt = "Google Logo"
                            />Sign in with Google
                        </button>
                        {/* <button
                            className={styles.appleSignInButton}
                            // onClick={signInWithApple}
                            >
                            <img src = {appleLogo}
                            className = {styles.logo}
                            />Sign in with Apple
                        </button> */}
                </span>
                <div className={styles.divider}>
                    <span className={styles.dividerLine}></span>
                    <span className={styles.dividerText}>or</span>
                    <span className={styles.dividerLine}></span>
                </div>

                <p className = {styles.signUpMessage}
                onClick = {handleSignUpMessage}
                >Create an account</p>
            </div>}
            {forgotPasswordIsEnabled && <div className = {styles.forgotPasswordContainer}>
                <p className = {styles.resetPasswordText}>Reset your password</p>
                <div className = {styles.forgotPasswordInputContainer} ref = {forgotPasswordInputContainerRef}>
                    <input                        
                        ref = {forgotPasswordInputRef}
                        type = 'email'
                        onChange = {handleForgotPasswordChange}
                        value = {forgotPasswordInput}
                        className = {styles.forgotPasswordInputfield}
                        placeholder="Enter your email"
                        disabled = {disableButton}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault(); // Prevent default form submission
                                handleForgotPasswordSubmit();
                            }
                        }}
                    />
                </div>
                <p  ref = {warningMessageRef} className = {styles.warningMessageTextDisabled}></p>
                <div className = {styles.forgotPasswordButtons}>
                    <button ref = {forgotPasswordCancelButtonRef} className = {styles.forgotPasswordCancel} onClick = {handleForgotPasswordCancel}>Cancel</button>
                    <button ref = {forgotPasswordButtonRef} className = {styles.forgotPasswordSendEmailButton} onClick = {handleForgotPasswordSubmit}>Send Email</button>
                </div>
            </div>}
            {passwordEmailSent &&
            <div className = {styles.passwordEmailSentContainer}>
                <p className = {styles.passwordSentText}>Password reset email sent. Check your inbox.</p>
                <button className = {styles.forgotPasswordSendEmailButton} onClick = {handlePasswordResetContinueButton}>Continue</button>
            </div>}
        </div>}
        {!signInHidden && foregroundBlockEnabled && <div className= {styles.foregroundBlock}/>}
    </>
    )
}

export default SignIn;