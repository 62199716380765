import styles from './RatedTask.module.css';
import {useState, useEffect} from 'react';
import fullStar from '../../assets/images/FullStar.png';
import emptyStar from '../../assets/images/EmptyStar.png';
import sampleProfile from '../../assets/images/ProfileSample.png';
import StarRating from './StarsRating/StarRating';
import {getUserById} from '../../Firebase';

const RatedTask = ({reviewer, stars, type, date, description, impressions}) => {
  const isMobile = window.targetPlatform =="mobile";

  const [reviewerData, setReviewerData] = useState("");
  const [reviewerLoaded, setReviewerLoaded] = useState(false);

  useEffect(() => {
    const fetchReviewerData = async () => {
      try {
        const data = await getUserById(reviewer); // Wait for the promise to resolve
        setReviewerData(data); // Set the reviewerData state with the resolved data
        setReviewerLoaded(true); // Set reviewerLoaded to true when data is available
      } catch (error) {
        console.error('Error fetching reviewer data:', error);
        setReviewerData(null); // Handle the error by setting reviewerData to null
        setReviewerLoaded(true); // Set reviewerLoaded to true even on error
      }
    };

    fetchReviewerData();
  }, [reviewer]);

  const getDate = ()=>{
    const seconds = date.seconds;
    const nanoseconds = date.nanoseconds;
  
    // Convert nanoseconds to milliseconds (divide by 1,000,000)
    const milliseconds = nanoseconds / 1000000;
  
    // Create a JavaScript Date object
    const modifiedDate = new Date(seconds * 1000 + milliseconds);
    const localizedDateString = modifiedDate.toLocaleDateString();
    
    return localizedDateString;
  }

    return (<>
      {reviewerLoaded && <div className={styles.desktopRatedTaskbodyContainer}>
        <div className={styles.profileDetais}>
          <img src={(reviewerData.profilePicture != null)?reviewerData.profilePicture : sampleProfile} alt="User Profile Picture" className={styles.profilePicture} />
          <div>
            <p className={styles.profileName}>{reviewerData.name} {reviewerData.surname}</p>
            <div className={styles.reviewDetails}>
              <span className = {styles.starRating}><StarRating starRating = {stars}/></span>
              <h1 className={styles.category}>{type}</h1>
              <p className={styles.datesubmited}>{getDate()}</p>
            </div>
          </div>
        </div>
        <p className={styles.description}>
          {description}
        </p>
        <div className={styles.shortratings}>
        {impressions.map((test, index) => {
          if (test !== "") {
            return (
              <p className={styles.shortrating} key={index}>
                {test}
              </p>
            );
          } else {
            // You can decide what to render when test is an empty string
            return null; // or any other component or content you prefer
          }
        })}
        </div>
      </div>}</>
    );
  };
  
  export default RatedTask;