import styles from './ContactUs.module.css'
import { useRef, useState, useEffect } from 'react';
import logo from '../../../assets/images/LogoTwo.png';
import {sendContactMessage} from '../../../Firebase';

const ContactUs = ()=>{

    const isMobile = window.targetPlatform == "mobile";

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [isSubmitEnabled, setSubmitEnabled] = useState(false);
    const [contactMessageSent, setContactMessageSent] = useState(false);

    const submitButtonRef = useRef(null);
    const submitButtonPressed = useRef(false);

    useEffect(() => {
            window.scrollTo({
              top: 0,
              behavior: 'auto'
            });
      }, []);

    useEffect(() => {
      // Check if all fields have values
      const allFieldsFilled =
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        subject.trim() !== '' &&
        message.trim() !== '';
      
      setSubmitEnabled(allFieldsFilled);

      if(submitButtonRef.current && !contactMessageSent){
        if(allFieldsFilled){
          submitButtonRef.current.classList.remove(styles.submitInactive);
          submitButtonRef.current.classList.add(styles.submitActive);
        }else{
          submitButtonRef.current.classList.remove(styles.submitActive);
          submitButtonRef.current.classList.add(styles.submitInactive);
        }
      }
    }, [firstName, lastName, email, subject, message]);
    

    //#region handle fields
    const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
    }
    
    const handleLastNameChange = (event) => {
      setLastName(event.target.value);
    }
    
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    }

    const handleSubjectChange = (event) => {
      setSubject(event.target.value);
    }

    const handleMessageChange = (event) => {
      setMessage(event.target.value);
    }
    //#endregion

    const handleSubmit = async() => {
      if(!isSubmitEnabled || submitButtonPressed.current){return;}

      const result = await sendContactMessage(firstName, lastName, email, subject, message);
      submitButtonPressed.current = true;
      if(result){
        setContactMessageSent(true);
      }
      
    }

    return(
    <div className = {(isMobile)?styles.contentContainerMobile:styles.contentContainerDesktop}>
      <div className = {(isMobile)?styles.logoContainerMobile:styles.logoContainerDesktop} onClick={() => window.location.href = "https://www.taskare.com"}>
          <img src = {logo} className={styles.logo} alt = "Taskare Logo" />
      </div>

      <div className = {styles.test}>
        <p className = {styles.contactUsText}>Contact us</p>
       {!contactMessageSent && <p className = {styles.simpleTextDesktop}>Have a question or need assistance? We're here to help. Please fill out the form below, and we'll get back to you as soon as possible.</p>}
      </div>

      {!contactMessageSent && <div className={(!isMobile)?styles.formContainerDesktop:styles.formContainerMobile}>
        <div className = {styles.nameContainer}>
          <div>
            <p className={styles.textTitle}>First name</p>
            <div className = {styles.nameInputContainer}>
              <input onChange = {handleFirstNameChange} value = {firstName} className = {styles.titleContact} disabled = {submitButtonPressed.current}/>
            </div>
          </div>

          <div style = {{marginRight: "-1.75em"}}>
            <p className={styles.textTitle}>Last name</p>
            <div className = {styles.nameInputContainer}>
              <input onChange = {handleLastNameChange} value = {lastName} className = {styles.titleContact} disabled = {submitButtonPressed.current}/>
            </div>
          </div>
        </div>

        <div>
          <p className={styles.textTitle}>Email</p>
          <div className = {styles.titleContainer}>
            <input onChange = {handleEmailChange} value = {email} className = {styles.titleContact} disabled = {submitButtonPressed.current}/>
          </div>
        </div>
        
        <div>
        <p className={styles.textTitle}>Subject</p>
          <div className = {styles.titleContainer} >
            <input onChange = {handleSubjectChange} value = {subject} className = {styles.titleContact} disabled = {submitButtonPressed.current}/>
          </div>
        </div>
        
        <div>
          <p className={styles.textTitle}>Message</p>
          <div className = {styles.descriptionContainer} >
            <textarea onChange = {handleMessageChange} value = {message} className = {styles.descriptionContact} disabled = {submitButtonPressed.current}/>
          </div>
        </div>
        <p ref = {submitButtonRef} className = {`${styles.submitButton} ${styles.submitInactive}`} onClick = {handleSubmit}>Submit</p>
      </div>}

      {contactMessageSent && <div className = {styles.successContainer}>
        <p className = {styles.successMessage}>Thanks for reaching out! Your message is important to us. We'll review it promptly and get back to you soon. Appreciate your patience! </p>
      </div>}
  </div>
    )
}

export default ContactUs;