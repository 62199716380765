import React from 'react';
import ReactDOM from 'react-dom/client';
import firebase from './Firebase'
import App from './App';
import UserContextProvider from './Firebase Contexts/UserContextProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </>
);