import React, { useRef, useState, useEffect, useLayoutEffect, useContext } from 'react';
import styles from './Tasks.module.css';
import History from '../../Tasks/History/History';
import Mine from '../../Tasks/Mine/Mine';
import Assigned from '../../Tasks/Assigned/Assigned';
import UserContext from '../../../Firebase Contexts/UserDataContext';
import ListingsContext from '../../../Firebase Contexts/Listings Context/ListingsContext';
import { getMineListings, getAssignedTasks, getHistoryListings} from '../../../Firebase';
import goBackImg from '../../../assets/images/Arrow.png'
import UIContext from '../../../Firebase Contexts/UIContext/UIContext';
const MyTasks = () => {
  
  const {user} = useContext(UserContext);
  const {mineListingsLoaded, assignedListingsLoaded, historyListingsLoaded} = useContext(UIContext);

  const isMobile = window.targetPlatform == "mobile";
  
  const {mineListings, setMineListings, assignedTasks, setAssignedTasks,
     historyListings, setHistoryListings} = useContext(ListingsContext);

  const [mineIsExpanded, setMineIsExpanded] = useState(window.selectedTask.current=="mine"?true:false);
  const [assignedIsExpanded, setAssignedIsExpanded] = useState(window.selectedTask.current=="assigned"?true:false);
  const [historyIsExpanded, setHistoryIsExpanded] = useState(window.selectedTask.current=="history"?true:false);

  const mineButtonRef = useRef(null);
  const assignedButtonRef = useRef(null);
  const historyButtonRef = useRef(null);


  useEffect(() => {
    fetchListings();
  }, []);

  const fetchListings = async() => {
    if(window.selectedTask.current == "mine" && !mineListingsLoaded.current){
      await fetchMineListings();
    }else if(window.selectedTask.current == "assigned" && !assignedListingsLoaded.current){
      await fetchAssignedListings();
    }else if(window.selectedTask.current == "history" && !historyListingsLoaded.current){
      await fetchHistoryListings();
    }
  }

  useLayoutEffect(() => {
    // Check if the ref is available before using it
    if (window.selectedTask.current=="mine") {
      // Access and manipulate mineButtonRef
      mineButtonRef.current.classList.add(styles.selectedCategory);
    }
    if (window.selectedTask.current=="assigned") {
      // Access and manipulate assignedButtonRef
      assignedButtonRef.current.classList.add(styles.selectedCategory);
    }
    if (window.selectedTask.current=="history") {
      // Access and manipulate assignedButtonRef
      historyButtonRef.current.classList.add(styles.selectedCategory);
    }
  }, []);

  const showSelectedTasks = async(windowToControl, shouldRefetchData)=>{
    window.selectedTask.current = windowToControl;
    if(windowToControl == "mine" && (!mineIsExpanded || shouldRefetchData)){
      setMineIsExpanded(true);
      setAssignedIsExpanded(false);
      setHistoryIsExpanded(false);

      //Handle button styles
      removeButtonStyles(assignedButtonRef);
      removeButtonStyles(historyButtonRef);
      mineButtonRef.current.classList.add(styles.selectedCategory);
      if(!mineListingsLoaded.current){
        await fetchMineListings();
      }
    } if(windowToControl == "assigned" && (!assignedIsExpanded || shouldRefetchData)){
      setMineIsExpanded(false);
      setAssignedIsExpanded(true);
      setHistoryIsExpanded(false);
      //Handle button styles
      removeButtonStyles(mineButtonRef);
      removeButtonStyles(historyButtonRef);
      assignedButtonRef.current.classList.add(styles.selectedCategory);
      if(!assignedListingsLoaded.current){
        await fetchAssignedListings();
      }
      // console.log(assignedTasks);
    } if(windowToControl == "history" && (!historyIsExpanded || shouldRefetchData)){
      setMineIsExpanded(false);
      setAssignedIsExpanded(false);
      setHistoryIsExpanded(true);
      //Handle button styles
      removeButtonStyles(mineButtonRef);
      removeButtonStyles(assignedButtonRef);
      historyButtonRef.current.classList.add(styles.selectedCategory);
      if(!historyListingsLoaded.current){
        await fetchHistoryListings();
      }
    }
  }

  window.showSelectedTasks = showSelectedTasks;

  const removeButtonStyles = (currentButton)=>{
    if(currentButton.current.classList.contains(styles.selectedCategory)){
      currentButton.current.classList.remove(styles.selectedCategory);
    }
  }


  //#region Load Mine tasks
  //#region Mine listings retrieval

  const fetchMineListings = async () => {
    try {
      const fetchedListings = await getMineListings(user.id);
      setMineListings(fetchedListings); // Set the fetched listings in state
      mineListingsLoaded.current = true;
    } catch (error) {
      // Handle errors if necessary
      console.error('Error fetching listings:', error);
    }
  }
  //#endregion

  //#region Load Assigned tasks Assigned tasks
  const fetchAssignedListings = async () => {
    try {
      const fetchedListings = await getAssignedTasks(user.id);
      setAssignedTasks(fetchedListings); // Set the fetched listings in state
      assignedListingsLoaded.current = true;
    } catch (error) {
      // Handle errors if necessary
      console.error('Error fetching listings:', error);
    }
  }
  //#endregion

  //#region History listings retrieval  
    const fetchHistoryListings = async () => {
      try {
        const fetchedListings = await getHistoryListings(user.id);
        // console.log(fetchedListings);
        setHistoryListings(fetchedListings); // Set the fetched listings in state
        historyListingsLoaded.current = true;
      } catch (error) {
        // Handle errors if necessary
        console.error('Error fetching listings:', error);
      }
    }
    //#endregion

  //#endregion

  const deleteListing = (index) => {
    // Create a new array that filters out undefined elements
    const tempListings = [...mineListings];

    delete tempListings[index];

    // Filter out undefined elements from tempListings
    const filteredListings = tempListings.filter((listing) => listing !== undefined);
        
    setMineListings(filteredListings);
  };

  //#region Tab slide
  const tasksTabRef = useRef(null);
  
  useEffect(()=>{
    if(isMobile){
      tasksTabRef.current.classList.add(styles.slideRight);
    }
    },[])

  const initializeComponentExit = ()=>{
    window.mobileHandleNavButtonsOnClick("Search")
    window.setTypeShouldBeVisible(true);
    window.mobileSetSearchScrollPosition();
    tasksTabRef.current.classList.add(styles.slideLeft);
  }

  const handleAnimEnd = (event)=>{
      if(event.animationName.includes("slideLeft")){
          window.setActiveComponent("Search");
          window.setPrevActiveComponent("Search");
      } else if(event.animationName.includes("slideRight")){
        if(tasksTabRef.current){
          setTimeout(() => {        
            tasksTabRef.current.classList.add(styles.containerAbsolute);
          }, 0);
        }
          window.setPrevActiveComponent("Tasks");
          window.setTypeShouldBeVisible(false);
      } 
  }

  const handleAnimStart = (event) => {
    if(window.prevActiveComponent == "Saved"){
      window.changeSavedIndex();
    } else if(window.prevActiveComponent == "Notifications"){
      window.changeNotificationsIndex();
    } else if(window.prevActiveComponent == "Profile"){
      window.changeProfileIndex();
    }else if(window.prevActiveComponent == "Account Settings"){
      window.changeAccountSettingsIndex();
    }else if(window.prevActiveComponent == "Applied Tasks"){
      window.changeAppliedTasksIndex();
    }else if(window.prevActiveComponent == "Messages"){
      window.changeMessagesIndex();
    }

    if(event.animationName.includes("slideRight")){
      tasksTabRef.current.classList.add(styles.highContainerIndex);
    }else if(event.animationName.includes("slideLeft")){
      tasksTabRef.current.classList.remove(styles.containerAbsolute);
    }
  }

  const changeTasksIndex = () => {
    tasksTabRef.current.classList.remove(styles.highContainerIndex);
    tasksTabRef.current.classList.remove(styles.containerAbsolute);
    tasksTabRef.current.classList.add(styles.lowContainerIndex);
  }
  window.changeTasksIndex = changeTasksIndex;
  //#endregion

  useEffect(()=>{
    if(!isMobile){
        window.hideSearch();
    }
},[])

  return (
    <div ref = {tasksTabRef} onAnimationEnd = {handleAnimEnd} onAnimationStart = {handleAnimStart} className = {isMobile? styles.container: styles.desktopContainer}>
      {isMobile && <img src = {goBackImg} style = {{width: "20px", marginTop:"4em", marginLeft:"1em", transform: "rotate(180deg)"}} onClick = {initializeComponentExit} alt = "Arrow"/>}
      <div className={styles.buttonsContainer}>
        {/*----------Mine----------*/}
        <div ref = {mineButtonRef} className={styles.category} onClick={()=>showSelectedTasks("mine")}>
          <p>Mine</p>
          {mineIsExpanded&&<div className = {styles.selectedButtonLine}/>}
        </div>
        {/*----------Assigned----------*/}
        <div ref = {assignedButtonRef} className={styles.category} onClick={()=>showSelectedTasks("assigned")}>
          <p>Assigned</p>
          {assignedIsExpanded&&<div className = {styles.selectedButtonLine}/>}
        </div>
        {/*----------History----------*/}
        <div ref = {historyButtonRef} className={styles.category} onClick={()=>showSelectedTasks("history")}>
          <p>History</p>
          {historyIsExpanded&&<div className = {styles.selectedButtonLine}/>}
        </div>
      </div>
    {mineIsExpanded &&
      <div className = {isMobile ? styles.mobileTasksLayout : styles.desktopTasksLayout}>
        {mineListings.length==0 && mineListingsLoaded.current &&<p className = {styles.taskSectionIsEmpty}>You haven't published anything so far</p>}
      {mineListings.map((listing, index) => (
      <Mine key={index} index={index} listing={listing} deleteListing = {deleteListing}/> // Provide a unique key and pass the listing as a prop
      ))}
    </div>}
    {/* Assigned is expanded */}
    {assignedIsExpanded &&
        <div className = {isMobile ? styles.mobileTasksLayout : styles.desktopTasksLayout}>
        {assignedTasks.length==0 && assignedListingsLoaded.current &&<p className = {styles.taskSectionIsEmpty}>Your assigned section is empty</p>}
      {assignedTasks.map((task, index) => (
      <Assigned key={index} index={index} task={task}/> // Provide a unique key and pass the listing as a prop
      ))}
    </div>}
    {/* History is expanded */}
    {historyIsExpanded &&
    <div className = {isMobile ? styles.mobileTasksLayout : styles.desktopTasksLayout}>
      {historyListings.length==0 && historyListingsLoaded.current &&<p className = {styles.taskSectionIsEmpty}>Your history section is empty</p>}
      {historyListings.map((listing, index) => (
      <History key={index} listing={listing} user = {user} /> // Provide a unique key and pass the listing as a prop
      ))}
    </div>}
    </div>
  );
};

export default MyTasks;