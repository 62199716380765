import styles from './SearchAndLocationComponent.module.css';
import {useRef, useEffect, useContext, useState} from 'react';
import backArrow from '../../../../../assets/images/Arrow.png';
import x from '../../../../../assets/images/XGrey.png';
import UIContext from '../../../../../Firebase Contexts/UIContext/UIContext';
import SearchContext from '../../../../../Firebase Contexts/SearchContext/SearchContext';
import magnifierIMG from "../../../../../assets/images/MagnifierUnselected.png"
import locationIMG from "../../../../../assets/images/location.png"
import UserContext from '../../../../../Firebase Contexts/UserDataContext';

const SearchAndLocationComponent = () => {

  const {setFiltersIdentifiedBySearch, setNewSearchFilters, setSearchFilters,
         prevLocation, setPrevLocation, locationNotFound,
         setLocationNotFound, setWrongLocationName, mobileSuggestedLocations,
         setMobileSuggestedLocations, typesOfTasks, suggestedLocationAlternative,
         setSuggestedLocationAlternative} = useContext(SearchContext);

  const {searchOrLocation, setSearchOrLocation, newlySelectedCountryIsoCode} = useContext(UIContext);

  const {userPreferredCountry} = useContext(UserContext);

  const isMobile = window.targetPlatform == "mobile";
  //isSearchMode is a flag that holds "search" and "location" strings
  const isSearchMode = searchOrLocation.selectedInput=="search";
  const [searchedValue, setSearchedValue] = useState((isSearchMode)?searchOrLocation.search:searchOrLocation.location);
  window.searchedValue = searchedValue;
  window.setSearchedValue = setSearchedValue;
  const [thereAreSuggestions, setThereAreSuggestions] = useState(false);
  const selectedSuggestionRefs = useRef(Array(mobileSuggestedLocations.length).fill(null)); // Initialize the ref
  const [autocompleteService, setAutocompleteService] = useState(null);
  
  //#region Common functionality for Search and Location
  useEffect(() => {
      // Initialize Google Places Autocomplete service
      if(!isSearchMode){
        setAutocompleteService(new window.google.maps.places.AutocompleteService());
      }

      window.shouldScroll(false);
    }, []);

    useEffect(() => {
      if ((suggestedTypes.length>0 || mobileSuggestedLocations.length>0) && searchedValue!="") {
        setThereAreSuggestions(true);
      } else {
        setThereAreSuggestions(false);
      }
    }, [searchedValue]);

  const parentContainer = useRef(null);
  const containerRef = useRef(null);
  const testInputRef = useRef(null);


  const handleInputChange = (event) => {
      setSearchedValue(event.target.value);
  }

  const handleClearButton = (event)=>{
      setSearchedValue("");
      testInputRef.current.focus();
  }

  const goBack = () => {
      testInputRef.current.blur();
      parentContainer.current.classList.remove(styles.slideTop);
      parentContainer.current.classList.add(styles.slideBottom);
  }

  const handleAnimEnd = (event)=>{
    if(event.animationName.includes("slideTop")){
      window.setTypeShouldBeVisible(false);
      // testInputRef.current.blur();
      testInputRef.current.focus();
      // setSearchedValue("FUCK THIS SHIT")
  }else if(event.animationName.includes("slideBottom")){
          window.setEnableSearchOrLocationInput(false);
          window.shouldScroll(true);
      }
  }

  const handleAnimStart = (event) => {
    if(event.animationName.includes("slideBottom")){
      window.setTypeShouldBeVisible(true);
    }
  }

  const handleLocation = async () => {
  
    const value = searchedValue;
    const onlyLetters = value.replace(/[^a-zA-Z\u00C0-\u02AF\u0370-\u1FFF\u2C60-\uD7FF]/gu, '');

    if (onlyLetters || value === '') {
      setSearchedValue(value);

      if (autocompleteService && value) {
        // Wrap getPlacePredictions in a Promise
        const getPlacePredictionsAsync = () => {
          return new Promise((resolve, reject) => {
            autocompleteService.getPlacePredictions(
              {
                input: value,
                types: ["(cities)"],
                componentRestrictions: { country: newlySelectedCountryIsoCode!=""?newlySelectedCountryIsoCode:userPreferredCountry.preferredCountryCode },
              },
              (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  resolve(predictions);
                } else {
                  resolve([]);
                }
              }
            );
          });
        };

        try {
          const predictions = await getPlacePredictionsAsync();
          setMobileSuggestedLocations(predictions.map((prediction) => prediction));
          return predictions;
          // console.log(predictions)
          // You can add further asynchronous tasks here

        } catch (error) {
          // console.log("Error fetching predictions:", error);
        }
      }
    }
  };
//#endregion

//#region Location functionality

const handleOnEnterKey = async (keyPressed)=>{
  

  keyPressed = (keyPressed!="notEnter")? "enter":"notEnter";

  let selectedSearchInput;
  let updatedSelectedPlace = {description: "", place_id: ""}
  let predictionResults;
  let result;
  if(!isSearchMode){
    predictionResults = await handleLocation();
    result = (predictionResults && predictionResults.length>0)?checkIfInputedPlaceExists(predictionResults):{result: false};
    updatedSelectedPlace = (result.result)? predictionResults[result.selectedIndex] : {description: "", place_id: ""};
  }

  //if this is searching for location
  if(!isSearchMode){
    //if there is a result from searching
    if(result.result) {
      selectedSearchInput = predictionResults[result.selectedIndex];

    } else if(result.result == false){
    }
    setSuggestedLocationAlternative(predictionResults);
  }

  if ((result && result.result) || searchedValue=="" || isSearchMode) {
    setLocationNotFound(false);

    testInputRef.current.blur();
    if(isSearchMode){
      setSearchOrLocation((prev) => ({
        ...prev,
        search: searchedValue
      }));
    }
    else if(!isSearchMode){

      setSearchOrLocation((prev) => ({
        ...prev,
        location: updatedSelectedPlace.description
      }));

      setFiltersIdentifiedBySearch((prev)=>({
          ...prev,
          location: updatedSelectedPlace
      }))

      setSearchFilters((prev)=>({
        ...prev,
        location: updatedSelectedPlace
      }))
    }

    goBack();
  }else if(result.result == false){
    setSearchOrLocation((prev) => ({
      ...prev,
      location: searchedValue
    }));
    setLocationNotFound(true);
    setWrongLocationName(searchedValue);
    setFiltersIdentifiedBySearch((prev)=>({
      ...prev,
      location: updatedSelectedPlace
  }))

  setSearchFilters((prev)=>({
    ...prev,
    location: updatedSelectedPlace
  }))
    goBack();
  }
}

const checkIfInputedPlaceExists = (predictionResults) => {

  const selectedLocation = searchedValue.description
    ? searchedValue.description
    : searchedValue;

  // Regular expression to match words with Unicode support
  const regex = /[\p{L}]+/gu;

  const selectedWords = selectedLocation.match(regex) || [];

  let maxCommonWords = 0; // Keep track of the maximum number of common words found.
  let selectedPrediction = null;
  let matchIndex = -1;

  for (let i = 0; i < predictionResults.length; i++) {
    const suggestedLocation = predictionResults[i];
    const suggestedWords = suggestedLocation.description.match(regex) || [];

    let commonWords = 0;

    for (const selectedWord of selectedWords) {
      const formattedSelectedWord = selectedWord.toLowerCase();

      for (const suggestedWord of suggestedWords) {
        const formattedSuggestedWord = suggestedWord.toLowerCase();

        if (formattedSelectedWord === formattedSuggestedWord) {
          commonWords++;
          break; // Exit the inner loop as we found a match for this selected word.
        }
      }
    }

    // Check if all selectedWords were found in suggestedWords before considering it a match.
    if (commonWords === selectedWords.length && commonWords > maxCommonWords) {
      maxCommonWords = commonWords;
      selectedPrediction = suggestedLocation;
      matchIndex = i;
    }
  }

  if (selectedPrediction) {
    return { result: true, selectedIndex: matchIndex };
  } else {
    return { result: false, selectedIndex: -1 };
  }
}
//#endregion


//#region Search Functionality

const [visibleSuggestions, setVisibleSuggestions] = useState([]);


const suggestedTypes = typesOfTasks.flatMap(category => category.tasks);

useEffect(() => {
  if (isSearchMode && typeof searchedValue === 'string') {
    // Filter and sort the suggested types
    const filteredAndSortedSuggestions = suggestedTypes
      .filter((suggestion) => suggestion.toLowerCase().includes(searchedValue.toLowerCase()))
      .sort((a, b) => {
        // Calculate the index of the typed letters in each suggestion
        const indexA = a.toLowerCase().indexOf(searchedValue.toLowerCase());
        const indexB = b.toLowerCase().indexOf(searchedValue.toLowerCase());

        // Sort by the index (earlier occurrence comes first)
        if (indexA !== indexB) {
          return indexA - indexB;
        }

        // If the index is the same, sort alphabetically
        return a.localeCompare(b);
      });
    setVisibleSuggestions(filteredAndSortedSuggestions);
  }
}, [searchedValue, isSearchMode]);


const handleSearchSuggestionSelection = (index)=>{
  setSearchedValue(visibleSuggestions[index]);
  testInputRef.current.focus();
}
//#endregion
    
return(
    <>
    {/* {console.log(searchOrLocation)} */}
        <div className = {`${styles.container} ${styles.slideTop}`} ref = {parentContainer} onAnimationEnd={handleAnimEnd} onAnimationStart={handleAnimStart}>
            <div style = {{display: "flex", flexDirection:"row", alignItems:"center", marginTop:"1em"}}>
              {isMobile && <img src = {backArrow} onClick = {goBack} style = {{width: "20px", marginLeft:"1em", transform: "rotate(180deg)"}}/>}
                <div ref = {containerRef} className={isMobile ? `${styles.searchContainer} ${styles.searchContainerMobile}`: `${styles.searchContainer} ${styles.searchContainerDesktop}`}>
                  <img src = {(isSearchMode)?magnifierIMG : locationIMG } style = {{width: "20px", paddingLeft: "0.5em"}}/>
                    <input
                        ref={testInputRef}
                        type = "text"
                        onChange={handleInputChange}
                        value={searchedValue && searchedValue.includes(',') ? searchedValue.split(',')[0] : searchedValue}
                        placeholder = {(isSearchMode)?"What?":"Where?"}
                        className= {isMobile ? styles.mobileSearch: styles.desktopSearch}
                        // disabled={isSearching} // Add the disabled attribute
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent default form submission
                              handleOnEnterKey(event);
                            }
                          }}
                    
                    />
                    {searchedValue!="" && <img onClick = {()=>handleClearButton()}
                    src = {x}
                    className = {styles.clearButton}
                    />}
                </div>
            </div>
        {/*<p className = {styles.searchButton} onClick = {handleOnEnterKey}>Search</p>} */}
    {thereAreSuggestions && (
        <div
          className={
            isMobile
              ? styles.mobileOptionsContainer
              : styles.desktopOptionsContainer
          }
        >
            {visibleSuggestions.map((x, index) => (
            <p
              key={index}
              ref={(el) =>
                (selectedSuggestionRefs.current[index] = el)
              } // Store the ref in the array
              className={styles.option}
              onClick={() => {
                handleSearchSuggestionSelection(index);
              }}
            >
              {x}
            </p>
          ))
          }
          </div>
    )}
        </div>
    </>
    )
}

export default SearchAndLocationComponent;