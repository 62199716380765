import SearchBar from "../SearchBar/SearchBar";
import styles from './Search.module.css'
import ListedTasks from "../ListedTasks/ListedTasks";
import Filters from "../Filters/Filters";
import locationIcon from '../../../../assets/images/location.png'
import questionMarkIcon from '../../../../assets/images/questionMarkIcon.png'
import {useState, useRef, useEffect, useContext, useLayoutEffect} from 'react';
import TypesSuggestions from '../TypesSuggestions/TypesSuggestions';
import SearchContext from "../../../../Firebase Contexts/SearchContext/SearchContext";
import SharedListedTask from "../../../Tasks/SharedListedTask/SharedListedTask";
import UserContext from "../../../../Firebase Contexts/UserDataContext";
import ListingsContext from "../../../../Firebase Contexts/Listings Context/ListingsContext";
import SearchStatusMessage from "./SearchStatusMessage/SearchStatusMessage";

const Search = ()=>{

    const {searchFilters, transitioned, setTransitioned, enterPressed, setEnterPressed,
            endedTransition, setEndedTransition, transitionedAlreadyHappened,
            setTransitionedAlreadyHappened, transitionInitiatedFromDesktop,
            typeIsSelected, scrollPosition, isListingLink, locationNotFound,
            currentLocation, searchedListings, suggestedLocationAlternative,
            setSuggestedLocationAlternative, preventMobileExecution} = useContext(SearchContext);
    
    const {user, userLocation, userPersistenceChecked} = useContext(UserContext);
    const {savedListingsLoaded, setDifferentSearchIsLoading, isLoadingForTasks} = useContext(ListingsContext);

    const isMobile = window.targetPlatform == "mobile";

    const [radiusIsOpened, setRadiusIsOpened] = useState(false);
    window.setRadiusIsOpened = setRadiusIsOpened;

    const [selectedType, setSelectedType] = useState("");
    const extraSpaceRef = useRef(null);
    
    const [shouldListedTaskBeVisible, setShouldListedTaskBeVisible] = useState(false);

    const [typeShouldBeVisible, setTypeShouldBeVisible] = useState(isListingLink? false : true);
      
    window.typeShouldBeVisible = typeShouldBeVisible;
    window.setTypeShouldBeVisible = setTypeShouldBeVisible;
    
    const test = ()=>{
        if(userPersistenceChecked && user.id && savedListingsLoaded.current){
            setShouldListedTaskBeVisible(true);
        }else if(userPersistenceChecked && !user.id){
            setShouldListedTaskBeVisible(true);
        }
    }

    useEffect(()=>{
        setTypeShouldBeVisible(!isListingLink);
    },[isListingLink])
    

//#region Check scroll position

    useEffect(() => {
        if(window.activeComponent == "Search" && !isMobile){
            window.scrollTo(0, scrollPosition.current);
        }
        if(isMobile && typeShouldBeVisible){
            window.scrollTo(0, scrollPosition.current);
        }
    });

    const mobileSetSearchScrollPosition = ()=>{
        window.scrollTo(0, scrollPosition.current);
    }
    window.mobileSetSearchScrollPosition = mobileSetSearchScrollPosition;

    const handleScroll = () => {
        if(window.activeComponent == "Search"){
            scrollPosition.current = window.scrollY;
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

//#endregion

    const startSearchTransition = (type)=>{
        if(!transitionedAlreadyHappened){
            typeIsSelected.current = true;
            transitionInitiatedFromDesktop.current = true;
            setSelectedType((type)?type:"");
            setTransitioned(true);
            setTransitionedAlreadyHappened(true);
            extraSpaceRef.current.classList.add(styles.fadeOut);
        }
    }

    const handleEndOfTransition = (event) => {
        if(transitionInitiatedFromDesktop.current){
            setEndedTransition(true);
        }

        if(isMobile && event.animationName.includes("fadeIn")){
            setTransitioned(false);
            setEndedTransition(false);
        }
    }
    //Check if search should be home page or search mode
    useEffect(()=>{
        if(endedTransition && extraSpaceRef.current){
            extraSpaceRef.current.classList.remove(styles.extraSpace);
            extraSpaceRef.current.classList.add(styles.noExtraSpace);
        }
    },[])

    const searchContainerRef = useRef(null)

    const hideSearch = ()=>{
        if(searchContainerRef.current.classList.contains(styles.hidden)==false){
            searchContainerRef.current.classList.add(styles.hidden);
        }
    }

    const unhideSearch = ()=>{
        if(searchContainerRef.current.classList.contains(styles.hidden)){
        searchContainerRef.current.classList.remove(styles.hidden);
        }
    }

    window.hideSearch = hideSearch;
    window.unhideSearch = unhideSearch;

    useEffect(()=>{
        test();
    })
    
    return(
        <div className = {styles.container} ref = {searchContainerRef}>
            {!endedTransition && <div className= {styles.extraSpace} ref = {extraSpaceRef} onAnimationEnd={handleEndOfTransition}/>}
            <div>
                {!transitioned && <h1 className = {(isMobile)?styles.mobileWelcomeText:styles.desktopWelcomeText}><span className = {styles.titleHighlightedFont}>Create</span> a better world, <br/><span className = {styles.titleHighlightedFont}>a task</span> at a time</h1>}
                {!transitioned && <div className={styles.backgroundColor}/>}
                <SearchBar startSearchTransition={startSearchTransition}
                    typeIsSelected = {typeIsSelected.current}
                    setSelectedType = {setSelectedType}
                    enterPressed = {enterPressed}
                />
                {!transitioned &&
                    <div className = {styles.createTaskMessageContainer} style = {{marginLeft:isMobile?"1em":"0em"}}>
                        <p className= {styles.createTaskMessage}>Need a helping hand? <span className={styles.clickHereMessage} onClick = {window.openCreationForm}>Post it here!</span></p>
                    </div>
                }

            </div>

            {typeShouldBeVisible && <TypesSuggestions transitioned = {transitioned} endedTransition = {endedTransition} startSearchTransition={startSearchTransition}/>}
            
            {endedTransition && !isListingLink && !locationNotFound && <Filters selectedType = {selectedType}/>}
            
            {!isMobile && !radiusIsOpened && (userLocation.latitude!=null || currentLocation !="") && <div style = {{height: "4em"}}/>}
            {currentLocation !="" && !locationNotFound && endedTransition && searchedListings.length > 0 && 
            <span className = {styles.grouppedSearchMessages}>
                <p className = {styles.alternativeLocations} style = {{fontSize:"0.9em", fontWeight:"300", color: "rgb(100,100,100)", marginLeft: isMobile ? "1em" : "0em"}}>Available tasks in <span style = {{fontWeight: "bold", color: "rgb(44,44,44)"}}>{currentLocation.split(',')[0].trim()}</span></p>
                {suggestedLocationAlternative!= null && suggestedLocationAlternative.length > 1 && <p className = {styles.alternativeLocations}><span style = {{color: "rgb( 44, 44, 44)", marginLeft: isMobile ? "1em" : "0em"}}>Not the location you're looking for?</span>  {isMobile && <br />} <span style = {{color: "rgb(50, 85, 160)", textDecoration: "underline", cursor:"pointer", marginLeft: isMobile ? "1em" : "0em"}} onClick = {()=>window.setShowAlternativeLocations(true)}>Choose from the alternatives</span></p>}
            </span>
            }

            {!locationNotFound && <ListedTasks />}
            {locationNotFound && endedTransition && <SearchStatusMessage />}
            {isListingLink && userPersistenceChecked && shouldListedTaskBeVisible && <SharedListedTask />}
        </div>
    );
};

export default Search;